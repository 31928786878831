import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { TranslationService } from 'src/app/translation.service';
import { CurrencyPipe, Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TabstateService } from 'src/app/tabstate.service';
import { DropdownstateService } from 'src/app/dropdownstate.service';
const ADMIN_URL = environment.adminUrl;

interface ProductResponse {
  products: any;
  currentPage: number;
  totalPages: number;
}

interface ServiceResponse {
  services: any;
  currentPage: number;
  totalPages: number;
}

interface SellerResponse {
  sellers: any;
  currentPage: number;
  totalPages: number;
}

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css'],
})
export class ProductsListComponent implements OnInit {
  activeTab: string; // Variable to track the active tab

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private tabService: TabstateService,
    public dropdownService: DropdownstateService,
    private location: Location
  ) {}
  switchLanguage(event: any) {
    const language = event.target.value;
    this.translationService.switchLanguage(language);
  }
  // Function to go back to the previous page
  goBack() {
    // Navigate back in the router history
    this.location.back();
  }
// -----------kitchen----------------
tiffin() {
  // Add your logic here if needed
  this.router.navigate(['/cloud-kitchen'],{ 
    queryParams: { param1: 'ID_TIF' } });
    console.log("testttttt")
}
canteen(){
  this.router.navigate(['/cloud-kitchen'],{
    queryParams: { param2: 'ID_CAN' }});
  
  console.log("testttttt11111");
}
pink(){
  this.router.navigate(['/cloud-kitchen'],{queryParams: { param3: 'ID_CAF' }});
  console.log("testttttt2222222");
}






// --------kitchen---------------




  //For services dropdown state retension
  setServiceStatusDropdownHeading(heading: string) {
    this.dropdownService.serviceStatusDropdownHeading = heading;
  }
  setServiceapprovalStatusDropdownHeading(heading: any) {
    this.dropdownService.serviceApprovalStatusDropdownHeading = heading;
  }
  setServiceCategoryDropdownHeading(heading: string) {
    if (heading === 'All') {
      this.dropdownService.serviceCategoryDropdownHeading = heading;
      this.dropdownService.serviceCategoryDropdown = 'All';
    } else if (heading === 'Farm Services') {
      this.dropdownService.serviceCategoryDropdownHeading = heading;
      this.dropdownService.serviceCategoryDropdown = 'ID_FRM';
    } else if (heading === 'Skilled Services') {
      this.dropdownService.serviceCategoryDropdownHeading = heading;
      this.dropdownService.serviceCategoryDropdown = 'ID_SKL';
    } else if (heading === 'Local Events') {
      this.dropdownService.serviceCategoryDropdownHeading = heading;
      this.dropdownService.serviceCategoryDropdown = 'ID_LLE';
    } else if (heading === 'Other Services') {
      this.dropdownService.serviceCategoryDropdownHeading = heading;
      this.dropdownService.serviceCategoryDropdown = 'ID_OTH';
    }
  }

  //For products dropdown state retension
  setProductStatusDropdownHeading(heading: string): void {
    this.dropdownService.productStatusDropdownHeading = heading;
  }
  setProductapprovalStatusDropdownHeading(heading: any): void {
    this.dropdownService.productApprovalStatusDropdownHeading = heading;
  }
  setProductCategoryDropdownHeading(heading: string): void {
    this.dropdownService.productSubCategoryDropdownHeading = 'All';
    this.dropdownService.productSubCategoryDropdown = 'All';

    this.dropdownService.productCategoryDropdownHeading = heading;
    if (heading === 'All') {
      this.selectedSubCategory = [];
    } else if (heading === 'Farm Produce') {
      this.selectedSubCategory = this.FarmProduceSubCategories;
    } else if (heading === 'Home Produce') {
      this.selectedSubCategory = this.HomeProduceSubCategories;
    } else if (heading === 'Garden') {
      this.selectedSubCategory = this.GardenSubCategories;
    } else if (heading === 'Pets') {
      this.selectedSubCategory = this.PetsSubCategories;
    } else if (heading === 'Livestocks') {
      this.selectedSubCategory = this.LivestocksSubCategories;
    } else if (heading === 'Used Items') {
      this.selectedSubCategory = this.UsedItemsSubCategories;
    }
  }
  setProductSubCategoryDropdownHeading(subCategory: any): void {
    switch (subCategory) {
      case 'ID_FNV':
        this.dropdownService.productSubCategoryDropdownHeading =
          'Fruits and Vegetables';
        this.dropdownService.productSubCategoryDropdown = 'ID_FNV';
        break;
      case 'ID_EGG':
        this.dropdownService.productSubCategoryDropdownHeading = 'Eggs';
        this.dropdownService.productSubCategoryDropdown = 'ID_EGG';
        break;
      case 'ID_FNM':
        this.dropdownService.productSubCategoryDropdownHeading = 'Fish & Meat';
        this.dropdownService.productSubCategoryDropdown = 'ID_FNM';
        break;
      case 'ID_SAO':
        this.dropdownService.productSubCategoryDropdownHeading =
          'Spices and Oils';
        this.dropdownService.productSubCategoryDropdown = 'ID_SAO';
        break;
      case 'ID_PUS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Pulses';
        this.dropdownService.productSubCategoryDropdown = 'ID_PUS';
        break;
      case 'ID_CRL':
        this.dropdownService.productSubCategoryDropdownHeading = 'Cereals';
        this.dropdownService.productSubCategoryDropdown = 'ID_CRL';
        break;
      case 'ID_FPO':
        this.dropdownService.productSubCategoryDropdownHeading = 'Others';
        this.dropdownService.productSubCategoryDropdown = 'ID_FPO';
        break;
      case 'ID_CNB':
        this.dropdownService.productSubCategoryDropdownHeading =
          'Cakes and Bakes';
        this.dropdownService.productSubCategoryDropdown = 'ID_CNB';
        break;
      case 'ID_CNS':
        this.dropdownService.productSubCategoryDropdownHeading =
          'Chips and Snacks';
        this.dropdownService.productSubCategoryDropdown = 'ID_CNS';
        break;
      case 'ID_RNB':
        this.dropdownService.productSubCategoryDropdownHeading = 'Meals';
        this.dropdownService.productSubCategoryDropdown = 'ID_RNB';
        break;
      case 'ID_HYS':
        this.dropdownService.productSubCategoryDropdownHeading =
          'Healthy Snacks';
        this.dropdownService.productSubCategoryDropdown = 'ID_HYS';
        break;
      case 'ID_PKS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Pickles';
        this.dropdownService.productSubCategoryDropdown = 'ID_PKS';
        break;
      case 'ID_HMH':
        this.dropdownService.productSubCategoryDropdownHeading = 'Handmades';
        this.dropdownService.productSubCategoryDropdown = 'ID_HMH';
        break;
      case 'ID_HPB':
        this.dropdownService.productSubCategoryDropdownHeading =
          'Beauty Products';
        this.dropdownService.productSubCategoryDropdown = 'ID_HPB';
        break;
      case 'ID_OHS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Others';
        this.dropdownService.productSubCategoryDropdown = 'ID_OHS';
        break;
      case 'ID_PLS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Plants';
        this.dropdownService.productSubCategoryDropdown = 'ID_PLS';
        break;
      case 'ID_SES':
        this.dropdownService.productSubCategoryDropdownHeading = 'Seeds';
        this.dropdownService.productSubCategoryDropdown = 'ID_SES';
        break;
      case 'ID_NUY':
        this.dropdownService.productSubCategoryDropdownHeading = 'Nursery';
        this.dropdownService.productSubCategoryDropdown = 'ID_NUY';
        break;
      case 'ID_OTS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Others';
        this.dropdownService.productSubCategoryDropdown = 'ID_OTS';
        break;
      case 'ID_DGS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Dogs';
        this.dropdownService.productSubCategoryDropdown = 'ID_DGS';
        break;
      case 'ID_CTS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Cats';
        this.dropdownService.productSubCategoryDropdown = 'ID_CTS';
        break;
      case 'ID_BDS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Birds';
        this.dropdownService.productSubCategoryDropdown = 'ID_BDS';
        break;
      case 'ID_OLF':
        this.dropdownService.productSubCategoryDropdownHeading =
          'Ornamental fish';
        this.dropdownService.productSubCategoryDropdown = 'ID_OLF';
        break;
      case 'ID_OES':
        this.dropdownService.productSubCategoryDropdownHeading = 'Others';
        this.dropdownService.productSubCategoryDropdown = 'ID_OES';
        break;
      case 'ID_CAE':
        this.dropdownService.productSubCategoryDropdownHeading = 'Cattle';
        this.dropdownService.productSubCategoryDropdown = 'ID_CAE';
        break;
      case 'ID_GNS':
        this.dropdownService.productSubCategoryDropdownHeading =
          'Goats and Sheep';
        this.dropdownService.productSubCategoryDropdown = 'ID_GNS';
        break;
      case 'ID_HES':
        this.dropdownService.productSubCategoryDropdownHeading = 'Poultry';
        this.dropdownService.productSubCategoryDropdown = 'ID_HES';
        break;
      case 'ID_ORS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Others';
        this.dropdownService.productSubCategoryDropdown = 'ID_ORS';
        break;
      case 'ID_HEA':
        this.dropdownService.productSubCategoryDropdownHeading =
          'Home appliance';
        this.dropdownService.productSubCategoryDropdown = 'ID_HEA';
        break;
      case 'ID_ELC':
        this.dropdownService.productSubCategoryDropdownHeading = 'Electronics';
        this.dropdownService.productSubCategoryDropdown = 'ID_ELC';
        break;
      case 'ID_AUS':
        this.dropdownService.productSubCategoryDropdownHeading = 'Autos';
        this.dropdownService.productSubCategoryDropdown = 'ID_AUS';
        break;
      case 'ID_UIB':
        this.dropdownService.productSubCategoryDropdownHeading = 'Books';
        this.dropdownService.productSubCategoryDropdown = 'ID_UIB';
        break;
      case 'ID_OHE':
        this.dropdownService.productSubCategoryDropdownHeading = 'Others';
        this.dropdownService.productSubCategoryDropdown = 'ID_OHE';
        break;
      default:
        this.dropdownService.productSubCategoryDropdownHeading = 'All';
        this.dropdownService.productSubCategoryDropdown = 'All';
    }
  }
  setActiveTab(tab: string): void {
    this.activeTab = tab;
    this.tabService.selectedTab = tab; // Update the service with the new selection
  }
  ngOnInit() {
    // Set the active tab based on the stored value
    this.activeTab = this.tabService.selectedTab;
    // Call the fetchProduct() method here
    this.authUser();
    if (this.activeTab === 'products') {
      this.fetchProducts();
    } else if (this.activeTab === 'services') {
      this.fetchServices();
    } else if (this.activeTab === 'sellers') {
      this.fetchSeller();
    }
  }

  //PRODUCT PAGINATION
  calculateIndexProduct(i: number): number {
    return (this.productCurrentPage - 1) * 15 + i + 1;
  }
  productCurrentPage = this.dropdownService.productCurrentPage || 1;
  productTotalPages = 0;
  productPages: number[] = [];
  productStartPage: number = this.dropdownService.productStartPage;
  productEndPage: number = this.dropdownService.productEndPage;
  productPrevPage(): void {
    if (this.productCurrentPage > 1) {
      this.dropdownService.productStartPage--;
      this.dropdownService.productEndPage--;
      this.productStartPage = this.dropdownService.productStartPage;
      this.productEndPage = this.dropdownService.productEndPage;
      this.productCurrentPage--;
      this.fetchProducts();
    }
  }
  productGoToPage(page: number): void {
    this.dropdownService.productCurrentPage = page;
    this.productCurrentPage = page;
    this.fetchProducts();
    console.log(this.productStartPage);
  }
  productNextPage(): void {
    console.log('working');
    if (this.productCurrentPage < this.productTotalPages) {
      this.dropdownService.productStartPage++;
      this.dropdownService.productEndPage++;
      this.productStartPage = this.dropdownService.productStartPage;
      this.productEndPage = this.dropdownService.productEndPage;
      this.productCurrentPage++;
      this.fetchProducts();
    }
  }

  //SERVICE PAGINATION
  calculateIndexService(i: number): number {
    return (this.serviceCurrentPage - 1) * 15 + i + 1;
  }
  serviceCurrentPage = this.dropdownService.serviceCurrentPage || 1;
  serviceTotalPages = 0;
  servicePages: number[] = [];
  serviceStartPage: number = this.dropdownService.serviceStartPage;
  serviceEndPage: number = this.dropdownService.serviceEndPage;
  servicePrevPage(): void {
    if (this.serviceCurrentPage > 1) {
      this.dropdownService.serviceStartPage--;
      this.dropdownService.serviceEndPage--;
      this.serviceStartPage = this.dropdownService.serviceStartPage;
      this.serviceEndPage = this.dropdownService.serviceEndPage;
      this.serviceCurrentPage--;
      this.fetchServices();
    }
  }
  serviceGoToPage(page: number): void {
    this.dropdownService.serviceCurrentPage = page;
    this.serviceCurrentPage = page;
    this.fetchServices();
  }
  serviceNextPage(): void {
    console.log('working');
    if (this.serviceCurrentPage < this.serviceTotalPages) {
      this.dropdownService.serviceStartPage++;
      this.dropdownService.serviceEndPage++;
      this.serviceStartPage = this.dropdownService.serviceStartPage;
      this.serviceEndPage = this.dropdownService.serviceEndPage;
      this.serviceCurrentPage++;
      this.fetchServices();
    }
  }

  //SELLER PAGINATION
  calculateIndexSeller(i: number): number {
    return (this.sellerCurrentPage - 1) * 15 + i + 1;
  }
  sellerCurrentPage = this.dropdownService.sellerCurrentPage || 1;
  sellerTotalPages = 0;
  sellerPages: number[] = [];
  sellerStartPage: number = this.dropdownService.sellerStartPage;
  sellerEndPage: number = this.dropdownService.sellerEndPage;
  sellerPrevPage(): void {
    console.log(this.sellerStartPage);

    if (this.sellerCurrentPage > 1) {
      this.dropdownService.sellerStartPage--;
      this.dropdownService.sellerEndPage--;
      this.sellerStartPage = this.dropdownService.sellerStartPage;
      this.sellerEndPage = this.dropdownService.sellerEndPage;
      this.sellerCurrentPage--;
      this.fetchSeller();
    }
  }
  sellerGoToPage(page: number): void {
    console.log(this.sellerStartPage);
    this.dropdownService.sellerCurrentPage = page;
    this.sellerCurrentPage = page;
    this.fetchSeller();
  }
  sellerNextPage(): void {
    console.log('working');
    if (this.sellerCurrentPage < this.sellerTotalPages) {
      this.dropdownService.sellerStartPage++;
      this.dropdownService.sellerEndPage++;
      this.sellerStartPage = this.dropdownService.sellerStartPage;
      this.sellerEndPage = this.dropdownService.sellerEndPage;
      this.sellerCurrentPage++;
      this.fetchSeller();
    }
  }

  searchInput: string;

  onLogout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
  onAdminClick() {
    this.router.navigate(['/dashboard']);
  }
  onSettings() {
    // Perform login logic here
    console.log('hi');
    // Redirect to the admin page
    this.router.navigate(['/settings']);
  }
  getAnalytics() {
    // Perform login logic here
    console.log('hi');
    // Redirect to the admin page
    this.router.navigate(['/analytics']);
  }

  goToCloudKitchen() {
    // Perform login logic here
    console.log('hi');
    // Redirect to the admin page
    this.router.navigate(['/cloud-kitchen']);
  }

  searchSeller() {
    // Perform login logic here
    console.log('hi');
    // Redirect to the admin page
    this.router.navigate(['/search-seller']);
  }
  goToSellerProfile(sellerId: string) {
    this.router.navigate(['/seller-details'], {
      queryParams: {
        sellerId: sellerId,
      },
    });
  }
  editProduct(productId: string) {
    // Navigate to the edit product page using the product ID
    console.log('edit', productId);
    this.router.navigate(['/edit-product'], {
      queryParams: {
        productId: productId,
      },
    });
  }
  editService(serviceId: string) {
    console.log('edit', serviceId);
    this.router.navigate(['/edit-service'], {
      queryParams: {
        serviceId: serviceId,
      },
    });
  }

  authUser() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    this.http.get(ADMIN_URL + '/verifyToken', { headers }).subscribe(
      (res) => {
        console.log('User is authenticated');
        if ((res as any).message === 'Invalid token') {
          this.router.navigate(['/login']);
        }
      },
      (err) => {
        // Token is invalid, redirect to the login page
        this.router.navigate(['/login']);
      }
    );
  }

  sellersLists: any[] = [];
  productLists: any[] = [];
  serviceLists: any[] = [];
  selectedSubCategory: any[] = [];
  subcategoryName: any;
  subCategorySwitch(item: any) {
    switch (item) {
      case 'ID_FNV':
        return 'Fruits and Vegetables';
      // Add more cases as needed
      case 'ID_EGG':
        return 'Eggs';
      case 'ID_FNM':
        return 'Fish & Meat';
      case 'ID_SAO':
        return 'Spices and Oils';
      case 'ID_PUS':
        return 'Pulses';
      case 'ID_CRL':
        return 'Cereals';
      case 'ID_FPO':
        return 'Others';
      case 'ID_CNB':
        return 'Cakes and Bakes';
      case 'ID_CNS':
        return 'Chips and Snacks';
      case 'ID_RNB':
        return 'Meals';
      case 'ID_HYS':
        return 'Healthy Snacks';
      case 'ID_PKS':
        return 'Pickles';
      case 'ID_HMH':
        return 'Handmades';
      case 'ID_HPB':
        return 'Beauty Products';
      case 'ID_OHS':
        return 'Others';
      case 'ID_PLS':
        return 'Plants';
      case 'ID_SES':
        return 'Seeds';
      case 'ID_NUY':
        return 'Nursery';
      case 'ID_OTS':
        return 'Others';
      case 'ID_DGS':
        return 'Dogs';
      case 'ID_CTS':
        return 'Cats';
      case 'ID_BDS':
        return 'Birds';
      case 'ID_OLF':
        return 'Ornamental fish';
      case 'ID_OES':
        return 'Others';
      case 'ID_CAE':
        return 'Cattle';
      case 'ID_GNS':
        return 'Goats and Sheep';
      case 'ID_HES':
        return 'Poultry';
      case 'ID_ORS':
        return 'Others';
      case 'ID_HEA':
        return 'Home appliance';
      case 'ID_ELC':
        return 'Electronics';
      case 'ID_AUS':
        return 'Autos';
      case 'ID_UIB':
        return 'Books';
      case 'ID_OHE':
        return 'Others';
      default:
        return 'All';
    }
  }
  FarmProduceSubCategories: any[] = [
    'ID_FNV',
    'ID_EGG',
    'ID_FNM',
    'ID_SAO',
    'ID_PUS',
    'ID_CRL',
    'ID_FPO',
  ];
  HomeProduceSubCategories: any[] = [
    'ID_CNB',
    'ID_CNS',
    'ID_RNB',

    'ID_HYS',

    'ID_PKS',
    'ID_HMH',
    'ID_HPB',
    'ID_OHS',
  ];
  GardenSubCategories: any[] = ['ID_PLS', 'ID_SES', 'ID_NUY', 'ID_OTS'];
  PetsSubCategories: any[] = ['ID_DGS', 'ID_CTS', 'ID_BDS', 'ID_OLF', 'ID_OES'];
  LivestocksSubCategories: any[] = ['ID_CAE', 'ID_GNS', 'ID_HES', 'ID_ORS'];
  UsedItemsSubCategories: any[] = [
    'ID_HEA',
    'ID_ELC',
    'ID_AUS',
    'ID_UIB',
    'ID_OHE',
  ];

  approvalDropdownHeading: string;
  serviceApprovalDropdownHeading: string = 'Approval Status';
  categoryDropdownHeading: string = 'Category';
  subCategoryDropdownHeading: string = 'Sub Category';
  statusDropdownHeading: string = 'Status';
  isApproved: any = 'Approval Status';

  //services dropdown
  categoryServiceDropdownHeading: string = 'Category';

  productStatus: string = 'Status';
  productCategory: string = 'Sub Category';
  serviceStatus: string = 'Status';
  serviceCategory: string = 'Category';
  isApprovedServiceStatus: any = 'Approval Status';
  serviceStatusDropdownHeading: string = 'Status';

  searchProducts() {
    this.dropdownService.productCurrentPage=1
    this.productCurrentPage = 1;
    this.fetchProducts();
  }
  searchServices() {
    this.dropdownService.serviceCurrentPage=1
    this.serviceCurrentPage = 1;
    this.fetchServices();
  }

  fetchProducts() {
    console.log(this.productStatus, this.productCategory);
    const params = new HttpParams()
      // .set('approvalStatus', this.approvalStatus)
      .set('page', this.productCurrentPage.toString()) // Pass the current page number
      .set('productCategory', this.dropdownService.productSubCategoryDropdown)
      .set('productStatus', this.dropdownService.productStatusDropdownHeading)
      .set(
        'isApproved',
        this.dropdownService.productApprovalStatusDropdownHeading
      );

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });

    this.http
      .get<ProductResponse>(ADMIN_URL + '/products', { params, headers })
      .subscribe(
        (response) => {
          console.log(response);
          this.productLists = response.products;
          this.productCurrentPage = response.currentPage;
          this.productTotalPages = response.totalPages;
          this.productPages = Array.from(
            { length: this.productTotalPages },
            (_, index) => index + 1
          );
        },
        (error) => {
          console.error('Error:', error);
        }
      );
  }

  fetchServices() {
    console.log('services clicked');
    // console.log(this.productStatus,this.productCategory)
    const params = new HttpParams()
      // .set('approvalStatus', this.approvalStatus)
      .set('page', this.serviceCurrentPage.toString()) // Pass the current page number
      .set('serviceCategory', this.dropdownService.serviceCategoryDropdown)
      .set('serviceStatus', this.dropdownService.serviceStatusDropdownHeading)
      .set(
        'isApprovedServiceStatus',
        this.dropdownService.serviceApprovalStatusDropdownHeading
      );

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });

    this.http
      .get<ServiceResponse>(ADMIN_URL + '/services', { params, headers })
      .subscribe(
        (response) => {
          console.log(response);
          this.serviceLists = response.services;
          this.serviceCurrentPage = response.currentPage;
          this.serviceTotalPages = response.totalPages;
          this.servicePages = Array.from(
            { length: this.serviceTotalPages },
            (_, index) => index + 1
          );
        },
        (error) => {
          console.error('Error:', error);
        }
      );
  }

  fetchSeller() {
    console.log('services clicked');
    // console.log(this.productStatus,this.productCategory)
    const params = new HttpParams().set(
      'page',
      this.sellerCurrentPage.toString()
    ); // Pass the current page number
    // .set('approvalStatus', this.approvalStatus)
    //.set('serviceCategory', this.serviceCategory)
    //.set('serviceStatus', this.serviceStatus)
    //.set('isApprovedServiceStatus', this.isApprovedServiceStatus);

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });

    this.http
      .get<SellerResponse>(ADMIN_URL + '/seller', { params, headers })
      .subscribe(
        (response) => {
          console.log(response);
          this.sellersLists = response.sellers;
          this.sellersLists.forEach((seller) => {
            this.fetchSellerPostCount(seller.profileID).subscribe(
              (postCount: any) => {
                seller.postCount = postCount.totalCount; // Store the post count for each seller
              }
            );
          });
          this.sellerCurrentPage = response.currentPage;
          this.sellerTotalPages = response.totalPages;
          this.sellerPages = Array.from(
            { length: this.sellerTotalPages },
            (_, index) => index + 1
          );
        },
        (error) => {
          console.error('Error:', error);
        }
      );
  }

  filterSeller() {
    console.log('services clicked');
    // console.log(this.productStatus,this.productCategory)
    const params = new HttpParams().set(
      'page',
      this.sellerCurrentPage.toString()
    ); // Pass the current page number
    // .set('approvalStatus', this.approvalStatus)
    //.set('serviceCategory', this.serviceCategory)
    //.set('serviceStatus', this.serviceStatus)
    //.set('isApprovedServiceStatus', this.isApprovedServiceStatus);

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });

    this.http
      .get<SellerResponse>(ADMIN_URL + '/seller', { params, headers })
      .subscribe(
        (response) => {
          console.log(response);
          this.sellersLists = response.sellers;
          this.sellersLists.forEach((seller) => {
            this.fetchSellerPostCount(seller.profileID).subscribe(
              (postCount: any) => {
                seller.postCount = postCount.totalCount; // Store the post count for each seller
              }
            );
          });
          this.sellerCurrentPage = response.currentPage;
          this.sellerTotalPages = response.totalPages;
          this.sellerPages = Array.from(
            { length: this.sellerTotalPages },
            (_, index) => index + 1
          );
        },
        (error) => {
          console.error('Error:', error);
        }
      );
  }

  totalPostCount: number;
  fetchSellerPostCount(profileID: string) {
    console.log(profileID);
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    // Assuming you have an API endpoint to fetch the post count for a specific seller
    const url = `${ADMIN_URL}/getTotalposts/${profileID}`;
    return this.http.get(url, { headers });
  }

  editSeller(sellerId: string, username: string) {
    console.log('edit', sellerId);
    this.router.navigate(['/seller-postings'], {
      queryParams: {
        sellerId: sellerId,
        username: username,
      },
    });
  }

  sellerDetails(sellerId: string) {
    this.router.navigate(['/seller-details'], {
      queryParams: {
        sellerId: sellerId,
      },
    });
  }
}
