<app-navbar></app-navbar>
<!-- cloud-kitchen-card.component.html -->
<!-- cloud-kitchen-card.component.html -->
<div class="container mt-4">
  <div class="card mainCard mb-4 border-3d shadow">
    <div class="row">
      <div class="col-lg-6">
        <div class="card-body">
          <h4 class="card-title" style="margin-bottom: 20px; text-align: center;">
            Cloud Kitchen Information
          </h4>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <strong>KCK ID:</strong> {{ kckDetails.profileID }}
            </li>
            <li class="list-group-item">
              <strong>Profile ID:</strong> {{ kckDetails.profileID }}
            </li>
            <li class="list-group-item">
              <strong>Registration Date:</strong>
              {{ kckDetails.date.slice(0,10) }}
            </li>
            <li class="list-group-item">
              <strong>Mobile:</strong> {{ kckDetails.mobile }}
            </li>
            <li class="list-group-item">
              <strong>Address:</strong> {{ kckDetails.address }}
            </li>
            <li class="list-group-item">
              <strong>District:</strong> {{ kckDetails.district }}
            </li>
            <li class="list-group-item">
              <strong>Block:</strong> {{ kckDetails.block }}
            </li>
            <li class="list-group-item">
              <strong>Panchayath:</strong> {{ kckDetails.panchayath }}
            </li>
            <li class="list-group-item">
              <strong>Kitchen Unit Member Name:</strong>
              {{ kckDetails.kitchenUnitMemberName }}
            </li>
            <li class="list-group-item">
              <strong>FSSAI License:</strong>
              {{ kckDetails.fssaiLicense }}
            </li>
            <li class="list-group-item">
              <strong>MeApp ID:</strong> {{ kckDetails.meAppId }}
            </li>
            <li class="list-group-item">
              <strong>CDS Registration ID:</strong>
              {{ kckDetails.cdsRegistrationId }}
            </li>
            <li class="list-group-item">
              <strong>Active Status:</strong>
              {{ kckDetails.active?"Active":"Inactive" }}
            </li>
            <li class="list-group-item">
              <strong>Approved:</strong> {{ kckDetails.approved?"Approved":"Not Approved" }}
            </li>
            <li class="list-group-item">
              <strong>UPI ID:</strong> {{ kckDetails.UPIID }}
            </li>

            <!-- Add other fields here -->
          </ul>
        </div>
      </div>

      <div class="col-lg-6" style="        margin-top: 60px;
      ">
        <!-- <img
          [src]="cloudKitchenImage"
          alt="Cloud Kitchen"
          class="img-fluid"
          style="
            width: 80%;
            height: 80%;
            margin: 50px;
            margin-bottom: 0;
            border-radius: 15px;
            object-fit: cover;
          "
        /> -->
        <!-- New Image Gallery Model start -->
     <div class="product-gallery">
      <div class="main-image">
        <img style="


        margin-bottom: 0;
        border-radius: 15px;
        object-fit: contain;
      " *ngIf="userImageURL.length!==0" [src]="userImageURL[currentIndex]" [alt]="'Product Image ' + (currentIndex + 1)">
        <img style="

        margin-bottom: 0;
        border-radius: 15px;
        object-fit: contain;
      " *ngIf="userImageURL.length===0" src='assets/burger1.jpg' [alt]="'Product Image ' + (currentIndex + 1)"
       alt="Cloud Kitchen"
       class="img-fluid">
      </div>
      <div class="thumbnail-images pt-1">
        <img *ngFor="let image of userImageURL; let i = index"
             [src]="image"
             [alt]="'Product Image ' + (i + 1)"
             [class.active]="i === currentIndex"
             alt="Cloud Kitchen"
             class="img-fluid"
             (click)="setCurrentIndex(i)">
      </div>
    </div>
        <div class="row mt-3">
          <div class="col btn1">
            <button
              type="button"
              class="btn btn-primary btn-block"
              (click)="startEdit()"
            >
              Edit Lunch bell details
            </button>
          </div>
        </div>
      </div>

    </div>
    <div class="row m-3">
      <h4 style="text-align: center">Menu Items</h4>
      <br /><br />
      <!-- Display Menu Items Here -->
      <div class="row mt-2">
        <div class="col">
          <p><strong>#</strong></p>
        </div>
        <div class="col">
          <p><strong>Item Name</strong></p>
        </div>
        <div class="col">
          <p><strong>Price</strong></p>
        </div>
        <div class="col">
          <p><strong>Type</strong></p>
        </div>
      </div>
      <div class="row" *ngFor="let menuItem of menuItems; let i = index">
        <div class="col">
          <p>{{ i + 1 }}</p>
        </div>
        <div class="col">
          <p>{{ menuItem.name }}</p>
        </div>
        <div class="col">
          <p>{{ menuItem.price }}</p>
        </div>
        <div class="col">
          <p>{{ menuItem.type }}</p>
        </div>
        <hr />
      </div>
      <div class="col btn1">
        <button
          type="button"
          class="btn btn-primary btn-block"
          (click)="openEditMenuPopup()"
        >
          Edit Menu Items
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Include the popup directly in the parent component -->
<div class="popup" *ngIf="isEditing">
  <div class="popup-content">
    <div class="popup-header">
      <h5>Edit Cloud Kitchen Information</h5>
      <button type="button" class="btn btnClose" (click)="cancelEdit()">
        &times;
      </button>
    </div>
    <div class="popup-body">
      <form>
        <div class="form-group">
          <label for="kckId">KCK ID:</label>
          <input
            type="text"
            id="kckId"
            [(ngModel)]="cloudKitchenDataForEdit.profileID"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="profileId">Profile ID:</label>
          <input
            type="text"
            id="profileId"
            [(ngModel)]="cloudKitchenDataForEdit.profileID"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="registrationDate">Registration Date:</label>
          <input
            type="text"
            id="registrationDate"
            [(ngModel)]="cloudKitchenDataForEdit.date"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="mobile">Mobile:</label>
          <input
            type="text"
            id="mobile"
            [(ngModel)]="cloudKitchenDataForEdit.mobile"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="address">Address:</label>
          <input
            type="text"
            id="address"
            [(ngModel)]="cloudKitchenDataForEdit.address"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="district">District:</label>
          <input
            type="text"
            id="district"
            [(ngModel)]="cloudKitchenDataForEdit.district"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="block">Block:</label>
          <input
            type="text"
            id="block"
            [(ngModel)]="cloudKitchenDataForEdit.block"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="panchayath">Panchayath:</label>
          <input
            type="text"
            id="panchayath"
            [(ngModel)]="cloudKitchenDataForEdit.panchayath"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="kitchenUnitMemberName">Kitchen Unit Member Name:</label>
          <input
            type="text"
            id="kitchenUnitMemberName"
            [(ngModel)]="cloudKitchenDataForEdit.kitchenUnitMemberName"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="fssaiLicense">FSSAI License:</label>
          <input
            type="text"
            id="fssaiLicense"
            [(ngModel)]="cloudKitchenDataForEdit.fssaiLicense"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="meAppId">MeApp ID:</label>
          <input
            type="text"
            id="meAppId"
            [(ngModel)]="cloudKitchenDataForEdit.meAppId"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="cdsRegistrationId">CDS Registration ID:</label>
          <input
            type="text"
            id="cdsRegistrationId"
            [(ngModel)]="cloudKitchenDataForEdit.cdsRegistrationId"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label for="activeStatus">Active Status:</label>
          <select
            id="activeStatus"
            [(ngModel)]="cloudKitchenDataForEdit.active"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          >
            <option value=true>Active</option>
            <option value=false>Inactive</option>
            <!-- Add more options as needed -->
          </select>
        </div>

        <div class="form-group">
          <label for="approved">Approved:</label>
          <select
            id="approved"
            [(ngModel)]="cloudKitchenDataForEdit.isApproved"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          >
            <option value=true>Approved</option>
            <option value=false>Not Approved</option>
            <!-- Add more options as needed -->
          </select>
        </div>


        <div class="form-group">
          <label for="upiId">UPI ID:</label>
          <input
            type="text"
            id="upiId"
            [(ngModel)]="cloudKitchenDataForEdit.UPIID"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
      </form>
    </div>
    <div class="popup-footer">
      <button
        type="button"
        style="margin-right: 10px"
        class="btn btn-danger"
        (click)="cancelEdit()"
      >
        Cancel
      </button>
      <button type="button" class="btn btn-success" (click)="updateCloudKitchen()">
        Save
      </button>
    </div>
  </div>
</div>

<!-- Edit Menu Popup Content -->
<!-- Edit Menu Popup Content -->
<div id="editMenuPopup" class="editMenuPopup" *ngIf="showEditMenuPopup">
  <div class="popup-content-editMenu">
    <h3 style="text-align: center">Edit Menu</h3>

    <!-- Headings row for Name, Price, and Type -->
    <div class="row">
      <div class="col-md-4">
        <label>Item Name</label>
      </div>
      <div class="col-md-4">
        <label>Price ₹</label>
      </div>
      <div class="col-md-4">
        <label>Type</label>
      </div>
    </div>

    <!-- Loop through all menu items -->
    <div class="row" *ngFor="let item of menuItemsForEdit; let i = index">
      <!-- Editable fields for each menu item -->
      <div class="form-group col-md-4">
        <input
          type="text"
          [(ngModel)]="item.name"
          class="form-control"

        />
      </div>
      <div class="form-group col-md-4">
        <input
          type="number"
          [(ngModel)]="item.price"
          class="form-control"

        />
      </div>
      <div class="form-group col-md-4">
        <input
          type="text"
          [(ngModel)]="item.type"
          class="form-control"
          
          disabled="true"
        />
      </div>
    </div>

    <!-- Save and Cancel buttons -->
    <div class="text-right">
      <button type="button" class="btn btn-success" (click)="saveMenuChanges()">
        Save
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="closeEditMenuPopup()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
