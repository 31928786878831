<div (click)="goBack()">
  <i class="fa fa-chevron-circle-left backButton" aria-hidden="true" ></i>
</div>
<div class="containerOverlay" *ngIf="showimg" >
  <div (click)="hideImg()"><i class="fa fa-window-close closebtn" aria-hidden="true"></i></div>
  <div class="zoomimg">
    <img [src]="zoomsrc" alt="ServiceImage">
  </div>
</div>
<app-navbar></app-navbar>
<div class="mainCon">
  <div class="container p-2">
    <div class="row">
      <div class="col text-center">
        <h3 class="headingmain notranslate">{{'editService' | translate}}</h3>
      </div>
    </div>
  </div>
  <div class="container">
    <form>
      <div class="form-group">
        <div class="row">
          <div class="col">
            <label for="serviceCategory">{{'serviceCategory' | translate}}</label>
            <input type="text" class="form-control" id="serviceCategory" [value]="serviceCategorySwitch(service.serviceCategory)" readonly>
          </div>
          <div class="col flexCat">

              <div class="dropdown">
                <button
                  class="btn btn-success dropdown-toggle category1"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{categoryDropdownHeading}}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Category'">All</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Farm Services';
                    serviceCat='ID_FRM'">Farm Services</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Skilled Services';
                    serviceCat='ID_SKL'">Skilled Services</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Local Events';
                    serviceCat='ID_LLE'">Local Events</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Other Services';
                    serviceCat='ID_OTH'">Other Services</a></li>
                </ul>
              </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="serviceTitle">{{'serviceTitle' | translate}}</label>
            <input type="text" class="form-control" id="serviceTitle" value="{{service.serviceTitle}}" [(ngModel)]="serviceTitle" [ngModelOptions]="{standalone: true}">
          </div>
        </div>


      </div>


      <div class="form-group">
        <label for="serviceDescription">{{'serviceDescription' | translate}}</label>
        <textarea class="form-control" id="serviceDescription" value="{{service.serviceDescription}}" [(ngModel)]="serviceDescription" [ngModelOptions]="{standalone: true}"></textarea>
      </div>
      <input class="approvalCheckBox" type="checkbox" [(ngModel)]="isApproved" [checked]="isApproved" [ngModelOptions]="{standalone: true}">
      <span class="approvalCheck">{{'approveService' | translate}}</span>
      <br>
      <br>
      <div class="row">
        <div class="col">
          <select class="form-control productUnit" id="productUnit" [(ngModel)]="serviceStatus" [ngModelOptions]="{ standalone: true }">
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>
        <div class="col">{{service.address}}</div>
        <div class="col"><b>Profile ID: </b>{{service.profileID}}<p><b>Posted Date:</b>
          <b  style="color: red;">
            {{ service.date | slice : 0 : 10 }}
          </b></p></div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
      <div class="row imagerow">
        <div class="col imageCon">
          <h5 class="noimage" *ngIf="service.serviceImageData&&service.serviceImageData.length===0">No images to show!</h5>
          <div class="imgItem" *ngFor="let imageUrl of serviceImageURLs; let i = index">
            <img class="mainimg" [src]="imageUrl" alt="ServiceImage" (click)="imageClick(imageUrl)">
            <button class="delete" (click)="onDelete(serviceId, i)">Delete Image</button>

          </div>

        </div>
      </div>
      <div class="container uploadImage" *ngIf="service.serviceImageData&&service.serviceImageData.length===0">


        <div class="imagePreview">
          <img
            class="uploadedImage"
            [src]="
              selectedImageUrls[0] ||
              editImagesArray[0] ||
              defaultImages
            "
            alt="selected images"
          />
          <button
            [disabled]="!uploadButton1Enabled"
            type="button"
            (click)="fileInput1.click()"
            class="btn btn-light imageUploadButtons btn-sm transparent-button"
          >
            Select Image
          </button>
          <input
            type="file"
            accept="image/*"
            #fileInput1
            style="display: none"
            (change)="onImageSelected($event, 0)"
          />
        </div>



  </div>
      <br><br>
      <div class="row">
        <!-- <div class="col-3"></div> -->
        <div class="col-4" style="text-align: center;"> <button class="dontapprove" (click)="onDontApprove()">Cancel</button></div>
        <div class="col-4" style="text-align: center;"> <button class="deleteBtn" (click)="confirmDeleteService()">Delete</button></div>
       <div class="col-4" style="text-align: center;"><button class="approve" (click)="onApprove()">Update</button>
        <!-- <div class="col-3"></div> -->
       </div>
      </div>
    </form>

  </div>
</div>



