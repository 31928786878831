import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { SubscriptionOrdersService } from '../subscription-orders/subscription-orders.service';

const ADMIN_URL = environment.adminUrl;
import Swal from 'sweetalert2';

import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-subscription-orders',

  templateUrl: './subscription-orders.component.html',
  styleUrl: './subscription-orders.component.css',
})
export class SubscriptionOrdersComponent {
  //variable names:
  orderDetails: any;
  filteredOrderDetails: any;
  selectedOrderIDs: string[] = [];
  selectedDate: any;
  endDate: any;
  // offerData:any;
  currentDate: Date;
  disableButton: boolean = false;
  downloadPDF: boolean = false;
  resultLoading: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private SubscriptionOrdersService: SubscriptionOrdersService
  ) {}

  ngOnInit(): void {
    this.checkLogin();
    this.currentDate = new Date();
  }

  disableButtons() {
    this.currentDate.setHours(0, 0, 0, 0);
    console.log(this.selectedDate);
    console.log(this.currentDate);
    console.log(this.disableButton);

    if (this.currentDate.getTime() === this.selectedDate.getTime()) {
      this.disableButton = true;
      console.log(this.disableButton);
      return;
    }

    this.disableButton = false;
  }

  checkLogin(): void {
    this.getOrderDetailsFromService();
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }

  isAdmin(): boolean {
    return this.authService.isAuthenticated() && this.authService.isAdmin();
  }

  isSuperAdmin(): boolean {
    return (
      this.authService.isAuthenticated() && this.authService.isSuperAdmin()
    );
  }

  isKitchen(): boolean {
    return this.authService.isAuthenticated() && this.authService.isKitchen();
  }

  // generateSubscriptionOrders() {
  //   const token = this.authService.getToken();
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   this.http
  //     .post(
  //       `${ADMIN_URL}/subscriptions/generateOrdersForToday`,
  //       {},
  //       { headers }
  //     )
  //     .subscribe(
  //       (response: any) => {
  //         console.log('Backend response:', response);

  //         if (
  //           response.message === 'Orders for today have already been generated'
  //         ) {
  //           // Show alert for already generated orders
  //           Swal.fire({
  //             icon: 'warning',
  //             title: 'Orders Already Generated',
  //             text: 'Orders for today have already been generated.',
  //           });
  //         } else if (
  //           response.message ===
  //           'Orders pushed to subscriptionDetails successfully'
  //         ) {
  //           // Show alert for successfully generated orders
  //           Swal.fire({
  //             icon: 'success',
  //             title: 'Orders Successfully Generated',
  //             text: 'Orders for today have been successfully generated.',
  //           });
  //         } else if (
  //           response.message ===
  //           'Orders can only be generated between 7 AM and 11 AM IST.'
  //         ) {
  //           // Show alert for time restriction
  //           Swal.fire({
  //             icon: 'info',
  //             title: 'Time Restriction',
  //             text: 'Orders can only be generated between 7 AM and 11 AM IST.',
  //           });
  //         }
  //       },
  //       (error) => {
  //         console.error('Error from backend:', error);
  //         // Handle error (e.g., display an error message)
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error',
  //           text: 'There was an error generating orders. Please try again.',
  //         });
  //       }
  //     );
  // }

  generateSubscriptionOrders(): void {
    // Display confirmation dialog using SweetAlert
    Swal.fire({
      title: 'Generate Subscription Orders',
      text: 'Are you sure you want to generate subscription orders for today?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with generating orders
        const token = this.authService.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        this.http
          .post(
            `${ADMIN_URL}/subscriptions/generateOrdersForToday`,
            {},
            { headers }
          )
          .subscribe(
            (response: any) => {
              console.log('Backend response:', response);

              if (
                response.message ===
                'Orders for today have already been generated'
              ) {
                // Show alert for already generated orders
                Swal.fire({
                  icon: 'warning',
                  title: 'Orders Already Generated',
                  text: 'Orders for today have already been generated.',
                });
              } else if (
                response.message ===
                'Orders pushed to subscriptionDetails successfully'
              ) {
                // Show alert for successfully generated orders
                Swal.fire({
                  icon: 'success',
                  title: 'Orders Successfully Generated',
                  text: 'Orders for today have been successfully generated.',
                });
              } else if (
                response.message ===
                'Orders can only be generated between 7 AM and 12 PM IST.'
              ) {
                // Show alert for time restriction
                Swal.fire({
                  icon: 'info',
                  title: 'Time Restriction',
                  text: 'Orders can only be generated between 7 AM and 12 PM IST.',
                });
              }
              else if (
                response.message ===
                'No subscriptions were found.'
              ) {
                // Show alert for time restriction
                Swal.fire({
                  icon: 'info',
                  title: 'No Subscriptions were found.',
                  // text: 'Orders can only be generated between 7 AM and 12 PM IST.',
                });
              }
            },
            (error) => {
              console.error('Error from backend:', error);
              // Handle error (e.g., display an error message)
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error generating orders. Please try again.',
              });
            }
          );
      } else {
        // User clicked "No" or closed the dialog
        Swal.fire(
          'Cancelled',
          'Subscription orders generation cancelled.',
          'info'
        );
      }
    });
  }

  promptDownloadOptions() {
    if (this.selectedDate) {
      const filterCriteria = {
        orderStatus: (
          document.getElementById('orderStatus') as HTMLSelectElement
        ).value,
        // deliveryAddress: (document.getElementById('addressFilter') as HTMLSelectElement).value

        address: {
          address: (
            document.getElementById('addressFilter') as HTMLSelectElement
          ).value,
        },
        subscriptionDays: parseInt(
          (document.getElementById('subscriptionFilter') as HTMLSelectElement)
            .value
        ),
      };

      console.log('the filterCritea', filterCriteria);

      Swal.fire({
        title: 'Choose download format',
        showCancelButton: true,
        confirmButtonText: 'Download as PDF',
        cancelButtonText: 'Download as Excel',
        reverseButtons: true,
        confirmButtonColor: '#FA0F00',
        cancelButtonColor: '#339966',
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.isAdmin() || this.isSuperAdmin()) {
            this.downloadOrders(
              'pdf',
              this.selectedDate,
              this.endDate,
              filterCriteria
            );
            this.downloadPDF = true;
          } else if (this.isKitchen()) {
            this.downloadOrdersForKitchen(
              'pdf',
              this.selectedDate,
              filterCriteria
            );
            this.downloadPDF = true;
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          if (this.isAdmin() || this.isSuperAdmin()) {
            this.downloadOrders(
              'excel',
              this.selectedDate,
              this.endDate,
              filterCriteria
            );
            this.downloadPDF = true;
          } else if (this.isKitchen()) {
            this.downloadOrdersForKitchen(
              'excel',
              this.selectedDate,
              filterCriteria
            );
            this.downloadPDF = true;
          }
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a date!',
      });
    }
  }

  downloadOrders(format: string, selectedDate, endDate, filterCriteria) {
    console.log('downloadOrders');
    let downloadUrl: string;
    console.log(this.selectedDate);
    console.log('the end date is ', endDate);
    console.log('the selectedDate is ', selectedDate);

    const token = this.authService.getToken();

    if (!endDate) {
      endDate = selectedDate;
    }

    console.log('the end date after if condition is ', endDate);

    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    if (format === 'pdf') {
      downloadUrl = `${ADMIN_URL}/adminOrderSubscriptionPdfDownload?date=${selectedDate}&endDate=${endDate}&filterCriteria=${JSON.stringify(
        filterCriteria
      )}`;
    } else if (format === 'excel') {
      downloadUrl = `${ADMIN_URL}/adminSubscriptionOrderExcelDownload?date=${selectedDate}&endDate=${endDate}&filterCriteria=${JSON.stringify(
        filterCriteria
      )}`;
    }

    this.http.get(downloadUrl, { headers, responseType: 'blob' }).subscribe(
      (response: Blob) => {
        const blob = new Blob([response]);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);

        if (format === 'pdf') {
          downloadLink.setAttribute('download', 'orders.pdf');
          this.downloadPDF = false;
        } else if (format === 'excel') {
          downloadLink.setAttribute('download', 'orders.xlsx');
          this.downloadPDF = false;
        }

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
      (error) => {
        console.error('Error fetching orders:', error);
      }
    );
  }

  downloadOrdersForKitchen(format: string, selectedDate, filterCriteria) {
    let downloadUrl: string;

    const token = this.authService.getToken();

    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    if (format === 'pdf') {
      downloadUrl = `${ADMIN_URL}/kitchen/kitchenSubscriptionOrderPdfDownload?date=${selectedDate}&filterCriteria=${JSON.stringify(
        filterCriteria
      )}`;
    } else if (format === 'excel') {
      downloadUrl = `${ADMIN_URL}/kitchen/kitchenSubscriptionOrderExcelDownload?date=${selectedDate}&filterCriteria=${JSON.stringify(
        filterCriteria
      )}`;
    }

    this.http.get(downloadUrl, { headers, responseType: 'blob' }).subscribe(
      (response: Blob) => {
        const blob = new Blob([response]);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);

        if (format === 'pdf') {
          downloadLink.setAttribute('download', 'orders.pdf');
          this.downloadPDF = false;
        } else if (format === 'excel') {
          downloadLink.setAttribute('download', 'orders.xlsx');
          this.downloadPDF = false;
        }

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
      (error) => {
        console.error('Error fetching orders:', error);
      }
    );
  }

  fetchOrderDetails(): void {
    if (this.selectedDate) {
      this.resultLoading = true;
      this.disableButtons();
      this.resetFilterCriteria();
      const token = this.authService.getToken();

      console.log('the order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = { currentDate: this.selectedDate.toISOString() };
      this.http
        .get(ADMIN_URL + `/subscriptionOrderDetails`, { headers, params })
        .subscribe(
          (data: any) => {
            this.orderDetails = data;
            console.log('order details', this.orderDetails);

            this.filteredOrderDetails = data;
            this.resultLoading = false;
            this.SubscriptionOrdersService.setSubscriptionOrderDetails(
              this.orderDetails,
              this.selectedDate,
              this.endDate
            );
          },
          (error) => {
            console.error('Error fetching orders:', error);
            this.resultLoading = false;
          }
        );
    } else {
      // Show SweetAlert for missing date selection
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a date!',
      });
    }
  }

  getOrderDetailsFromService(): void {
    if (this.SubscriptionOrdersService.getOrderDetails()) {
      this.orderDetails = this.SubscriptionOrdersService.getOrderDetails();
      this.selectedDate = this.SubscriptionOrdersService.getSelectedDate();
      this.endDate = this.SubscriptionOrdersService.getEndDate();
      if (!this.orderDetails) {
        this.fetchOrderDetails(); // Fetch order details if not available in service
      } else {
        this.filteredOrderDetails = this.orderDetails;
      }
    }
  }

  resetFilterCriteria(): void {
    // Reset filter criteria to default values

    const orderStatusSelect = document.getElementById(
      'orderStatus'
    ) as HTMLSelectElement;
    const addressFilterSelect = document.getElementById(
      'addressFilter'
    ) as HTMLSelectElement;

    orderStatusSelect.value = '';
    addressFilterSelect.value = '';

    // Trigger filterOrders() to update filteredOrderDetails
    // this.filterOrders();
  }
subscriptionDetails:any 
  fetchOrderDetailsForKitchen(): void {
    if (this.selectedDate) {
      this.resultLoading = true;
      this.selectAll = 'Select All';
      this.disableButtons();
      this.resetFilterCriteria();
      const token = this.authService.getToken();

      // const date = this.selectedDate.toISOString();
      console.log('Selected Date', this.selectedDate);
      // console.log('UTCDATE', date);
      const params = { currentDate: this.selectedDate.toISOString() };

      console.log('the order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      // const params = { currentDate: this.selectedDate };
      this.http
        .get(ADMIN_URL + `/kitchen/subscriptionOrderDetailsForKitchen`, {
          headers,
          params,
        })
        .subscribe(
          (data: any) => {
            console.log('data', data)

            this.orderDetails = data;
            // console.log('order details', this.orderDetails);
this.subscriptionDetails=this.orderDetails.subscriptionDetails;

console.log('subscriptionDetails',this.subscriptionDetails);

            this.filteredOrderDetails = data;
            this.resultLoading = false;
            console.log(
              'the filtered order details is :',
              this.filteredOrderDetails
            );
            this.SubscriptionOrdersService.setSubscriptionOrderDetails(
              this.orderDetails,
              this.selectedDate,
              this.endDate
            );
          },
          (error) => {
            console.error('Error fetching orders:', error);
            this.resultLoading = false;
          }
        );
    } else {
      // Show SweetAlert for missing date selection
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a date!',
      });
    }
  }

  gotoOrderDescription(orderID: string): void {
    this.router.navigate(['/subscriptionDetails'], {
      queryParams: {
        dateBaseID: orderID,
      },
    });
  }

  getPaymentDetails(name: string): string {
    switch (name) {
      case 'NPD':
        return 'Not paid';
      case 'PAID':
        return 'Paid';
      case 'FAIL':
        return 'Payment failed';
      case 'PEN':
        return 'Payment pending';
      case 'CON':
        return 'Confirmed';
      default:
        return name;
    }
  }

  getPaymentMethod(name: string): string {
    switch (name) {
      case 'COD':
        return 'COD';
      case 'UPI':
        return 'UPI';
      case 'TBD':
        return 'TBD';
      default:
        return name;
    }
  }

  getOrderStatus(name: string): string {
    switch (name) {
      case 'ACC':
        return 'Accepted';
      case 'DEL':
        return 'Delivered';
      case 'CAN':
        return 'Cancelled';
      case 'OFD':
        return 'Out for delivery';
      case 'PEN':
        return 'Pending';
      default:
        return name;
    }
  }

  filterOrders(): void {
    const orderStatus = (
      document.getElementById('orderStatus') as HTMLSelectElement
    ).value;
    const addressFilter = (
      document.getElementById('addressFilter') as HTMLSelectElement
    ).value;

    const subscriptionFilter = parseInt(
      (document.getElementById('subscriptionFilter') as HTMLSelectElement).value
    );

    this.filteredOrderDetails = this.orderDetails.filter((order) => {
      return (
        (!orderStatus || order.orderStatus === orderStatus) &&
        (!addressFilter || order.address.address === addressFilter) &&
        (!subscriptionFilter || order.subscriptionDays === subscriptionFilter)
      );
    });
  }

  // toggleOrderSelection(orderID: string, event: Event) {
  //   const isChecked = (event.target as HTMLInputElement).checked;
  //   if (isChecked) {
  //     this.selectedOrderIDs.push(orderID);
  //     console.log('the selected orderID is:', this.selectedOrderIDs);
  //   } else {
  //     const index = this.selectedOrderIDs.indexOf(orderID);
  //     if (index !== -1) {
  //       this.selectedOrderIDs.splice(index, 1);
  //     }
  //   }
  // }

  toggleOrderSelection(subscriptionID: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      // Add to selectedOrderIDs if checked
      this.selectedOrderIDs.push(subscriptionID);
    } else {
      // Remove from selectedOrderIDs if unchecked
      const index = this.selectedOrderIDs.indexOf(subscriptionID);
      if (index !== -1) {
        this.selectedOrderIDs.splice(index, 1);
      }
    }
  }

  sendSelectedOrderIDsToBackend(status: string): void {
    if (this.selectedOrderIDs.length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This action will update selected orders. Do you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          const orderStatus = status;
          const token = this.authService.getToken();
          const endpointUrl = ADMIN_URL + `/kitchen/updateSubscriptionOrder`;
          const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${token}`
          );
          const options = { headers };

          // Make the HTTP POST request
          this.http
            .put(
              endpointUrl,
              {
                selectedOrderIDs: this.selectedOrderIDs,
                orderStatus: orderStatus,
                selectedDate: this.selectedDate,
              },
              options
            )
            .subscribe(
              (response) => {
                console.log(
                  'Successfully sent selected order IDs to the backend:',
                  response
                );
                this.fetchOrderDetailsForKitchen();
                this.selectedOrderIDs = [];
              },
              (error) => {
                console.error(
                  'Error sending selected order IDs to the backend:',
                  error
                );
                // Handle error response from the backend if needed
              }
            );
        }
      });
    } else {
      // Show SweetAlert when selectedOrderIDs is empty
      Swal.fire({
        icon: 'warning',
        title: 'No orders selected',
        text: 'Please select orders before proceeding.',
      });
    }
  }

  selectAll: string = 'Select All';

  selectAllOrders(): void {
    // If all orders are already selected, clear the selected order IDs array
    if (this.selectedOrderIDs.length === this.filteredOrderDetails.length) {
      this.selectedOrderIDs = [];
      this.selectAll = 'Select All';
    } else {
      this.selectAll = 'Deselect All';
      // Otherwise, add all order IDs to the selectedOrderIDs array
      this.selectedOrderIDs = this.filteredOrderDetails.map(
        (order) => order.subscriptionID
      );
    }
  }

  isSelectedOrder(orderID: string): boolean {
    return this.selectedOrderIDs.includes(orderID);
  }

  downloadToday() {
    if (this.selectedDate) {
      this.todayOrder = true;
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a date!',
      });
    }
  }

  closeDownload() {
    this.todayOrder = false;
  }

  selectedAddress: string = '';
  anotherVariable: string = '';
  todayOrder: boolean = false;

  onAddressSelected(event: any) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.selectedAddress = selectedValue;
    this.anotherVariable = selectedValue; // Assign the selected value to another variable
    console.log('Selected Address:', this.selectedAddress);
    console.log('Another Variable:', this.anotherVariable);
  }

  downloadTodayOrder() {
    if (this.selectedDate) {
      this.todayOrder = true;
      if (this.selectedAddress) {
        let downloadUrl: string;
        let selectedDate = this.selectedDate;
        const token = this.authService.getToken();
        console.log('the order function is called');
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        downloadUrl = `${ADMIN_URL}/kitchen/SubscriptionPdfDownloadOFD?date=${selectedDate}&filterCriteria=${this.selectedAddress}`;

        this.http.get(downloadUrl, { headers, responseType: 'blob' }).subscribe(
          (response: Blob) => {
            const blob = new Blob([response]);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);

            downloadLink.setAttribute('download', 'orders.pdf');

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            this.todayOrder = false;
          },
          (error) => {
            console.error('Error fetching orders:', error);
            this.todayOrder = false;
          }
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select a location!',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a date!',
      });
      this.todayOrder = false;
    }
  }

  // redirect to the subscription page
  goToSubscriptionPage() {
    this.router.navigate(['/subscriptionPage']);
  }
}
