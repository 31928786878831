import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { SubscriptionService } from './subscription.service';

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrl: './subscription-details.component.css',
})
export class SubscriptionDetailsComponent {
  //variables
  dataBaseID: string;
  subscriptionDetails: any;
  allSubscriptions: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.dataBaseID = params['dateBaseID'];
    });

    this.checkLogin();
  }

  //to check if the user is logged in
  checkLogin(): void {
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
      this.getSubscriptions();
    } else {
      this.router.navigate(['/login']);
    }
  }

  //to check the user is admin
  isAdmin(): boolean {
    return this.authService.isAuthenticated() && this.authService.isAdmin();
  }

  //to check the user is superAdmin
  isSuperAdmin(): boolean {
    return (
      this.authService.isAuthenticated() && this.authService.isSuperAdmin()
    );
  }

  //to check the user is kitchen
  isKitchen(): boolean {
    return this.authService.isAuthenticated() && this.authService.isKitchen();
  }

  // to get the subscription details for superadmin/ admin
  getSubscriptions() {
    this.subscriptionService.getSubscriptionsDetails(this.dataBaseID).subscribe(
      (data) => {
        this.subscriptionDetails = data.subscriptionDetails;
        this.allSubscriptions = data.allSubscriptionDetails;
        console.log('the subscription details is:', this.subscriptionDetails);
        console.log(
          'the other subscription details is:',
          this.allSubscriptions
        );
      },
      (error) => {
        console.log('Error fetching subscriptions:', error);
      }
    );
  }

  goToSubscriptionDetails(id: string) {
    this.dataBaseID = id;
    this.subscriptionService.getSubscriptionsDetails(this.dataBaseID).subscribe(
      (data) => {
        this.subscriptionDetails = data.subscriptionDetails;
        this.allSubscriptions = data.allSubscriptionDetails;
        console.log('the subscription details is:', this.subscriptionDetails);
        console.log(
          'the other subscription details is:',
          this.allSubscriptions
        );
      },
      (error) => {
        console.log('Error fetching subscriptions:', error);
      }
    );
  }
}
