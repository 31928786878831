<app-kckdashboard></app-kckdashboard>

<div class="main">
  <div class="container card cardmain">
    <div class="row">
      <div class="col">
        <p class="card-title" style="text-align: center; margin-bottom: 25px">
          <b>KITCHEN DETAILS</b>
        </p>

        <!--  details -->
        <div class="container">
          <div class="row">
            <div class="col">
              <p
                class="card-title"
                *ngIf="kitchenDetails"
                style="padding-bottom: 5px"
              >
                {{ kitchenDetails.username }}
              </p>
            </div>
            <div class="col">
              <p class="card-title" style="padding-bottom: 5px">ITEMS OFFER</p>
            </div>
          </div>
          <div class="row">
            <!-- image--- -->
            <div class="col">
              <img class="profileImage" src="assets/kckimage/burger1.jpg" />
              <!-- item details -->

              <div *ngIf="kitchenDetails">
                <div class="row pt-2">
                  <div class="col">
                    <div class="card">
                      <div class="row">
                        <!-- <p class="card-title" style="padding-bottom: 5px">
                          <b>{{ kitchenDetails.username }}</b>
                        </p> -->
                        <p class="card-text">
                          UnitID:
                          <span class="kitchenDetails">{{
                            kitchenDetails?.KCKID
                          }}</span>
                        </p>
                        <p class="card-text">
                          ProfileID:
                          <span class="kitchenDetails">{{
                            kitchenDetails?.profileID
                          }}</span>
                        </p>
                        <p class="card-text">
                          Address:
                          <span class="kitchenDetails">{{
                            kitchenDetails?.address
                          }}</span>
                        </p>
                        <p class="card-text">
                          Contact:
                          <span class="kitchenDetails">{{
                            kitchenDetails?.mobile
                          }}</span>
                        </p>
                        <p class="card-text">
                          Unit member name:
                          <span class="kitchenDetails">{{
                            kitchenDetails?.kitchenUnitMemberName
                          }}</span>
                        </p>
                        <p class="card-text">
                          UPIID:
                          <span class="kitchenDetails">{{
                            kitchenDetails?.UPIID
                          }}</span>
                        </p>
                        <p class="card-text">
                          Me appId:
                          <span class="kitchenDetails">{{
                            kitchenDetails?.meAppId
                          }}</span>
                        </p>
                        <p class="card-text">
                          Cds registration Id:
                          <span class="kitchenDetails">{{
                            kitchenDetails?.cdsRegistrationId
                          }}</span>
                        </p>
                        <p class="card-text">
                          FSSAI license:
                          <span class="kitchenDetails">{{
                            kitchenDetails?.fssaiLicense
                          }}</span>
                        </p>
                      </div>
                      <div
                        class="col"
                        *ngIf="kitchenDetails.isApproved === false"
                      >
                        <button
                          *ngIf="isAdmin() || isSuperAdmin()"
                          style="float: right"
                          (click)="giveApproval()"
                          type="button"
                          class="btn btn-success"
                        >
                          Approval
                        </button>
                      </div>

                      <div
                        class="col"
                        *ngIf="kitchenDetails.isApproved === true"
                      >
                        <button
                          *ngIf="isAdmin() || isSuperAdmin()"
                          style="float: right"
                          (click)="disapproval()"
                          type="button"
                          class="btn btn-success"
                        >
                          Disapproval
                        </button>
                      </div>

                      <div class="row mt-4" *ngIf="isAdmin() || isSuperAdmin()">
                        <mat-accordion>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <p style="margin-bottom: 0">
                                  <b>Download Invoices</b>
                                </p>
                              </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class="col mt-2">
                              <div class="row">
                                <mat-form-field class="">
                                  <mat-label>Start Date</mat-label>
                                  <input
                                    matInput
                                    [matDatepicker]="startDatePicker"
                                    [matDatepickerFilter]="startDateFilter"
                                    (dateInput)="onStartDateSelected($event)"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="startDatePicker"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker
                                    #startDatePicker
                                  ></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field class="">
                                  <mat-label>End Date</mat-label>
                                  <input
                                    matInput
                                    [matDatepicker]="endDatePicker"
                                    [matDatepickerFilter]="endDateFilter"
                                    (dateInput)="onEndDateSelected($event)"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="endDatePicker"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker
                                    #endDatePicker
                                  ></mat-datepicker>
                                </mat-form-field>
                                <button
                                  style="float: right"
                                  *ngIf="isAdmin() || isSuperAdmin()"
                                  class="btn btn-success"
                                  (click)="downloadInvoices()"
                                >
                                  Download Invoices
                                </button>
                              </div>

                              <div class="row pt-2">
                                <button
                                style="float: right"
                                *ngIf="isAdmin() || isSuperAdmin()"
                                class="btn btn-light"
                                (click)="downloadSubscriptionInvoices()"
                              >
                                Download Subscription Invoices
                              </button>

                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- profile details -->
            <div class="col">
              <!-- <p class="card-title" style="text-align: center">
                <b> Items</b>
              </p> -->

              <div class="row card mb-2" *ngFor="let menuItems of menuItems">
                <!-- <div class="col p-0">
                  <img class="menuImages" src="assets/kckimage/burger1.jpg" />
                </div> -->

                <div class="col p-0">
                  <div class="row">
                    <div class="col-3">
                      <div class="col p-0">
                        <img
                          *ngIf="!menuItems.menuItemsImageURLs"
                          class="menuImages"
                          src="assets/kckimage/burger2.webp"
                        />

                        <img
                          *ngIf="menuItems.menuItemsImageURLs"
                          class="menuImages"
                          [src]="menuItems.menuItemsImageURLs"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <p style="margin-top: 5px" class="card-text">
                        Name:&nbsp;&nbsp;<b>{{ menuItems.name }}</b>
                      </p>
                      <p style="margin-top: 5px" class="card-text submenu">
                        List:&nbsp;&nbsp;<input
                          type="text"
                          [(ngModel)]="menuItems.subName"
                          [disabled]="!menuItems.isSubNameDisabled"
                        />&nbsp;
                        <button
                          type="button"
                          (click)="toggleSubNameEdit(menuItems)"
                          class="btn btn-light"
                        >
                          <i class="fa-solid fa-pencil"></i>
                        </button>
                      </p>
                      <p class="card-text price">
                        Price:&nbsp;&nbsp;<input
                          type="number"
                          [(ngModel)]="menuItems.price"
                          [disabled]="!menuItems.isPriceDisabled"
                        />
                        <button
                          type="button"
                          (click)="togglePriceEdit(menuItems)"
                          class="btn btn-light"
                        >
                          <i class="fa-solid fa-pencil"></i>
                        </button>
                      </p>
                      <p class="card-text maximumOrder">
                        Maximum orders:&nbsp;&nbsp;
                        <input
                          type="number"
                          [(ngModel)]="menuItems.maximumOrders"
                          [disabled]="!menuItems.ismaximumOrderDisabled"
                        />
                        <button
                          type="button"
                          (click)="toggleMaximunOrdersEdit(menuItems)"
                          class="btn btn-light"
                        >
                          <i class="fa-solid fa-pencil"></i>
                        </button>
                      </p>
                      <div class="row">
                        <div class="col-5 pe-0">
                          <p class="card-text">Remaining orders:</p>
                        </div>
                        <div class="col-3 mb-1 ps-0 pe-0">
                          <input
                            type="number"
                            class="form-control"
                            [(ngModel)]="menuItems.remainingOrders"
                            [disabled]="!menuItems.isInputEnabled"
                            placeholder="remaining orders"
                            oninput="this.value = this.value.slice(0, 5)"
                          />
                        </div>
                        <div class="col">
                          <button
                            type="button"
                            (click)="toggleInput(menuItems)"
                            class="btn btn-light"
                          >
                            <i class="fa-solid fa-pencil"></i>
                          </button>
                        </div>
                        <!-- <div class="col-2">
                          <button type="button" class="btn btn-success"><i class="fa-solid fa-floppy-disk"></i></button>

                        </div> -->
                      </div>

                      <button
                        type="button"
                        (click)="
                          updateSaveChange(
                            menuItems._id,
                            menuItems.remainingOrders,
                            menuItems.subName,
                            menuItems.price,
                            menuItems.maximumOrders
                          )
                        "
                        class="btn btn-success"
                      >
                        Save Changes
                      </button>

                      <button
                        type="button"
                        (click)="deleteMenu(menuItems._id)"
                        style="float: right"
                        class="btn btn-danger"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <p style="margin-bottom: 0">
                          <b>Add Menu</b>
                        </p>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row"><button (click)="savedeliverableLocations()" class="btn">add Loc test</button></div>

                    <div class="overlay">
                      <div class="modal-content">
                        <div class="modal-body text-left p-3">
                          <h3 class="text-center">Add menu items</h3>
                          <hr />
                          <!-- Form for Adding Menu Items -->
                          <div class="form-group pb-2 m-2">
                            <label for="itemNameDropdown "
                              >Select Item <span class="reqSpan">*</span></label
                            >
                            <select
                              class="form-control mt-2"
                              [(ngModel)]="selectedItem"
                              id="itemNameDropdown"
                              required
                            >
                              <option value="">Select Item</option>
                              <option
                                *ngFor="let item of menuItem"
                                [value]="item.name"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                          </div>

                          <div class="form-group pb-2 m-2">
                            <label for="itemNameDropdown ">Sub name </label>
                            <input
                              type="text"
                              class="form-control mt-2"
                              [(ngModel)]="subName"
                              placeholder="Enter Item sub name"
                              maxlength="300"
                            />
                          </div>

                          <div class="form-group pb-2 m-2">
                            <label for="itemPrice"
                              >Item price <span class="reqSpan">*</span></label
                            >
                            <input
                              type="number"
                              class="form-control mt-2"
                              [(ngModel)]="newItem.price"
                              placeholder="Enter Item Price"
                              required
                              (input)="limitDigits($event)"
                            />
                          </div>
                          <div class="form-group m-2">
                            <label for="maximumOrder"
                              >Total quantity / day
                              <span class="reqSpan">*</span></label
                            >
                            <input
                              type="number"
                              class="form-control mt-2"
                              [(ngModel)]="newItem.maximumOrders"
                              placeholder="Enter Maximum Quantity"
                              required
                              (input)="limitMaximumOrderDigits($event)"
                            />
                          </div>

                          <!--to upload image start-->
                          <div class="form-group pb-2 m-2">
                            <label for="itemImage">Upload Item Image</label>
                            <input
                              type="file"
                              class="form-control"
                              (change)="handleImageUpload($event)"
                              accept="image/*"
                            />
                          </div>
                          <!--to upload image ends-->

                          <div class="form-group m-2">
                            <div class="form-check"></div>
                          </div>

                          <div class="row">
                            <div class="col-6">
                              <button
                                type="button"
                                style="float: right"
                                class="btn btn-success saveBtn"
                                (click)="addMenuItemdrop()"
                              >
                                Save
                              </button>
                            </div>
                            <div class="col-6">
                              <button
                                type="button"
                                class="btn btn-danger cancelBtn"
                                (click)="hideModallisting()"
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>

              <div class="row mt-2">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <p style="margin-bottom: 0">
                          <b>Upload kitchen carousel images</b>
                        </p>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row"></div>

                    <div class="overlay">
                      <div class="modal-content">
                        <div class="modal-body text-left p-3">
                          <h3 class="text-center">Add kitchen carousel images</h3>
                          <hr />
                          <!-- Form for Adding kitchen carousel images starts-->

 <!-- Image upload section starts -->
 <div
 *ngIf="
   (checkProductImage &&
     checkProductImage.endsWith('_thumbnailImage.jpg')) ||
   checkProductImage == ''
 "
>
 <div class="row pt-4 pb-0">
   <label>
     <p class="labelText">Upload Images:</p>
   </label>
 </div>
 <div class="container p-3">
   <div class="row">
     <div class="col">
       <div class="imagePreview">
         <img
           class="uploadedImage"
           [src]="selectedImageUrls[0] || productImage1 || defaultImages"
           alt="selected images"
         />
         <button
           type="button"
           (click)="fileInput1.click()"
           class="btn btn-light imageUploadButtons btn-sm transparent-button"
         >
           Select Image
         </button>
         <input
           type="file"
           accept="image/*"
           #fileInput1
           style="display: none"
           (change)="onImageSelected($event, 0)"
         />
       </div>
     </div>
     <div class="col">
       <ng-container>
         <!-- Show "+" sign when the first image is not selected -->
         <div
           class="add-image-placeholder"
           *ngIf="!selectedImageUrls[0] && !productImage1"
         >
           <i class="fas fa-plus"></i>
         </div>
       </ng-container>
       <ng-container *ngIf="selectedImageUrls[0] || productImage1">
         <div class="imagePreview">
           <img
             class="uploadedImage"
             [src]="selectedImageUrls[1] || productImage2 || defaultImages"
             alt="selected images"
           />
           <button
             type="button"
             (click)="fileInput2.click()"
             class="btn btn-light imageUploadButtons btn-sm transparent-button"
           >
             Select Image
           </button>
           <input
             type="file"
             accept="image/*"
             #fileInput2
             style="display: none"
             (change)="onImageSelected($event, 1)"
           />
         </div>
       </ng-container>
     </div>
     <div class="col">
       <ng-container>
         <!-- Show "+" sign when the first image is not selected -->
         <div
           class="add-image-placeholder"
           *ngIf="!selectedImageUrls[1] && !productImage2"
         >
           <i class="fas fa-plus"></i>
         </div>
       </ng-container>
       <ng-container *ngIf="selectedImageUrls[1] || productImage2">
         <div class="imagePreview">
           <img
             class="uploadedImage"
             [src]="selectedImageUrls[2] || productImage3 || defaultImages"
             alt="selected images"
           />
           <button
             type="button"
             (click)="fileInput3.click()"
             class="btn btn-light imageUploadButtons btn-sm transparent-button"
           >
             Select Image
           </button>
           <input
             type="file"
             accept="image/*"
             #fileInput3
             style="display: none"
             (change)="onImageSelected($event, 2)"
           />
         </div>
       </ng-container>
     </div>
   </div>
 </div>
 <button class="btn btn-success" (click)="saveKitchenImages('save')">Save</button>
</div>

                          
                          <!-- Form for Adding kitchen carousel images ends -->
                          
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>

              <div class="row mt-2">
                <div class="col">
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <p style="margin-bottom: 0">
                            <b>Set holidays</b>
                          </p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <!-- <button mat-icon-button [matMenuTriggerFor]="appMenu">
                        <mat-icon>calendar_today</mat-icon>
                      </button> -->
                      <!-- <button
                        [matMenuTriggerFor]="appMenu"
                        type="button"
                        class="btn btn-secondary"
                      >
                        calendar
                      </button>
                      <mat-menu #appMenu="matMenu" class="drop-calendar">
                        <div (click)="$event.stopPropagation()">
                          <mat-calendar
                            #calendar
                            [minDate]="tomorrow"
                            (selectedChange)="select($event, calendar)"
                            [dateClass]="isSelected"
                          ></mat-calendar>
                        </div>
                      </mat-menu> -->

                      <button
                        [matMenuTriggerFor]="appMenu"
                        type="button"
                        class="btn btn-secondary"
                      >
                        calendar
                      </button>
                      <mat-menu #appMenu="matMenu" class="drop-calendar">
                        <div (click)="$event.stopPropagation()">
                          <mat-calendar
                            #calendar
                            [minDate]="tomorrow"
                            [startAt]="today"
                            startView="month"
                            [maxDate]="twoMonthsLater"
                            (selectedChange)="select($event, calendar)"
                            [dateClass]="isSelected"
                          ></mat-calendar>
                        </div>
                      </mat-menu>

                      <div *ngIf="daysSelected.length > 0">
                        <h3 style="margin-bottom: 0">Selected Dates:</h3>
                        <ul>
                          <li *ngFor="let selectedDate of daysSelected">
                            {{ selectedDate }}
                          </li>
                        </ul>
                      </div>

                      <!-- <div *ngFor="let holiday of holidayDates">
                        <div class="card mt-1">
                          <p style="margin-bottom: 0">
                            Start Date:
                            {{ holiday.startHoliday | date : "medium" : "IST" }}
                          </p>
                          <p style="margin-bottom: 0">
                            End Date:
                            {{ holiday.endHoliday | date : "medium" : "IST" }}
                          </p>
                        </div>
                      </div> -->

                      <div *ngFor="let holiday of holidayDates">
                        <div class="card mt-1">
                          <p style="margin-bottom: 0">
                            Start Date:
                            {{
                              holiday.startHoliday
                                | date : "medium" : "IST"
                                | date : "medium"
                            }}
                          </p>
                          <p style="margin-bottom: 0">
                            End Date:
                            {{
                              holiday.endHoliday
                                | date : "medium" : "IST"
                                | date : "medium"
                            }}
                          </p>
                        </div>
                      </div>

                      <div class="row mt-2">
                        <div class="col mt-2">
                          <b
                            (click)="removeHolidays()"
                            style="color: rgb(101, 101, 221)"
                            >Clear holidays</b
                          >
                        </div>
                        <div class="col">
                          <button
                            style="float: right"
                            (click)="setHolidays()"
                            type="button"
                            class="btn btn-success"
                          >
                            save holiday
                          </button>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col">
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <p style="margin-bottom: 0">
                            <b>Account Details</b>
                          </p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row"></div>

                      <div class="form-group pb-2 m-2 mb-0">
                        <label for="bankName"
                          >Bank name<span class="reqSpan">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control mt-2"
                          [(ngModel)]="bankDetails.bankName"
                          placeholder="Enter bank name"
                          maxlength="30"
                          required
                        />
                      </div>

                      <div class="form-group pb-2 m-2 mb-0">
                        <label for="bankBranch"
                          >Bank branch<span class="reqSpan">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control mt-2"
                          [(ngModel)]="bankDetails.bankBranch"
                          placeholder="Enter bank branch"
                          maxlength="30"
                          required
                        />
                      </div>

                      <div class="form-group pb-2 m-2 mb-0">
                        <label for="IFSCcode"
                          >IFSC code<span class="reqSpan">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control mt-2"
                          [(ngModel)]="bankDetails.IFSCcode"
                          placeholder="Enter IFSC code"
                          maxlength="30"
                          required
                        />
                      </div>

                      <div class="form-group pb-2 m-2 mb-0">
                        <label for="accountNumber"
                          >Account number<span class="reqSpan">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control mt-2"
                          [(ngModel)]="bankDetails.accountNumber"
                          placeholder="Enter account number"
                          maxlength="30"
                          required
                        />
                      </div>

                      <div class="form-group pb-2 m-2 mb-0">
                        <label for="gstNumber"
                          >GSTIN number<span class="reqSpan">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control mt-2"
                          [(ngModel)]="bankDetails.gstNumber"
                          placeholder="Enter GSTIN number"
                          maxlength="30"
                          required
                        />
                      </div>

                      <div class="form-group pb-2 m-2 mb-0">
                        <label for="legalEntityName"
                          >Legal entity name<span class="reqSpan"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          class="form-control mt-2"
                          [(ngModel)]="bankDetails.legalEntityName"
                          placeholder="Enter legal entity name"
                          maxlength="30"
                          required
                        />
                      </div>

                      <div class="row mt-4">
                        <div class="col-6">
                          <button
                            type="button"
                            style="float: right; width: 100%"
                            class="btn btn-success"
                            (click)="saveBankDetails()"
                          >
                            Save bank details
                          </button>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col">
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <p style="margin-bottom: 0">
                            <b>Add Offers</b>
                          </p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row"></div>

                      <div class="overlay">
                        <div class="modal-content">
                          <div class="modal-body text-left p-3">
                            <!-- Form for Adding Menu Items -->
                            <div class="form-group pb-2 m-2 mb-0">
                              <label for="offerName"
                                >Offer name<span class="reqSpan">*</span></label
                              >
                              <input
                                type="text"
                                class="form-control mt-2"
                                [(ngModel)]="offer.name"
                                placeholder="Enter offer name"
                                maxlength="30"
                                required
                              />
                            </div>

                            <div class="row">
                              <div class="col-7">
                                <div class="form-group m-2">
                                  <label for="maximumOrder"
                                    >First X orders<span class="reqSpan"
                                      >*</span
                                    ></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control mt-2"
                                    [(ngModel)]="offer.offer1"
                                    placeholder="X"
                                    required
                                    (input)="limitMaximumOrderDigits($event)"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group m-2">
                                  <label for="maximumOrder"
                                    >Y % offer<span class="reqSpan"
                                      >*</span
                                    ></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control mt-2"
                                    [(ngModel)]="offer.offerpercentage1"
                                    placeholder="Y"
                                    required
                                    (input)="limitMaximumOrderDigits($event)"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="row"
                              *ngIf="offer.offer1 && offer.offerpercentage1"
                            >
                              <div class="col-7">
                                <div class="form-group m-2">
                                  <label for="maximumOrder"
                                    >Second X1 orders<span class="reqSpan"
                                      >*</span
                                    ></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control mt-2"
                                    [(ngModel)]="offer.offer2"
                                    placeholder="X1"
                                    required
                                    (input)="limitMaximumOrderDigits($event)"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group m-2">
                                  <label for="maximumOrder"
                                    >Y1 % offer<span class="reqSpan"
                                      >*</span
                                    ></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control mt-2"
                                    [(ngModel)]="offer.offerpercentage2"
                                    placeholder="Y1"
                                    required
                                    (input)="limitMaximumOrderDigits($event)"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="row"
                              *ngIf="offer.offer2 && offer.offerpercentage2"
                            >
                              <div class="col-7">
                                <div class="form-group m-2">
                                  <label for="maximumOrder"
                                    >Third X2 orders<span class="reqSpan"
                                      >*</span
                                    ></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control mt-2"
                                    [(ngModel)]="offer.offer3"
                                    placeholder="X2"
                                    required
                                    (input)="limitMaximumOrderDigits($event)"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group m-2">
                                  <label for="maximumOrder"
                                    >Y2 % offer<span class="reqSpan"
                                      >*</span
                                    ></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control mt-2"
                                    [(ngModel)]="offer.offerpercentage3"
                                    placeholder="Y2"
                                    required
                                    (input)="limitMaximumOrderDigits($event)"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="row"
                              *ngIf="offer.offer3 && offer.offerpercentage3"
                            >
                              <div class="col-7">
                                <div class="form-group m-2">
                                  <label for="maximumOrder"
                                    >Fourth X3 order<span class="reqSpan"
                                      >*</span
                                    ></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control mt-2"
                                    [(ngModel)]="offer.offer4"
                                    placeholder="X3"
                                    required
                                    (input)="limitMaximumOrderDigits($event)"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group m-2">
                                  <label for="maximumOrder"
                                    >Y3 % offer<span class="reqSpan"
                                      >*</span
                                    ></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control mt-2"
                                    [(ngModel)]="offer.offerpercentage4"
                                    placeholder="Y3"
                                    required
                                    (input)="limitMaximumOrderDigits($event)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-6">
                          <button
                            type="button"
                            style="float: right; width: 100%"
                            class="btn btn-success"
                            (click)="saveOffers()"
                          >
                            Save offers
                          </button>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
              <div class="row mt-2">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <p style="margin-bottom: 0">
                          <b>Add Delivery Charge</b>
                        </p>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row"></div>
                    <div class="row p-4">
                      <div class="col-6 pe-0 d-flex align-items-center">
                        <label for="exampleInputEmail1"
                          ><b>Delivery charges :</b></label
                        >
                      </div>
                      <div class="col-5 ps-0">
                        <input
                          type="number"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          (keydown)="onPinCodeKeyDown($event)"
                          [(ngModel)]="deliveryCharges"
                          placeholder="delivery charges"
                        />
                      </div>
                      <div class="col-1"></div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <button
                          type="button"
                          style="float: right; width: 100%"
                          class="btn btn-success"
                          (click)="saveDeliveryCharge()"
                        >
                          Save delivery charge
                        </button>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div class="row mt-2">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <p style="margin-bottom: 0">
                          <b>Add subscription offers</b>
                        </p>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row"></div>

                    <div class="overlay">
                      <div class="modal-content">
                        <div class="modal-body text-left p-3">
                          <div class="form-group m-2">
                            <label for="maximumOrder"
                              >Enter offer percentage for 15 days.
                            </label>
                            <input
                              type="number"
                              class="form-control mt-2"
                              [(ngModel)]="subscriptionOfferPercentage"
                              placeholder="Enter subscription offer percentage for 15 days."
                              required
                              (input)="limitMaximumOrderDigits($event)"
                            />
                            <br />

                            <label for="maximumOrder"
                              >Enter offer percentage for 30 days.
                            </label>
                            <input
                              type="number"
                              class="form-control mt-2"
                              [(ngModel)]="subscriptionOfferPercentage2"
                              placeholder="Enter subscription offer percentage for 30 days."
                              required
                              (input)="limitMaximumOrderDigits($event)"
                            />
                          </div>

                          <div class="row">
                            <div class="col-6">
                              <button
                                type="button"
                                style="float: right; width: 100%"
                                class="btn btn-success saveBtn"
                                (click)="saveSubscriptionOfferPercent()"
                              >
                                Save subscription offers
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>

              <!-- <div class="row mt-2">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <p style="margin-bottom: 0">
                          <b>Set invoice numbers</b>
                        </p>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row"></div>

                    <div class="overlay">
                      <div class="modal-content">
                        <div class="modal-body text-left p-3">
                          <div class="form-group m-2">
                            <label for="maximumOrder"
                              >Enter Invoice Prefix
                            </label>
                            <input
                              type="text"
                              class="form-control mt-2"
                              [(ngModel)]="invoicePrefix"
                              placeholder="Enter Invoice Prefix."
                              required
                            />
                            <br />

                            <label for="maximumOrder"
                              >Enter Invoice Prefix
                            </label>
                            <input
                              type="number"
                              class="form-control mt-2"
                              [(ngModel)]="invoiceSuffix"
                              placeholder="Enter Invoice Suffix."
                              required
                            />
                          </div>

                          <div class="row">
                            <div class="col-6">
                              <button
                                type="button"
                                style="float: right; width: 100%"
                                class="btn btn-success saveBtn"
                                (click)="saveInvoiceSettings()"
                              >
                                Save Invoice Numbers
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
