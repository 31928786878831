import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2';
const ADMIN_URL = environment.adminUrl;

@Component({
  selector: 'app-order-description',
  templateUrl: './order-description.component.html',
  styleUrls: ['./order-description.component.css'],
})
export class OrderDescriptionComponent {
  //variables name:
  dataBaseID: string;
  oderDescription: any;
  orderID: string;
  orderedDate: Date;
  orderedFor: Date;
  orderStatus: string;
  paymentStatus: string;
  totalAmount: number;
  buyerName: string;
  buyerPhone: string;
  deliveryAddress: any;
  paymentMethod: string;
  orderDetails: any;
  deliveryCharge: number;
  offerPrice: number;
  gstPrice: number;
  cancelOrderAlert: boolean = false;
  orderCancelReason: string;
  refund: string = 'no';
  cancelReason: string = '';
  refundStatus: string = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.dataBaseID = params['dateBaseID'];
    });
    console.log('the profile id is ', this.dataBaseID);
    this.checkLogin();
    this.fetchOrderDetails();
  }

  isKitchen(): boolean {
    return this.authService.isAuthenticated() && this.authService.isKitchen();
  }

  checkLogin(): void {
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }

  fetchOrderDetails(): void {
    const token = this.authService.getToken();

    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    console.log('the oder function is called');
    this.http
      .get(`${ADMIN_URL}/orderDetailsDescription/${this.dataBaseID}`, {
        headers,
      })
      .subscribe(
        (data) => {
          console.log('Fetched order details:', data);
          this.oderDescription = data;
          this.orderID = this.oderDescription.order.orderID;
          this.orderStatus = this.oderDescription.order.orderStatus;
          this.totalAmount = this.oderDescription.order.totalPrice;
          if (
            this.oderDescription &&
            this.oderDescription.order &&
            this.oderDescription.order.address
          ) {
            this.deliveryAddress = this.oderDescription.order.address;
          }

          this.orderedFor = this.oderDescription.order.orderForDate;
          this.orderedDate = this.oderDescription.order.orderedDate;
          this.paymentStatus = this.oderDescription.order.paymentStatus;
          this.paymentMethod = this.oderDescription.order.paymentMethod;
          this.deliveryCharge = this.oderDescription.order.deliveryCharge;
          this.offerPrice = this.oderDescription.order.offerPrice;
          this.orderDetails = this.oderDescription.order.orderItems;
          this.gstPrice = this.oderDescription.order.gstPrice;
          this.cancelReason = this.oderDescription.order.orderCancelReason;
          this.refundStatus = this.oderDescription.order.refund;

          this.buyerName = this.oderDescription.user.username;
          this.buyerPhone = this.oderDescription.user.mobile;

          console.log(this.orderID);
        },
        (error) => {
          console.error('Error fetching order details:', error);
        }
      );
  }

  //switch statement
  getOrderStatus(oderStatus: string) {
    switch (oderStatus) {
      case 'ACC':
        return 'Accepted';
      case 'DEL':
        return 'Delivered';
      case 'CAN':
        return 'Cancelled';
      case 'OFD':
        return 'Out for delivery';
      default:
        return oderStatus;
    }
  }

  getPaymentStatus(paymentStatus: string) {
    switch (paymentStatus) {
      case 'NPD':
        return 'Not  paid';
      case 'PAID':
        return 'Paid';
      case 'CON':
        return 'Confirmed';
      default:
        return paymentStatus;
    }
  }

  getPaymentMethod(paymentMethod: string) {
    switch (paymentMethod) {
      case 'COD':
        return 'Cash on delivery';
      case 'UPI':
        return 'UPI';
      case 'TBD':
        return 'Not decided';
      default:
        return paymentMethod;
    }
  }

  cancelOrder() {
    this.cancelOrderAlert = true;
    // Swal.fire({
    //   title: 'Cancel Order',
    //   text: '',
    //   icon: 'info',
    //   showCancelButton: true,
    //   confirmButtonText: 'OK',
    //   cancelButtonText: 'Cancel'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //    this.updateOrder();
    //   } else {
    //     Swal.close();
    //   }
    // });
  }


  cancelOrders(){
    this.cancelOrderAlert = false;
    if(this.orderCancelReason){
      Swal.fire({
        title: 'Cancel Order',
        text: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
         this.updateOrder();
        } else {
          Swal.close();
        }
      });
    }else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter the reason for canceling the order.',
      });
    }

    console.log('the cancel order reason is ',this.orderCancelReason);
    
  }

  updateOrder() {
    const token = this.authService.getToken(); // Assuming you have a method to get the authentication token
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const updateData = {
      status: 'CAN',
      orderCancelReason: this.orderCancelReason,
      refund: this.refund
    };

    this.http
      .put<any>(
        `${ADMIN_URL}/kitchen/cancelOrder/${this.orderID}`,
        updateData,
        { headers }
      )
      .subscribe(
        (response) => {
          console.log('Order updated successfully:', response);
          this.fetchOrderDetails();
        },
        (error) => {
          console.error('Error updating order:', error);
        }
      );
  }


  closeDownload(){
    this.cancelOrderAlert = false;
  }
}
