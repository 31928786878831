<app-kckdashboard></app-kckdashboard>


 <div class="main mt-4">
 
    <div class="container-fluid m">

      <div class="row">
        <div class="col">
          <div class="btn btn-secondary" (click)="createKitchen()">Register new kitchen</div>
        </div>
      </div>

     
      <div class="row  ">
        <div class="col"></div>
        <div class="col">
          <h2 style="margin-top: 40px;"><b>Create Dashboard for user</b></h2>
            <hr>
        </div>
        <div class="col">
         
        </div>
      </div>

      <div class="row">
        <div class="col"></div>
        <div class="col">
          <div class="form-outline mb-1 ">
            <label
              class="form-label"
              for="form3Example3"
              *ngIf="role === 'kitchen'"
              >Kitchen name</label
            >
            <input
              *ngIf="role === 'kitchen'"
              type="text"
              id="form3Example3"
              name="kitchenName"
              class="form-control form-control-lg"
              placeholder="Enter kitchen name"
              [(ngModel)]="kitchenName"
            />

            <label
              class="form-label mt-1"
              for="form3Example1"
              *ngIf="role === 'kitchen'"
              >Phone number</label
            >
            <input
              *ngIf="role === 'kitchen'"
              type="tel"
              id="form3Example1"
              name="username"
              class="form-control form-control-lg"
              placeholder="Registered phone number"
              pattern="[0-9]*"
              maxlength="10"
              [(ngModel)]="username"
            />
            <label class="form-label mt-1" for="form3Example3" *ngIf="role === 'admin'">User name</label>
            <input
              type="text"
              *ngIf="role === 'admin'"
              id="form3Example3"
              name="username"
              class="form-control form-control-lg"
              placeholder="Enter user name"
              [(ngModel)]="username"
            />

            <label class="form-label mt-1" for="form3Example4">Password</label>
            <input
              type="password"
              id="form3Example4"
              name="password"
              class="form-control form-control-lg"
              placeholder="Enter password"
              [(ngModel)]="password"
            />

            <label class="form-label mt-1" for="form3Example5"
              >Confirm Password</label
            >
            <input
              type="password"
              id="form3Example5"
              name="confirmPassword"
              class="form-control form-control-lg"
              placeholder="Confirm password"
              [(ngModel)]="confirmPassword"
            />
          </div>
        </div>  
        <div class="col"></div>
      </div>
    </div>
    <div class="row">
      <div class="col"></div>
        <div class="col">
            <hr>
        </div>
        <div class="col"></div>
    </div>
    <div class="container">
      <div class="row">
         <div class="col"></div>
          <div class="col">
              <h3 style="margin: 0;"><b>Select Role</b> </h3>
            </div>
            <div class="col"></div>
        <div class="row mt-2">

             <div class="col-4"></div>
             <div class="col-2" style="text-align: center;">
              <div class="form-check form-check-inline">
                  <input type="radio" id="adminRole" name="role" class="form-check-input" [(ngModel)]="role" value="admin">
                  <label class="form-check-label" for="adminRole">Admin</label>
              </div>
              </div>
              <div class="col-2 ">
              <div class="form-check form-check-inline m-0">
                  <input type="radio" id="kitchenRole" name="role" class="form-check-input" [(ngModel)]="role" value="kitchen">
                  <label class="form-check-label" for="kitchenRole">Kitchen</label>
              </div>
          </div>
          <div class="col-4"></div>
      </div>
      </div>
      <div class="row">
        <div class="col-5"></div>
      
        <div class="col-2">
          <div class="text-center text-lg-start mt-2 mb-2">
              <button
                type="button"
                class="btn btn-success btn-lg"
                style="padding-left: 2.5rem; padding-right: 2.5rem"
                (click)="register()"
              >
                Sign up
              </button>
          </div>
          </div>
        </div>
        <div class="col-5"></div>
  </div>




<!-- <div class="main">
  <section class="vh-100">
    <div class="container-fluid h-custom">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-md-9 col-lg-6 col-xl-5">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
            class="img-fluid"
            alt="Sample image"
          />
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
          <form>
            <img
              class="logologin"
              src="../../../assets/logos/pocketmart-011.png"
              alt="pocketMart"
            />

            <!- Password and email input -->
            <!-- <form>
              <label class="form-label" for="roleSelect">Select Role</label>
              <select
                id="roleSelect"
                name="role"
                class="form-control form-control-lg"
                [(ngModel)]="role"
              >
                <option value="admin">Admin</option>
                <option value="kitchen">Kitchen</option>
              </select>

              <div class="form-outline mb-3">
                <label
                  class="form-label"
                  for="form3Example3"
                  *ngIf="role === 'kitchen'"
                  >Kitchen name</label
                >
                <input
                  *ngIf="role === 'kitchen'"
                  type="text"
                  id="form3Example3"
                  name="kitchenName"
                  class="form-control form-control-lg"
                  placeholder="Enter kitchen name"
                  [(ngModel)]="kitchenName"
                />

                <label
                  class="form-label"
                  for="form3Example1"
                  *ngIf="role === 'kitchen'"
                  >Phone number</label
                >
                <input
                  *ngIf="role === 'kitchen'"
                  type="tel"
                  id="form3Example1"
                  name="username"
                  class="form-control form-control-lg"
                  placeholder="Registered phone number"
                  pattern="[0-9]*"
                  maxlength="10"
                  [(ngModel)]="username"
                />

                <label class="form-label" for="form3Example3" *ngIf="role === 'admin'">User name</label>
                <input
                  type="text"
                  *ngIf="role === 'admin'"
                  id="form3Example3"
                  name="username"
                  class="form-control form-control-lg"
                  placeholder="Enter user name"
                  [(ngModel)]="username"
                />

                <label class="form-label" for="form3Example4">Password</label>
                <input
                  type="password"
                  id="form3Example4"
                  name="password"
                  class="form-control form-control-lg"
                  placeholder="Enter password"
                  [(ngModel)]="password"
                />

                <label class="form-label" for="form3Example5"
                  >Confirm Password</label
                >
                <input
                  type="password"
                  id="form3Example5"
                  name="confirmPassword"
                  class="form-control form-control-lg"
                  placeholder="Confirm password"
                  [(ngModel)]="confirmPassword"
                />
              </div>
            </form> -->

            <!-- <div class="text-center text-lg-start mt-2">
              <button
                type="button"
                class="btn btn-success btn-lg"
                style="padding-left: 2.5rem; padding-right: 2.5rem"
                (click)="register()"
              >
                Sign up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div> -->
    <!-- <div
      class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-3 px-4 px-xl-5 bg-success"
    >
      <!- Copyright -->
      <!-- <div class="text-white mb-3 mb-md-0">
        <p>PocketMart</p>
      </div> -->
      <!-- Copyright -->

      <!-- Right -->
      <!-- <div>
        <a href="#!" class="text-white me-4">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="#!" class="text-white me-4">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="#!" class="text-white me-4">
          <i class="fab fa-google"></i>
        </a>
        <a href="#!" class="text-white">
          <i class="fab fa-linkedin-in"></i>
        </a>
      </div> -->
      <!-- Right -->
    <!-- </div> 
  </section>
</div> -->

