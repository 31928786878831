<app-kckdashboard></app-kckdashboard>

<div class="container main">
  <div class="row m-3">
    <h3 class="header"><b>Kitchen Reports</b></h3>
  </div>
  <div class="container m-2 p-2">
    <table class="table caption-top table-striped">
      <caption>
        <P class="list">List of kitchen</P>
      </caption>
      <thead>
        <tr>
          <th scope="col">Sl.No</th>
          <th scope="col">{{ "KitchenID" | translate }}</th>
          <th scope="col">{{ "Kitchenname" | translate }}</th>
          <th scope="col">{{ "Contact" | translate }}</th>
          <th scope="col">{{ "Joiningdate" | translate }}</th>
          <th scope="col">Account status</th>
          <th scope="col">Analytics</th>
        </tr>
      </thead>

      <tbody class="usertable">
        <tr *ngFor="let kitchenDetails of kitchenDetails; let i = index">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ kitchenDetails.profileID }}</td>
          <td>{{ kitchenDetails.username }}</td>
          <td>{{ kitchenDetails.mobile }}</td>
          <td>{{ kitchenDetails.date | slice : 0 : 10 }}</td>
          <td>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm"
              (click)="kckDetails(kitchenDetails._id)"
            >
              Details
            </button>
          </td>

          <td>
            <button
              type="button"
              class="btn btn-outline-success btn-sm"
              (click)="KCKanalytics(kitchenDetails.profileID, kitchenDetails.username)"
            >
              Analytics
            </button>
          </td>
        </tr>
      </tbody>
    </table>

   
  </div>
</div>
