import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent {
  username: string = '';
  password: string = '';
  confirmPassword: string = '';
  phoneNumber: number;
  error: string = '';
  kitchenName: string = '';
  role: string = 'kitchen';

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.checkLogin();
  }

  validatePhoneNumber(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.trim();

    // Allow only numeric characters and restrict the length to 10
    if (!/^\d{0,9}$/.test(inputValue)) {
      event.preventDefault();
    }
  }

  checkLogin(): void {
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }


  createKitchen(){
    this.router.navigate(['/create-kitchen']);
    
  }

  register(): void {
    if (this.role === 'kitchen') {
      if (
        this.password === '' ||
        this.confirmPassword === '' ||
        this.username === '' ||
        this.kitchenName === ''
      ) {
        return alert('All input fields are required');
      }
      if (this.password !== this.confirmPassword) {
        return alert('Passwords does not match');
      }
      // Password regex pattern
      // const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (this.password.length < 8) {
        return alert(
          'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
        );
      }
    }

    if (this.role === 'admin') {
      if (
        this.password === '' ||
        this.confirmPassword === '' ||
        this.username === ''
      ) {
        return alert('All input fields are required');
      }
      if (this.password !== this.confirmPassword) {
        return alert('Passwords does not match');
      }
      // Password regex pattern
      // const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (this.password.length < 8) {
        return alert(
          'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
        );
      }
    }

    const user = {
      kitchenName: this.kitchenName,
      username: this.username,
      password: this.password,
      role: this.role,
      phoneNumber: this.username,
    };

    this.authService.register(user).subscribe(
      (res) => {
        console.log(user);
        if (res.message == 'User registered successfully') {
          alert('Account successfully registered');
        }
        if (res.message == 'Username already exists. Signup is blocked') {
          return alert('Username already exists. Signup is blocked');
        }
        if (
          res.message ==
          'Maximum number of admin users reached. Signup is blocked'
        ) {
          return alert(
            'Maximum number of admin users reached. Signup is blocked'
          );
        }
        if (
          res.message ==
          'Maximum number of kitchen users reached. Signup is blocked'
        ) {
          return alert(
            'Maximum number of kitchen users reached. Signup is blocked'
          );
        }
        // this.router.navigate(['/login']);
        this.clearField();
        alert(res.message);
      },
      (error) => {
        this.error = error.error.message;
        console.log(error, 'err');
      }
    );
  }

  clearField() {
    this.username = '';
    this.password = '';
    this.confirmPassword = '';
    this.phoneNumber = null;
    this.error = '';
    this.kitchenName = '';
  }
}
