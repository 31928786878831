



<div (click)="goBack()">
  <i class="fa fa-chevron-circle-left backButton" aria-hidden="true"></i>
</div>
<!--Space to show the elements below floating header-->
<br />

<div class="container main p-1 profileDetailsContainer">
  <div class="row p-3">
    <div class="col-3">
      <img
        class="profileImage"
        *ngIf="imageURL || selectedImageUrls[0]"
        [src]="selectedImageUrls[0] || imageURL || defaultProfileImage"
        alt="seller images"
      />
      <div *ngIf="!imageURL">
        <img
          class="profileImage"
          *ngIf="!selectedImageUrls[0]"
          src="assets/personImages/defaultimage.jpg"
          alt="Default Image"
        />
      </div>
    </div>

    <div class="col-4">
      <p class="detailName">Profile id:&nbsp;</p>
      <p class="detailContent">
        <b style="font-size: large; color: red">{{ profileID }}</b>
      </p>
      <br />
      <button
        type="button"
        class="btn btn-light imageUploadButton btn-sm"
        [disabled]="!edit"
        (click)="deleteProfileImage()"
      >
        Delete profile picture
      </button>
      <input
        type="file"
        #fileInput
        style="display: none"
        (change)="onImageSelected($event, 0)"
      />
    </div>
    <div class="col-4">
      <!-- <button type="button" class="btn btn-primary" (click)="onEdit()">
        Enable Edit
      </button> -->
      <!-- <button
        class="btn btn-primary"
        (click)="sellerPostings(profileID, userName)"
      >
        Seller Postings
      </button> -->
    </div>
  </div>
  <div class="row">
    <div class="container">
      <div class="col-3"></div>
      <div class="col-6"></div>
      <div class="col-3"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <p class="detailName">Name:</p>
    </div>
    <div class="col-9">
      <p class="detailContent">
        <input
          type="text"
          [(ngModel)]="userName"
          class="form-control inputBoxes"
          id="formGroupExampleInput"
          placeholder="Enter Name"
          value="{{ userName }}"
          (input)="onInputChange()"
          maxlength="30"
          required
          [disabled]="!edit"
        />
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <p class="detailName">Tel:</p>
    </div>
    <div class="col-9">+91&nbsp;{{ mobileNumber }}</div>
  </div>
  <div class="row"></div>

  <div class="WhatsAppBox">
    <div class="row">
      <div class="col-3">
        <p class="detailName">WhatsApp:</p>
      </div>
      <div class="col-9">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">+91</span>
          </div>
          <input
            type="tel"
            class="form-control inputBoxes"
            (input)="onInputChange()"
            id="formGroupExampleInput"
            placeholder="Enter Whatsapp Number"
            value="{{ whatsApp }}"
            [(ngModel)]="whatsApp"
            maxlength="10"
            required
            [disabled]="!edit"
          />
        </div>
      </div>
    </div>
    <div class="row"></div>
  </div>

  <br />

  <div class="row">
    <div class="col-3">
      <p class="detailName">Address:</p>
    </div>
    <div class="col-9">
      <textarea
        [(ngModel)]="address"
        class="form-control inputBoxes"
        (input)="onInputChange()"
        id="addDescriptionTextarea"
        rows="3"
        maxlength="400"
        placeholder="Enter Address"
        [disabled]="!edit"
      ></textarea>
    </div>
  </div>

  <!--"select-category-and-subcategory for product" button ends-->

  <!-- <div class="row pt-3 pb-3">
    <label class="detailName"
      >Area of interest/Service:
      <span style="color: red">{{ areaOfInterest }}</span></label
    >
  </div> -->
  <!-- <div class="row pb-3">
    <div class="col p-1">
      <button
        type="button"
        class="btn btn-outline-success monthButton btn-sm"
        [class.active]="areaOfInterest === '2'"
        (click)="getAreaOfInterest(2)"
        [disabled]="!edit"
      >
        2 Km
      </button>
    </div>
    <div class="col p-1">
      <button
        type="button"
        class="btn btn-outline-success monthButton"
        [class.active]="areaOfInterest === '4'"
        (click)="getAreaOfInterest(4)"
        [disabled]="!edit"
      >
        4 Km
      </button>
    </div>
    <div class="col p-1">
      <button
        type="button"
        class="btn btn-outline-success monthButton"
        [class.active]="areaOfInterest === '8'"
        (click)="getAreaOfInterest(8)"
        [disabled]="!edit"
      >
        8 Km
      </button>
    </div>
    <div class="col p-1">
      <button
        type="button"
        class="btn btn-outline-success monthButton"
        [class.active]="areaOfInterest === '15'"
        (click)="getAreaOfInterest(15)"
        [disabled]="!edit"
      >
        15 Km
      </button>
    </div>
    <div class="col p-1">
      <button
        type="button"
        class="btn btn-outline-success monthButton"
        [class.active]="areaOfInterest === '25'"
        (click)="getAreaOfInterest(25)"
        [disabled]="!edit"
      >
        25 Km
      </button>
    </div>
  </div> -->

  <!-- <div class="row">
    <div class="col">
      <p class="aresInterest">
        (When you add a category to your favorites list, you'll receive
        notifications for new posts in that category, considering the above
        distance.)
      </p>
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="text-center">
      <button
        type="button"
        (input)="onInputChange()"
        class="btn btn-light optionsButton"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        Seller Options <i class="fa-solid fa-chevron-down"></i>
      </button>
    </div>
  </div> -->
  <br />
  <!--Collapsible panel (Accordian)-->
  <!-- <div class="container p-0">
    <div class="accordion" id="accordionExample">
      <h2 class="accordion-header" id="headingOne"></h2>
      <div
        id="collapseOne"
        class="accordion-collapse hide"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div>
          
          <div class="row p-1">
            <div class="col-3">
              <p class="detailName">Category:</p>
            </div>
            <div class="col-9">
              <select
                id="inputState"
                [(ngModel)]="category"
                (input)="onInputChange()"
                class="form-select"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="!edit"
              >
                <option value="ID_CAT_SEL" selected>Seller</option>
                <option value="ID_CAT_STO">Store</option>
                <option value="ID_CAT_KUD">Kudumbashree</option>
                <option value="ID_CAT_HOM">Homemade</option>
                <option value="ID_CAT_PRO">Farm Produce</option>
                <option value="ID_CAT_IND">Cottage Industry</option>
                <option value="ID_CAT_KCK">Kudumbashree Cloud Kitchen</option>
              </select>
            </div>
          </div>
          <div class="row p-1">
            <div class="col">
              <p class="detailName">About your business:</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <textarea
                [(ngModel)]="about"
                (input)="onInputChange()"
                class="form-control inputBoxes"
                id="addDescriptionTextarea"
                rows="6"
                maxlength="400"
                placeholder="Enter About"
                [disabled]="!edit"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!--Collapsible panel ends-->
</div>

<!-- Reports -->
<!-- <div class="container mt-4">
  <h2 class="text-center">Report Table</h2>
  <table class="table">
    <thead>
      <tr class="text-left">
        <th style="width: 20%">Profile ID</th>
        <th style="width: 80%">Message</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of reports" class="text-left">
        <td
          style="color: blue; font-weight: 500; cursor: pointer"
          (click)="goToSellerProfile(item.reporterID)"
        >
          {{ item.reporterID }}
        </td>
        <td class="message-cell">
          {{ item.message }}
        </td>
      </tr>

     
    </tbody>
  </table>
</div> -->
<br />

<div class="container">
  <div class="row">
    <div class="col"></div>
    <div class="col">
      <div class="editSave">
        <!-- <button
          class="btn btn-success"
          [disabled]="!hasChanges"
          (click)="save()"
        >
          Save
        </button> -->
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>

<br /><br />

<!-- <app-navigation-bar></app-navigation-bar> -->
