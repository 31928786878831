import { Component, OnInit } from '@angular/core';
import { TiffinDeliveryLocationsService } from './tiffin-delivery-locations.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
const BACKEND_URL = environment.adminUrl;

@Component({
  selector: 'app-add-delivery-person',
  templateUrl: './add-delivery-person.component.html',
  styleUrls: ['./add-delivery-person.component.css']
})
export class AddDeliveryPersonComponent implements OnInit {
  tiffinLocations: any;
  selectedLocation:any=[];
  username:any=null;
  phoneNumber:any=null;
  keyword:any=null;

  // defaultLocation(){
  //   const defaultLocation = {
  //     address: 'Trivandrum medical college',
  //     latitude: 18.254,
  //     longitude: 15.1,
  //   };

  //   this.selectedLocation.push(defaultLocation.address)
  // }

  toggleSelected(location: any): void {
    if (location.selected) {
      this.selectedLocation.push(location.address);
    } else {
      const index = this.selectedLocation.findIndex((loc: any) => loc.id === location.id);
      if (index !== -1) {
        this.selectedLocation.splice(index, 1);
      }
    }
  }


  ngOnInit(): void {
    // this.defaultLocation()
    this.tiffinLocations = this.TiffinDeliveryLocationsService.tiffinLocations;
  }

  constructor(private http: HttpClient, private TiffinDeliveryLocationsService: TiffinDeliveryLocationsService) {}


  createDeliveryAccount() {
    if(this.selectedLocation.length==0||!this.username||!this.keyword||!this.phoneNumber){
      Swal.fire({
        icon: 'error',
        title: 'Fill all required data',
        text: 'Please fill all the required datas.'
      });
      return
    }
    console.log('createDeliveryAccount', this.selectedLocation, this.username, this.phoneNumber, this.keyword);

    const newDeliveryPerson = {
      username: this.username,
      keyword: this.keyword,
      phone: this.phoneNumber,
      assignedLocation: this.selectedLocation
    };
 console.log('createDeliveryAccount', newDeliveryPerson);
    this.http.post<any>(`${BACKEND_URL}/delivery/create`, newDeliveryPerson)
      .subscribe(
        response => {
          console.log('Delivery person created successfully:', response.message);
          if (response.message === 'Delivery person created successfully') {
            Swal.fire({
              icon: 'success',
              title: 'User Created Successfully',
              text: 'The user has been created successfully.'
            }).then(() => {
              // Reload the window
              window.location.reload();
            });
          }
        },
        error => {
          console.error('Error creating delivery person:', error.error);
          if(error.error.message=="User already exists"){
            Swal.fire({
              icon: 'error',
              title: 'User Already Exists',
              text: 'The user with this phone number already exists.'
            });
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops an error occured',
              text: 'Internal server error.'
            });
          }
          // Handle error (e.g., display an error message)
        }
      );
  }

}
