<nav>
  <div class="navbar">
    <a class="nav-link" >
      <i class="fa fa-user-circle fa-2x adminicon"></i>
      <h6 (click)="onAdminClick()" class="adminheading">Admin</h6>
      <button class="settingsbtn" (click)="onSettings()"><i class="fa fa-sliders settingsicon" aria-hidden="true"></i></button>
    </a>
    <div class="logout" (click)="onLogout()">
      <i class="fa fa-sign-out logouticon" aria-hidden="true"></i>
      <h6>Log Out</h6></div>
    <img class="imglogo" src="../../../assets/logo.jpg" alt="Amigo">
  </div>
</nav>
