import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
const ADMIN_URL = environment.adminUrl;

export interface ChangePasswordResponse {
  message: string;
}
@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css'],
})
export class PasswordChangeComponent {
  username: string = '';
  curentPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  phoneNumber: number;
  error: string = '';
  kitchenName: string = '';
  role: string = 'kitchen';

  constructor(
    private authService: AuthService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.checkLogin();
  }

  validatePhoneNumber(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.trim();

    // Allow only numeric characters and restrict the length to 10
    if (!/^\d{0,9}$/.test(inputValue)) {
      event.preventDefault();
    }
  }

  checkLogin(): void {
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }

  submit(): void {
    if (
      this.curentPassword === '' ||
      this.confirmPassword === '' ||
      this.username === '' ||
      this.role === ''
    ) {
      return alert('All input fields are required');
    }
    if (this.newPassword !== this.confirmPassword) {
      return alert('Passwords does not match');
    }
    // Password regex pattern
    // const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (this.newPassword.length < 8) {
      return alert(
        'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
      );
    }

    const user = {
      username: this.username,
      curentPassword: this.curentPassword,
      newPassword: this.newPassword,
      role: this.role,
    };

    console.log(user)

    const token = this.authService.getToken();
    
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('username', this.username)
      .set('currentPassword', this.curentPassword)
      .set('newPassword', this.newPassword)
      .set('role', this.role);
    console.log('headers',headers)
    console.log('params',params);
    const options = { headers, params };
    this.http.put(ADMIN_URL + '/passwordChange', {}, options).subscribe(
      (data:any) => {
        Swal.fire( data.message);
        this.clearField();
        this.authService.logOut();
      },
      (error) => {
        console.log(error)
      }
    );
  }

  clearField() {
    this.username = '';
    this.curentPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.phoneNumber = null;
    this.error = '';
    this.kitchenName = '';
  }
}
