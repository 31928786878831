import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  constructor(private router: Router) {}

  onSettings() {
    // Perform login logic here

    // Redirect to the admin page
    this.router.navigate(['/settings']);
  }
  onAdminClick(){
    this.router.navigate(['/dashboard']);
  }
  onLogout(){
    localStorage.removeItem('token');
    this.router.navigate(['/login']);


  }
}
