import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { SignupComponent } from './components/signup/signup.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { EditServiceComponent } from './components/edit-service/edit-service.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SellerPostingsComponent } from './components/seller-postings/seller-postings.component';
import { SellerDetailsComponent } from './components/seller-details/seller-details.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { SearchSellerComponent } from './components/search-seller/search-seller.component';
import { CloudKitchenComponent } from './components/cloud-kitchen/cloud-kitchen.component';
import { CloudKitchenDetailsComponent } from './components/cloud-kitchen-details/cloud-kitchen-details.component';
import { KckdashboardComponent } from './kckdashboard/kckdashboard.component';
import { KckanalyticsComponent } from './kckanalytics/kckanalytics.component';
import { UserdetailsComponent } from './userdetails/userdetails.component';
import { KitchenOrderDetailsComponent } from './kitchen-order-details/kitchen-order-details.component';
import { OrderDescriptionComponent } from './order-description/order-description.component';
import { CentralkitchenComponent } from './centralkitchen/centralkitchen.component';
import { KitchendetailsComponent } from './kitchendetails/kitchendetails.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { AddDeliveryPersonComponent } from './add-delivery-person/add-delivery-person.component';
import { ViewDeliveryPersonsComponent } from './view-delivery-persons/view-delivery-persons.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NotificationComponent } from './notification/notification.component';
import { SubscriptionPageComponent } from './subscription-page/subscription-page.component';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { SubscriptionOrdersComponent } from './subscription-orders/subscription-orders.component';
import { CreateKitchenComponent } from './create-kitchen/create-kitchen.component';

import { SettingsComponent } from './settings/settings.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProductsListComponent,
    EditProductComponent,
    SignupComponent,
    ChangePasswordComponent,
    NavbarComponent,
    EditServiceComponent,
    SellerPostingsComponent,
    SellerDetailsComponent,
    AnalyticsComponent,
    SearchSellerComponent,
    CloudKitchenComponent,
    CloudKitchenDetailsComponent,
    KckdashboardComponent,
    KckanalyticsComponent,
    UserdetailsComponent,
    KitchenOrderDetailsComponent,
    OrderDescriptionComponent,
    CentralkitchenComponent,
    KitchendetailsComponent,
    PasswordChangeComponent,
    AccountDetailsComponent,
    AddDeliveryPersonComponent,
    ViewDeliveryPersonsComponent,
    NotificationComponent,
    SubscriptionPageComponent,
    SubscriptionDetailsComponent,
    SubscriptionOrdersComponent,
    CreateKitchenComponent,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatExpansionModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatIconModule,
    MatMenuModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
