import { Injectable } from '@angular/core';
interface tiffinLocations {
  address: string;
  latitude: number;
  longitude: number;
}
@Injectable({
  providedIn: 'root',
})
export class TiffinDeliveryLocationsService {
  constructor() {}

  // tiffinLocations: tiffinLocations[] = [
  //   {
  //     address: 'Trivandrum medical college',
  //     latitude: 18.254,
  //     longitude: 15.1,
  //   },
  //   { address: 'Trivandrum secretariat', latitude: 14.254, longitude: 25.1 },
  //   { address: 'Pattom', latitude: 15.254, longitude: 29.1 },
  //   { address: 'PMG', latitude: 15.254, longitude: 29.1 },
  // ];

  tiffinLocations: tiffinLocations[] = [
    {
      address: 'Trivandrum medical college',
      latitude: 8.5236,
      longitude: 76.9283,
    },
    { address: 'Pattom', latitude: 8.518645, longitude: 76.942038 },
    { address: 'LMS', latitude: 8.508539, longitude: 76.952246 },
    { address: 'Statue', latitude: 8.497644, longitude: 76.948482 },
    { address: 'Ayurveda college', latitude: 8.491349, longitude: 76.946842 },
    // { address: 'Nalanchira', latitude: 8.5456, longitude: 76.9444 },
    // { address: 'Poojapura', latitude: 8.4878, longitude: 76.980582 },
    // { address: 'Civil Station', latitude: 8.555472, longitude: 76.962056 },
    // { address: 'Karamana', latitude: 8.481936, longitude: 76.965851 },
    // { address: 'Vazhuthacaud', latitude: 8.502234, longitude: 76.964263 },
    // { address: 'Jagathy', latitude: 8.495958, longitude: 76.966231 },
  ];




}
