<app-kckdashboard></app-kckdashboard>

<div class="main">
  <div class="container card cardmain">
    <div class="row">
      <div class="col-10">
        <p class="details">Order Details</p>
        <p style="color: gray">Order listings > Order Details</p>

        <div class="container">
          <div class="row">
            <div class="col">
              <div class="container card">
                <div class="row p-1">
                  <div class="col">
                    Order ID:
                    <span style="color: brown"
                      ><b>{{ orderID }}</b></span
                    >
                  </div>
                  <div class="col" style="text-align: right">
                    Ordered Date:
                    <span style="color: brown"
                      ><b>{{ orderedDate | date : "dd-MM-yyyy" }}</b></span
                    >
                  </div>
                </div>
                <div class="row p-1">
                  <div class="col">
                    <p>
                      Ordered For:
                      <span>{{ orderedFor | date : "dd-MM-yyyy" }}</span>
                    </p>
                    <p>
                      Order Status:
                      <span>{{ getOrderStatus(orderStatus) }}</span>
                    </p>
                    <p>
                      Payment Status:
                      <span>{{ getPaymentStatus(paymentStatus) }}</span>
                    </p>
                    <p>
                      Payment Method:
                      <span>{{ getPaymentMethod(paymentMethod) }}</span>
                    </p>

                    <p *ngIf="orderStatus === 'CAN'">
                      Order cancel reason:
                      <span>{{cancelReason}}</span>
                    </p>

                    <p *ngIf="orderStatus === 'CAN'">
                      Refund:
                      <span>{{refundStatus}}</span>
                    </p>
                    <div class="div" >
                      <button
                        
                        style="float: right"
                        type="button"
                        class="btn btn-danger"
                        (click)="cancelOrder()"
                        [disabled]="orderStatus == 'CAN'"
                      >
                        Cancel Order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container mt-3 card">
                <div class="row p-3 pb-0">
                  <div class="col">
                    <p class="card-title">
                      <b>Order Summary</b>
                    </p>
                  </div>
                </div>

                <div
                  class="row p-3 pb-0"
                  *ngFor="let orderDetails of orderDetails"
                >
                  <div class="col">{{ orderDetails.name }}</div>
                  <div class="col">
                    quantity:&nbsp;{{ orderDetails.quantity }}
                  </div>
                  <div class="col" style="text-align: right">
                    {{ orderDetails.price }}
                  </div>
                  <hr style="margin-bottom: 1px" />
                </div>

                <div class="row p-3 pb-0">
                  <div class="col">
                    Packing charge
                  </div>
                  <div class="col" style="text-align: right;">
                    {{ deliveryCharge }}
                  </div>
                  <hr style="margin-bottom: 1px" />
                </div>

                <div class="row p-3 pb-0">
                  <div class="col">
                    GST(5%)
                  </div>
                  <div class="col" style="text-align: right;">
                    {{ gstPrice }}
                  </div>
                  <hr style="margin-bottom: 1px" />
                </div>

                <div class="row p-3 pb-0">
                  <div class="col">
                    Offer price
                  </div>
                  <div class="col" style="text-align: right;">
                    -{{ offerPrice }}
                  </div>
                  <hr style="margin-bottom: 1px" />
                </div>

                <div
                  class="row p-3"
                  style="
                    background: linear-gradient(
                      to right,
                      #f3f3d5 0%,
                      #ffd5d5 100%
                    );
                  "
                >
                  <div class="col">Amount to be paid</div>
                  <div class="col" style="text-align: right">
                    {{ totalAmount }}
                  </div>
                </div>
              </div>
            </div>
            <!-- delivery details -->
            <div class="col">
              <div class="row card">
                <p class="card-title pt-2">
                  <b>Delivery details</b>
                </p>
                <div class="row">
                  <div class="col-4">
                    <p>Delivery For</p>
                  </div>
                  <div class="col">
                    <p>{{ buyerName }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <p style="margin-bottom: 0">Contact Details</p>
                  </div>
                  <div class="col">
                    <p>
                      {{ oderDescription.order.address.deliveryNumber }} - ({{
                        buyerPhone
                      }})
                    </p>
                  </div>
                </div>
                <div class="row p-1">
                  <div class="col-5 card" style="margin-left: 40px">
                    <p style="margin-bottom: 0"><u>Delivery address >></u></p>
                    <p>
                      <b>{{ deliveryAddress.address }}</b>
                    </p>
                    <p>
                      <b>{{ deliveryAddress.building
                      }}</b>
                    </p>
                    <p>
                      <b>{{ deliveryAddress.locality
                      }}</b>
                    </p>
                  </div>

                  <div class="col-7"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-2"></div>
    </div>
  </div>


  <!---->

  <div class="container box"  *ngIf="cancelOrderAlert">
    <div class="row">
      <div class="col card todayOrder">

      <div class="row">
        <div class="col">
          <button style="float: right; padding-left: 6px; padding-right: 6px;"
          type="button"
          (click)="closeDownload()"
          class="btn btn-secondary"
        >
        <i class="fa-regular fa-rectangle-xmark"></i>
        </button>
        </div>

      </div>


        <!-- <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Reason for canceling the order</label>
           
            <input type="text" class="form-control" id="exampleInputEmail1" 
            maxlength="80"
            [(ngModel)]="orderCancelReason"
            [ngModelOptions]="{standalone: true}"
             placeholder="Please enter the reason for canceling the order.">
          </div>
        </form> -->

        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Reason for canceling the order</label>
            <input type="text" class="form-control" id="exampleInputEmail1" maxlength="80" [(ngModel)]="orderCancelReason" [ngModelOptions]="{standalone: true}" placeholder="Please enter the reason for canceling the order.">
          </div>

          <br>
  
          <div class="form-group">
            <label>Eligible for refund</label><br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="refundOption" id="refundYes" value="yes" [(ngModel)]="refund">
              <label class="form-check-label" for="refundYes">Yes</label>
            </div>
            <br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="refundOption" id="refundNo" value="no" [(ngModel)]="refund">
              <label class="form-check-label" for="refundNo">No</label>
            </div>
          </div>
        </form>
        

        <div class="row mt-3">
          <div class="col-4">
            
          </div>
          <div class="col">
            <button
              type="button"
              style="float: right;"
             (click)="cancelOrders()"
              class="btn btn btn-danger"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---->
</div>

