<app-kckdashboard></app-kckdashboard>
<div class="main">
  <div class="container mt-4">
    <div class="row">
      <div class="col">
        <p class="header">
          <b> Notifications </b>
        </p>
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <div class="row">
      <div class="col ">
        <div class="div card p-4">
        <form>
          <div class="form-group">
            <label for="notificationTitle">Notification title</label>
            <input
              type="text"
              class="form-control"
              id="notificationTitle"
              [(ngModel)]="notificationTitle"
              [ngModelOptions]="{standalone: true}"
              aria-describedby="emailHelp"
              maxlength="60"
              placeholder="Enter notification title"
            />
          </div>

          <br>

          <div class="form-group">
            <label for="notificationBody">Notification body</label>
            <input
              type="text"
              class="form-control"
              id="notificationBody"
              [(ngModel)]="notificationBody"
              [ngModelOptions]="{standalone: true}"
              aria-describedby="emailHelp"
              maxlength="300"
              placeholder="Enter notification body"
            />
          </div>

          <br>
          <div *ngIf="spinner" class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <button *ngIf="!spinner" style="float: right;" type="submit" (click)="notificationSubmit()" class="btn btn-primary">Submit</button>
        </form>
      </div>
      </div>
      <div class="col">
        <div class="div card p-4 mb-2" *ngFor="let notificationData of notificationData">
          <p style="color: grey;">ID:&nbsp;&nbsp;{{notificationData._id}}</p>
         <p><b>Notification title:</b>&nbsp;&nbsp;{{notificationData.notificationTitle}}</p>
         <p><b>Notification title:</b>&nbsp;&nbsp;{{notificationData.notificationBody}}</p>

        </div>


      </div>
    </div>
  </div>
</div>
