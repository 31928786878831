<!-- Pills navs -->
<app-navbar></app-navbar>
<div class="mainCon">
  <br>
  <br>
  <br>
  <div class="container">
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">

        <!-- Pills navs -->

        <!-- Pills content -->
        <div class="tab-content">
          <div class="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
            <form>
              <div class="text-center mb-3">
                <h5>Change password</h5>

              </div>

              <!-- Email input -->
              <div class="form-outline mb-3">
                <input type="username" id="loginUsername" class="form-control" [(ngModel)]="username" [ngModelOptions]="{standalone: true}"/>
                <label class="form-label" for="loginUsername">Enter username</label>
              </div>
              <div class="form-outline mb-3">
                <input type="password" id="loginName" class="form-control" [(ngModel)]="currentPassword" [ngModelOptions]="{standalone: true}"/>
                <label class="form-label" for="loginName">Enter current password</label>
              </div>

              <!-- Password input -->
              <div class="form-outline mb-3">
                <input type="password" id="loginPassword" class="form-control" [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}"/>
                <label class="form-label" for="loginPassword">Enter new password</label>
              </div>
              <div class="form-outline mb-3">
                <input type="password" id="loginPassword" class="form-control" [(ngModel)]="ConfirmNewPassword" [ngModelOptions]="{standalone: true}"/>
                <label class="form-label" for="loginPassword">Re-enter new password</label>
              </div>

              <!-- 2 column grid layout -->


              <!-- Submit button -->
              <button type="submit" class="btn btn-primary btn-block mb-4 changedpass" (click)="changePassword()">Change Password</button>

              <!-- Register buttons -->
              <div class="text-center">
                <p class="signinagain" (click)="onLogin()">Sign-In</p>
              </div>
            </form>
          </div>

        </div>

      </div>

      </div>
    </div>

</div>
