import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

const ADMIN_URL = environment.adminUrl;


@Component({
  selector: 'app-seller-postings',
  templateUrl: './seller-postings.component.html',
  styleUrls: ['./seller-postings.component.css']
})
export class SellerPostingsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}
 // Function to go back to the previous page
 goBack() {
  // Navigate back in the router history
  this.location.back()
}
  ngOnInit() {
    // Call the fetchProduct() method here
    this.authUser();

    // this.fetchSeller()
    this.route.queryParams.subscribe((params) => {
      if (params['sellerId']) {
        console.log('available SELLER');
        this.sellerProfileID = params['sellerId'];
        this.sellerUsername = params['username'];
      }
    });
    this.fetchProducts();
    this.fetchServices();
  }

  sellerProfileID: string;
  sellerUsername: string;
  searchInput: string;

  onLogout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
  onAdminClick() {
    this.router.navigate(['/dashboard']);
  }
  onSettings() {
    // Perform login logic here
    console.log('hi');
    // Redirect to the admin page
    this.router.navigate(['/settings']);
  }
  editProduct(productId: string) {
    // Navigate to the edit product page using the product ID
    console.log('edit', productId);
    this.router.navigate(['/edit-product'], {
      queryParams: {
        productId: productId,
      },
    });
  }
  editService(serviceId: string) {
    console.log('edit', serviceId);
    this.router.navigate(['/edit-service'], {
      queryParams: {
        serviceId: serviceId,
      },
    });
  }

  authUser() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    this.http.get(ADMIN_URL + '/verifyToken', { headers }).subscribe(
      (res) => {
        console.log('User is authenticated');
        if ((res as any).message === 'Invalid token') {
          this.router.navigate(['/login']);
        }
      },
      (err) => {
        // Token is invalid, redirect to the login page
        this.router.navigate(['/login']);
      }
    );
  }


  productLists: any[] = [];
  serviceLists: any[] = [];
  selectedSubCategory: any[] = [];

  isApproved: any = 'Approval Status';


  categoryServiceDropdownHeading: string = 'Category';

  productStatus: string = 'Status';
  productCategory: string = 'Sub Category';
  serviceStatus: string = 'Status';
  serviceCategory: string = 'Category';
  isApprovedServiceStatus: any = 'Approval Status';
  serviceStatusDropdownHeading: string = 'Status';

  fetchProducts() {
    console.log(this.productStatus, this.productCategory);


    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
if(this.sellerProfileID){
  this.http
  .get<any[]>(ADMIN_URL + `/products/${this.sellerProfileID}`, {  headers })
  .subscribe(
    (response) => {
      console.log(response,"line noum");
      this.productLists = response;
    },
    (error) => {
      console.error('Error:', error);
    }
  );
}

  }

  fetchServices() {

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
if(this.sellerProfileID){
  this.http
  .get<any[]>(ADMIN_URL + `/services/${this.sellerProfileID}`, { headers })
  .subscribe(
    (response) => {
      console.log(response);
      this.serviceLists = response;
    },
    (error) => {
      console.error('Error:', error);
    }
  );
}

  }

  editSeller(sellerId: string) {
    console.log('edit', sellerId);
    this.router.navigate(['/edit-seller'], {
      queryParams: {
        sellerId: sellerId,
      },
    });
  }



}
