import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

const ADMIN_URL = environment.adminUrl;

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.css'],
})
export class EditServiceComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  categoryDropdownHeading: string = 'Category';
  serviceCategory: string = 'Sub Category';
  serviceCategorySwitch(item: any) {
    switch (item) {
      case 'ID_FRM':
        return 'Farm Services';
      // Add more cases as needed
      case 'ID_SKL':
        return 'Skilled Services';
      case 'ID_LLE':
        return 'Local Events';
      case 'ID_OTH':
        return 'Other Services';
      default:
        return 'All';
    }
  }

  // imageURL = adminUrl;
  serviceImageURLs = null;

  service: any = [];
  serviceImageName: string = '';
  isApproved: boolean;
  serviceId: string = '';
  serviceCat: string = 'Select service category';
  serviceTitle: string = '';
  serviceDescription: string = '';
  serviceStatus: string;
  serviceID: string = '';

  // Function to go back to the previous page
  goBack() {
    // Navigate back in the router history
    this.location.back();
  }
  ngOnInit(): void {
    this.authUser();
    this.route.queryParams.subscribe((params) => {
      if (params['serviceId']) {
        console.log('available service', params);
        this.serviceId = params['serviceId'];
      }
    });
    console.log(this.serviceId, 'hello serviceId');

    this.getService(this.serviceId).subscribe(
      (res) => {
        console.log(res);
        this.service = res.services;
        this.serviceImageURLs = res.services.serviceImageURL;
        this.serviceCat = res.services.serviceCategory;
        this.serviceDescription = res.services.serviceDescription;
        this.serviceTitle = res.services.serviceTitle;
        this.serviceStatus = res.services.serviceStatus;
        this.isApproved = res.services.isApproved;
        this.serviceID = res.services.serviceID;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  setServiceCategory(option: string) {
    console.log(option, 'option');
    this.serviceCat = option;
  }

  getService(serviceId: string): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    const url = `${ADMIN_URL}/getService/${serviceId}`;
    return this.http.get(url, { headers });
  }

  onDelete(serviceId: string, imageIndex: Number) {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    const url = `${ADMIN_URL}/images/services/${serviceId}/${imageIndex}`;
    if (
      window.confirm(
        'Are you sure you want to delete?\nOnce deleted the image cannot be recovered'
      )
    ) {
      this.http.delete(url, { headers }).subscribe(
        (response) => {
          console.log(response);
          if ((response as any).message === 'Image deleted successfully') {
            alert('Image deleted successfully');
            this.getService(this.serviceId).subscribe(
              (res) => {
                console.log(res.services);
                this.service = res.services;
                this.serviceImageURLs = res.serviceImageURL;
              },
              (err) => {
                console.log(err);
              }
            );
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  authUser() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    this.http.get(ADMIN_URL + '/verifyToken', { headers }).subscribe(
      (res) => {
        console.log('User is authenticated');
        if ((res as any).message === 'Invalid token') {
          this.router.navigate(['/login']);
        }
      },
      (err) => {
        // Token is invalid, redirect to the login page
        this.router.navigate(['/login']);
      }
    );
  }

  onApprove() {
    // Prepare the payload based on user input
    const payload: any = {};
    let formData = new FormData();
    // Append each selected file to the FormData object
    if (this.serviceImageData.length > 0) {
      for (let i = 0; i < this.serviceImageData.length; i++) {
        formData.append('images', this.serviceImageData[i]);
      }
    }
    // Append listingImage to formData
    if (this.serviceThumbNailImage) {
      formData.append('images', this.serviceThumbNailImage);
    }
    // Add fields to the payload if they have been updated
    if (this.serviceCat) {
      payload.serviceCategory = this.serviceCat;
    }
    if (this.serviceStatus) {
      payload.serviceStatus = this.serviceStatus;
    }
    if (this.serviceDescription) {
      payload.serviceDescription = this.serviceDescription;
    }
    if (this.serviceTitle) {
      payload.serviceTitle = this.serviceTitle;
    }
    payload.isApproved = this.isApproved;
    payload.profileID = this.service.profileID;
    //Update the Service Date
    payload.date = new Date().toISOString().substr(0, 10).toString();
    console.log(payload);
    // Append the payload as a JSON string to formData
    formData.append('payload', JSON.stringify(payload));

    console.log(this.isApproved);
    // Make an API call to update the service
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    if (
      window.confirm('Are you sure you want to update the service details?')
    ) {
      this.http
        .put(ADMIN_URL + `/service/${this.serviceId}`, formData, {
          headers,
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            if (response.message === 'Service updated successfully') {
              alert('Service updated successfully');
            }
            // Handle success, display a message, etc.
            this.router.navigate(['/dashboard']);
          },
          (error) => {
            console.error('Error updating service', error);
            alert('Error updating service!');
            // Handle error
          }
        );
    }
  }

  onDontApprove() {
    this.router.navigate(['/dashboard']);
  }
  showimg: boolean = false;
  zoomsrc: string;
  imageClick(imageUrl) {
    console.log('Image clicked');
    this.showimg = true;
    this.zoomsrc = imageUrl;
  }
  hideImg() {
    this.showimg = false;
  }
  confirmDeleteService() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    if (window.confirm('Are you sure you want to delete?')) {
      this.http
        .delete<any>(ADMIN_URL + `/service/${this.serviceId}`, {
          headers,
        })
        .subscribe((res) => {
          console.log(res);
          console.log('Deleted', this.serviceId);
          this.location.back();
        });
    }
  }

  uploadButton1Enabled: boolean = true;

  editImagesArray: any[] = [];
  serviceForEdit: any;
  defaultImages = 'assets/personImages/defaultimage.jpg';

  selectedImageUrls: (string | ArrayBuffer)[] = [];
  serviceImageData: File[] = [];
  serviceThumbNailImage: File | undefined;

  onImageSelected(event, index) {
    console.log(event);
    const file = event.target.files[0];
    this.compressImage(file, index)
      .then((compressedFile) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImageUrls[index] = reader.result;
        };
        reader.readAsDataURL(compressedFile as Blob);

        const compressedFileName = this.getCompressedImageName(index);
        const compressedImageFile = new File(
          [compressedFile as Blob],
          compressedFileName
        );
        this.serviceImageData[index] = compressedImageFile;

        if (index === 0) {
          this.compressFurtherImage(compressedImageFile)
            .then((furtherCompressedFile) => {
              this.serviceThumbNailImage = furtherCompressedFile;
            })
            .catch((error) => {
              console.error('Listing image compression error:', error);
            });
        }
      })
      .catch((error) => {
        console.error('Image compression error:', error);
      });
    if (index === 0) {
      this.uploadButton1Enabled = true;
    }
  }

  getCompressedImageName(index) {
    const productID = this.serviceID; // Replace with the actual profile ID variable
    return `${productID}_image${index + 1}`;
  }
  compressImage(file, index) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const size = Math.min(image.width, image.height);
          const xOffset = (image.width - size) / 2;
          const yOffset = (image.height - size) / 2;
          const maxWidth = 800;
          const maxHeight = 800;
          let width = size;
          let height = size;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(
            image,
            xOffset,
            yOffset,
            size,
            size,
            0,
            0,
            width,
            height
          );

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], 'compressed_image.jpg');
              if (compressedFile.size > 300 * 1024) {
                const quality = (300 * 1024) / compressedFile.size;
                canvas.toBlob(
                  (furtherBlob) => {
                    const furtherCompressedFile = new File(
                      [furtherBlob],
                      'further_compressed_image.jpg'
                    );
                    resolve(furtherCompressedFile);
                  },
                  'image/jpeg',
                  quality
                );
              } else {
                resolve(compressedFile);
              }
            },
            'image/jpeg',
            0.9
          );
        };
        if (typeof event.target.result === 'string') {
          image.src = event.target.result;
        }
      };
      reader.onerror = (error) => {
        console.error('File reading error:', error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  compressFurtherImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          let width = image.width;
          let height = image.height;

          if (file.size > 300 * 1024) {
            const maxSize = 150;
            if (width > height) {
              height *= maxSize / width;
              width = maxSize;
            } else {
              width *= maxSize / height;
              height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob: Blob) => {
                const furtherCompressedFile = new File(
                  [blob],
                  this.serviceID + '_thumbnailImage.jpg'
                );
                resolve(furtherCompressedFile);
              },
              'image/jpeg',
              1
            );
          } else {
            // If the image size is smaller or equal to 300 KB, perform only square cropping
            const size = Math.min(width, height);
            const xOffset = (width - size) / 2;
            const yOffset = (height - size) / 2;
            const squareSize = 150;

            canvas.width = squareSize;
            canvas.height = squareSize;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(
              image,
              xOffset,
              yOffset,
              size,
              size,
              0,
              0,
              squareSize,
              squareSize
            );

            canvas.toBlob(
              (blob: Blob) => {
                const croppedFile = new File(
                  [blob],
                  this.serviceID + '_thumbnailImage.jpg'
                );
                resolve(croppedFile);
              },
              'image/jpeg',
              1
            );
          }
        };
        image.src = event.target.result;
      };
      reader.onerror = (error) => {
        console.error('Listing image file reading error:', error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }
}
