<app-kckdashboard></app-kckdashboard>

<div class="main mt-4">
  <div class="container">
    <div class="row">
      <div class="col-5 card">
        <div class="container-fluid m">
          <div class="row">
            <div class="col">
              <h2 style="margin-top: 40px"><b>Add New Delivery Person</b></h2>
              <hr />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-outline mb-1">
                <label class="form-label" for="form3Example3">Username</label>
                <input
                  type="text"
                  id="form3Example3"
                  name="kitchenName"
                  class="form-control form-control-lg"
                  placeholder="Enter username"
                  [(ngModel)]="username"
                />

                <label class="form-label mt-1" for="form3Example1"
                  >Phone number</label
                >
                <input
                  type="tel"
                  id="form3Example1"
                  name="username"
                  class="form-control form-control-lg"
                  placeholder="Registered phone number"
                  pattern="[0-9]*"
                  maxlength="10"
                  [(ngModel)]="phoneNumber"
                />
                <label class="form-label mt-1" for="form3Example3"
                  >Keyword</label
                >
                <input
                  type="text"
                  id="form3Example3"
                  name="keyword"
                  class="form-control form-control-lg"
                  placeholder="Enter keyword"
                  [(ngModel)]="keyword"
                />
                <label class="form-label mt-1" for="form3Example4"
                  >Confirm Keyword</label
                >
                <input
                  type="text"
                  id="form3Example4"
                  name="password"
                  class="form-control form-control-lg"
                  placeholder="Confirm keyword"
                />
                <br />
                <label class="form-label mt-1" for="bootstrapDropdown"
                  >Assign delivery location</label
                >
                <div class="truncate">
                  <!-- <select
                    id="bootstrapDropdown"
                    class="form-select"
                    [(ngModel)]="selectedLocation"
                  >
                    <option
                      class="dropdownList"
                      *ngFor="let location of tiffinLocations"
                      [ngValue]="location.address"
                    >
                      {{ location.address }}
                    </option>
                  </select> -->
                  <div *ngFor="let location of tiffinLocations" class="mt-2">
                    <input
                      type="checkbox"
                      id="{{ location.id }}"
                      [(ngModel)]="location.selected"
                      (change)="toggleSelected(location)"
                    />
                    &nbsp;
                    <label for="{{ location.id }}">{{
                      location.address
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col text-center mb-3">
            <hr />
            <button class="btn btn-success" (click)="createDeliveryAccount()">
              Create Account
            </button>
          </div>
        </div>
      </div>
      <div class="col card ms-1">
        <app-view-delivery-persons></app-view-delivery-persons>
      </div>
    </div>
  </div>
</div>
