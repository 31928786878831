<app-kckdashboard></app-kckdashboard>

<div class="main">
  <div class="container analtic">
    <div class="row">
      <div class="col-2"></div>
      <div class="col">
        <p class="header">
          <b>
            Lunch bell analytics
            <span *ngIf="KCKuserName">of {{ KCKuserName }}</span></b
          >
        </p>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <p style="color: #006600; font-size: 25px">Orders overview</p>
    </div>

    <div class="row">
      <div class="col">
        <p class="dateShow">
          <b>Date: {{ currentDate | date : "dd-MM-yyyy" }}</b>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="startDatePicker"
            placeholder="Start Date"
            [(ngModel)]="startDateString"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [matDatepicker]="endDatePicker"
            placeholder="End Date"
            [(ngModel)]="endDateString"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <button
          style="margin-left: 25px"
          type="button"
          class="btn btn-secondary"
          (click)="searchAnalystics()"
        >
          Search
        </button>

        <!-- <button *ngIf="isSuperAdmin()"
          style="margin-left: 25px"
          type="button"
          class="btn btn-outline-dark"
          (click)="fetchOfferDetails()"
        >
          Search for offers
        </button> -->
      </div>
    </div>
  </div>

  <div class="container mb-2">
    <div class="row">
      <div class="col">
        <p style="color: #001866; font-size: 25px; margin-bottom: 0">
          <u>Daily Orders</u>
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card pending">
          <div class="card-body">
            <p class="card-title">No of orders delivered</p>
            <p class="card-text" style="text-align: center">
              <b>{{ orderDelivered }}</b>
            </p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card">
          <div class="card-body cod">
            <p class="card-title">Offers</p>
            <p class="card-text" style="text-align: center">
              <b> {{ offersApplied }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card upi">
          <div class="card-body">
            <p class="card-title">UPI count</p>
            <p class="card-text" style="text-align: center">
              <b>{{ UPI }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card pay">
          <div class="card-body">
            <p class="card-title">Total amount</p>

            <p class="card-text" style="text-align: center">
              <b>{{ totalAmount }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />

  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card cancel">
          <div class="card-body">
            <p class="card-title">No of orders cancelled</p>
            <p class="card-text" style="text-align: center">
              <b> {{ orderCancelled }}</b>
            </p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card codpymt">
          <div class="card-body">
            <p class="card-title">Offer payment</p>
            <p class="card-text" style="text-align: center">
              <b> {{ offerPrice }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card upipymt">
          <div class="card-body">
            <p class="card-title">UPI payment</p>
            <p class="card-text" style="text-align: center">
              <b> {{ totalUPIPrice }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card offers">
          <div class="card-body">
            <p class="card-title">Total number of meals delivered</p>
            <p class="card-text" style="text-align: center">
              <b> {{ totalMealCount }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />

  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card codpymt">
          <div class="card-body">
            <p class="card-title">GST amount</p>
            <p class="card-text" style="text-align: center">
              <b> {{ totalGSTPrice }}</b>
            </p>
          </div>
        </div>
      </div>

      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
    </div>
  </div>

  <br />
  <br />

  <!--subscription details -->
  <div class="container mb-2">
    <div class="row">
      <div class="col">
        <p style="color: #001866; font-size: 25px; margin-bottom: 0">
          <u>Subscription Orders</u>
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <!-- <div class="col">
        <div class="card cancel">
          <div class="card-body">
            <p class="card-title">No of orders delivered</p>
            <p class="card-text" style="text-align: center">
              <b>{{ subscriptionDeliveredOrders }}</b>
            </p>
          </div>
        </div>
      </div> -->

      <!-- <div class="col">
        <div class="card offers">
          <div class="card-body">
            <p class="card-title">Total number of meals delivered</p>
            <p class="card-text" style="text-align: center">
              <b> {{ subscriptionTotalMeals }} </b>
            </p>
          </div>
        </div>
      </div> -->
      <!-- <div class="col">
        <div class="card subDays15">
          <div class="card-body">
            <p class="card-title">Per day orders for 15 day subscriptions</p>
            <p class="card-text" style="text-align: center">
              <b> {{ subscription15Days }} </b>
            </p>
          </div>
        </div>
      </div> -->
      <div class="col-3">
        <div class="card subDays30">
          <div class="card-body">
            <p class="card-title">Subscriptions</p>
            <p class="card-text" style="text-align: center">
              <b> {{ noOfSubscriptions }} </b>
            </p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card codpymt">
          <div class="card-body">
            <p class="card-title">Offer Payment</p>
            <p class="card-text" style="text-align: center">
              <b> {{ totalOfferAmount }} </b>
            </p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card codpymt">
          <div class="card-body">
            <p class="card-title">GST Amount</p>
            <p class="card-text" style="text-align: center">
              <b> {{ totalGSTAmount }} </b>
            </p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card pay">
          <div class="card-body">
            <p class="card-title">Total Amount</p>
            <p class="card-text" style="text-align: center">
              <b> {{ subscriptionOrdersTotalAmount }} </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />

  <!------------------------->

  <br /><br />
  <!--accepted orders details starts-->
  <div class="container">
    <div class="row">
      <div class="col">
        <p style="color: #006600; font-size: 25px; margin-bottom: 0">
          Accepted Orders
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Items</p></th>

              <th scope="col"><p class="card-title">Daily meals</p></th>

              <!-- <th scope="col"><p class="card-title">Area</p></th> -->
            </tr>
          </thead>
          <tbody *ngFor="let menuItems of acceptedItemsCount; let i = index">
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ getDisplayNameForAcceptedOrders(menuItems.name) }}</td>
              <td>{{ menuItems.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Items</p></th>

              <th scope="col"><p class="card-title">Subscription meals</p></th>

              <!-- <th scope="col"><p class="card-title">Area</p></th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="subscriptionAcceptedVegMeals">
              <th scope="row">1</th>
              <td>Veg meals</td>
              <td>{{ subscriptionAcceptedVegMeals }}</td>
            </tr>

            <tr *ngIf="subscriptionAcceptedNonVegMeals">
              <th scope="row">2</th>
              <td>Non-Veg meals</td>
              <td>{{ subscriptionAcceptedNonVegMeals }}</td>
            </tr>

            <tr *ngIf="subscriptionAcceptedFibreRichLunchComboCount">
              <th scope="row">3</th>
              <td>Fibre rich lunch combo</td>
              <td>{{ subscriptionAcceptedFibreRichLunchComboCount }}</td>
            </tr>

            <tr *ngIf="subscriptionAcceptedCutFruitsComboCount">
              <th scope="row">4</th>
              <td>Cut fruits Combo</td>
              <td>{{ subscriptionAcceptedCutFruitsComboCount }}</td>
            </tr>

            <tr *ngIf="subscriptionAcceptedChickenBiriyaniCount">
              <th scope="row">5</th>
              <td>Biriyani (Chicken)</td>
              <td>{{ subscriptionAcceptedChickenBiriyaniCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Address</p></th>

              <th scope="col"><p class="card-title">Menu name</p></th>

              <th scope="col"><p class="card-title">Total meals</p></th>

            
            </tr>
          </thead>
          <tbody *ngFor="let itemsByAddress of acceptedOrders; let i = index">
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ itemsByAddress.address }}</td>
              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemName }}
                </div>
              </td>

              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemsByAddress.items[itemName] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
    <!---->
    <!---->
    <div class="row">
      <!-- <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Address</p></th>

              <th scope="col"><p class="card-title">Menu name</p></th>

              <th scope="col"><p class="card-title">Subscription meals</p></th>

            
            </tr>
          </thead>
          <tbody
            *ngFor="
              let itemsByAddress of subscriptionAcceptedOrders;
              let i = index
            "
          >
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ itemsByAddress.address }}</td>
              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemName }}
                </div>
              </td>

              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemsByAddress.items[itemName] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
    <!---->
    <!---->
  </div>
  <!--accepted orders details ends-->

  <!--out for delivery orders details starts-->
  <div class="container">
    <div class="row">
      <div class="col">
        <p style="color: #006600; font-size: 25px; margin-bottom: 0">
          Out for delivery
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Items</p></th>

              <th scope="col"><p class="card-title">Daily meals</p></th>
            </tr>
          </thead>
          <tbody *ngFor="let menuItems of ofdItemsCount; let i = index">
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ getDisplayNameForAcceptedOrders(menuItems.name) }}</td>
              <td>{{ menuItems.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Items</p></th>

              <th scope="col"><p class="card-title">Subscription meals</p></th>

              <!-- <th scope="col"><p class="card-title">Area</p></th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="subscriptionOutForDeliveryVegMeals">
              <th scope="row">1</th>
              <td>Veg meals</td>
              <td>{{ subscriptionOutForDeliveryVegMeals }}</td>
            </tr>

            <tr *ngIf="subscriptionOutForDeliveryNonVegMeals">
              <th scope="row">2</th>
              <td>Non-Veg meals</td>
              <td>{{ subscriptionOutForDeliveryNonVegMeals }}</td>
            </tr>

            <tr *ngIf="subscriptionOutForDeliveryFibreRichLunchComboCount">
              <th scope="row">3</th>
              <td>Fibre rich lunch combo</td>
              <td>{{ subscriptionOutForDeliveryFibreRichLunchComboCount }}</td>
            </tr>

            <tr *ngIf="subscriptionOutForDeliveryCutFruitsComboCount">
              <th scope="row">4</th>
              <td>Cut fruits Combo</td>
              <td>{{ subscriptionOutForDeliveryCutFruitsComboCount }}</td>
            </tr>

            <tr *ngIf="subscriptionOutForDeliveryChickenBiriyaniCount">
              <th scope="row">5</th>
              <td>Biriyani (Chicken)</td>
              <td>{{ subscriptionOutForDeliveryChickenBiriyaniCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Address</p></th>

              <th scope="col"><p class="card-title">Menu name</p></th>

              <th scope="col"><p class="card-title">Total meals</p></th>

          
            </tr>
          </thead>
          <tbody *ngFor="let itemsByAddress of OFDOrders; let i = index">
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ itemsByAddress.address }}</td>
              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemName }}
                </div>
              </td>

              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemsByAddress.items[itemName] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
    <!---->
    <!---->
    <div class="row">
      <!-- <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Address</p></th>

              <th scope="col"><p class="card-title">Menu name</p></th>

              <th scope="col"><p class="card-title">Subscription meals</p></th>

            </tr>
          </thead>
          <tbody
            *ngFor="
              let itemsByAddress of subscriptionOutForDeliveryOrders;
              let i = index
            "
          >
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ itemsByAddress.address }}</td>
              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemName }}
                </div>
              </td>

              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemsByAddress.items[itemName] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
    <!---->
    <!---->
  </div>
  <!--out for delivery orders details ends-->

  <!--delivered orders details starts-->
  <div class="container">
    <div class="row">
      <div class="col">
        <p style="color: #006600; font-size: 25px; margin-bottom: 0">
          Order details (delivered)
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Items</p></th>

              <th scope="col"><p class="card-title">Daily meals</p></th>

              <!-- <th scope="col"><p class="card-title">Area</p></th> -->
            </tr>
          </thead>
          <tbody *ngFor="let menuItems of DELOrderItemCount; let i = index">
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ getDisplayNameForAcceptedOrders(menuItems.name) }}</td>
              <td>{{ menuItems.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Items</p></th>

              <th scope="col"><p class="card-title">Subscription meals</p></th>

              <!-- <th scope="col"><p class="card-title">Area</p></th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="subscriptionDeliveredVegMeals">
              <th scope="row">1</th>
              <td>Veg meals</td>
              <td>{{ subscriptionDeliveredVegMeals }}</td>
            </tr>

            <tr *ngIf="subscriptionDeliveredNonVegMeals">
              <th scope="row">2</th>
              <td>Non-Veg meals</td>
              <td>{{ subscriptionDeliveredNonVegMeals }}</td>
            </tr>

            <tr *ngIf="subscriptionDeliveredFibreRichLunchComboCount">
              <th scope="row">3</th>
              <td>Fibre rich lunch combo</td>
              <td>{{ subscriptionDeliveredFibreRichLunchComboCount }}</td>
            </tr>

            <tr *ngIf="subscriptionDeliveredCutFruitsComboCount">
              <th scope="row">4</th>
              <td>Cut fruits Combo</td>
              <td>{{ subscriptionDeliveredCutFruitsComboCount }}</td>
            </tr>

            <tr *ngIf="subscriptionDeliveredChickenBiriyaniCount">
              <th scope="row">5</th>
              <td>Biriyani (Chicken)</td>
              <td>{{ subscriptionDeliveredChickenBiriyaniCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!---->

    <!---->
  </div>

  <div class="container">
    <div class="row">
      <div class="col">
        <p style="color: #006600; font-size: 25px; margin-bottom: 0">
          Location based order details
        </p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <b>Daily Meals</b>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Address</p></th>

              <th scope="col"><p class="card-title">Menu name</p></th>

              <th scope="col"><p class="card-title">Total meals</p></th>
            </tr>
          </thead>
          <tbody *ngFor="let itemsByAddress of itemsByAddress; let i = index">
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ itemsByAddress.address }}</td>
              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemName }}
                </div>
              </td>

              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemsByAddress.items[itemName] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!---->
    <div class="row mt-2">
      <div class="col">
        <b>Subscription Meals</b>
      </div>
    </div>
    <div class="row">
      <div class="col tableshow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><p class="card-title">#</p></th>

              <th scope="col"><p class="card-title">Address</p></th>

              <th scope="col"><p class="card-title">Menu name</p></th>

              <th scope="col"><p class="card-title">Subscription meals</p></th>
            </tr>
          </thead>
          <tbody
            *ngFor="
              let itemsByAddress of subscriptionCombinedOrders;
              let i = index
            "
          >
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ itemsByAddress.address }}</td>
              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemName }}
                </div>
              </td>

              <td>
                <div
                  *ngFor="let itemName of getItemNames(itemsByAddress.items)"
                >
                  {{ itemsByAddress.items[itemName] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!---->
  </div>
  <br />
  <div class="container" *ngIf="isSuperAdmin()">
    <div class="row">
      <div class="col">
        <p style="color: #006600; font-size: 25px; margin-bottom: 0">
          Subscription items details
        </p>
      </div>
    </div>
    
  </div>
  <div class="container p-4" *ngIf="isSuperAdmin()">
    <div class="row">
      <div class="col card">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Items</th>
              <th scope="col">15 days</th>
              <th scope="col">30 days</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="total15DayVegMealQuantity!=0 || total30DayVegMealQuantity!=0">
              <th scope="row">Veg Meals</th>
              <td>{{ total15DayVegMealQuantity }}</td>
              <td>{{ total30DayVegMealQuantity }}</td>
            </tr>
            <tr *ngIf="total15DayNonVegMealQuantity!=0 || total30DayNonVegMealQuantity!=0">
              <th scope="row" >Non-Veg Meals</th>
              <td>{{ total15DayNonVegMealQuantity }}</td>
              <td>{{ total30DayNonVegMealQuantity }}</td>
            </tr>
            <tr *ngIf="total15DayFibreRichLunchComboQuantity!=0 || total30DayFibreRichLunchComboQuantity!=0">
              <th scope="row">Fibre rich lunch combo</th>
              <td>{{ total15DayFibreRichLunchComboQuantity }}</td>
              <td>{{ total30DayFibreRichLunchComboQuantity }}</td>
            </tr>
            <tr  *ngIf="total15DayCutFruitsComboQuantity!=0 || total30DayCutFruitsComboQuantity!=0">
              <th scope="row">Cut fruits Combo</th>
              <td>{{ total15DayCutFruitsComboQuantity }}</td>
              <td>{{ total30DayCutFruitsComboQuantity }}</td>
            </tr>
            <tr  *ngIf="total15DayBiriyaniChickenQuantity!=0 || total15DayBiriyaniChickenQuantity!=0">
              <th scope="row">Biriyani (Chicken)</th>
              <td>{{ total15DayBiriyaniChickenQuantity }}</td>
              <td>{{ total15DayBiriyaniChickenQuantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  
  <br>
  <!-- <div class="container" *ngIf="isSuperAdmin()">
    <div class="row">
      <div class="col">
        <p style="color: #006600; font-size: 25px; margin-bottom: 0">
          Order details based on offer percent
        </p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">This excludes subscription orders.</div>
    </div>
  </div>
  <div class="container p-4" *ngIf="isSuperAdmin()">
    <div class="row">
      <div class="col card">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Items</th>
              <th scope="col">100%</th>
              <th scope="col">50%</th>
              <th scope="col">20%</th>
              <th scope="col">0%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Veg Meals</th>
              <td>{{ vegMealsWithOffer100 }}</td>
              <td>{{ vegMealsWithOffer50 }}</td>
              <td>{{ vegMealsWithOffer20 }}</td>
              <td>{{ vegMealsWithoutOffer }}</td>
            </tr>
            <tr>
              <th scope="row">Non-Veg Meals</th>
              <td>{{ nonVegMealsWithOffer100 }}</td>
              <td>{{ nonVegMealsWithOffer50 }}</td>
              <td>{{ nonVegMealsWithOffer20 }}</td>
              <td>{{ nonVegMealsWithoutOffer }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!--delivered orders details ends-->

  <br /><br />
</div>
