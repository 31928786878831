import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http'; // Add this line



const ADMIN_URL = environment.adminUrl;

@Component({
  selector: 'app-cloud-kitchen',
  templateUrl: './cloud-kitchen.component.html',
  styleUrls: ['./cloud-kitchen.component.css']
})
export class CloudKitchenComponent{


  filteredCloudKitchens: any[] = [];


  // Inject the HttpClient module into the component
  constructor(private http: HttpClient,
     private router: Router,
    private location: Location,
    private route: ActivatedRoute) {}
    


  approvalStatus: string = '';
  active: string = '';
  // district: string = '';
  block: string = '';
  panchayat: string = '';
  param1: string;

  districts: any[] = [];
  blocks: any = {};
  gramPanchayats: any = {};

 
  selectedGramPanchayat: string = '';

  gramPanchayatsLoaded: boolean = false;
  
  selectedDistrict: string = "";
  selectedBlock: string = "";
  district: string = '';
  // district = [
  //   { id: "DIS_TVM", name: "Thiruvananthapuram" },
  //   { id: "DIS_KLM", name: "Kollam" },
  //   { id: "DIS_PTA", name: "Pathanamthitta" },
  //   { id: "DIS_ALA", name: "Alappuzha" },
  //   { id: "DIS_KTM", name: "Kottayam" },
  //   { id: "DIS_IDK", name: "Idukki" },
  //   { id: "DIS_ERK", name: "Ernakulam" },
  //   { id: "DIS_TSR", name: "Thrissur" },
  //   { id: "DIS_PKD", name: "Palakkad" },
  //   { id: "DIS_MPM", name: "Malappuram" },
  //   { id: "DIS_KOZ", name: "Kozhikode" },
  //   { id: "DIS_WYD", name: "Wayanad" },
  //   { id: "DIS_KNR", name: "Kannur" },
  //   { id: "DIS_KSG", name: "Kasargode" }
  // ];
ngOnInit():void{

  
  this.route.queryParams.subscribe(params => {
    this.param1 = params['param1'];
    console.log(this.param1)
  });
  this.fetchKckUsers();
  this.fetchDistricts();
  this.fetchkckdata();
}

fetchDistricts(): void {
  this.http
    .get<any>('assets/cloudKitchenPlaces/districtBlocks/districts.json')
    .subscribe((data) => {
      this.districts = data.districts;
      console.log(this.districts);
    });
}



fetchBlocksForDistrict(districtId: string): void {
  this.http
    .get<any>('assets/cloudKitchenPlaces/districtBlocks/blocks.json')
    .subscribe((data) => {
      this.blocks = data;
   
      console.log(this.blocks);
    });
}

fetchGramPanchayatsForDistrict(districtId: string): void {
  this.http
    .get<any>(
      'assets/cloudKitchenPlaces/gramaPanchayats/' + districtId + '.json'
    )
    .subscribe((data) => {
      this.gramPanchayats[districtId] = data;
      this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
      console.log(this.gramPanchayats);
    });
}
// fetchKckUsers(): void {
//   this.http.get(`${ADMIN_URL}/ckAdmin/kckusers${this.param1}`).subscribe(
//     (data: any) => {
//       this.filteredCloudKitchens = data;
//       console.log(data,"testttt")
//     },
//     (error) => {
//       console.error('Error fetching KckUsers:', error);
//     }
//   );
// }

fetchkckdata(): void {
  const url = `${ADMIN_URL}/ckAdmin/kckdata`; // Change the URL to your backend endpoint

  // Create a data object with common properties
  const data = {
    district: this.selectedDistrict,
  };

  // Conditionally add properties based on user selections
  if (this.selectedGramPanchayat) {
    data['panchayath'] = this.selectedGramPanchayat;
  }

  if (this.selectedMunicipalityId) {
    data['municipality'] = this.selectedMunicipalityId;
  }

  if (this.selectedMunicipalCorporationId) {
    data['municipalCorporation'] = this.selectedMunicipalCorporationId;
  }

  console.log(data, "data");

  this.http.post(url, data).subscribe(
    (response: any) => {
      console.log('Response from backend:', response);
      this.filteredCloudKitchens=response
    },
    (error: HttpErrorResponse) => {
      console.error('Error sending data to backend:', error);
    }
  );
}

// fetchkckdata(): void {
//   const url = `${ADMIN_URL}/api/sendData`; // Change the URL to your backend endpoint

//   const data = {
//     district: this.selectedDistrict,
//     block: this.selectedBlock,
//     panchayath: this.selectedGramPanchayat,
//     municipality: this.selectedMunicipalityId,
//     municipalCorporation: this.selectedMunicipalCorporationId,
//   };
//   console.log(data,"dattatt")

//   this.http.post(url, data).subscribe(
//     (response: any) => {
//       console.log('Response from backend:', response);
//     },
//     (error: HttpErrorResponse) => {
//       console.error('Error sending data to backend:', error);
//     }
//   );
// }

fetchKckUsers(): void {
  const url = `${ADMIN_URL}/ckAdmin/kckusers`;

  
// Add the new fields to the data object
//   const data = {
    
//     district: this.selectedDistrict,
//     block: this.selectedBlock,
//     panchayath: this.selectedGramPanchayat,
//     municipality: this.selectedMunicipalityId,
//     municipalCorporation: this.selectedMunicipalCorporationId,
// }
// Assuming param1 is a string and is not null or undefined
  const params = { param1: this.param1 };

  this.http.get(url, { params }).subscribe(
    (data: any) => {
      this.filteredCloudKitchens = data;
      console.log(data, "testttt");
    },
    
    (error) => {
      console.error('Error fetching KckUsers:', error);
    }
  );
}

selectedLevel: string = 'blockLevel';
 //For Municipal Corporation Level;
 municipalCorporations: any = {};
 selectedMunicipalCorporation: any;
 selectedMunicipalCorporationId: any;
 municipalities: any = {};
 selectedMunicipality: any;
 selectedMunicipalityId: any;

  // Method to handle radio button selection
  onLevelSelection(): void {
    this.selectedDistrict = '';
    this.selectedBlock = ''; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = '';
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    this.selectedMunicipality = null;
    this.selectedMunicipalCorporation = null;

    console.log(this.selectedMunicipalityId);
    if (this.selectedLevel === 'municipalityLevel') {
      this.fetchMunicipalitiesForDistrict();
    }
    if (this.selectedLevel === 'municipalCorporation') {
      this.fetchMunicipalCorporationsForDistrict();
    }
  }

  onBlockChange(): void {
    this.selectedGramPanchayat = ''; // Reset selected gram panchayat when block changes
    if (this.selectedBlock) {
      this.selectedGramPanchayat = ''; // Reset selected gram panchayat when block changes
      // this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
    }
    // Set the gram panchayats data based on the selected block
    if (this.selectedBlock && this.blocks[this.selectedDistrict]) {
      this.gramPanchayats =
        this.gramPanchayats[this.selectedDistrict][this.selectedBlock];
      console.log(this.gramPanchayats);
    }
  }
  onDistrictChange(): void {
    this.selectedBlock = ''; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = '';
    this.selectedMunicipality = null;
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    if (this.selectedDistrict) {
      if (this.selectedLevel === 'municipalityLevel') {
        this.selectedMunicipality = this.municipalities[this.selectedDistrict];
        console.log('municipaliti', this.selectedMunicipality);
        return;
      } else if (this.selectedLevel == 'municipalCorporation') {
        this.selectedMunicipalCorporation =
          this.municipalCorporations[this.selectedDistrict];
        console.log('municipalCorporation', this.selectedMunicipalCorporation);
        return;
      } else {
        this.fetchBlocksForDistrict(this.selectedDistrict);
        // Preload gram panchayats data for the selected district
        this.fetchGramPanchayatsForDistrict(this.selectedDistrict);
      }
    }
  }

  onPChange(): void {
    console.log(
      this.selectedBlock,
      this.selectedDistrict,
      this.selectedGramPanchayat
    );
  }


 fetchMunicipalitiesForDistrict(): void {
  this.http
    .get<any>('assets/cloudKitchenPlaces/municipalities/municipalities.json')
    .subscribe((data) => {
      this.municipalities = data.municipalities;
      console.log(data.municipalities);
    });
}
fetchMunicipalCorporationsForDistrict(): void {
  // Assuming you are fetching data from a JSON file named municipal_corporations.json
  this.http
    .get<any>(
      'assets/cloudKitchenPlaces/municipalCorporations/municipal_corporation.json'
    )
    .subscribe((data) => {
      this.municipalCorporations = data.municipal_corporations || {};
      console.log(this.municipalCorporations);
    });
}




  onMunicipalityChange(): void {
    // Implement actions to handle the change in selected municipality
    console.log('Selected municipality:', this.selectedMunicipalityId);
    // You can perform additional actions here based on the selected municipality
  }

  onMunicipalCorporationChange(): void {
    // Implement actions to handle the change in selected municipality
    console.log('Selected municipality:', this.selectedMunicipalCorporationId);
    // You can perform additional actions here based on the selected municipality
  }
  KCKID: string = ''; 
  address: string = '';
search(): void{

  this.fetchkckdata();

}



  // Function to get filtered items directly in the component
  // search(): void {
  //   // Construct the API endpoint with query parameters
  //   console.log(this.approvalStatus,this.active)
  //      // Prepare data to be sent in the request body
  //   const filter = {
  //     approvalStatus: this.approvalStatus,
  //     active: this.active
  //   };

  //   // Make the HTTP GET request
  //   this.http.post(`${ADMIN_URL}/ckAdmin/cloud-kitchens`, filter).subscribe(
  //     (data: any) => {
  //       // Handle the retrieved items
  //       console.log(data);
  //       this.filteredCloudKitchens = data; // Update your component data with the retrieved items
  //     },
  //     (error) => {
  //       console.error('Error fetching filtered items:', error);
  //     }
  //   );
  // }
  goBack() {
    // Use the location service to navigate back
    this.location.back();
  }
  navigateToCloudKitchenDetails(CKID:string) {
    // Assuming you have a unique identifier for each cloud kitchen, e.g., kckId
    this.router.navigate(['/cloud-kitchen-details'], { queryParams: { CKID: CKID } });  }
}

