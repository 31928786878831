import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownstateService {
  productStatusDropdownHeading: string = 'All';
  productApprovalStatusDropdownHeading: any = 'All';
  productCategoryDropdownHeading: string = 'All';
  productSubCategoryDropdownHeading: any = 'All';
  productSubCategoryDropdown: any = "All";
  productCurrentPage:number = 1;
  productStartPage:number=0;
  productEndPage:number=15;


  serviceStatusDropdownHeading: string = 'All';
  serviceApprovalStatusDropdownHeading: any = 'All';
  serviceCategoryDropdownHeading: string = 'All';
  serviceCategoryDropdown: any = "All";
  serviceCurrentPage:number = 1;
  serviceStartPage:number=0;
  serviceEndPage:number=15;

  //seller
  sellerCurrentPage:number = 1;
  sellerStartPage:number=0;
  sellerEndPage:number=15;

  constructor() { }
}
