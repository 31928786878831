<app-kckdashboard></app-kckdashboard>

<div class="main">
  <section class="vh-100">
    <div class="container-fluid h-custom">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-md-9 col-lg-6 col-xl-5">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
            class="img-fluid"
            alt="Sample image"
          />
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
          <form>
            <img
              class="logologin"
              src="../../../assets/logos/pocketmart-011.png"
              alt="pocketMart"
            />

            <!-- Password and email input -->
            <form>
              
              <div class="form-outline mb-3">
                <label class="form-label" for="form3Example3">user name</label>
                <input
                  type="text"
                  id="form3Example3"
                  name="username"
                  class="form-control form-control-lg"
                  placeholder="Enter user name"
                  [(ngModel)]="username"
                />

                <label class="form-label" for="form3Example1"
                  >Current password</label
                >
                <input
                type="password"
                  id="form3Example1"
                  name="curentPassword"
                  class="form-control form-control-lg"
                  placeholder="Enter current password"
                  [(ngModel)]="curentPassword"
                />

                <label class="form-label" for="form3Example4">New password</label>
                <input
                  type="password"
                  id="form3Example4"
                  name="newPassword"
                  class="form-control form-control-lg"
                  placeholder="Enter new password"
                  [(ngModel)]="newPassword"
                />

                <label class="form-label" for="form3Example5"
                  >Confirm new password</label
                >
                <input
                  type="password"
                  id="form3Example5"
                  name="confirmPassword"
                  class="form-control form-control-lg"
                  placeholder="Confirm new password"
                  [(ngModel)]="confirmPassword"
                />
              </div>
            </form>

            <div class="text-center text-lg-start mt-4 pt-2">
              <button
                type="button"
                class="btn btn-success btn-lg"
                style="padding-left: 2.5rem; padding-right: 2.5rem"
                (click)=" submit()"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    
  </section>
</div>
