import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

const ADMIN_URL = environment.adminUrl;

@Component({
  selector: 'app-centralkitchen',
  templateUrl: './centralkitchen.component.html',
  styleUrls: ['./centralkitchen.component.css'],
})
export class CentralkitchenComponent {
  //variables name:
  kitchenDetails: any;
  luchBellsID: string = 'ID_TIF';
  profileID: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.checkLogin();
    this.fetchLucnchBells();
  }

  checkLogin(): void {
    console.log("check login worked");
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }

  fetchLucnchBells(): void {
    const ID = this.luchBellsID;
    const token = this.authService.getToken();

    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    console.log('the token is ', headers)
    console.log('the order function is called');
    this.http
      .get(ADMIN_URL + `/kitchenDetail?id=${ID}`, { headers })
      .subscribe(
        (data) => {
          this.kitchenDetails = data;
          console.log('the order details are:', this.kitchenDetails);
        },
        (error) => {
          console.error('Error fetching lunch bells:', error);
        }
      );
  }

  kckDetails(kckID: string) {
    this.router.navigate(['/kitchendetails'], {
      queryParams: {
        dateBaseID: kckID,
      },
    });
    console.log('Profile ID:', kckID);
  }

  KCKanalytics(kckID: string, KCKuserName: string) {
    this.router.navigate(['/Kckanalytics'], {
      queryParams: {
        KCKProfileID: kckID,
        KCKuserName:KCKuserName
      },
    });
    console.log('Profile ID:', kckID);
  }
}
