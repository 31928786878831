import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { Location } from '@angular/common';

import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
const ADMIN_URL = environment.adminUrl;

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css'],
})
export class EditProductComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  selectedSubCategory: any[] = [];
  subCategorySwitchRetrieval(item: any) {
    switch (item) {
      case 'ID_FNV':
        return 'Fruits and Vegetables / Farm Produce';
      // Add more cases as needed
      case 'ID_EGG':
        return 'Eggs / Farm Produce';
      case 'ID_FNM':
        return 'Fish & Meat / Farm Produce';
      case 'ID_SAO':
        return 'Spices and Oils / Farm Produce';
      case 'ID_PUS':
        return 'Pulses / Farm Produce';
      case 'ID_CRL':
        return 'Cereals / Farm Produce';
      case 'ID_FPO':
        return 'Farm Produce / Others';
      case 'ID_CNB':
        return 'Cakes and Bakes / Home Produce';
      case 'ID_CNS':
        return 'Chips and Snacks / Home Produce';
      case 'ID_RNB':
        return 'Meals / Home Produce';
      case 'ID_HYS':
        return 'Healthy Snacks / Home Produce';
      case 'ID_PKS':
        return 'Pickles / Home Produce';
      case 'ID_HMH':
        return 'Handmades / Home Produce';
      case 'ID_HPB':
        return 'Beauty Products / Home Produce';
      case 'ID_OHS':
        return 'Home Produce / Others';
      case 'ID_PLS':
        return 'Plants / Garden';
      case 'ID_SES / Garden':
        return 'Seeds / Garden';
      case 'ID_NUY':
        return 'Nursery / Garden';
      case 'ID_OTS':
        return 'Garden / Others';
      case 'ID_DGS':
        return 'Dogs / Pets';
      case 'ID_CTS / Pets':
        return 'Cats / Pets';
      case 'ID_BDS':
        return 'Birds / Pets';
      case 'ID_OLF':
        return 'Ornamental fish / Pets';
      case 'ID_OES':
        return 'Pets / Others';
      case 'ID_CAE':
        return 'Cattle / Livestocks';
      case 'ID_GNS':
        return 'Goats and Sheep / Livestocks';
      case 'ID_HES':
        return 'Poultry / Livestocks';
      case 'ID_ORS':
        return 'Livestocks / Others';
      case 'ID_HEA':
        return 'Home appliance / Used Items';
      case 'ID_ELC':
        return 'Electronics / Used Items';
      case 'ID_AUS':
        return 'Autos / Used Items';
      case 'ID_UIB':
        return 'Books / Used Items';
      case 'ID_OHE':
        return 'Used Items / Others';
      default:
        return 'All';
    }
  }
  subCategorySwitch(item: any) {
    switch (item) {
      case 'ID_FNV':
        return 'Fruits and Vegetables';
      // Add more cases as needed
      case 'ID_EGG':
        return 'Eggs';
      case 'ID_FNM':
        return 'Fish & Meat';
      case 'ID_SAO':
        return 'Spices and Oils';
      case 'ID_PUS':
        return 'Pulses';
      case 'ID_CRL':
        return 'Cereals';
      case 'ID_FPO':
        return 'Others';
      case 'ID_CNB':
        return 'Cakes and Bakes';
      case 'ID_CNS':
        return 'Chips and Snacks';
      case 'ID_RNB':
        return 'Meals';
      case 'ID_HYS':
        return 'Healthy Snacks';
      case 'ID_PKS':
        return 'Pickles';
      case 'ID_HMH':
        return 'Handmades';
      case 'ID_HPB':
        return 'Beauty Products';
      case 'ID_OHS':
        return 'Others';
      case 'ID_PLS':
        return 'Plants';
      case 'ID_SES':
        return 'Seeds';
      case 'ID_NUY':
        return 'Nursery';
      case 'ID_OTS':
        return 'Others';
      case 'ID_DGS':
        return 'Dogs';
      case 'ID_CTS':
        return 'Cats';
      case 'ID_BDS':
        return 'Birds';
      case 'ID_OLF':
        return 'Ornamental fish';
      case 'ID_OES':
        return 'Others';
      case 'ID_CAE':
        return 'Cattle';
      case 'ID_GNS':
        return 'Goats and Sheep';
      case 'ID_HES':
        return 'Poultry';
      case 'ID_ORS':
        return 'Others';
      case 'ID_HEA':
        return 'Home appliance';
      case 'ID_ELC':
        return 'Electronics';
      case 'ID_AUS':
        return 'Autos';
      case 'ID_UIB':
        return 'Books';
      case 'ID_OHE':
        return 'Others';
      default:
        return 'All';
    }
  }
  FarmProduceSubCategories: any[] = [
    'ID_FNV',
    'ID_EGG',
    'ID_FNM',
    'ID_SAO',
    'ID_PUS',
    'ID_CRL',
    'ID_FPO',
  ];
  HomeProduceSubCategories: any[] = [
    'ID_CNB',
    'ID_CNS',
    'ID_RNB',
    'ID_HYS',
    'ID_PKS',
    'ID_HMH',
    'ID_HPB',
    'ID_OHS',
  ];
  GardenSubCategories: any[] = ['ID_PLS', 'ID_SES', 'ID_NUY', 'ID_OTS'];
  PetsSubCategories: any[] = ['ID_DGS', 'ID_CTS', 'ID_BDS', 'ID_OLF', 'ID_OES'];
  LivestocksSubCategories: any[] = ['ID_CAE', 'ID_GNS', 'ID_HES', 'ID_ORS'];
  UsedItemsSubCategories: any[] = [
    'ID_HEA',
    'ID_ELC',
    'ID_AUS',
    'ID_UIB',
    'ID_OHE',
  ];

  categoryDropdownHeading: string = 'Category';
  subCategoryDropdownHeading: any = 'Sub Category';
  productCategory: string = 'Sub Category';

  // imageURL = adminUrl;
  updateDate:boolean = false;
  productImageURLs = null;
  product: any = [];
  productImageName: string = '';
  isApproved: boolean;
  productStatus: string;
  productId: string = '';
  productCat: string = 'Select product category';
  productTitle: string = '';
  productPrice: string = '';
  productUnit: string = '';
  productDescription: string = '';
  productID: string = '';
  ngOnInit(): void {
    this.authUser();
    this.route.queryParams.subscribe((params) => {
      if (params['productId']) {
        console.log('available product');
        this.productId = params['productId'];
      }
    });
    console.log(this.productId, 'hello productId');

    this.getProduct(this.productId).subscribe(
      (res) => {
        console.log(res, 'prooasdoasd');
        this.product = res.products;
        this.productImageURLs = res.products.productImageURL;
        console.log(this.productImageURLs);
        this.productCat = res.products.productCategory;
        this.productDescription = res.products.productDescription;
        this.productTitle = res.products.productTitle;
        this.productUnit = res.products.productUnit;
        this.productPrice = res.products.productPrice;
        this.isApproved = res.products.isApproved;
        this.productStatus = res.products.productStatus;
        this.productValidity = res.products.productValidity;
        this.productID = res.products.productID;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // Function to go back to the previous page
  goBack() {
    // Navigate back in the router history
    this.location.back();
  }
  //Select Validity
  productValidity: number;
  getDays(days) {
    console.log('The days is: ', days);
    this.productValidity = days;
  }

  setProductCategory(option: string) {
    console.log(option, 'option');
    this.productCat = option;
  }

  getProduct(productId: string): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    const url = `${ADMIN_URL}/getProductforEdit/${productId}`;
    return this.http.get(url, { headers });
  }

  onDelete(productId: string, productImageIndex: Number) {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    const url = `${ADMIN_URL}/images/products/${productId}/${productImageIndex}`;
    if (
      window.confirm(
        'Are you sure you want to delete?\nOnce deleted the image cannot be recovered'
      )
    ) {
      this.http.delete(url, { headers }).subscribe(
        (response) => {
          console.log(response);
          if ((response as any).message === 'Image deleted successfully') {
            alert('Image deleted successfully');
            this.getProduct(this.productId).subscribe(
              (res) => {
                console.log(res);
                this.product = res.products;
                this.productImageURLs = res.productImageURL;
              },
              (err) => {
                console.log(err);
              }
            );
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  authUser() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    this.http.get(ADMIN_URL + '/verifyToken', { headers }).subscribe(
      (res) => {
        console.log('User is authenticated');
        if ((res as any).message === 'Invalid token') {
          this.router.navigate(['/login']);
        }
      },
      (err) => {
        // Token is invalid, redirect to the login page
        this.router.navigate(['/login']);
      }
    );
  }

  onApprove() {
    // Prepare the payload based on user input
    const payload: any = {};
    let formData = new FormData();
    // Append each selected file to the FormData object
    if (this.productImageData.length > 0) {
      for (let i = 0; i < this.productImageData.length; i++) {
        formData.append('images', this.productImageData[i]);
      }
    }
    // Append listingImage to formData
    if (this.productThumbNailImage) {
      formData.append('images', this.productThumbNailImage);
    }
    // Add fields to the payload if they have been updated
    if (this.productCat) {
      payload.productCategory = this.productCat;
    }
    if (this.productStatus) {
      payload.productStatus = this.productStatus;
    }
    if (this.productDescription) {
      payload.productDescription = this.productDescription;
    }
    if (this.productTitle) {
      payload.productTitle = this.productTitle;
    }
    if (this.productUnit) {
      payload.productUnit = this.productUnit;
    }
    if (this.productPrice) {
      payload.productPrice = this.productPrice;
    }
    if (this.productValidity) {
      payload.productValidity = this.productValidity;
    }

    //Update the product Date
    if(this.updateDate){
      payload.date = new Date().toISOString().substr(0, 10).toString();

    }
    payload.isApproved = this.isApproved;
    payload.profileID = this.product.profileID;
    console.log(payload);
    // Append the payload as a JSON string to formData
    formData.append('payload', JSON.stringify(payload));
    console.log(this.isApproved);
    // Make an API call to update the product
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    if (
      window.confirm('Are you sure you want to update the product details?')
    ) {
      this.http
        .put(ADMIN_URL + `/product/${this.productId}`, formData, {
          headers,
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            if (response.message === 'Product updated successfully') {
              alert('Product updated successfully');
            }
            // Handle success, display a message, etc.
            this.router.navigate(['/dashboard']);
          },
          (error) => {
            console.error('Error updating product', error);
            alert('Error updating product!');
            // Handle error
          }
        );
    }
  }

  onDontApprove() {
    this.router.navigate(['/dashboard']);
  }
  showimg: boolean = false;
  zoomsrc: string;
  imageClick(product) {
    console.log('Image clicked');
    this.showimg = true;
    this.zoomsrc = product;
  }
  hideImg() {
    this.showimg = false;
  }
  confirmDelete() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    if (window.confirm('Are you sure you want to delete?')) {
      this.http
        .delete<any>(ADMIN_URL + `/product/${this.productId}`, { headers })
        .subscribe((res) => {
          console.log(res);
          console.log('Deleted', this.productId);
          this.location.back();
        });
    }
  }

  uploadButton1Enabled: boolean = true;

  editImagesArray: any[] = [];
  productForEdit: any;
  defaultImages = 'assets/personImages/defaultimage.jpg';

  selectedImageUrls: (string | ArrayBuffer)[] = [];
  productImageData: File[] = [];
  productThumbNailImage: File | undefined;

  onImageSelected(event, index) {
    console.log(event);
    const file = event.target.files[0];
    this.compressImage(file, index)
      .then((compressedFile) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImageUrls[index] = reader.result;
        };
        reader.readAsDataURL(compressedFile as Blob);

        const compressedFileName = this.getCompressedImageName(index);
        const compressedImageFile = new File(
          [compressedFile as Blob],
          compressedFileName
        );
        this.productImageData[index] = compressedImageFile;

        if (index === 0) {
          this.compressFurtherImage(compressedImageFile)
            .then((furtherCompressedFile) => {
              this.productThumbNailImage = furtherCompressedFile;
            })
            .catch((error) => {
              console.error('Listing image compression error:', error);
            });
        }
      })
      .catch((error) => {
        console.error('Image compression error:', error);
      });
    if (index === 0) {
      this.uploadButton1Enabled = true;
    }
  }

  getCompressedImageName(index) {
    const productID = this.productID; // Replace with the actual profile ID variable
    return `${productID}_image${index + 1}`;
  }
  compressImage(file, index) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const size = Math.min(image.width, image.height);
          const xOffset = (image.width - size) / 2;
          const yOffset = (image.height - size) / 2;
          const maxWidth = 800;
          const maxHeight = 800;
          let width = size;
          let height = size;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(
            image,
            xOffset,
            yOffset,
            size,
            size,
            0,
            0,
            width,
            height
          );

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], 'compressed_image.jpg');
              if (compressedFile.size > 300 * 1024) {
                const quality = (300 * 1024) / compressedFile.size;
                canvas.toBlob(
                  (furtherBlob) => {
                    const furtherCompressedFile = new File(
                      [furtherBlob],
                      'further_compressed_image.jpg'
                    );
                    resolve(furtherCompressedFile);
                  },
                  'image/jpeg',
                  quality
                );
              } else {
                resolve(compressedFile);
              }
            },
            'image/jpeg',
            0.9
          );
        };
        if (typeof event.target.result === 'string') {
          image.src = event.target.result;
        }
      };
      reader.onerror = (error) => {
        console.error('File reading error:', error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  compressFurtherImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          let width = image.width;
          let height = image.height;

          if (file.size > 300 * 1024) {
            const maxSize = 150;
            if (width > height) {
              height *= maxSize / width;
              width = maxSize;
            } else {
              width *= maxSize / height;
              height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob: Blob) => {
                const furtherCompressedFile = new File(
                  [blob],
                  this.productID + '_thumbnailImage.jpg'
                );
                resolve(furtherCompressedFile);
              },
              'image/jpeg',
              1
            );
          } else {
            // If the image size is smaller or equal to 300 KB, perform only square cropping
            const size = Math.min(width, height);
            const xOffset = (width - size) / 2;
            const yOffset = (height - size) / 2;
            const squareSize = 150;

            canvas.width = squareSize;
            canvas.height = squareSize;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(
              image,
              xOffset,
              yOffset,
              size,
              size,
              0,
              0,
              squareSize,
              squareSize
            );

            canvas.toBlob(
              (blob: Blob) => {
                const croppedFile = new File(
                  [blob],
                  this.productID + '_thumbnailImage.jpg'
                );
                resolve(croppedFile);
              },
              'image/jpeg',
              1
            );
          }
        };
        image.src = event.target.result;
      };
      reader.onerror = (error) => {
        console.error('Listing image file reading error:', error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }
}
