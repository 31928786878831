import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const ADMIN_URL = environment.adminUrl;

interface DecodedToken {
  user: {
    id: string;
    role: string;
  };
  iat: number;
  exp: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenKey = 'token';
  private tokenTimer: any;
  token: string;
  isAuthenticatedValue: boolean;
  private authStatusListener = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router,) {}

  register(user: any): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(`${ADMIN_URL}/register`, user, {  headers });
  }

  login(user: any): Observable<any> {
    return this.http.post(`${ADMIN_URL}/login`, user);
  }

  saveToken(token: string, expiresIn: number): void {
    this.setAuthTimer(expiresIn);
    const now = new Date(); // Get the current date
    const expirationDate = new Date(now.getTime() + expiresIn * 1000); // Calculate expiration date
    console.log('the expirationDate', expirationDate);
    this.saveAuthData(token, expirationDate);
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  removeToken(): void {
    localStorage.removeItem(this.tokenKey);
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token !== null;
  }

  isAdmin(): boolean {
    const token = this.getToken();
    if (token) {
      // Decode token
      const decodedToken = jwtDecode(token) as DecodedToken;
      // Check if decodedToken and decodedToken.user exist
      if (decodedToken && decodedToken.user && decodedToken.user.role) {
        // Return true if the user has an admin role
        return decodedToken.user.role === 'admin';
      }
    }
    return false;
  }

  isKitchen(): boolean {
    const token = this.getToken();
    if (token) {
      // Decode token
      const decodedToken = jwtDecode(token) as DecodedToken;
      // Check if decodedToken and decodedToken.user exist
      if (decodedToken && decodedToken.user && decodedToken.user.role) {

        // Return true if the user has an admin role
        return decodedToken.user.role === 'kitchen';
      }
    }
    return false;
  }

  isSuperAdmin(): boolean {
    const token = this.getToken();
    if (token) {
      // Decode token
      const decodedToken = jwtDecode(token) as DecodedToken;
      // Check if decodedToken and decodedToken.user exist
      if (decodedToken && decodedToken.user && decodedToken.user.role) {
        // Return true if the user has an admin role
        return decodedToken.user.role === 'superAdmin';
      }
    }
    return false;
  }


  

  logOut(): void {
    // localStorage.removeItem('token');
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/login']);
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if(!authInformation){
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    console.log(expiresIn, authInformation)
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticatedValue = true;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);

    }
  }


  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  

  private setAuthTimer(duration: number) {
    console.log('setAuthTimer is :', duration);
    this.tokenTimer = setTimeout(() => {
      this.logOut();
    }, duration * 1000); // Convert expiresIn to milliseconds
  }

  private saveAuthData(token: string, expirationDate: Date): void {
    console.log('the saveAuthData method is called');
    localStorage.setItem(this.tokenKey, token);
    localStorage.setItem('expirationDate', expirationDate.toISOString());
  }

  private clearAuthData(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem('expirationDate');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expirationDate');
    if (!token || !expirationDate) {
      return null;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
    };
  }
}
