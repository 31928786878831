<app-kckdashboard></app-kckdashboard>



<div [@fadeInOut]>
    
    <!-- Space to show the elements
       below the floating header -->
    <br /><br /><br />
  
    <!-- Enter Product details section starts -->
    <div class="container p-4">
      <div class="wholePage">

      
      <!-----------------------CURRENT Location---------------->
      <!-- <div class="row">
        <div class="col">
          <label for="selectLocation" class="form-label required">
            <p class="labelText">Select Location</p>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p>
            <b
              >Please set the actual location of your kitchen by setting current
              location from kitchen premise.</b
            >
          </p>
        </div>
      </div>
      <br />
  
      <div class="row">
        <div class="col d-flex justify-content-center">
          <button
            class="btn btn-success"
            style="background-color: #922155; border-color: #922155"
          >
            <a
              (click)="getCurrentLocations()"
              class="useCurrentLocation"
              style="text-align: center"
              ><i class="fa-solid fa-location-crosshairs fa-lg"></i
              ><span>Use Current Location</span>
            </a>
          </button>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div
            [@fadeInOut]
            *ngIf="showSpinner"
            class="spinner-border locationSpinner"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
  
      <div
        [@fadeInOut]
        *ngIf="selectLocation"
        class="container p-3"
        style="background-color: #f7edf3; border-radius: 10px"
      >
        <div class="row">
          <div class="col">
            {{ selectLocation }}
          </div>
        </div>
      </div>
  
      <br /> -->
      <!-----------------------CURRENT Location---------------->
  
      <div class="row">
        <div class="mb-4 mt-4">
          <label for="userName" class="form-label">
            <p class="labelText required">Unit name :</p>
          </label>
  
          <input
            class="form-control"
            id="userName"
            placeholder="Enter unit name"
            maxlength="30"
            [(ngModel)]="username"
          />
        </div>
      </div>
  
      <div class="row">
        <div class="col">
          <label for="KCKID" class="form-label required">
            <p class="labelText">Unit ID</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="KCKID"
            placeholder="Enter Kudumbashree Cloud Kitchen ID"
            maxlength="10"
            required
            [(ngModel)]="KCKID"
            name="KCKID"
            (keypress)="keyPressAlphanumeric($event)"
          />
        </div>
      </div>
  
      <br />
      <div class="row">
        <div class="col">
          <label for="address" class="form-label required">
            <p class="labelText">Locality name</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="address"
            placeholder="Enter Locality"
            maxlength="24"
            [(ngModel)]="address"
            name="address"
          />
        </div>
        <br />
      </div>
      <br />
  
      <div class="row pt-2">
        <div class="col">
          <label for="kitchenUnitMemberName" class="form-label">
            <p class="labelText">Unit member name</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="kitchenUnitMemberName"
            placeholder="Enter Kitchen Unit Member Name"
            maxlength="30"
            [(ngModel)]="kitchenUnitMemberName"
            name="kitchenUnitMemberName"
          />
        </div>
      </div>
      <br>

      <div class="row pt-2">
        <div class="col">
          <label for="newUserPhoneNumber" class="form-label required">
            <p class="labelText">Enter mobile number</p>
          </label>
          <input
      type="text"
      class="form-control newUserPhoneNumber"
      id="newUserPhoneNumber"
      [(ngModel)]="newUserPhoneNumber"
      [ngModelOptions]="{ standalone: true }"
      placeholder="Enter your Phone number"
      required
      pattern="^\d{10}$"
      maxlength="10"
      inputmode="numeric"
      title="Please enter a valid 10-digit phone number"
      oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 10) this.value = this.value.slice(0, 10);"
      (input)="checkPhoneNumberLength()"
  
    />
        </div>
      </div>

     

    <br>
  
      <!-- Repeat similar code blocks for other new fields -->
  
      <br />
  
      <!-- Add new input fields for the additional information -->
      <!-- Radio Buttons for Level Selection -->
      <div class="row">
        <br />
        <div class="col">
          <label class="form-label">
            <p class="labelText">Select local body</p>
          </label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="level"
              id="blockLevel"
              value="blockLevel"
              [(ngModel)]="selectedLevel"
              (change)="onLevelSelection('blockLevel')"
            />
            <label class="form-check-label" for="blockLevel">Panchayat</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="level"
              id="municipalityLevel"
              value="municipalityLevel"
              [(ngModel)]="selectedLevel"
              (change)="onLevelSelection('municipalityLevel')"
            />
            <label class="form-check-label" for="municipalityLevel"
              >Municipality</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="level"
              id="municipalCorporation"
              value="municipalCorporation"
              [(ngModel)]="selectedLevel"
              (change)="onLevelSelection('municipalCorporation')"
            />
            <label class="form-check-label" for="municipalCorporation"
              >Municipal Corporation</label
            >
          </div>
        </div>
        <br />
      </div>
      <br />
      <!-- Add new input fields for the additional information -->
      <div class="row">
        <div class="col">
          <label for="district" class="form-label required">
            <p class="labelText">District</p>
          </label>
          <!-- Dropdown for District -->
          <select
            class="form-select inputBoxes"
            id="district"
            [(ngModel)]="selectedDistrict"
            name="district"
            (change)="onDistrictChange()"
          >
            <option value="" disabled selected>Select District</option>
            <option *ngFor="let district of districts" [value]="district.id">
              {{ district.name }}
            </option>
          </select>
        </div>
      </div>
  
      <br />
  
      <!-- New Dropdown for Municipality -->
      <div class="row pt-2" *ngIf="selectedMunicipality">
        <div class="col">
          <label for="municipality" class="form-label required">
            <p class="labelText">Municipality</p>
          </label>
          <select
            class="form-select inputBoxes"
            id="municipality"
            [(ngModel)]="selectedMunicipalityId"
            name="municipality"
            (click)="onMunicipalityChange()"
          >
            <option value="" disabled selected>Select Municipality</option>
            <option
              *ngFor="let municipality of selectedMunicipality"
              [value]="municipality.id"
            >
              {{ municipality.name }}
            </option>
          </select>
        </div>
      </div>
      <br *ngIf="selectedMunicipality" />
      <div class="row pt-2" *ngIf="selectedMunicipalCorporation">
        <div class="col">
          <label for="districtMunicipalCorporation" class="form-label required">
            <p class="labelText">District Municipal Corporation</p>
          </label>
          <select
            class="form-select inputBoxes"
            id="districtMunicipalCorporation"
            [(ngModel)]="selectedMunicipalCorporationId"
            name="districtMunicipalCorporation"
            (click)="onMunicipalCorporationChange()"
          >
            <option value="" disabled selected>
              Select District Municipal Corporation
            </option>
            <option
              *ngFor="
                let municipalCorporation of municipalCorporations[
                  selectedDistrict
                ]
              "
              [value]="municipalCorporation.id"
            >
              {{ municipalCorporation.name }}
            </option>
          </select>
        </div>
      </div>
      <br *ngIf="selectedMunicipalCorporation" />
  
      <div class="row pt-2" *ngIf="selectedDistrict && gramPanchayatsLoaded">
        <div class="col">
          <label for="block" class="form-label required">
            <p class="labelText">Block</p>
          </label>
          <!-- Dropdown for Block -->
          <select
            class="form-select inputBoxes"
            id="block"
            [(ngModel)]="selectedBlock"
            name="block"
            (change)="onBlockChange()"
          >
            <option value="" disabled selected>Select Block</option>
            <option
              *ngFor="let block of blocks[selectedDistrict]"
              [value]="block.id"
            >
              {{ block.name }}
            </option>
          </select>
        </div>
        <br />
      </div>
      <br *ngIf="selectedDistrict && gramPanchayatsLoaded" />
  
      <div class="row pt-2" *ngIf="selectedBlock">
        <div class="col">
          <label for="gramPanchayat" class="form-label required">
            <p class="labelText">Grama Panchayat</p>
          </label>
          <!-- Dropdown for Gram Panchayat -->
          <select
            class="form-select inputBoxes"
            id="gramPanchayat"
            [(ngModel)]="selectedGramPanchayat"
            name="gramPanchayat"
            (change)="onPChange()"
          >
            <option value="" disabled selected>Select Gram Panchayat</option>
            <option *ngFor="let gp of gramPanchayats" [value]="gp.id">
              {{ gp.name }}
            </option>
          </select>
        </div>
      </div>
  
      <br *ngIf="selectedBlock" />
  
      <div class="row pt-2">
        <div class="col">
          <!-- <div class="form-group"> -->
          <label for="exampleFormControlTextarea12">
            <p class="labelText">Serviceable areas</p></label
          >
          <!-- <textarea
            class="form-control"
            [(ngModel)]="deliveryAreas"
            id="exampleFormControlTextarea12"
            type="text"
            placeholder="Enter serviceable areas"
            name="deliveryAreas"
            maxlength="300"
            rows="3"
          ></textarea> -->
  
          <!-- </div> -->
  
          <!-- <label for="deliveryAreas" class="form-label">
            <p class="labelText">Delivery areas</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="deliveryAreas"
            placeholder="Enter FSSAI License"
            maxlength="14"
            [(ngModel)]="fssaiLicense"
            name="deliveryAreas"
          /> -->
        </div>
      </div>
  
      <div class="row">
        <div class="col">
          <div>
            <ul>
              <li>Trivandrum medical college</li>
              <li>Trivandrum secretariat</li>
              <li>Pattom</li>
              <li>PMG</li>
            </ul>
          </div>
        </div>
      </div>
  
      <!-- FSSAI License -->
      <br />
  
      <div class="row pt-2">
        <div class="col">
          <label for="fssaiLicense" class="form-label">
            <p class="labelText">FSSAI License</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="fssaiLicense"
            placeholder="Enter FSSAI License"
            maxlength="14"
            [(ngModel)]="fssaiLicense"
            name="fssaiLicense"
            (keypress)="keyPressAlphanumeric($event)"
          />
        </div>
      </div>
  
      <!-- Repeat similar code blocks for other new fields -->
      <!-- ME App ID -->
      <br />
  
      <div class="row">
        <div class="col">
          <label for="meAppId" class="form-label">
            <p class="labelText">ME App ID</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="meAppId"
            placeholder="Enter ME App ID"
            maxlength="15"
            [(ngModel)]="meAppId"
            name="meAppId"
            (keypress)="keyPressAlphanumeric($event)"
          />
        </div>
      </div>
  
      <!-- Repeat similar code blocks for other new fields -->
      <!-- CDS Registration ID -->
      <br />
  
      <div class="row">
        <div class="col">
          <label for="cdsRegistrationId" class="form-label">
            <p class="labelText">CDS Registration ID</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="cdsRegistrationId"
            placeholder="Enter CDS Registration ID"
            maxlength="15"
            [(ngModel)]="cdsRegistrationId"
            name="cdsRegistrationId"
            (keypress)="keyPressAlphanumeric($event)"
          />
        </div>
      </div>
      <br />


      <div class="row">
        <div class="col">
          <label for="latitude" class="form-label">
            <p class="labelText">Enter Latitude</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="latitude"
            placeholder="Enter Latitude"
            maxlength="15"
            [(ngModel)]="latitude"
            name="latitude"
          
          />
        </div>
      </div>
<br>
      <div class="row">
        <div class="col">
          <label for="longitude" class="form-label">
            <p class="labelText">Enter longitude</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="latitude"
            placeholder="Enter longitude"
            maxlength="15"
            [(ngModel)]="longitude"
            name="longitude"
          
          />
        </div>
      </div>
      <br />
  
      <!-- upiid -->
      <!-- <div class="row">
        <div class="col">
          <label for="GSTINnumber" class="form-label">
            <p class="labelText">GSTIN number</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="GSTINnumber"
            placeholder="Enter GSTIN number"
            maxlength="15"
            [(ngModel)]="GSTINnumber"
            name="GSTINnumber"
            (keypress)="keyPressAlphanumeric($event)"
          />
        </div>
      </div>
      <br /> -->
  
      <!-- upiid -->
      <!-- <div class="row">
        <div class="col">
          <label for="upiId" class="form-label">
            <p class="labelText">Enter UPI ID</p>
          </label>
          <input
            type="text"
            class="form-control inputBoxes"
            id="upiId"
            placeholder="Enter UPI to receive payments online"
            maxlength="30"
            [(ngModel)]="upiId"
            name="upiId"
          />
        </div>
      </div> -->
  
      <!-- <br /> -->
       <br>
  
      <div class="row">
        <div class="col">
          <button
            [disabled]="!KCKID || !address || !selectedDistrict || !newUserPhoneNumber"
            type="submit"
            class="btn btn-success saveProductButton"
            (click)="continue()"
          >
           Create new kitchen
          </button>
        </div>
      </div>
    </div>
  </div></div>
  