<nav>
  <div class="navbar">
    <a class="nav-link">
      <i class="fa fa-user-circle fa-2x adminicon"></i>
      <h6 (click)="onAdminClick()" class="adminheading">Admin</h6>
      <button class="settingsbtn" (click)="onSettings()">
        <i class="fa fa-sliders settingsicon" aria-hidden="true"></i>
      </button>
    </a>
    <div class="logout" (click)="onLogout()">
      <i class="fa fa-sign-out logouticon" aria-hidden="true"></i>
      <h6>Log Out</h6>
    </div>
    <img class="imglogo" src="../../../assets/logo.jpg" alt="Amigo" />
  </div>
</nav>
<div (click)="goBack()">
  <i class="fa fa-chevron-circle-left backButton" aria-hidden="true"></i>
</div>

<select (change)="switchLanguage($event)">
  <option value="en">English</option>
  <option value="ml">Malayalam</option>
  <option value="kn">Kannada</option>
  <option value="jv">Japanese</option>
</select>
<br> <br><br>
<div class="container main">
<div class="inner-container kmain">
  <div class="row">

  <div class="col-12">
    <h3 class="h_kck"> Cloud Kitchen</h3>
  </div>
 </div>
 <br>

<div class="row">
  <div class="col-4">
    <button type="button" class="btn  btn-secondary btn_kck" (click)="tiffin()">Lunch bells</button>
  </div>
  <div class="col-4">
    <button type="button" class="btn  btn-secondary  btn_kck" (click)="canteen()">Canteen</button>
  </div>
  <div class="col-4">
    <button type="button" class="btn  btn-secondary  btn_kck" (click)="pink()">Cafe</button>
  </div>
  
</div>
</div>

<br>
<br>

<!-- <div class="inner-container">
  <div class="row">
    <div class="col-8">
      <h3 class="h_kck"> Farm/Non-Farm Livelihood</h3>
    </div>
  </div>
    <br>

    <div class="row">
      <div class="col-2">
        <button type="button" class="btn  btn-secondary btn_farm">Buds</button>
      </div>
      <div class="col-2">
        <button type="button" class="btn  btn-secondary btn_farm">Tribals</button>
      </div>
      <div class="col-2">
        <button type="button" class="btn  btn-secondary btn_farm">SVEP</button>
      </div>
      <div class="col-2">
        <button type="button" class="btn  btn-secondary btn_farm">Non-farm</button>
      </div>
      <div class="col-2">
        <button type="button" class="btn  btn-secondary btn_farm">Farm</button>
      </div>
    
    </div>

</div>
 <br>
 <br> -->

 <!-- <div class="inner-container">
  <div class="row">
    <div class="col-4">
      <h3 class="h_kck"> Service</h3>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-2">
      <button type="button" class="btn  btn-secondary  btn_svc">Harsham</button>
    </div>
    <div class="col-2">
      <button type="button"   class="btn  btn-secondary  btn_svc">Santhwanam</button>
    </div>
    <div class="col-2">
      <button type="button" class="btn  btn-secondary  btn_svc">Gender</button>
    
    </div>
    <div class="col-2">
      <button type="button" class="btn  btn-secondary  btn_svc">Construction</button>
    </div>
    <div class="col-2">
      <button type="button" class="btn  btn-secondary  btn_svc">E-Seva</button>
    </div>
    <div class="col-2">
      <button type="button" class="btn  btn-secondary  btn_svc">Events</button>
    </div>
    

  </div>
 </div>
</div> -->




<!-- <div class="container p-1 settings">
  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        [class.active]="activeTab === 'products'"
        id="pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-home"
        type="button"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true"
        (click)="fetchProducts(); setActiveTab('products')"
      >
        {{ "products" | translate }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        [class.active]="activeTab === 'services'"
        id="pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-profile"
        type="button"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false"
        (click)="fetchServices(); setActiveTab('services')"
      >
        {{ "services" | translate }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        [class.active]="activeTab === 'sellers'"
        id="pills-sellers-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-sellers"
        type="button"
        role="tab"
        aria-controls="pills-sellers"
        aria-selected="false"
        (click)="fetchSeller(); setActiveTab('sellers')"
      >
        {{ "sellers" | translate }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" type="button" (click)="searchSeller()">
        Search Seller
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" type="button" (click)="getAnalytics()">
        Analytics
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" type="button" (click)="goToCloudKitchen()">
        Cloud Kitchen
      </button>
    </li>
  </ul>
  <hr />
  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade"
      [class.show]="activeTab === 'products'"
      [class.active]="activeTab === 'products'"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div class="container p-4 settings">
        <div class="row">
          <div class="col-2 filters">
            <div class="dropdown">
              <b>Approval Status</b>
              <button
                class="btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{
                  (dropdownService.productApprovalStatusDropdownHeading ===
                    "All" &&
                    "All") ||
                    (dropdownService.productApprovalStatusDropdownHeading ===
                      true &&
                      "Approved") ||
                    (dropdownService.productApprovalStatusDropdownHeading ===
                      false &&
                      "Not Approved")
                }}
              </button>
              <ul
                class="dropdown-menu approvalDropdown"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setProductapprovalStatusDropdownHeading('All')"
                    >All</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setProductapprovalStatusDropdownHeading(true)"
                    >Approved</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setProductapprovalStatusDropdownHeading(false)"
                    >Not Approved</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-2 filters">
            <div class="dropdown">
              <b>Status</b>
              <button
                class="btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ dropdownService.productStatusDropdownHeading }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setProductStatusDropdownHeading('All')"
                    >All</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setProductStatusDropdownHeading('Active')"
                    >Active</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setProductStatusDropdownHeading('Inactive')"
                    >Inactive</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="col-2 filters">
            <div class="dropdown">
              <b>Category</b>
              <button
                class="btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ dropdownService.productCategoryDropdownHeading }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setProductCategoryDropdownHeading('All')"
                    >All</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="
                      setProductCategoryDropdownHeading('Farm Produce');
                      selectedSubCategory = FarmProduceSubCategories
                    "
                    >Farm Produce</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="
                      setProductCategoryDropdownHeading('Home Produce');
                      selectedSubCategory = HomeProduceSubCategories
                    "
                    >Home Produce</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="
                      setProductCategoryDropdownHeading('Garden');
                      selectedSubCategory = GardenSubCategories
                    "
                    >Garden</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="
                      setProductCategoryDropdownHeading('Pets');
                      selectedSubCategory = PetsSubCategories
                    "
                    >Pets</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="
                      setProductCategoryDropdownHeading('Livestocks');
                      selectedSubCategory = LivestocksSubCategories
                    "
                    >Livestocks</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="
                      setProductCategoryDropdownHeading('Used Items');
                      selectedSubCategory = UsedItemsSubCategories
                    "
                    >Used Items</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-2 filters">
            <div class="dropdown">
              <b>Sub Category</b>
              <button
                class="btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ dropdownService.productSubCategoryDropdownHeading }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    (click)="
                      subCategoryDropdownHeading = 'Sub Category';
                      productCategory = 'Sub Category'
                    "
                    (click)="setProductSubCategoryDropdownHeading('All')"
                    >All</a
                  >
                </li>
                <!- <li *ngFor="let item of selectedSubCategory"><a class="dropdown-item"  (click)="subCategoryDropdownHeading = subCategorySwitch(item);
                  productCategory=item">{{subCategorySwitch(item)}}</a>
                </li> -->
                <!-- <li *ngFor="let item of selectedSubCategory">
                  <a
                    class="dropdown-item"
                    (click)="
                      setProductSubCategoryDropdownHeading(item);
                      productCategory = item
                    "
                    >{{ subCategorySwitch(item) }}</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="col-2 filters">
            <div class="dropdown">
              <b>Search Filter</b>
              <button
                type="button"
                class="btn btn-primary btnmainsearch"
                (click)="searchProducts()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <table class="table caption-top table-striped">
          <caption>
            {{
              "listOfProducts" | translate
            }}
          </caption>
          <thead>
            <tr>
              <th scope="col">Sl.No</th>
              <th scope="col">{{ "productTitle" | translate }}</th>
              <th scope="col">Seller ID</th>
              <th scope="col">{{ "postedDate" | translate }}</th>
              <th scope="col">{{ "status" | translate }}</th>
              <th scope="col">{{ "approval" | translate }}</th>
              <th scope="col"> view</th>
              <th scope="col"> contact</th>
              <th>{{ "action" | translate }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of productLists; let i = index">
              <th scope="row">{{ calculateIndexProduct(i) }}</th>
              <td>{{ item.productTitle }}</td>
              <td (click)="goToSellerProfile(item.profileID)" class="profileID">
                {{ item.profileID }}
              </td>
              <td>{{ item.date | slice : 0 : 10 }}</td>
              <td *ngIf="item.productStatus === 'Active'">
                <span class="badge rounded-pill bg-success">{{
                  item.productStatus
                }}</span>
              </td>
              <td *ngIf="item.productStatus === 'Inactive'">
                <span class="badge rounded-pill bg-danger">{{
                  item.productStatus
                }}</span>
              </td>
              <td *ngIf="item.isApproved === true">
                <span class="badge rounded-pill bg-success">Approved</span>
              </td>
              <td *ngIf="item.isApproved === false">
                <span class="badge rounded-pill bg-danger">Not Approved</span>
              </td>
              <td style="font-weight: 600;">{{item.views}} </td>
              <td style="font-weight: 600;">{{item.contacts}}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  (click)="editProduct(item._id)"
                >
                  Edit
                </button> -->
                <!-- <button type="button" class="btn btn-outline-danger btn-sm" (click)="confirmDelete(item._id)">Delete</button></td> -->
              <!-- </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col"></div>
          <div class="col">
            <ul class="pagination">
              <li class="page-item" [class.disabled]="productStartPage === 0">
                <a
                  class="page-link"
                  (click)="productPrevPage()"
                  style="cursor: pointer"
                  >Previous</a
                >
              </li>
              <li
                class="page-item"
                *ngFor="let page of productPages.slice(productStartPage, productEndPage)"
                [class.active]="productCurrentPage === page"
              >
                <a
                  class="page-link"
                  (click)="productGoToPage(page)"
                  style="cursor: pointer"
                  >{{ page }}</a
                >
              </li>
              <li
                class="page-item"
                [class.disabled]="productCurrentPage === productTotalPages || productTotalPages<=15"
              >
                <a
                  class="page-link"
                  (click)="productNextPage()"
                  style="cursor: pointer"
                  >Next</a
                >
              </li>
            </ul>
          </div>
          <div class="col"></div>
        </div>

        <h5 class="nodata" *ngIf="productLists.length === 0">
          {{ "noDataToShow" | translate }}
        </h5>
      </div>
    </div>

    <--------------------------------- Products ends here ---------------------------------------->

    <!--------------------------------------- Services starts here ---------------------------------------->
<!-- 
    <div
      class="tab-pane fade"
      [class.show]="activeTab === 'services'"
      [class.active]="activeTab === 'services'"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div class="container p-4 settings">
        <div class="row">
          <div class="col-2">
            <div class="dropdown">
              <b>Approval Status</b>
              <button
                class="btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{
                  (dropdownService.serviceApprovalStatusDropdownHeading ===
                    "All" &&
                    "All") ||
                    (dropdownService.serviceApprovalStatusDropdownHeading ===
                      true &&
                      "Approved") ||
                    (dropdownService.serviceApprovalStatusDropdownHeading ===
                      false &&
                      "Not Approved")
                }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setServiceapprovalStatusDropdownHeading('All')"
                    >All</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setServiceapprovalStatusDropdownHeading(true)"
                    >Approved</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setServiceapprovalStatusDropdownHeading(false)"
                    >Not Approved</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-2">
            <div class="dropdown">
              <b>Status</b>
              <button
                class="btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ dropdownService.serviceStatusDropdownHeading }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setServiceStatusDropdownHeading('All')"
                    >All</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setServiceStatusDropdownHeading('Active')"
                    >Active</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setServiceStatusDropdownHeading('Inactive')"
                    >Inactive</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="col-2">
            <div class="dropdown">
              <b>Category</b>
              <button
                class="btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ dropdownService.serviceCategoryDropdownHeading }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setServiceCategoryDropdownHeading('All')"
                    >All</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setServiceCategoryDropdownHeading('Farm Services')"
                    >Farm Services</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="
                      setServiceCategoryDropdownHeading('Skilled Services')
                    "
                    >Skilled Services</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="setServiceCategoryDropdownHeading('Local Events')"
                    >Local Events</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="
                      setServiceCategoryDropdownHeading('Other Services')
                    "
                    >Other Services</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="col-2">
            <div class="dropdown">
              <b>Search Filter</b>
              <button
                type="button"
                class="btn btn-primary btnmainsearch"
                (click)="searchServices()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <table class="table caption-top table-striped">
          <caption>
            {{
              "listOfProducts" | translate
            }}
          </caption>
          <thead>
            <tr>
              <th scope="col">Sl.No</th>
              <th scope="col">{{ "productTitle" | translate }}</th>
              <th scope="col">{{ "postedDate" | translate }}</th>
              <th scope="col">Seller ID</th>
              <th scope="col">{{ "status" | translate }}</th>
              <th scope="col">{{ "approval" | translate }}</th>
              <th>{{ "action" | translate }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of serviceLists; let i = index">
              <th scope="row">{{ calculateIndexService(i) }}</th>
              <td>{{ item.serviceTitle }}</td>
              <td (click)="goToSellerProfile(item.profileID)" class="profileID">
                {{ item.profileID }}
              </td>
              <td>{{ item.date | slice : 0 : 10 }}</td>
              <td *ngIf="item.serviceStatus === 'Active'">
                <span class="badge rounded-pill bg-success">{{
                  item.serviceStatus
                }}</span>
              </td>
              <td *ngIf="item.serviceStatus === 'Inactive'">
                <span class="badge rounded-pill bg-danger">{{
                  item.serviceStatus
                }}</span>
              </td>
              <td *ngIf="item.isApproved === true">
                <span class="badge rounded-pill bg-success">Approved</span>
              </td>
              <td *ngIf="item.isApproved === false">
                <span class="badge rounded-pill bg-danger">Not Approved</span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  (click)="editService(item._id)"
                >
                  Edit
                </button>
                 <button type="button" class="btn btn-outline-danger btn-sm" (click)="confirmDeleteService(item._id)">Delete</button></td> -->
              <!-- </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col"></div>
          <div class="col">
            <ul class="pagination">
              <li class="page-item" [class.disabled]="serviceStartPage === 0">
                <a
                  class="page-link"
                  (click)="servicePrevPage()"
                  style="cursor: pointer"
                  >Previous</a
                >
              </li>
              <li
                class="page-item"
                *ngFor="let page of servicePages.slice(serviceStartPage, serviceEndPage)"
                [class.active]="serviceCurrentPage === page"
              >
                <a
                  class="page-link"
                  (click)="serviceGoToPage(page)"
                  style="cursor: pointer"
                  >{{ page }}</a
                >
              </li>
              <li
                class="page-item"
                [class.disabled]="serviceCurrentPage === serviceTotalPages || serviceTotalPages<=15"
              >
                <a
                  class="page-link"
                  (click)="serviceNextPage()"
                  style="cursor: pointer"
                  >Next</a
                >
              </li>
            </ul>
          </div>
          <div class="col"></div>
        </div>
        <h5 class="nodata" *ngIf="serviceLists.length === 0">
          {{ "noDataToShow" | translate }}
        </h5>
      </div>
    </div>
  </div> -->
  <!--------------------------------------- Services ends here ---------------------------------------->

  <!--------------------------------------- Sellers starts here ---------------------------------------->
<!-- 
  <div
    class="tab-pane fade"
    [class.show]="activeTab === 'sellers'"
    [class.active]="activeTab === 'sellers'"
    id="pills-sellers"
    role="tabpanel"
    aria-labelledby="pills-sellers-tab"
  >
    <div class="container">
      <table class="table caption-top table-striped">
        <caption>
          {{
            "listOfSellers" | translate
          }}
        </caption>
        <thead>
          <tr>
            <th scope="col">Sl.No</th>
            <th scope="col">{{ "sellerId" | translate }}</th>
            <th scope="col">{{ "sellerName" | translate }}</th>
            <th scope="col">{{ "sellerContact" | translate }}</th>
            <th scope="col">{{ "joiningDate" | translate }}</th>
            <th scope="col">Account Status</th>
            <th scope="col">Postings</th>

            <th>{{ "action" | translate }}</th>
            <th>No of posts</th>
            <th>Reports</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of sellersLists; let i = index">
            <th scope="row">{{ calculateIndexSeller(i) }}</th>
            <td>{{ item.profileID }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.mobile }}</td>
            <td>{{ item.date | slice : 0 : 10 }}</td>
            <td *ngIf="item.delete === false">
              <span class="badge rounded-pill bg-success">Active</span>
            </td>
            <td *ngIf="item.delete === true">
              <span class="badge rounded-pill bg-danger">Inactive</span>
            </td>
            <td>
              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                (click)="editSeller(item.profileID, item.username)"
              >
                Posting Details
              </button>
            </td>
            <td>
              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                (click)="sellerDetails(item.profileID)"
              >
                Seller Details
              </button>
            </td>
            <td style="font-weight: 600;">{{ item.postCount }}</td>
            <td [ngStyle]="{'color': item.reports.length > 0 ? 'red' : '', 'font-weight': item.reports.length > 0 ? '600' : '600'}">{{item.reports.length}}</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col"></div>
        <div class="col">
          <ul class="pagination">
            <li class="page-item" [class.disabled]="sellerStartPage === 0">
              <a
                class="page-link"
                (click)="sellerPrevPage()"
                style="cursor: pointer"
                >Previous</a
              >
            </li>
            <li
              class="page-item"
              *ngFor="let page of sellerPages.slice(sellerStartPage,sellerEndPage)"
              [class.active]="sellerCurrentPage === page"
            >
              <a
                class="page-link"
                (click)="sellerGoToPage(page)"
                style="cursor: pointer"
                >{{ page }}</a
              >
            </li>
            <li
              class="page-item"
              [class.disabled]="sellerCurrentPage === sellerTotalPages || sellerTotalPages <= 15"
            >
              <a
                class="page-link"
                (click)="sellerNextPage()"
                style="cursor: pointer"
                >Next</a
              >
            </li>
          </ul>
        </div>
        <div class="col"></div>
      </div>
      <h5 class="nodata" *ngIf="sellersLists.length === 0">
        {{ "noDataToShow" | translate }}
      </h5>
    </div>
  </div> -->

  <!--------------------------------------- Sellers ends here ------------------------------------------>
<!-- </div> -->
