import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const ADMIN_URL = environment.adminUrl;

@Component({
  selector: 'app-cloud-kitchen-details',
  templateUrl: './cloud-kitchen-details.component.html',
  styleUrls: ['./cloud-kitchen-details.component.css'],
})
export class CloudKitchenDetailsComponent implements OnInit {
  CKID: string;
  kckDetails: any; // Update the type as needed
  userImageURL: any;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.CKID = params['CKID'];
      console.log(this.CKID);
      this.getKckDetails();
      // Now, you can use this.CKID in your component logic
    });
  }

  getKckDetails() {
    const baseUrl = `${ADMIN_URL}/ckAdmin/cloud-kitchen-by-id`; // Update the base URL as needed

    const body = { CKID: this.CKID };

    this.http.post<any>(baseUrl, body).subscribe(
      (data) => {
        this.kckDetails = data;
        console.log('KCK Details:', this.kckDetails);
        this.userImageURL = data.userImageURL;
        this.menuItems = data.menuItems;
        this.menuItemsForEdit = data.menuItems
        console.log(this.menuItems);

      },
      (error) => {
        console.error('Error fetching KCK details:', error);
      }
    );
  }

  // cloudKitchenData = {
  //   kckId: 'Your KCK ID',
  //   profileId: 'Your Profile ID',
  //   registrationDate: 'Registration Date',
  //   mobile:"47982234",
  //   address: "wjhofphoip",
  //   district: "hdasoidh",
  //   block: "oisbf",
  //   panchayath: "oiehfrwf",
  //   kitchenUnitMemberName: "esfgasefsefghois",
  //   fssaiLicense: "sdofiha",
  //   meAppId: "dsafhasif",
  //   cdsRegistrationId : "so8yhoaif",
  //   activeStatus: false,
  //   approved: true,
  //   upiId: "so8yhoadsf@yvbdsl"
  //   // ... other fields
  // };

  // showMenuItemsPopup:boolean=false;
  // closeMenuItemsPopup(){
  //   this.showMenuItemsPopup = false;

  // }
  // showMenuItems(){
  //   this.showMenuItemsPopup = true;
  // }
  // productImages = ['assets/burger1.jpg','assets/burger2.webp','assets/burger3.webp'];
  currentIndex = 0;

  setCurrentIndex(index: number) {
    this.currentIndex = index;
  }
  currentImageIndex: number = 0;
  saveMenuChanges() {
    // Perform any necessary logic to save the changes to your data
    // In this example, I'm assuming you want to update menu items
    const CKID = this.CKID; // Replace with your CKID

    // Send update request for menu items
    console.log(this.menuItemsForEdit);

    this.http
      .put(`${ADMIN_URL}/ckAdmin/update-menu-items/${CKID}`, {
        menuItems: this.menuItemsForEdit,
      })
      .subscribe(
        (response: any) => {
          console.log('Menu items updated successfully:', response);
          // Add any additional logic or UI updates here
          if (response.message === 'Menu items updated successfully.') {
            alert('Menu items updated successfully');
            this.getKckDetails()
          }

          // Close the edit menu popup after saving changes
          this.showEditMenuPopup = false;
        },
        (error) => {
          console.error('Error updating menu items:', error);
          // Handle errors or show error messages
        }
      );
  }

  menuItemsForEdit: any;
  menuItems:any[]=[];

  openEditMenuPopup() {
// Create a deep copy of menuItems for editing
this.menuItemsForEdit = JSON.parse(JSON.stringify(this.menuItems));
    this.showEditMenuPopup = true;
    // console.log(typeof(this.menuItems))
    // console.log(this.menuItemsForEdit,this.menuItems)
  }

  closeEditMenuPopup() {
    this.showEditMenuPopup = false;
  }

  // editedMenuItem: any = {}; // This object will hold the edited menu item
  showEditMenuPopup: boolean = false;

  cloudKitchenDataForEdit: any;

  // Provide the path to your cloud kitchen image
  cloudKitchenImage = 'assets/burger1.jpg';
  cancelEdit() {
    this.cloudKitchenDataForEdit = {};
    this.isEditing = false;
  }
  startEdit() {
    this.cloudKitchenDataForEdit = { ...this.kckDetails };
    this.isEditing = true;
  }

  isEditing: boolean = false;
  updateCloudKitchen() {
    const CKID = this.CKID; // Replace with your CKID
    // Identify fields that have changed
    const updateData = {};
    for (const key in this.cloudKitchenDataForEdit) {
      if (this.cloudKitchenDataForEdit.hasOwnProperty(key)) {
        if (this.cloudKitchenDataForEdit[key] !== this.kckDetails[key]) {
          updateData[key] = this.cloudKitchenDataForEdit[key];
        }
      }
    }

    this.http
      .put(`${ADMIN_URL}/ckAdmin/update-cloud-kitchen/${CKID}`, updateData)
      .subscribe(
        (response: any) => {
          console.log('Cloud Kitchen updated successfully:', response);
          // Add any additional logic or UI updates here
          if (response.message === 'Cloud Kitchen updated successfully.') {
            this.getKckDetails();
            alert('Cloud Kitchen updated successfully');
          }
        },
        (error) => {
          console.error('Error updating Cloud Kitchen:', error);
          // Handle errors or show error messages
        }
      );
    this.isEditing = false;
    console.log(this.kckDetails);
  }
}
