import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchSellerServiceService {

  constructor() { }
  private sellerSearchResults: any[] = [];
  searched:boolean = false;

  getSearchResults(): any[] {
    return this.sellerSearchResults;
  }

  setSearchResults(results: any[]): void {
    this.sellerSearchResults = results;
  }
}
