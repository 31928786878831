<section class="vh-100">
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-9 col-lg-6 col-xl-5">
        <img
          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
          class="img-fluid"
          alt="Sample image"
        />
      </div>
      <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
        <form>
          <img class="logologin" src="../../../assets/logos/pocketmart-011.png" alt="Amigo" />

          <!-- Email input -->
          <form>
            <div class="form-outline mb-3">
              <label class="form-label" for="form3Example3">Username</label>
              <input
                type="email"
                id="form3Example3"
                name="username"
                class="form-control form-control-lg"
                placeholder="Enter username"
                [(ngModel)]="username"
              />

              <label class="form-label" for="form3Example4">Password</label>
              <input
                type="password"
                id="form3Example4"
                name="password"
                class="form-control form-control-lg"
                placeholder="Enter password"
                [(ngModel)]="password"
              />
            </div>
          </form>

          <div class="d-flex justify-content-between align-items-center">
            <!-- Checkbox -->
            <!-- <div class="form-check mb-0"> -->
            <!-- <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3" /> -->
            <!-- <label class="form-check-label" for="form2Example3">
                  Remember me
                </label> -->
            <!-- </div> -->
            <!-- <a href="#!" class="text-body">Forgot password?</a> -->
          </div>

          <div class="text-center text-lg-start mt-4 pt-2">
            <button
              type="button submit"
              class="btn btn-success btn-lg"
              style="padding-left: 2.5rem; padding-right: 2.5rem"
              (click)="onLogin()"
            >
              Login
            </button>
            <!--  -->
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-3 px-4 px-xl-5 bg-success"
  >
    <!-- Copyright -->
    <div class="text-white mb-3 mb-md-0">
      <p>PocketMart</p>
    </div>
    <!-- Copyright -->

    <!-- Right -->
    <div>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-google"></i>
      </a>
      <a href="#!" class="text-white">
        <i class="fab fa-linkedin-in"></i>
      </a>
    </div>
    <!-- Right -->
  </div>
</section>
