import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';

const ADMIN_URL = environment.adminUrl;

@Component({
  selector: 'app-kckanalytics',
  templateUrl: './kckanalytics.component.html',
  styleUrls: ['./kckanalytics.component.css'],
})
export class KckanalyticsComponent {
  //variables declarations:
  orders: any;
  currentDate: string;
  totalAmount: number;
  COD: number;
  UPI: number;
  orderCancel: number;
  orderDelivered: number;
  notPaid: number;
  orderCancelled: number;
  menuItems: any[] = [];
  itemsByAddress: any[] = [];
  startDateString: string;
  endDateString: string;
  KCKProfileID: string;
  KCKuserName: string;
  totalCODPrice: number;
  totalUPIPrice: number;
  offersApplied: number;
  acceptedOrders: any;
  acceptedItemsCount: any;
  offerPrice: number;
  totalMealCount: number;
  totalGSTPrice: number;
  OFDOrders: any;
  ofdItemsCount: any;
  DELOrderItemCount:any
  offerDetails: any;

  //for subscriptions
  subscriptionDeliveredOrders: number;
  subscriptionDeliveredVegMeals: number;
  subscriptionDeliveredNonVegMeals: number;
  subscriptionAcceptedVegMeals: number;
  subscriptionAcceptedNonVegMeals: number;
  subscriptionOutForDeliveryVegMeals: number;
  subscriptionOutForDeliveryNonVegMeals: number;
  subscriptionTotalMeals: number;
  subscriptionAcceptedOrders: any;
  subscriptionOutForDeliveryOrders: any;
  subscriptionDeliveryOrders: any;
  noOfSubscriptions: number;

  subscriptionCombinedOrdersByAddress: any;
  subscriptionCombinedOrders: any;

  countCancelSubscriptionDates: number;
  totalOfferAmount: number;
  totalGSTAmount: number;
  subscriptionOrdersTotalAmount: number;
  subscriptionAcceptedFibreRichLunchComboCount: number;
  subscriptionDeliveredFibreRichLunchComboCount: number;

  subscriptionDeliveredCutFruitsComboCount: number;
  subscriptionDeliveredChickenBiriyaniCount: number;
  subscriptionAcceptedCutFruitsComboCount: number;
  subscriptionAcceptedChickenBiriyaniCount: number;
  subscriptionOutForDeliveryFibreRichLunchComboCount: number;
  subscriptionOutForDeliveryCutFruitsComboCount: number;
  subscriptionOutForDeliveryChickenBiriyaniCount: number;


  total15DayVegMealQuantity: number = 0;
     total15DayNonVegMealQuantity : number= 0;
     total15DayFibreRichLunchComboQuantity: number = 0;
     total15DayCutFruitsComboQuantity : number= 0;
     total15DayBiriyaniChickenQuantity : number= 0;

     total30DayVegMealQuantity : number= 0;
     total30DayNonVegMealQuantity : number= 0;
     total30DayFibreRichLunchComboQuantity: number = 0;
     total30DayCutFruitsComboQuantity : number= 0;
     total30DayBiriyaniChickenQuantity : number= 0;


  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.KCKProfileID = params['KCKProfileID'];
      this.KCKuserName = params['KCKuserName'];
      console.log(this.KCKProfileID, this.KCKuserName);
    });
    this.checkLogin();
  }

  //to check the login status starts --------------------------------
  checkLogin(): void {
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }
  //to check the login status ends --------------------------------

  searchAnalystics() {
    console.log('startDateString: ', this.startDateString);
    console.log('endDateString: ', this.endDateString);
  
    if (!this.startDateString) {
      return alert('Please select a date');
    }

    if (!this.endDateString) {
      this.endDateString = this.startDateString;
      return this.search();
    }

    if (this.startDateString || this.endDateString) {
      // this.fetchOrders();
      this.search();
    }

    // this.fetchOfferDetails();
  }

  search() {
    if (this.isAdmin() || this.isSuperAdmin()) {
      if (this.KCKProfileID) {
        this.fetchSingleKitchenDetails();
        this.fetchSubscriptionAnalyticsForSingleKitchen();
      } else {
        this.fetchOrders();
        
        this.fetchOfferDetails();
        this.fetchSubscriptionAnalytics();
      }
    } else if (this.isKitchen()) {
      this.fetchOrdersForKitchen();
      this.fetchSubscriptionAnalyticsForKitchen();
    }
  }

  //to check the loged user status starts----------------------------------------------------------------
  isAdmin(): boolean {
    return this.authService.isAuthenticated() && this.authService.isAdmin();
  }

  isSuperAdmin(): boolean {
    return (
      this.authService.isAuthenticated() && this.authService.isSuperAdmin()
    );
  }

  isKitchen(): boolean {
    return this.authService.isAuthenticated() && this.authService.isKitchen();
  }
  //to check the logged user status ends----------------------------------------------------------------

  fetchOrders(): void {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    console.log('the headers are', headers);

    const params = new HttpParams()
      .set('startDate', this.startDateString)
      .set('endDate', this.endDateString);

    this.http.get(ADMIN_URL + '/orders', { headers, params }).subscribe(
      (data) => {
        this.orders = data;
        console.log('the order details are::', this.orders);
        this.currentDate = this.orders.todayDate;
        // this.noOfOrders = this.orders.totalOrderCount;
        this.totalAmount = this.orders.totalAmount;
        this.COD = this.orders.COD;
        this.UPI = this.orders.UPI;
        this.offerPrice = this.orders.totalOfferPrice;
        this.totalCODPrice = this.orders.totalCODPrice;
        this.totalUPIPrice = this.orders.totalUPIPrice;
        this.orderDelivered = this.orders.DEL;
        this.orderCancelled = this.orders.CAN;
        this.offersApplied = this.orders.offerCount;
        this.totalGSTPrice = this.orders.totalGSTPrice;
        // this.totalMealCount=
        this.menuItems = Object.entries(this.orders.orderItemCount).map(
          ([name, count]) => ({ name, count })
        );

        this.totalMealCount = this.orders.totalMealCount;

        this.acceptedItemsCount = Object.entries(
          this.orders.acceptedOrderItemCount
        ).map(([name, count]) => ({ name, count }));

        this.ofdItemsCount = Object.entries(this.orders.OFDOrderItemCount).map(
          ([name, count]) => ({ name, count })
        );

        this.DELOrderItemCount = Object.entries(this.orders.DELOrderItemCount).map(
          ([name, count]) => ({ name, count })
        );

        this.itemsByAddress = Object.entries(this.orders.itemsByAddress).map(
          ([address, items]) => ({ address, items })
        );

        this.acceptedOrders = Object.entries(this.orders.acceptedOrders).map(
          ([address, items]) => ({ address, items })
        );

        this.OFDOrders = Object.entries(this.orders.outForDeliveryOrders).map(
          ([address, items]) => ({ address, items })
        );

        console.log('the items by address', this.itemsByAddress);
        console.log('the menu items are::', this.menuItems);
      },
      (error) => {
        console.error('Error fetching orders:', error);
      }
    );
  }
  subscriptions: any;
  fetchSubscriptionAnalytics(): void {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    console.log('the headers are', headers);

    const params = new HttpParams()
      .set('startDate', this.startDateString)
      .set('endDate', this.endDateString);

    this.http
      .get(ADMIN_URL + '/subscriptionsAnalytics', { headers, params })
      .subscribe(
        (data) => {
          console.log('subscriptionOrders:', data);

          this.orders = data;
        
          this.totalGSTAmount = this.orders.totalGSTAmount;
          this.subscriptionOrdersTotalAmount =
            this.orders.subscriptionOrdersTotalAmount;
          console.log('the results are', this.orders);
          this.totalOfferAmount = this.orders.totalOfferAmount;
          this.noOfSubscriptions = this.orders.noOfSubscriptions;

          this.subscriptions = this.orders.subscriptions;
          console.log('the Subscriptions are', this.subscriptions);
          this.countCancelSubscriptionDates =
            this.orders.countCancelSubscriptionDates;

          this.subscriptionDeliveredOrders = this.orders.deliveredOrders;
          

         
        //subscriptionQuantity
        this.total15DayVegMealQuantity = this.orders.total15DayVegMealQuantity;
        this.total15DayNonVegMealQuantity = this.orders.total15DayNonVegMealQuantity;
        this.total15DayFibreRichLunchComboQuantity = this.orders.total15DayFibreRichLunchComboQuantity;
        this.total15DayCutFruitsComboQuantity = this.orders.total15DayCutFruitsComboQuantity;
        this.total15DayBiriyaniChickenQuantity = this.orders.total15DayBiriyaniChickenQuantity;
        
        this.total30DayVegMealQuantity = this.orders.total30DayVegMealQuantity;
        this.total30DayNonVegMealQuantity = this.orders.total30DayNonVegMealQuantity;
        this.total30DayFibreRichLunchComboQuantity = this.orders.total30DayFibreRichLunchComboQuantity;
        this.total30DayCutFruitsComboQuantity = this.orders.total30DayCutFruitsComboQuantity;
        this.total30DayBiriyaniChickenQuantity = this.orders.total30DayBiriyaniChickenQuantity;


          //DELIVERED MEALS
          this.subscriptionDeliveredVegMeals =
            this.orders.deliveredVegMealsCount;
          this.subscriptionDeliveredNonVegMeals =
            this.orders.deliveredNonVegMealsCount;
          this.subscriptionDeliveredFibreRichLunchComboCount =
            this.orders.deliveredFibreRichLunchComboCount;
          this.subscriptionDeliveredCutFruitsComboCount =
            this.orders.deliveredCutFruitsComboCount;
          this.subscriptionDeliveredChickenBiriyaniCount =
            this.orders.deliveredChickenBiriyaniCount;

          //ACCEPTED MEALS
          this.subscriptionAcceptedVegMeals = this.orders.acceptedVegMealsCount;
          this.subscriptionAcceptedNonVegMeals =
            this.orders.acceptedNonVegMealsCount;
          this.subscriptionAcceptedFibreRichLunchComboCount =
            this.orders.acceptedFibreRichLunchComboCount;
          this.subscriptionAcceptedCutFruitsComboCount =
            this.orders.acceptedCutFruitsComboCount;
          this.subscriptionAcceptedChickenBiriyaniCount =
            this.orders.acceptedChickenBiriyaniCount;

          //OUT FOR DELIVERY MEALS
          this.subscriptionOutForDeliveryVegMeals =
            this.orders.outForDeliveryVegMealsCount;
          this.subscriptionOutForDeliveryNonVegMeals =
            this.orders.outForDeliveryNonVegMealsCount;
          this.subscriptionOutForDeliveryFibreRichLunchComboCount =
            this.orders.outForDeliveryFibreRichLunchComboCount;
          this.subscriptionOutForDeliveryCutFruitsComboCount =
            this.orders.outForDeliveryCutFruitsComboCount;
          this.subscriptionOutForDeliveryChickenBiriyaniCount =
            this.orders.outForDeliveryChickenBiriyaniCount;

          //TOTAL MEALS
          this.subscriptionTotalMeals =
            this.subscriptionDeliveredVegMeals +
            this.subscriptionDeliveredNonVegMeals +
            this.subscriptionDeliveredFibreRichLunchComboCount;
          +this.subscriptionDeliveredCutFruitsComboCount +
            this.subscriptionDeliveredChickenBiriyaniCount;

          this.subscriptionAcceptedOrders = Object.entries(
            this.orders.acceptedItemsByAddress
          ).map(([address, items]) => ({ address, items }));

          console.log(
            'subscriptionAcceptedOrders:',
            this.subscriptionAcceptedOrders
          );

          this.subscriptionOutForDeliveryOrders = Object.entries(
            this.orders.outForDeliveryItemsByAddress
          ).map(([address, items]) => ({ address, items }));

          console.log(
            'subscriptionOutForDeliveryOrders:',
            this.subscriptionOutForDeliveryOrders
          );

          this.subscriptionDeliveryOrders = Object.entries(
            this.orders.deliveryItemsByAddress
          ).map(([address, items]) => ({ address, items }));

          console.log(
            'subscriptionDeliveryOrders:',
            this.subscriptionDeliveryOrders
          );

          this.subscriptionCombinedOrdersByAddress = [
            ...this.subscriptionAcceptedOrders,
            ...this.subscriptionOutForDeliveryOrders,
            ...this.subscriptionDeliveryOrders,
          ];

          console.log(
            'subscriptionCombinedOrdersByAddress:',
            this.subscriptionCombinedOrdersByAddress
          );

          // Object to store combined orders
          let combinedOrders = {};

          // Iterate over each order
          this.subscriptionCombinedOrdersByAddress.forEach((order) => {
            const { address, items } = order;

            // Check if the address exists in combinedOrders
            if (combinedOrders[address]) {
              // Update existing items with incremented counts
              for (const itemName in items) {
                if (combinedOrders[address].items[itemName]) {
                  combinedOrders[address].items[itemName] += items[itemName];
                } else {
                  combinedOrders[address].items[itemName] = items[itemName];
                }
              }
            } else {
              // Add new address with its items to combinedOrders
              combinedOrders[address] = { address, items: { ...items } };
            }
          });

          // Convert combinedOrders object back to array of values
          const result = Object.values(combinedOrders);

          console.log('result:', result);

          // this.subscriptionCombinedOrders = Object.entries(
          //   this.subscriptionCombinedOrdersByAddress
          // ).map(([address, items]) => ({ address, items }));
          this.subscriptionCombinedOrders = result;

          console.log('combinedOrders: ', this.subscriptionCombinedOrders);
        },
        (error) => {
          console.error('Error fetching orders:', error);
        }
      );
  }

  fetchSubscriptionAnalyticsForKitchen(): void {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    console.log('the headers are', headers);

    const params = new HttpParams()
      .set('startDate', this.startDateString)
      .set('endDate', this.endDateString);

    this.http
      .get(ADMIN_URL + '/subscriptionsAnalyticsForKitchen', { headers, params })
      .subscribe(
        (data) => {
          this.orders = data;
          console.log('the results are', this.orders);
          this.totalGSTAmount = this.orders.totalGSTAmount;
          this.subscriptionOrdersTotalAmount =
            this.orders.subscriptionOrdersTotalAmount;
          this.totalOfferAmount = this.orders.totalOfferAmount;
          this.noOfSubscriptions = this.orders.noOfSubscriptions;
          this.countCancelSubscriptionDates =
            this.orders.countCancelSubscriptionDates;

          this.subscriptionDeliveredOrders = this.orders.deliveredOrders;

          //DELIVERED MEALS
          this.subscriptionDeliveredVegMeals =
            this.orders.deliveredVegMealsCount;
          this.subscriptionDeliveredNonVegMeals =
            this.orders.deliveredNonVegMealsCount;
          this.subscriptionDeliveredFibreRichLunchComboCount =
            this.orders.deliveredFibreRichLunchComboCount;
          this.subscriptionDeliveredCutFruitsComboCount =
            this.orders.deliveredCutFruitsComboCount;
          this.subscriptionDeliveredChickenBiriyaniCount =
            this.orders.deliveredChickenBiriyaniCount;

          //ACCEPTED MEALS
          this.subscriptionAcceptedVegMeals = this.orders.acceptedVegMealsCount;
          this.subscriptionAcceptedNonVegMeals =
            this.orders.acceptedNonVegMealsCount;
          this.subscriptionAcceptedFibreRichLunchComboCount =
            this.orders.acceptedFibreRichLunchComboCount;
          this.subscriptionAcceptedCutFruitsComboCount =
            this.orders.acceptedCutFruitsComboCount;
          this.subscriptionAcceptedChickenBiriyaniCount =
            this.orders.acceptedChickenBiriyaniCount;

          //OUT FOR DELIVERY MEALS
          this.subscriptionOutForDeliveryVegMeals =
            this.orders.outForDeliveryVegMealsCount;
          this.subscriptionOutForDeliveryNonVegMeals =
            this.orders.outForDeliveryNonVegMealsCount;
          this.subscriptionOutForDeliveryFibreRichLunchComboCount =
            this.orders.outForDeliveryFibreRichLunchComboCount;
          this.subscriptionOutForDeliveryCutFruitsComboCount =
            this.orders.outForDeliveryCutFruitsComboCount;
          this.subscriptionOutForDeliveryChickenBiriyaniCount =
            this.orders.outForDeliveryChickenBiriyaniCount;

          //TOTAL MEALS
          this.subscriptionTotalMeals =
            this.subscriptionDeliveredVegMeals +
            this.subscriptionDeliveredNonVegMeals +
            this.subscriptionDeliveredFibreRichLunchComboCount;
          +this.subscriptionDeliveredCutFruitsComboCount +
            this.subscriptionDeliveredChickenBiriyaniCount;

          this.subscriptionAcceptedOrders = Object.entries(
            this.orders.acceptedItemsByAddress
          ).map(([address, items]) => ({ address, items }));

          console.log(
            'subscriptionAcceptedOrders:',
            this.subscriptionAcceptedOrders
          );

          this.subscriptionOutForDeliveryOrders = Object.entries(
            this.orders.outForDeliveryItemsByAddress
          ).map(([address, items]) => ({ address, items }));

          console.log(
            'subscriptionOutForDeliveryOrders:',
            this.subscriptionOutForDeliveryOrders
          );

          this.subscriptionDeliveryOrders = Object.entries(
            this.orders.deliveryItemsByAddress
          ).map(([address, items]) => ({ address, items }));

          console.log(
            'subscriptionDeliveryOrders:',
            this.subscriptionDeliveryOrders
          );

          this.subscriptionCombinedOrdersByAddress = [
            ...this.subscriptionAcceptedOrders,
            ...this.subscriptionOutForDeliveryOrders,
            ...this.subscriptionDeliveryOrders,
          ];

          console.log(
            'subscriptionCombinedOrdersByAddress:',
            this.subscriptionCombinedOrdersByAddress
          );

          // Object to store combined orders
          let combinedOrders = {};

          // Iterate over each order
          this.subscriptionCombinedOrdersByAddress.forEach((order) => {
            const { address, items } = order;

            // Check if the address exists in combinedOrders
            if (combinedOrders[address]) {
              // Update existing items with incremented counts
              for (const itemName in items) {
                if (combinedOrders[address].items[itemName]) {
                  combinedOrders[address].items[itemName] += items[itemName];
                } else {
                  combinedOrders[address].items[itemName] = items[itemName];
                }
              }
            } else {
              // Add new address with its items to combinedOrders
              combinedOrders[address] = { address, items: { ...items } };
            }
          });

          // Convert combinedOrders object back to array of values
          const result = Object.values(combinedOrders);

          console.log('result:', result);

          // this.subscriptionCombinedOrders = Object.entries(
          //   this.subscriptionCombinedOrdersByAddress
          // ).map(([address, items]) => ({ address, items }));
          this.subscriptionCombinedOrders = result;

          console.log('combinedOrders: ', this.subscriptionCombinedOrders);
        },
        (error) => {
          console.error('Error fetching orders:', error);
        }
      );
  }

  fetchSubscriptionAnalyticsForSingleKitchen(): void {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    console.log('the headers are', headers);

    const params = new HttpParams()
      .set('startDate', this.startDateString)
      .set('endDate', this.endDateString)
      .set('kitchenID', this.KCKProfileID);

    this.http
      .get(ADMIN_URL + '/subscriptionsAnalyticsForSingleKitchen', {
        headers,
        params,
      })
      .subscribe(
        (data) => {
          this.orders = data;
          this.totalGSTAmount = this.orders.totalGSTAmount;
          this.subscriptionOrdersTotalAmount =
            this.orders.subscriptionOrdersTotalAmount;
          console.log('the results SINGLE KITCHEN are', this.orders);
          this.totalOfferAmount = this.orders.totalOfferAmount;
          this.noOfSubscriptions = this.orders.noOfSubscriptions;

          this.countCancelSubscriptionDates =
            this.orders.countCancelSubscriptionDates;

          this.subscriptionDeliveredOrders = this.orders.deliveredOrders;



 //subscriptionQuantity
 this.total15DayVegMealQuantity = this.orders.total15DayVegMealQuantity;
 this.total15DayNonVegMealQuantity = this.orders.total15DayNonVegMealQuantity;
 this.total15DayFibreRichLunchComboQuantity = this.orders.total15DayFibreRichLunchComboQuantity;
 this.total15DayCutFruitsComboQuantity = this.orders.total15DayCutFruitsComboQuantity;
 this.total15DayBiriyaniChickenQuantity = this.orders.total15DayBiriyaniChickenQuantity;
 
 this.total30DayVegMealQuantity = this.orders.total30DayVegMealQuantity;
 this.total30DayNonVegMealQuantity = this.orders.total30DayNonVegMealQuantity;
 this.total30DayFibreRichLunchComboQuantity = this.orders.total30DayFibreRichLunchComboQuantity;
 this.total30DayCutFruitsComboQuantity = this.orders.total30DayCutFruitsComboQuantity;
 this.total30DayBiriyaniChickenQuantity = this.orders.total30DayBiriyaniChickenQuantity;

          //DELIVERED MEALS
          this.subscriptionDeliveredVegMeals =
            this.orders.deliveredVegMealsCount;
          this.subscriptionDeliveredNonVegMeals =
            this.orders.deliveredNonVegMealsCount;
          this.subscriptionDeliveredFibreRichLunchComboCount =
            this.orders.deliveredFibreRichLunchComboCount;
          this.subscriptionDeliveredCutFruitsComboCount =
            this.orders.deliveredCutFruitsComboCount;
          this.subscriptionDeliveredChickenBiriyaniCount =
            this.orders.deliveredChickenBiriyaniCount;

          //ACCEPTED MEALS
          this.subscriptionAcceptedVegMeals = this.orders.acceptedVegMealsCount;
          this.subscriptionAcceptedNonVegMeals =
            this.orders.acceptedNonVegMealsCount;
          this.subscriptionAcceptedFibreRichLunchComboCount =
            this.orders.acceptedFibreRichLunchComboCount;
          this.subscriptionAcceptedCutFruitsComboCount =
            this.orders.acceptedCutFruitsComboCount;
          this.subscriptionAcceptedChickenBiriyaniCount =
            this.orders.acceptedChickenBiriyaniCount;

          //OUT FOR DELIVERY MEALS
          this.subscriptionOutForDeliveryVegMeals =
            this.orders.outForDeliveryVegMealsCount;
          this.subscriptionOutForDeliveryNonVegMeals =
            this.orders.outForDeliveryNonVegMealsCount;
          this.subscriptionOutForDeliveryFibreRichLunchComboCount =
            this.orders.outForDeliveryFibreRichLunchComboCount;
          this.subscriptionOutForDeliveryCutFruitsComboCount =
            this.orders.outForDeliveryCutFruitsComboCount;
          this.subscriptionOutForDeliveryChickenBiriyaniCount =
            this.orders.outForDeliveryChickenBiriyaniCount;

          //TOTAL MEALS
          this.subscriptionTotalMeals =
            this.subscriptionDeliveredVegMeals +
            this.subscriptionDeliveredNonVegMeals +
            this.subscriptionDeliveredFibreRichLunchComboCount;
          +this.subscriptionDeliveredCutFruitsComboCount +
            this.subscriptionDeliveredChickenBiriyaniCount;

          this.subscriptionAcceptedOrders = Object.entries(
            this.orders.acceptedItemsByAddress
          ).map(([address, items]) => ({ address, items }));

          console.log(
            'subscriptionAcceptedOrders:',
            this.subscriptionAcceptedOrders
          );

          this.subscriptionOutForDeliveryOrders = Object.entries(
            this.orders.outForDeliveryItemsByAddress
          ).map(([address, items]) => ({ address, items }));

          console.log(
            'subscriptionOutForDeliveryOrders:',
            this.subscriptionOutForDeliveryOrders
          );

          this.subscriptionDeliveryOrders = Object.entries(
            this.orders.deliveryItemsByAddress
          ).map(([address, items]) => ({ address, items }));

          console.log(
            'subscriptionDeliveryOrders:',
            this.subscriptionDeliveryOrders
          );

          this.subscriptionCombinedOrdersByAddress = [
            ...this.subscriptionAcceptedOrders,
            ...this.subscriptionOutForDeliveryOrders,
            ...this.subscriptionDeliveryOrders,
          ];

          console.log(
            'subscriptionCombinedOrdersByAddress:',
            this.subscriptionCombinedOrdersByAddress
          );

          // Object to store combined orders
          let combinedOrders = {};

          // Iterate over each order
          this.subscriptionCombinedOrdersByAddress.forEach((order) => {
            const { address, items } = order;

            // Check if the address exists in combinedOrders
            if (combinedOrders[address]) {
              // Update existing items with incremented counts
              for (const itemName in items) {
                if (combinedOrders[address].items[itemName]) {
                  combinedOrders[address].items[itemName] += items[itemName];
                } else {
                  combinedOrders[address].items[itemName] = items[itemName];
                }
              }
            } else {
              // Add new address with its items to combinedOrders
              combinedOrders[address] = { address, items: { ...items } };
            }
          });

          // Convert combinedOrders object back to array of values
          const result = Object.values(combinedOrders);

          console.log('result:', result);

          // this.subscriptionCombinedOrders = Object.entries(
          //   this.subscriptionCombinedOrdersByAddress
          // ).map(([address, items]) => ({ address, items }));
          this.subscriptionCombinedOrders = result;

          console.log('combinedOrders: ', this.subscriptionCombinedOrders);
        },
        (error) => {
          console.error('Error fetching orders:', error);
        }
      );
  }

  vegMealsWithoutOffer: number = 0;
  nonVegMealsWithoutOffer: number = 0;

  vegMealsWithOffer100: number = 0;
  vegMealsWithOffer50: number = 0;
  vegMealsWithOffer20: number = 0;

  nonVegMealsWithOffer100: number = 0;
  nonVegMealsWithOffer50: number = 0;
  nonVegMealsWithOffer20: number = 0;

  fetchOfferDetails(): void {
    if (!this.startDateString) {
      return alert('Please select a start date');
    }

    if (!this.endDateString) {
      this.endDateString = this.startDateString;
    }

    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    console.log('the headers are', headers);

    const params = new HttpParams()
      .set('startDate', this.startDateString)
      .set('endDate', this.endDateString);

    this.http.get(ADMIN_URL + '/indOffers', { headers, params }).subscribe(
      (data) => {
        this.offerDetails = data;
        console.log('the orders are', this.offerDetails);
        this.vegMealsWithoutOffer = this.offerDetails.vegMealsWithoutOffer;
        this.nonVegMealsWithoutOffer =
          this.offerDetails.nonVegMealsWithoutOffer;

        this.vegMealsWithOffer100 = this.offerDetails.vegMealsWithOffer100;
        this.vegMealsWithOffer50 = this.offerDetails.vegMealsWithOffer50;
        this.vegMealsWithOffer20 = this.offerDetails.vegMealsWithOffer20;

        this.nonVegMealsWithOffer100 =
          this.offerDetails.nonVegMealsWithOffer100;
        this.nonVegMealsWithOffer50 = this.offerDetails.nonVegMealsWithOffer50;
        this.nonVegMealsWithOffer20 = this.offerDetails.nonVegMealsWithOffer20;
      },
      (error) => {
        console.error('Error fetching orders:', error);
      }
    );
  }

  fetchOrdersForKitchen(): void {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = new HttpParams()
      .set('startDate', this.startDateString)
      .set('endDate', this.endDateString);

    this.http
      .get(ADMIN_URL + '/kitchen/kitchenOrders', { headers, params })
      .subscribe(
        (data) => {
          this.orders = data;
          console.log('the order details are::', this.orders);
          this.currentDate = this.orders.todayDate;
          // this.noOfOrders = this.orders.totalOrderCount;
          this.totalAmount = this.orders.totalAmount;
          this.COD = this.orders.COD;
          this.UPI = this.orders.UPI;
          this.offerPrice = this.orders.totalOfferPrice;
          this.offersApplied = this.orders.offerCount;
          this.totalCODPrice = this.orders.totalCODPrice;
          this.totalUPIPrice = this.orders.totalUPIPrice;
          this.orderDelivered = this.orders.DEL;
          this.orderCancelled = this.orders.CAN;
          this.totalMealCount = this.orders.totalMealCount;
          this.totalGSTPrice = this.orders.totalGSTPrice;
          this.menuItems = Object.entries(this.orders.orderItemCount).map(
            ([name, count]) => ({ name, count })
          );

          this.acceptedOrders = Object.entries(this.orders.acceptedOrders).map(
            ([address, items]) => ({ address, items })
          );

          this.acceptedItemsCount = Object.entries(
            this.orders.acceptedOrderItemCount
          ).map(([name, count]) => ({ name, count }));

          this.itemsByAddress = Object.entries(this.orders.itemsByAddress).map(
            ([address, items]) => ({ address, items })
          );

          this.OFDOrders = Object.entries(this.orders.outForDeliveryOrders).map(
            ([address, items]) => ({ address, items })
          );

          this.ofdItemsCount = Object.entries(
            this.orders.OFDOrderItemCount
          ).map(([name, count]) => ({ name, count }));

          this.DELOrderItemCount = Object.entries(this.orders.DELOrderItemCount).map(
            ([name, count]) => ({ name, count })
          );
  
          this.itemsByAddress = Object.entries(this.orders.itemsByAddress).map(
            ([address, items]) => ({ address, items })
          );
  
          this.acceptedOrders = Object.entries(this.orders.acceptedOrders).map(
            ([address, items]) => ({ address, items })
          );
  
          this.OFDOrders = Object.entries(this.orders.outForDeliveryOrders).map(
            ([address, items]) => ({ address, items })
          );
          console.log('the items by address', this.itemsByAddress);
          console.log('the menu items are::', this.menuItems);
        },
        (error) => {
          console.error('Error fetching orders:', error);
        }
      );
  }

  // fetchSingleKitchenDetails(): void {
  //   const token = this.authService.getToken();
  //   console.log('the order function is called');
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   const params = new HttpParams()
  //     .set('startDate', this.startDateString)
  //     .set('endDate', this.endDateString)
  //     .set('profileID', this.KCKProfileID);

  //   this.http
  //     .get(ADMIN_URL + '/sigleKitchen/orders', { headers, params })
  //     .subscribe(
  //       (data) => {
  //         this.orders = data;
  //         console.log('the order details are::', this.orders);
  //         this.currentDate = this.orders.todayDate;
  //         // this.noOfOrders = this.orders.totalOrderCount;
  //         this.totalAmount = this.orders.totalAmount;
  //         this.COD = this.orders.COD;
  //         this.UPI = this.orders.UPI;
  //         this.offersApplied = this.orders.offerCount;
  //         this.totalCODPrice = this.orders.totalCODPrice;
  //         this.offerPrice = this.orders.totalOfferPrice;
  //         this.totalUPIPrice = this.orders.totalUPIPrice;
  //         this.orderDelivered = this.orders.DEL;
  //         this.orderCancelled = this.orders.CAN;
  //         this.totalMealCount = this.orders.totalMealCount;
  //         this.totalGSTPrice = this.orders.totalGSTPrice;
  //         this.menuItems = Object.entries(this.orders.orderItemCount).map(
  //           ([name, count]) => ({ name, count })
  //         );
  //         this.itemsByAddress = Object.entries(this.orders.itemsByAddress).map(
  //           ([address, items]) => ({ address, items })
  //         );

  //         this.acceptedOrders = Object.entries(this.orders.acceptedOrders).map(
  //           ([address, items]) => ({ address, items })
  //         );

  //         this.OFDOrders = Object.entries(this.orders.outForDeliveryOrders).map(
  //           ([address, items]) => ({ address, items })
  //         );

  //         this.ofdItemsCount = Object.entries(
  //           this.orders.OFDOrderItemCount
  //         ).map(([name, count]) => ({ name, count }));

  //         this.acceptedItemsCount = Object.entries(
  //           this.orders.acceptedOrderItemCount
  //         ).map(([name, count]) => ({ name, count }));

  //         this.DELOrderItemCount = Object.entries(this.orders.DELOrderItemCount).map(
  //           ([name, count]) => ({ name, count })
  //         );
  
  //         this.itemsByAddress = Object.entries(this.orders.itemsByAddress).map(
  //           ([address, items]) => ({ address, items })
  //         );
  
  //         this.acceptedOrders = Object.entries(this.orders.acceptedOrders).map(
  //           ([address, items]) => ({ address, items })
  //         );
  
  //         this.OFDOrders = Object.entries(this.orders.outForDeliveryOrders).map(
  //           ([address, items]) => ({ address, items })
  //         );
  //         console.log('the items by address', this.itemsByAddress);
  //         console.log('the menu items are::', this.menuItems);
  //       },
  //       (error) => {
  //         console.error('Error fetching orders:', error);
  //       }
  //     );
  // }


  fetchSingleKitchenDetails(): void {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = new HttpParams()
      .set('startDate', this.startDateString)
      .set('endDate', this.endDateString)
      .set('profileID', this.KCKProfileID);

    this.http
      .get(ADMIN_URL + '/sigleKitchen/orders', { headers, params })
      .subscribe(
        (data) => {
          this.orders = data;
          console.log('the order details are::', this.orders);
          this.currentDate = this.orders.todayDate;
          // this.noOfOrders = this.orders.totalOrderCount;
          this.totalAmount = this.orders.totalAmount;
          this.COD = this.orders.COD;
          this.UPI = this.orders.UPI;
          this.offerPrice = this.orders.totalOfferPrice;
          this.offersApplied = this.orders.offerCount;
          this.totalCODPrice = this.orders.totalCODPrice;
          this.totalUPIPrice = this.orders.totalUPIPrice;
          this.orderDelivered = this.orders.DEL;
          this.orderCancelled = this.orders.CAN;
          this.totalMealCount = this.orders.totalMealCount;
          this.totalGSTPrice = this.orders.totalGSTPrice;
          this.menuItems = Object.entries(this.orders.orderItemCount).map(
            ([name, count]) => ({ name, count })
          );

          this.acceptedOrders = Object.entries(this.orders.acceptedOrders).map(
            ([address, items]) => ({ address, items })
          );

          this.acceptedItemsCount = Object.entries(
            this.orders.acceptedOrderItemCount
          ).map(([name, count]) => ({ name, count }));

          this.itemsByAddress = Object.entries(this.orders.itemsByAddress).map(
            ([address, items]) => ({ address, items })
          );

          this.OFDOrders = Object.entries(this.orders.outForDeliveryOrders).map(
            ([address, items]) => ({ address, items })
          );

          this.ofdItemsCount = Object.entries(
            this.orders.OFDOrderItemCount
          ).map(([name, count]) => ({ name, count }));

          this.DELOrderItemCount = Object.entries(this.orders.DELOrderItemCount).map(
            ([name, count]) => ({ name, count })
          );
  
          this.itemsByAddress = Object.entries(this.orders.itemsByAddress).map(
            ([address, items]) => ({ address, items })
          );
  
          this.acceptedOrders = Object.entries(this.orders.acceptedOrders).map(
            ([address, items]) => ({ address, items })
          );
  
          this.OFDOrders = Object.entries(this.orders.outForDeliveryOrders).map(
            ([address, items]) => ({ address, items })
          );
          console.log('the items by address', this.itemsByAddress);
          console.log('the menu items are::', this.menuItems);
        },
        (error) => {
          console.error('Error fetching orders:', error);
        }
      );
  }


  //switch statements
  getDisplayName(name: string): string {
    switch (name) {
      case 'vegMeals':
        return 'Veg meals';
      case 'nonVegMeals':
        return 'Non-Veg meals';
      case 'fibreRichLunchCombo':
        return 'Fibre rich lunch combo';
      case 'cutFruitsCombo':
        return 'Cut fruits Combo';
      case 'chickenBiriyani':
        return 'Biriyani (Chicken)';
      default:
        return name;
    }
  }

  getDisplayNameForAcceptedOrders(name: string): string {
    switch (name) {
      case 'acceptedVegMeals':
        return 'Veg meals';
      case 'acceptedNonVegMeals':
        return 'Non-Veg meals';
      case 'acceptedFibreRichLunchCombo':
        return 'Fibre rich lunch combo';
      case 'acceptedCutFruitsCombo':
        return 'Cut fruits Combo';
      case 'acceptedChickenBiriyani':
        return 'Biriyani (Chicken)';
      case 'ofdVegMealsCount':
        return 'Veg meals';
      case 'ofdNonVegMealsCount':
        return 'Non-Veg meals';
      case 'ofdFibreRichLunchComboCount':
        return 'Fibre rich lunch combo';
      case 'ofdCutFruitsComboCount':
        return 'Cut fruits Combo';
      case 'ofdBiriyaniChickenCount':
        return 'Biriyani (Chicken)';
        case 'delVegMealsCount':
        return 'Veg meals';
      case 'delNonVegMealsCount':
        return 'Non-Veg meals';
      case 'delFibreRichLunchComboCount':
        return 'Fibre rich lunch combo';
      case 'delCutFruitsComboCount':
        return 'Cut fruits Combo';
      case 'delBiriyaniChickenCount':
        return 'Biriyani (Chicken)';
      default:
        return name;
    }
  }

  getItemNames(items: { [itemName: string]: number }): string[] {
    return Object.keys(items);
  }
}
