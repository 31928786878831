import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { environment } from 'src/environments/environment';
const ADMIN_URL = environment.adminUrl;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  error: string = '';

  constructor(private authService: AuthService, private router: Router) {}

  onLogin(): void {
    if (this.username === '' || this.password === '') {
      return alert('All input fields are required');
    }
    // Perform login logic here
    const user = {
      username: this.username,
      password: this.password,
    };
    this.authService.login(user).subscribe(
      (res) => {
        console.log(res, 'res');
        if (res.message == 'Invalid credentials') {
          return alert('Invalid credentials');
        }
        if (res.message == 'User not found') {
          return alert('User not found');
        } else {
          const expiresIn = res.expiresIn;
          this.authService.saveToken(res.token, expiresIn);
          this.router.navigate(['/Kckanalytics']);
        }
      },
      (error) => {
        this.error = error.error.message;
      }
    );
  }
 

}
