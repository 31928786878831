import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionOrdersService {
  orderDetails: any; // Property to store order details
  selectedDate: any;
  endDate: any;
  constructor() {}

  setSubscriptionOrderDetails(
    details: any,
    selectedDate: any,
    endDate: any
  ): void {
    this.selectedDate = selectedDate;
    this.endDate = endDate;
    this.orderDetails = details;
    console.log('orderDetails set in service: ', this.orderDetails);
  }

  getOrderDetails(): any {
    console.log('orderDetails called in service: ', this.orderDetails);
    return this.orderDetails;
  }

  getSelectedDate(): any {
    console.log('getSelectedDate called in service: ', this.selectedDate);
    return this.selectedDate;
  }

  getEndDate(): any {
    console.log('getEndDate called in service: ', this.endDate);
    return this.endDate;
  }
}
