import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
const ADMIN_URL = environment.adminUrl;
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { SearchSellerServiceService } from './search-seller-service.service';


@Component({
  selector: 'app-search-seller',
  templateUrl: './search-seller.component.html',
  styleUrls: ['./search-seller.component.css']
})
export class SearchSellerComponent implements OnInit {
  ngOnInit(): void {
    // Retrieve search results from the service when the component is loaded
  this.sellerSearch = this.sellerSearchService.getSearchResults();
  this.searched = this.sellerSearchService.searched;
  }
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private sellerSearchService: SearchSellerServiceService

  ) {}
  searchTerm: string = '';


  editSeller(sellerId: string, username: string) {
    console.log('edit', sellerId);
    this.router.navigate(['/seller-postings'], {
      queryParams: {
        sellerId: sellerId,
        username: username,
      },
    });
  }

  sellerDetails(sellerId: string) {
    this.router.navigate(['/seller-details'], {
      queryParams: {
        sellerId: sellerId,
      },
    });
  }
  fetchSellerPostCount(profileID: string) {
    console.log(profileID)
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    // Assuming you have an API endpoint to fetch the post count for a specific seller
    const url = `${ADMIN_URL}/getTotalposts/${profileID}`;
    return this.http.get(url, {headers});
  }
  searched:boolean = false;
  searchSeller() {
    console.log(this.searchTerm)
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    // Send a request to the backend to search for users
    const url = `${ADMIN_URL}/searchSeller?searchSeller=${this.searchTerm}`;
    this.http.get(url, {headers}).subscribe((data: any) => {
      this.sellerSearch = data.seller;
      this.sellerSearch.forEach((seller) => {
        this.fetchSellerPostCount(seller.profileID).subscribe((postCount: any) => {
          seller.postCount = postCount.totalCount; // Store the post count for each seller
        });
      });
      this.sellerSearchService.searched=true;
      this.searched = this.sellerSearchService.searched;
      console.log(this.sellerSearch)
      // Store the search results in the service
      this.sellerSearchService.setSearchResults(this.sellerSearch);
    });
  }
  sellerSearch:any[]=[];
  goBack() {
    // Navigate back in the router history
    this.location.back()
  }
  onLogout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
  onAdminClick() {
    this.router.navigate(['/dashboard']);
  }
  onSettings() {
    // Perform login logic here
    console.log('hi');
    // Redirect to the admin page
    this.router.navigate(['/settings']);
  }
}
