import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';



const ADMIN_URL = environment.adminUrl;

@Component({
  selector: 'app-create-kitchen',

  templateUrl: './create-kitchen.component.html',
  styleUrl: './create-kitchen.component.css'
})
export class CreateKitchenComponent {

// Only AlphaNumeric
keyPressAlphanumeric(event) {
  var inp = String.fromCharCode(event.keyCode);

  if (/^[a-zA-Z0-9\-\/]$/.test(inp)) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
}


profileID: string ='';
username: String = '';
KCKID: string = '';
address: string = '';
mobile: string = '';
// Add the new fields
district: string = '';
block: string = '';
panchayath: string = '';
kitchenUnitMemberName: string = '';
fssaiLicense: string = '';
deliveryAreas: string = '';
meAppId: string = '';
cdsRegistrationId: string = '';
GSTINnumber: string = '';
upiId: string = '';
latitude: string='';
longitude: string='';
newUserPhoneNumber: string = '';
checkPhoneNumberLength() {
  // Check if the phone number is exactly 10 digits
  return this.newUserPhoneNumber.length !== 10;
}
continue() {
  if (
    (this.selectedMunicipalityId ||
      this.selectedMunicipalCorporationId ||
      this.selectedGramPanchayat) &&
    this.address
  ) {


    this.profileID =   this.profileID = 'KID' + new Date().getTime().toString().substr(3, 10);
    
    
    const data = {

      username: this.username,
      mobile: this.newUserPhoneNumber,
      KCKID: this.KCKID,
      profileID: this.profileID,
      address: this.address,
      // Add the new fields to the data object
      legalBody: this.legalBody,
      district: this.selectedDistrict,
      block: this.selectedBlock,
      panchayath: this.selectedGramPanchayat,
      municipality: this.selectedMunicipalityId,
      municipalCorporation: this.selectedMunicipalCorporationId,

      kitchenUnitMemberName: this.kitchenUnitMemberName,
      fssaiLicense: this.fssaiLicense,
      meAppId: this.meAppId,
      cdsRegistrationId: this.cdsRegistrationId,
      UPIID: this.upiId,
      deliveryAreas: this.deliveryAreas,
      latitude: this.latitude,
      longitude: this.longitude,
      GSTINnumber: this.GSTINnumber,
    };

    // Assuming you have a placeholder value for profileID or you get it from somewhere else
    const profileID = this.profileID;
    const token = this.authService.getToken();

    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const options = { headers };
    // Make the API call directly within the component
    this.http
      .post(`${ADMIN_URL}/updateKCKDetails/${profileID}`, data, options)
      .subscribe(
        (response) => {
          console.log('User updated:', response);
            console.log('User updated:', response);
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Details updated successfully',
            confirmButtonText: 'Okay',
            confirmButtonColor: 'rgb(38 117 79)',
          }).then(() => {
            this.router.navigate(['/signup']);
          });
          
        },
        (error) => {
          console.log(error.error);

          console.error('Error updating user:', error);
          if (
            error.error.error == 'KCKID is already in use by another user.'
          ) {
            // Show SweetAlert when KCK ID already exists.
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'ID is already in use by another user.',
              confirmButtonText: 'Okay',
              confirmButtonColor: 'rgb(38 117 79)',
            });
            return;
          }

          // Handle the error as needed (show a message, log it, etc.)
        }
      );
  } else {
    // Show SweetAlert when KCK required fields are missing
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Please enter on all required fields',
      confirmButtonText: 'Okay',
      confirmButtonColor: 'rgb(38 117 79)',
    });
    return;
  }
}

constructor(
  private http: HttpClient,
  private router: Router,
  private authService: AuthService,
) {}

selectedLevel: string = 'blockLevel'; // Property to store the selected level
legalBody: string;
districts: any[] = [];
blocks: any = {};
gramPanchayats: any = {};

selectedDistrict: string = '';
selectedBlock: string = '';
selectedGramPanchayat: string = '';

gramPanchayatsLoaded: boolean = false;
// Flag to track whether gram panchayats are loaded

ngOnInit(): void {
  this.fetchDistricts();
}

fetchDistricts(): void {
  this.http
    .get<any>('assets/cloudKitchenPlaces/districtBlocks/districts.json')
    .subscribe((data) => {
      this.districts = data.districts;
      console.log(this.districts);
    });
}

fetchDistrictsforMunicipalCorporation(): void {
  this.http
    .get<any>(
      'assets/cloudKitchenPlaces/municipalCorporations/districtsCorp.json'
    )
    .subscribe((data) => {
      this.districts = data.districts;
      console.log(this.districts);
    });
}

fetchBlocksForDistrict(districtId: string): void {
  this.http
    .get<any>('assets/cloudKitchenPlaces/districtBlocks/blocks.json')
    .subscribe((data) => {
      this.blocks = data;
      console.log(this.blocks);
    });
}

fetchGramPanchayatsForDistrict(districtId: string): void {
  this.http
    .get<any>(
      'assets/cloudKitchenPlaces/gramaPanchayats/' + districtId + '.json'
    )
    .subscribe((data) => {
      this.gramPanchayats[districtId] = data;
      this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
      console.log(this.gramPanchayats);
    });
}

onDistrictChange(): void {
  this.selectedBlock = ''; // Reset selected block and gram panchayat when district changes
  this.selectedGramPanchayat = '';
  this.selectedMunicipalityId = '';
  this.selectedMunicipalCorporationId = '';
  this.selectedMunicipality = null;
  this.gramPanchayatsLoaded = false; // Reset flag when district changes
  if (this.selectedDistrict) {
    if (this.selectedLevel === 'municipalityLevel') {
      this.selectedMunicipality = this.municipalities[this.selectedDistrict];
      console.log('municipality', this.selectedMunicipality);

      return;
    } else if (this.selectedLevel == 'municipalCorporation') {
      this.selectedMunicipalCorporation =
        this.municipalCorporations[this.selectedDistrict];
      console.log('municipalCorporation', this.selectedMunicipalCorporation);

      return;
    } else {
      this.fetchBlocksForDistrict(this.selectedDistrict);
      // Preload gram panchayats data for the selected district
      this.fetchGramPanchayatsForDistrict(this.selectedDistrict);
    }
  }
}

onBlockChange(): void {
  this.fetchGramPanchayatsForDistrict(this.selectedDistrict);
  this.selectedGramPanchayat = ''; // Reset selected gram panchayat when block changes
  if (this.selectedBlock) {
    this.selectedGramPanchayat = ''; // Reset selected gram panchayat when block changes
    // this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
  }
  // Set the gram panchayats data based on the selected block
  if (this.selectedBlock && this.blocks[this.selectedDistrict]) {
    this.gramPanchayats =
      this.gramPanchayats[this.selectedDistrict][this.selectedBlock];
    console.log(this.gramPanchayats);
  }
}
onPChange(): void {
  console.log(
    this.selectedBlock,
    this.selectedDistrict,
    this.selectedGramPanchayat
  );
}

// For Municipal Level

municipalities: any;
selectedMunicipality: any;
selectedMunicipalityId: any;

fetchMunicipalitiesForDistrict(): void {
  this.http
    .get<any>('assets/cloudKitchenPlaces/municipalities/municipalities.json')
    .subscribe((data) => {
      this.municipalities = data.municipalities;
      console.log(data.municipalities);
    });
}

// Method to handle radio button selection
onLevelSelection(selectedLevel: string): void {
  this.selectedDistrict = '';
  this.selectedBlock = ''; // Reset selected block and gram panchayat when district changes
  this.selectedGramPanchayat = '';
  this.gramPanchayatsLoaded = false; // Reset flag when district changes
  this.selectedMunicipality = null;
  this.selectedMunicipalCorporation = null;
  this.selectedLevel = selectedLevel;
  console.log(this.selectedMunicipalityId);

  if (this.selectedLevel === 'blockLevel') {
    this.legalBody = 'PAN';

    this.fetchDistricts();
  }
  if (this.selectedLevel === 'municipalityLevel') {
    this.legalBody = 'MUN';

    this.fetchDistricts();
    this.fetchMunicipalitiesForDistrict();
  }
  if (this.selectedLevel === 'municipalCorporation') {
    this.legalBody = 'MNC';

    this.fetchDistrictsforMunicipalCorporation();
    this.fetchMunicipalCorporationsForDistrict();
  }
  console.log('legalBody', this.legalBody);
}

onMunicipalityChange(): void {
  // Implement actions to handle the change in selected municipality
  console.log('Selected municipality:', this.selectedMunicipalityId);
  // You can perform additional actions here based on the selected municipality
}
onMunicipalCorporationChange(): void {
  // Implement actions to handle the change in selected municipality
  console.log('Selected municipality:', this.selectedMunicipalCorporationId);
  // You can perform additional actions here based on the selected municipality
}

//For Municipal Corporation Level;
municipalCorporations: any = {};
selectedMunicipalCorporation: any;
selectedMunicipalCorporationId: any;

fetchMunicipalCorporationsForDistrict(): void {
  // Assuming you are fetching data from a JSON file named municipal_corporations.json
  this.http
    .get<any>(
      'assets/cloudKitchenPlaces/municipalCorporations/municipal_corporation.json'
    )
    .subscribe((data) => {
      this.municipalCorporations = data.municipal_corporations || {};
      console.log(this.municipalCorporations);
    });
}


}
