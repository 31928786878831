
<br> <br> 
<!-- your-component.component.html -->

<div class="container">

  <div class="row">
    <br />
    <div class="col-2 ">
      <label class="form-label">
        <p class="labelText"><b>Select local body</b></p>
      </label>
    <div class="row ">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="level"
          id="blockLevel"
          value="blockLevel"
          [(ngModel)]="selectedLevel"
          (change)="onLevelSelection()"
        />
        <label class="form-check-label" for="blockLevel">Panchayat</label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="level"
          id="municipalityLevel"
          value="municipalityLevel"
          [(ngModel)]="selectedLevel"
          (change)="onLevelSelection()"
        />
        <label class="form-check-label" for="municipalityLevel"
          >Municipality</label
        >
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="level"
          id="municipalCorporation"
          value="municipalCorporation"
          [(ngModel)]="selectedLevel"
          (change)="onLevelSelection()"
        />
        <label class="form-check-label" for="municipalCorporation"
          >Municipal Corporation</label
        >
      </div>
      </div>
    </div>
     <br />
  

      <!-- Add new input fields for the additional information -->
  
        <div class="col-2">
          <label for="district" class="form-label">
            <p class="labelText"><b>District </b></p>
          </label>
          <!-- Dropdown for District -->
          <select
            class="form-select inputBoxes "
            id="district"
            [(ngModel)]="selectedDistrict"
            name="district"
            (change)="onDistrictChange()"
          >
            <option value="" disabled selected>Select District</option>
            <option *ngFor="let district of districts" [value]="district.id">
              {{ district.name }}
            </option>
          </select>
       </div>
      
  
      <br />

   <!-- New Dropdown for Municipality -->

    <div class="col-2"  *ngIf="selectedMunicipality" >
      <label for="municipality" class="form-label">
        <p class="labelText"><b>Municipality</b></p>
      </label>
      <select
        class="form-select inputBoxes"
        id="municipality"
        [(ngModel)]="selectedMunicipalityId"
        name="municipality"
        (click)="onMunicipalityChange()"
      >
        <option value="" disabled selected>Select Municipality</option>
        <option
          *ngFor="let municipality of selectedMunicipality"
          [value]="municipality.id"
        >
          {{ municipality.name }}
        </option>
      </select>
    </div>
    <br *ngIf="selectedMunicipality" />
      <div class="col-3" *ngIf="selectedMunicipalCorporation"   >
        <label for="districtMunicipalCorporation" class="form-label">
          <p class="labelText"><b>District Municipal Corporation</b></p>
        </label>
        <select
          class="form-select inputBoxes"
          id="districtMunicipalCorporation"
          [(ngModel)]="selectedMunicipalCorporationId"
          name="districtMunicipalCorporation"
          (click)="onMunicipalCorporationChange()"
        >
          <option value="" disabled selected>
            Select District Municipal Corporation
          </option>
          <option
            *ngFor="
              let municipalCorporation of municipalCorporations[
                selectedDistrict
              ]
            "
            [value]="municipalCorporation.id"
          >
            {{ municipalCorporation.name }}
          </option>
        </select>
      </div>
      <br *ngIf="selectedMunicipalCorporation" />
    
      <div class="col-2" *ngIf="selectedDistrict && gramPanchayatsLoaded">
        <label for="block" class="form-label">
          <p class="labelText"><b>Block</b></p>
        </label>
        <!-- Dropdown for Block -->
        <select
          class="form-select inputBoxes"
          id="block"
          [(ngModel)]="selectedBlock"
          name="block"
          (change)="onBlockChange()"
        >
          <option value="" disabled selected>Select Block</option>
          <option
            *ngFor="let block of blocks[selectedDistrict]"
            [value]="block.id"
          >
            {{ block.name }}
          </option>
        </select>
      </div>
      <br />
      <br *ngIf="selectedDistrict && gramPanchayatsLoaded" />

    
      <div class="col-2" *ngIf="selectedBlock">
        <label for="gramPanchayat" class="form-label">
          <p class="labelText"><b>Grama Panchayat</b></p>
        </label>
        <!-- Dropdown for Gram Panchayat -->
        <select
          class="form-select inputBoxes"
          id="gramPanchayat"
          [(ngModel)]="selectedGramPanchayat"
          name="gramPanchayat"
          (change)="onPChange()"
        >
          <option value="" disabled selected>Select Gram Panchayat</option>
          <option *ngFor="let gp of gramPanchayats" [value]="gp.id">
            {{ gp.name }}
          </option>
        </select>
      </div>
      <br *ngIf="selectedBlock" />
      <div class="col-md-1 d-inline-flex align-items-center justify-content-center">
        <button class="btn btn-primary search-btn" (click)="search()">Search</button>
      </div> 
    
  </div>

    
  

  <!-- <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label>Approval Status </label>
        <select class="form-control custom-dropdown" [(ngModel)]="approvalStatus">
          <option value="">Approval Status</option>
          <option value=true>Approved</option>
          <option value=false>Not Approved</option>
        </select>
      </div>
     </div> 

     <div class="col-md-2">
      <div class="form-group">
        <label>Active</label>
        <select class="form-control custom-dropdown" [(ngModel)]="active">
          <option value="">Activity Status</option>
          <option value=true>Active</option>
          <option value=false>Inactive</option>
        </select>
      </div>
     </div>

     <div class="col-md-2">
      <div class="form-group">
        <label>District</label>
        <select class="form-control custom-dropdown" [(ngModel)]="selectedDistrict">
          <option value="">Select District</option>
          <option *ngFor="let district of districts" [value]="district.id">{{ district.name }}</option>
        </select>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Block</label>
        <select class="form-control custom-dropdown" [(ngModel)]="block">
          <option value="">Block</option>
          <option value="block1">Block 1</option>
          
        </select>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Panchayat</label>
        <select class="form-control custom-dropdown" [(ngModel)]="panchayat">
          <option value="">Select Panchayat</option>
          <option value="panchayat1">Panchayat 1</option>
      
        </select>
      </div>
    </div>


    <div class="col-md-1 d-flex align-items-center justify-content-center">
      <button class="btn btn-primary search-btn" (click)="search()">Search</button>
    </div>
  </div> -->
  <div class="col-1 " (click)="goBack()">
    <i class="fa fa-chevron-circle-left backButton" aria-hidden="true"></i>
  </div>
<br><br>
  <table class="table">
    <thead class="table-dark">
      <tr>
        <th>Sl.No</th>
        <th>Cloud Kitchen Name</th>
        <th>Cloud Kitchen ID</th>
        <th>District</th>
        <th>Block</th>
        <th>panchayat</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of filteredCloudKitchens; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ item.username }}</td>
        <td>{{ item.KCKID }}</td>
        <td> {{item.district}}</td>
        <td> {{item.block}}</td>
        <td> {{item.panchayath}}</td>
<!--        
        <td *ngIf="item.active === true">
          <span class="badge rounded-pill bg-success">{{
            "Active"
          }}</span>
        </td> -->
        <!-- <td *ngIf="item.active === false">
          <span class="badge rounded-pill bg-danger">{{
            "Inactive"
          }}</span>
        </td> -->
        <!-- <td *ngIf="item.isApproved === true">
          <span class="badge rounded-pill bg-success">Approved</span>
        </td> -->
        <!-- <td *ngIf="item.isApproved === false">
          <span class="badge rounded-pill bg-danger">Not Approved</span>
        </td> -->

        <td>
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="navigateToCloudKitchenDetails(item.profileID)">Edit</button>
        </td>
      </tr>
    </tbody>
  </table>



</div>
