<nav>
  <div class="navbar">
    <a class="nav-link">
      <i class="fa fa-user-circle fa-2x adminicon"></i>
      <h6 (click)="onAdminClick()" class="adminheading">Admin</h6>
      <button class="settingsbtn" (click)="onSettings()">
        <i class="fa fa-sliders settingsicon" aria-hidden="true"></i>
      </button>
    </a>
    <!-- <form onsubmit="event.preventDefault()" class="searchuser">
        <input class="formsearch" placeholder="Search" aria-label="Search" [(ngModel)]="searchInput" [ngModelOptions]="{ standalone: true }">
        <button class="btnsearch">Search UserId</button>
      </form> -->
    <div class="logout" (click)="onLogout()">
      <i class="fa fa-sign-out logouticon" aria-hidden="true"></i>
      <h6>Log Out</h6>
    </div>
    <img class="imglogo" src="../../../assets/logo.jpg" alt="Amigo" />
  </div>
</nav>
<div (click)="goBack()">
  <i class="fa fa-chevron-circle-left backButton" aria-hidden="true" ></i>
</div> 

<h3 class="username">
  {{ sellerUsername && sellerUsername }} -
  {{ sellerProfileID && sellerProfileID }}
</h3>
<div class="container p-1 settings">
  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-home"
        type="button"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true"
      >
        {{ "products" | translate }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-profile"
        type="button"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false"
      >
        {{ "services" | translate }}
      </button>
    </li>
  </ul>
  <hr />
  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div class="container">
        <table class="table caption-top table-striped">
          <caption>
            <b>Products listed by seller</b>
          </caption>
          <thead>
            <tr>
              <th scope="col">Sl.No</th>
              <th scope="col">{{ "productTitle" | translate }}</th>
              <th scope="col">{{ "postedDate" | translate }}</th>
              <th scope="col">{{ "status" | translate }}</th>
              <th scope="col">{{ "approval" | translate }}</th>
              <th>{{ "action" | translate }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of productLists; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ item.productTitle }}</td>
              <td>{{ item.date | slice : 0 : 10 }}</td>
              <td *ngIf="item.productStatus === 'Active'">
                <span class="badge rounded-pill bg-success">{{
                  item.productStatus
                }}</span>
              </td>
              <td *ngIf="item.productStatus === 'Inactive'">
                <span class="badge rounded-pill bg-danger">{{
                  item.productStatus
                }}</span>
              </td>
              <td *ngIf="item.isApproved === true">
                <span class="badge rounded-pill bg-success">Approved</span>
              </td>
              <td *ngIf="item.isApproved === false">
                <span class="badge rounded-pill bg-danger">Not Approved</span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  (click)="editProduct(item._id)"
                >
                  Edit
                </button>
                <!-- <button type="button" class="btn btn-outline-danger btn-sm" (click)="confirmDelete(item._id)">Delete</button></td> -->
              </td>
            </tr>
          </tbody>
        </table>
        <h5 class="nodata" *ngIf="productLists.length === 0">
          {{ "noDataToShow" | translate }}
        </h5>
      </div>
    </div>

    <!--------------------------------------- Products ends here ---------------------------------------->

    <!--------------------------------------- Services starts here ---------------------------------------->

    <div
      class="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div class="container">
        <table class="table caption-top table-striped">
          <caption>
            <b>Services listed by seller</b>
          </caption>
          <thead>
            <tr>
              <th scope="col">Sl.No</th>
              <th scope="col">{{ "productTitle" | translate }}</th>
              <th scope="col">{{ "postedDate" | translate }}</th>
              <th scope="col">{{ "status" | translate }}</th>
              <th scope="col">{{ "approval" | translate }}</th>
              <th>{{ "action" | translate }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of serviceLists; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ item.serviceTitle }}</td>
              <td>{{ item.date | slice : 0 : 10 }}</td>
              <td *ngIf="item.serviceStatus === 'Active'">
                <span class="badge rounded-pill bg-success">{{
                  item.serviceStatus
                }}</span>
              </td>
              <td *ngIf="item.serviceStatus === 'Inactive'">
                <span class="badge rounded-pill bg-danger">{{
                  item.serviceStatus
                }}</span>
              </td>
              <td *ngIf="item.isApproved === true">
                <span class="badge rounded-pill bg-success">Approved</span>
              </td>
              <td *ngIf="item.isApproved === false">
                <span class="badge rounded-pill bg-danger">Not Approved</span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  (click)="editService(item._id)"
                >
                  Edit
                </button>
                <!-- <button type="button" class="btn btn-outline-danger btn-sm" (click)="confirmDeleteService(item._id)">Delete</button></td> -->
              </td>
            </tr>
          </tbody>
        </table>
        <h5 class="nodata" *ngIf="serviceLists.length === 0">
          {{ "noDataToShow" | translate }}
        </h5>
      </div>
    </div>
  </div>
  <!--------------------------------------- Services ends here ---------------------------------------->

  <!--------------------------------------- Sellers starts here ---------------------------------------->

  <!--------------------------------------- Sellers ends here ------------------------------------------>
</div>
