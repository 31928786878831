


 <div class="container mt-4">
  <div class="container mt-4">
    <h2><u>Delivery Persons</u></h2>
    <table class="table">
      <thead>
        <tr>
          <th>Username</th>
          <th>Phone</th>
          <th>Assigned Locations</th>
          <th>Keyword</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let person of deliveryPersons">
          <td>{{ person.username }}</td>
          <td>{{ person.phone }}</td>
          <td>{{ getAssignedLocations(person) }}</td>
          <td>{{ person.keyword }}</td>
          <td><button (click)="deleteAccount(person._id)" type="button" class="btn btn-danger">Delete</button></td>
        </tr>
      </tbody>
    </table>
  </div>
 </div>

