
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';

const ADMIN_URL = environment.adminUrl;
@Component({
  selector: 'app-settings',

  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnInit {
  invoiceSettings: any;



  ngOnInit() {
    this.getInvoiceSettings();
  }


  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService,

  ) {}
//Invoice Number------------------------
invoicePrefix: string;
invoiceSuffix: string;
profileID: string;
// saveInvoiceSettings() {
//   console.log(this.invoicePrefix, this.invoiceSuffix);

//   // Set your headers
//   const token = localStorage.getItem('token');
//   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

//   // Define the body of your request including bankDetails
//   const body = {
//     invoicePrefix: this.invoicePrefix,
//     invoiceSuffix: this.invoiceSuffix,
//     profileID: this.profileID,
//   };

//   console.log('the body is ', body);

//   // Make the HTTP request with headers and body
//   this.http
//     .put(ADMIN_URL + '/dashboard/updateInvoice', body, { headers })
//     .subscribe(
//       (data: any) => {
//         Swal.fire(data.message);
//         if (this.isAdmin() || this.isSuperAdmin()) {
//           this.fetchOrderDetails();
//         } else if (this.isKitchen()) {
//           this.fetchOrderDetailsForKitchen();
//         }
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
// }
getInvoiceSettings() {
  // Set your headers
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  this.http.get(ADMIN_URL + '/getInvoiceSettings', { headers })
    .subscribe(
      (data: any) => {
        this.invoiceSettings = data;
        this.invoicePrefix = data.invoicePrefix;
        this.invoiceSuffix = data.invoiceSuffix;
        console.log(this.invoiceSettings);
      },
      (error) => {
        console.error('Error fetching invoice settings', error);
      }
    );
}


saveInvoiceSettings() {
  if(this.invoiceSuffix|| this.invoicePrefix){
  // Display a confirmation alert
  Swal.fire({
    title: 'Are you sure?',
    text: 'Do you want to save the invoice settings?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, save it!',
    cancelButtonText: 'No, cancel!',
  }).then((result) => {
    if (result.isConfirmed) {
      // Set your headers
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

      // Define the body of your request including bankDetails
      const body = {
        invoicePrefix: this.invoicePrefix,
        invoiceSuffix: this.invoiceSuffix,
        profileID: this.profileID,
      };

      console.log('the body is ', body);

      // Make the HTTP request with headers and body
      this.http
        .put(ADMIN_URL + '/dashboard/updateInvoice', body, { headers })
        .subscribe(
          (data: any) => {
            Swal.fire(data.message);
            // if (this.isAdmin() || this.isSuperAdmin()) {
            //   this.fetchOrderDetails();
            // } else if (this.isKitchen()) {
            //   this.fetchOrderDetailsForKitchen();
            // }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  });
}
else{
  Swal.fire('Please fill out invoice prefix or invoice suffix');
}
}


//Carousel images Pocket mart
selectedLBFiles: FileList;
selectedPMFiles: FileList;
selectedQSFiles: FileList;
onLBFileSelected(event: any): void {
  this.selectedLBFiles = event.target.files;
}

onPMFileSelected(event: any): void {
  this.selectedPMFiles = event.target.files;
}

onQSFileSelected(event: any): void {
  this.selectedQSFiles = event.target.files;
}

confirmUpload(type: string): void {
  Swal.fire({
    title: 'Are you sure?',
    text: 'Do you want to upload the selected images?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, upload it!',
    cancelButtonText: 'No, cancel!',
  }).then((result) => {
    if (result.isConfirmed) {
      if (type === 'LB') {
        this.uploadLBCarouselImages();
      } else if (type === 'PM') {
        this.uploadPMCarouselImages();
      } else if (type === 'QS') {
        this.uploadQSCarouselImages();
      }
    }
  });
}

uploadLBCarouselImages(): void {
  if (!this.selectedLBFiles || this.selectedLBFiles.length === 0) {
    alert('No files selected.');
    return;
  }

  const formData: FormData = new FormData();
  for (let i = 0; i < this.selectedLBFiles.length; i++) {
    formData.append('images', this.selectedLBFiles[i]);
  }

  this.http.post(ADMIN_URL +'/LB-upload-carousel-images', formData).subscribe(
    (response) => {
      console.log('Upload successful', response);
      Swal.fire('Uploaded!', 'Images uploaded successfully.', 'success');
    },
    (error) => {
      console.error('Error uploading images', error);
      Swal.fire('Error!', 'Error uploading images.', 'error');
    }
  );
}

uploadPMCarouselImages(): void {
  if (!this.selectedPMFiles || this.selectedPMFiles.length === 0) {
    alert('No files selected.');
    return;
  }

  const formData: FormData = new FormData();
  for (let i = 0; i < this.selectedPMFiles.length; i++) {
    formData.append('images', this.selectedPMFiles[i]);
  }

  this.http.post(ADMIN_URL +'/PM-upload-carousel-images', formData).subscribe(
    (response) => {
      console.log('Upload successful', response);
      Swal.fire('Uploaded!', 'Images uploaded successfully.', 'success');
    },
    (error) => {
      console.error('Error uploading images', error);
      Swal.fire('Error!', 'Error uploading images.', 'error');
    }
  );
}

uploadQSCarouselImages(): void {
  if (!this.selectedQSFiles || this.selectedQSFiles.length === 0) {
    alert('No files selected.');
    return;
  }

  const formData: FormData = new FormData();
  for (let i = 0; i < this.selectedQSFiles.length; i++) {
    formData.append('images', this.selectedQSFiles[i]);
  }

  this.http.post(ADMIN_URL +'/QS-upload-carousel-images', formData).subscribe(
    (response) => {
      console.log('Upload successful', response);
      Swal.fire('Uploaded!', 'Images uploaded successfully.', 'success');
    },
    (error) => {
      console.error('Error uploading images', error);
      Swal.fire('Error!', 'Error uploading images.', 'error');
    }
  );
}
}
