<div (click)="goBack()">
  <i class="fa fa-chevron-circle-left backButton" aria-hidden="true" ></i>
</div>
<div class="containerOverlay" *ngIf="showimg" >
  <div (click)="hideImg()"><i class="fa fa-window-close closebtn" aria-hidden="true"></i></div>
  <div class="zoomimg">
    <img [src]="zoomsrc" alt="ProductImage">
  </div>
</div>
<app-navbar></app-navbar>
<div class="mainCon">
  <div class="container p-2">
    <div class="row">
      <div class="col text-center">
        <h3 class="headingmain">Edit product</h3>
      </div>
    </div>
  </div>
  <div class="container">
    <form>
      <div class="form-group">
        <div class="row">
          <div class="col">
            <label for="productCategory">Product Category</label>
            <input type="text" class="form-control" id="productCategory" [value]="subCategorySwitchRetrieval(product.productCategory)" readonly>
          </div>
          <div class="col flexCat">

              <div class="dropdown">
                <button
                  class="btn btn-success dropdown-toggle category1"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{categoryDropdownHeading}}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Category';
                    subCategoryDropdownHeading='Sub Category'">All</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Farm Produce';
                    subCategoryDropdownHeading='Sub Category';
                    selectedSubCategory=FarmProduceSubCategories">Farm Produce</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Home Produce';
                    subCategoryDropdownHeading='Sub Category';
                    selectedSubCategory=HomeProduceSubCategories">Home Produce</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Garden';
                    subCategoryDropdownHeading='Sub Category';
                    selectedSubCategory=GardenSubCategories">Garden</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Pets';
                    subCategoryDropdownHeading='Sub Category';
                    selectedSubCategory=PetsSubCategories">Pets</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Livestocks';
                    subCategoryDropdownHeading='Sub Category';
                    selectedSubCategory=LivestocksSubCategories">Livestocks</a></li>
                  <li><a class="dropdown-item"  (click)="categoryDropdownHeading = 'Used Items';
                    subCategoryDropdownHeading='Sub Category';
                    selectedSubCategory=UsedItemsSubCategories">Used Items</a></li>
                </ul>
              </div>


              <div class="dropdown">
                <button
                  class="btn btn-success dropdown-toggle category1"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                {{subCategoryDropdownHeading}}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item"  (click)="subCategoryDropdownHeading = 'Sub Category';
                    productCategory='Sub Category'">All</a></li>
                  <li *ngFor="let item of selectedSubCategory"><a class="dropdown-item"  (click)="subCategoryDropdownHeading = subCategorySwitch(item);
                    productCategory=item;productCat=item">{{subCategorySwitch(item)}}</a>
                  </li>
                </ul>
              </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="productTitle">Product Title</label>
            <input type="text" class="form-control" id="productTitle" value="{{product.productTitle}}" [(ngModel)]="productTitle" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
       <div class="col">
        <div class="form-group">
          <label for="productPrice">Product Price</label>
          <input type="number" class="form-control" id="productPrice" value="{{product.productPrice}}" [(ngModel)]="productPrice" [ngModelOptions]="{standalone: true}" disabled="true">
        </div>
       </div>

      </div>

      <div class="form-group">
        <label for="productUnit">Product Unit</label>
        <!-- <input type="text" class="form-control" id="productUnit" value="{{product.productUnit}}" [(ngModel)]="productUnit" [ngModelOptions]="{standalone: true}"> -->
        <select class="form-control productUnit" id="productUnit" [(ngModel)]="productUnit" [ngModelOptions]="{ standalone: true }">
          <option value="Nos">Nos</option>
          <option value="Kg">Kg</option>
          <option value="Packet">Packet</option>
          <option value="Dozen">Dozen</option>
          <option value="litre">litre</option>
          <option value="Bottle">Bottle</option>
        </select>
      </div>
      <div class="form-group">
        <label for="productDescription">Product Description</label>
        <textarea class="form-control" id="productDescription" value="{{product.productDescription}}" [(ngModel)]="productDescription" [ngModelOptions]="{standalone: true}"></textarea>
      </div>
      <input class="approvalCheckBox" type="checkbox" [(ngModel)]="isApproved" [checked]="isApproved" [ngModelOptions]="{standalone: true}">
      <span class="approvalCheck">Approve product</span><br><br>
    <div class="row">
      <div class="col">
        <select class="form-control productUnit" id="productUnit" [(ngModel)]="productStatus" [ngModelOptions]="{ standalone: true }">
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
      </div>
      <div class="col">{{product.address}}</div>
      <div class="col"><b>Profile ID: </b>{{product.profileID}}<p><b>Posted Date:</b>
        <b  style="color: red;">
          {{ product.date | slice : 0 : 10 }}
        </b></p></div>
      <div class="col">
        <input class="approvalCheckBox" type="checkbox" [(ngModel)]="updateDate" [checked]="updateDate" [ngModelOptions]="{standalone: true}">
      <span class="approvalCheck">Update Date</span><br><br>
      </div>
      <div class="col"></div>
      <div class="col"></div>
    </div>
      <div class="row">
        <div class="row pt-3 pb-2">
                <label
                  ><p class="labelText">
                    Post Validity
                  </p></label
                >
              </div>
              <div class="row">
                <div class="col p-0 text-center">
                  <button
                    type="button"
                    [class.active]="productValidity === 5"
                    class="btn btn-outline-success monthButton"
                    (click)="getDays(5)"
                  >
                    5 days
                  </button>
                </div>
                <div class="col p-0 text-center">
                  <button
                    type="button"
                    [class.active]="productValidity === 14"
                    class="btn btn-outline-success active monthButton"
                    (click)="getDays(14)"
                  >
                    2 Weeks
                  </button>
                </div>
                <div class="col p-0 text-center">
                  <button
                    type="button"
                    [class.active]="productValidity === 30"
                    class="btn btn-outline-success monthButton"
                    (click)="getDays(30)"
                  >
                    1 month
                  </button>
                </div>
                <div class="col p-0 text-center">
                  <button
                    type="button"
                    [class.active]="productValidity === 90"
                    class="btn btn-outline-success monthButton"
                    (click)="getDays(90)"
                  >
                    3 month
                  </button>
                </div>
              </div>
      </div>
      <div class="row imagerow">
        <div class="col imageCon">
          <h5 class="noimage" *ngIf="product.productImageURL&&product.productImageURL.length===0">No images to show!</h5>

          <div class="imgItem" *ngFor="let imageUrl of productImageURLs; let i =index">
            <img class="mainimg" [src]="imageUrl" alt="ProductImage" (click)="imageClick(imageUrl)">
            <button class="delete" (click)="onDelete(productId, i)">Delete Image</button>

          </div>

        </div>
        <!-- If no image upload image -->


      </div>
      <div class="container uploadImage" *ngIf="product.productImageURL&&product.productImageURL.length===0">


        <div class="imagePreview">
          <img
            class="uploadedImage"
            [src]="
              selectedImageUrls[0] ||
              editImagesArray[0] ||
              defaultImages
            "
            alt="selected images"
          />
          <button
            [disabled]="!uploadButton1Enabled"
            type="button"
            (click)="fileInput1.click()"
            class="btn btn-light imageUploadButtons btn-sm transparent-button"
          >
            Select Image
          </button>
          <input
            type="file"
            accept="image/*"
            #fileInput1
            style="display: none"
            (change)="onImageSelected($event, 0)"
          />
        </div>



  </div>
      <br><br>
      <div class="row">
        <div class="col-4" style="text-align: center;"> <button class="dontapprove" (click)="onDontApprove()">Cancel</button></div>
        <div class="col-4" style="text-align: center;"> <button class="deleteBtn" (click)="confirmDelete()">Delete</button></div>
       <div class="col-4" style="text-align: center;"><button class="approve" (click)="onApprove()">Update</button>
       </div>
      </div>
    </form>

  </div>
</div>



