import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { SubscriptionService } from './subscription.service';

const ADMIN_URL = environment.adminUrl;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrl: './subscription-page.component.css',
})
export class SubscriptionPageComponent {
  //variable declaration
  subscriptionDetails: any;
  filteredSubscriptionDetails: any;
  subscriptionCounts: any;
  subscriptionTotalCount: number = 0;
  subscriptionActiveCount: number = 0;
  // subscriptionInactiveCount: number = 0;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.checkLogin();
  }

  //to check if the user is logged in
  checkLogin(): void {
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
      if (this.isSuperAdmin() || this.isAdmin()) {
        this.getSubscriptionCounts();
        this.getSubscriptions();
      } else if (this.isKitchen()) {
        this.getSubscriptionCountsForKitchen();
        this.getSubscriptionsForKitchen();
        // this.router.navigate(['/login']);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  //to check the user is admin
  isAdmin(): boolean {
    return this.authService.isAuthenticated() && this.authService.isAdmin();
  }

  //to check the user is superAdmin
  isSuperAdmin(): boolean {
    return (
      this.authService.isAuthenticated() && this.authService.isSuperAdmin()
    );
  }

  //to check the user is kitchen
  isKitchen(): boolean {
    return this.authService.isAuthenticated() && this.authService.isKitchen();
  }

  // to get the subscription details for superadmin/ admin
  getSubscriptions() {
    this.subscriptionService.getSubscriptionsListing().subscribe(
      (data) => {
        this.subscriptionDetails = data;
        this.filterOrders();
        console.log('the subscription details is:', this.subscriptionDetails);
      },
      (error) => {
        console.log('Error fetching subscriptions:', error);
      }
    );
  }

  // to get the subscription count for superadmin/ admin
  getSubscriptionCounts() {
    this.subscriptionService.getSubscriptionCounts().subscribe(
      (data) => {
        this.subscriptionCounts = data;
        this.subscriptionTotalCount =
          this.subscriptionCounts.subscriptionTotalCount;
        this.subscriptionActiveCount =
          this.subscriptionCounts.subscriptionActiveCount;
        // this.subscriptionInactiveCount =
        //   this.subscriptionCounts.subscriptionInactiveCount;
        console.log('Subscription count:', this.subscriptionCounts);
        console.log('subscriptionTotalCount:', this.subscriptionTotalCount);
        console.log('subscriptionActiveCount:', this.subscriptionActiveCount);
        // console.log(
        //   'subscriptionInactiveCount:',
        //   this.subscriptionInactiveCount
        // );
      },
      (error) => {
        console.log('Error fetching subscription counts:', error);
      }
    );
  }

  // to get the subscription details if logged in as kitchen
  getSubscriptionsForKitchen() {
    this.subscriptionService.getSubscriptionsListingForKitchen().subscribe(
      (data) => {
        this.subscriptionDetails = data;
        this.filterOrders();
        console.log('the subscription details is:', this.subscriptionDetails);
      },
      (error) => {
        console.log('Error fetching subscriptions:', error);
      }
    );
  }

  // to get the subscription count if loged in as kitchen
  getSubscriptionCountsForKitchen() {
    this.subscriptionService.getSubscriptionCountsForKitchen().subscribe(
      (data) => {
        this.subscriptionCounts = data;
        this.subscriptionTotalCount =
          this.subscriptionCounts.subscriptionTotalCount;
        this.subscriptionActiveCount =
          this.subscriptionCounts.subscriptionActiveCount;
        // this.subscriptionInactiveCount =
        //   this.subscriptionCounts.subscriptionInactiveCount;
        console.log('Subscription count:', this.subscriptionCounts);
        console.log('subscriptionTotalCount:', this.subscriptionTotalCount);
        console.log('subscriptionActiveCount:', this.subscriptionActiveCount);
        // console.log(
        //   'subscriptionInactiveCount:',
        //   this.subscriptionInactiveCount
        // );
      },
      (error) => {
        console.log('Error fetching subscription counts:', error);
      }
    );
  }

  // to go subcription details page
  goToSubscriptionDetails(id: string) {
    this.router.navigate(['/subscriptionDetails'], {
      queryParams: {
        dateBaseID: id,
      },
    });
  }

  filterOrders(): void {
    const subscriptionActive = (
      document.getElementById('subscriptionActive') as HTMLSelectElement
    ).value;
    let check = true;
    console.log(subscriptionActive);
    if (subscriptionActive == 'true') {
      check = true;
    } else if (subscriptionActive == 'false') {
      check = false;
    }
    this.filteredSubscriptionDetails = this.subscriptionDetails.filter(
      (order) => {
        return !subscriptionActive || order.subscriptionActive === check;
      }
    );
  }
}
