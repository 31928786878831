import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const ADMIN_URL = environment.adminUrl;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.css',
})
export class NotificationComponent {
  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  //variable declaration------
  notificationTitle: string = '';
  notificationBody: string = '';
  notificationData: any;
  spinner: boolean = false;

  ngOnInit(): void {
    this.getNotifications();
  }

  //to check the user role is admin or super admin
  isAdmin(): boolean {
    return this.authService.isAuthenticated() && this.authService.isAdmin();
  }
  isSuperAdmin(): boolean {
    return (
      this.authService.isAuthenticated() && this.authService.isSuperAdmin()
    );
  }

  //to clear the text box-------------------------------------------
  clearTextBox() {
    this.notificationTitle = '';
    this.notificationBody = '';
  }

  //notification submit method--------------------------------
  notificationSubmit() {
    if (!this.notificationTitle || !this.notificationBody) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Notification title and body are required!',
      });
      return;
    }
    Swal.fire({
      icon: 'info',
      title: 'Sending notification to all customers',
      text: 'Do you want to continue?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title:
            'Sending notification to all customers will take some time to complete',
          text: 'You may continue with your work',
        });

        this.spinner = true;

        const token = this.authService.getToken();
        console.log('the order function is called');
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        console.log('the headers are', headers);

        const params = new HttpParams()
          .set('notificationTitle', this.notificationTitle)
          .set('notificationBody', this.notificationBody);

        this.http
          .post(ADMIN_URL + '/adminNotification', {}, { headers, params })
          .subscribe(
            (data) => {
              console.log('Notification submitted successfully');
              this.getNotifications();
              this.clearTextBox();
              this.spinner = false;
              // Handle success response if needed
            },
            (error) => {
              console.error('Error submitting notification:', error);
              this.spinner = false;
              // Handle error response
            }
          );
      } else {
        // Handle if the user clicks 'No'
        console.log('User chose not to continue');
      }
    });
  }

  //to get the notifications from the database
  getNotifications() {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    console.log('the headers are', headers);
    this.http
      .get(ADMIN_URL + '/adminNotification/get', { headers })
      .subscribe((data) => {
        console.log('the data is', data);
        this.notificationData = data;
      });
  }
}
