import { Component, OnInit } from '@angular/core';

//Other imports
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { environment } from 'src/environments/environment';
const ADMIN_URL = environment.adminUrl;
interface UserProfile {
  userName: string;
  whatsApp: string;
  address: string;
  profileID: string;
  areaOfInterest: string;
  category: string;
  about: string;
}
@Component({
  selector: 'app-seller-details',
  templateUrl: './seller-details.component.html',
  styleUrls: ['./seller-details.component.css'],
})
export class SellerDetailsComponent implements OnInit {
  //Declare an empty array and default values of input to null.
  newUserArray: any[] = [];
  currentNewUserDatabaseID = '';

  userId: string = '';
  imageId: string = '';
  //to disable the whatsapp
  disableWhatsApp: boolean;

  userForEdit: any;
  dataBaId: string;
  userArray: any;
  hasChanges: boolean = false;

  sellerID: string;
  edit: boolean = false;
  onEdit() {
    this.edit = !this.edit;
  }

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authService: AuthService
  ) {}
  // Function to go back to the previous page
  goBack() {
    // Navigate back in the router history
    this.location.back();
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  ngOnInit(): void {
    this.checkLogin();

    this.route.queryParams.subscribe((params) => {
      this.sellerID = params['sellerId'];
      this.fetchSeller();
      this.scrollToTop();
    });
    console.log(this.dataBaId);
  }
  authUser() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    this.http.get(ADMIN_URL + '/verifyToken', { headers }).subscribe(
      (res) => {
        console.log('User is authenticated');
        if ((res as any).message === 'Invalid token') {
          this.router.navigate(['/login']);
        }
      },
      (err) => {
        // Token is invalid, redirect to the login page
        this.router.navigate(['/login']);
      }
    );
  }

  //to check the login status starts --------------------------------
  checkLogin(): void {
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }
  //to check the login status ends --------------------------------

  goToSellerProfile(sellerId: string) {
    console.log('working line 88');
    const queryParams = { sellerId: sellerId };
    this.router.navigate(['/seller-details'], { queryParams });
  }

  detectChanges() {
    // Implement your logic to detect changes in the user details
    // For example, compare the current values with the original values fetched during fetchUser().
    // For demonstration purposes, let's assume the "userName", "whatsApp", and "address" fields are the ones being edited.
    if (
      this.userName !== this.userArray.userName ||
      this.whatsApp !== this.userArray.whatsApp ||
      this.address !== this.userArray.address
    ) {
      this.hasChanges = true;
    } else {
      this.hasChanges = false;
    }
  }

  onInputChange() {
    this.detectChanges();
  }

  onLogout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
  onAdminClick() {
    this.router.navigate(['/dashboard']);
  }
  onSettings() {
    // Perform login logic here
    console.log('hi');
    // Redirect to the admin page
    this.router.navigate(['/settings']);
  }
  /********************************************* */

  /**profile image upload */
  defaultProfileImage = 'assets/personImages/defaultimage.jpg';
  selectedImageUrls: (string | ArrayBuffer)[] = [];
  images: File[] = [];

  generateID: string;

  onImageSelected(event, index) {
    console.log(event);
    const file = event.target.files[0];
    this.compressImage(file)
      .then((compressedFile) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImageUrls[index] = reader.result;
        };
        reader.readAsDataURL(compressedFile as Blob);

        const compressedFileName = file.name;
        const compressedImageFile = new File(
          [compressedFile as Blob],
          compressedFileName
        );
        this.images[index] = compressedImageFile;
      })
      .catch((error) => {
        console.error('Image compression error:', error);
      });
    console.log(this.images, 'line9437593475');
    this.detectChanges();
  }
  compressImage(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const size = Math.min(image.width, image.height);
          const xOffset = (image.width - size) / 2;
          const yOffset = (image.height - size) / 2;
          const maxWidth = 800;
          const maxHeight = 800;
          let width = size;
          let height = size;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(
            image,
            xOffset,
            yOffset,
            size,
            size,
            0,
            0,
            width,
            height
          );

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], 'compressed_image.jpg');
              if (compressedFile.size > 300 * 1024) {
                const quality = (300 * 1024) / compressedFile.size;
                canvas.toBlob(
                  (furtherBlob) => {
                    const furtherCompressedFile = new File(
                      [furtherBlob],
                      'further_compressed_image.jpg'
                    );
                    resolve(furtherCompressedFile);
                  },
                  'image/jpeg',
                  quality
                );
              } else {
                resolve(compressedFile);
              }
            },
            'image/jpeg',
            0.9
          );
        };
        if (typeof event.target.result === 'string') {
          image.src = event.target.result;
        }
      };
      reader.onerror = (error) => {
        console.error('File reading error:', error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  deleteProfileImage() {
    if (
      window.confirm(
        'Are you sure you want to delete the user profile picture?'
      )
    ) {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders({
        Authorization: token, // Include the token in the Authorization header
      });
      this.http
        .delete<any>(ADMIN_URL + `/deleteImage/${this.sellerID}`, { headers })
        .subscribe(
          (response) => {
            console.log(response);
            if (response.message === 'All images deleted successfully.') {
              return alert('User profile is picture deleted successfully! 😀');
            } else if (
              response.message !== 'All images deleted successfully.'
            ) {
              return alert('User profile picture deletion unsuccessful! 😀');
            }
          },
          (error) => {
            alert('User update failed 🙄');
          }
        );
    }
  }

  save() {
    const profileData: UserProfile = {
      userName: this.userName,
      whatsApp: this.whatsApp,
      address: this.address,
      profileID: this.profileID,
      areaOfInterest: this.areaOfInterest,
      category: this.category,
      about: this.about,
    };

    const formData = new FormData();

    // Append other profile data to formData
    for (const key in profileData) {
      if (profileData.hasOwnProperty(key)) {
        formData.append(key, profileData[key]);
      }
    }

    // Send the PUT request with the user profile data and images
    if (
      window.confirm('Are you sure you want to update user profile details?')
    ) {
      const token = this.authService.getToken();
      const headers = new HttpHeaders({
        Authorization: token, // Include the token in the Authorization header
      });
      this.http
        .put<any>(ADMIN_URL + `/updateProfile`, formData, { headers })
        .subscribe(
          (response) => {
            console.log(response);
            if (
              response.message === 'The user has been updated successfully!'
            ) {
              return alert('User profile is updated successfully! 😀');
            } else if (
              response.message !== 'The user has been updated successfully!'
            ) {
              return alert('User profile update failed!');
            }
            localStorage.setItem('userCategory', this.category);
            this.router.navigate(['/dashboard']);
          },
          (error) => {
            alert('User update failed 🙄');
          }
        );
    }
  }

  /******************************************************************** */

  /*selected image upload 1*/
  defaultImages = 'assets/personImages/defaultimage.jpg';
  selectedImageUrl1: string | ArrayBuffer = null;
  imageFile1: File;
  generatedImageId: string;

  onImageSelected1(event) {
    console.log(event);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.selectedImageUrl1 = reader.result;
    };
    reader.readAsDataURL(file);
  }

  /*selected image upload 2*/
  selectedImageUrl2: string | ArrayBuffer = null;

  onImageSelected2(event) {
    console.log(event);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.selectedImageUrl2 = reader.result;
    };
    reader.readAsDataURL(file);
  }

  /*selected image upload 3*/
  selectedImageUrl3: string | ArrayBuffer = null;
  onImageSelected3(event) {
    console.log(event);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.selectedImageUrl3 = reader.result;
    };
    reader.readAsDataURL(file);
  }

  //Selected image upload ENDS

  //Whatsapp Checkbox
  myModel = false;

  //Image Upload Section
  files: File[] = [];
  onSelect(event: any) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  //-----------------------for the edit the profile--------------------
  userName: string = '';
  profileID: string = '';
  mobileNumber: string = '';
  whatsApp: string = '';
  areaOfInterest: string = '8';
  address: string = '';
  category: string;
  about: string = '';
  formattedWhatsApp: string = '';
  toggleWhatsApp() {
    if (this.myModel) {
      this.whatsApp = this.mobileNumber;
    } else {
      this.whatsApp = '';
    }
  }

  getAreaOfInterest(area) {
    console.log('the area is :', area);
    this.areaOfInterest = area;
    this.detectChanges();
  }
  imageURL: string = null;
  reports: any[] = [];
  fetchSeller(): void {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.http
      .get(`${ADMIN_URL}/seller/${this.sellerID}`, { headers })
      .subscribe(
        (response) => {
          console.log(response);
          this.userArray = response;
          console.log(this.userArray);
          this.profileID = this.userArray.profileID;
          this.userName = this.userArray.username;
          this.mobileNumber = this.userArray.mobile;
          this.imageURL = this.userArray.imageURL;
          this.reports = this.userArray.reports;
          if (this.userArray.whatsApp !== 'undefined') {
            this.whatsApp = this.userArray.whatsApp;
            if (this.whatsApp == this.mobileNumber) {
              this.disableWhatsApp = true;
            }
          }
          this.areaOfInterest = this.userArray.areaOfInterest;
          if (this.userArray.address !== 'undefined') {
            this.address = this.userArray.address;
          }
          this.category = this.userArray.category;
          if (this.userArray.about !== 'undefined') {
            this.about = this.userArray.about;
          }

          this.imageURL = this.userArray.profileImageURL;
        },
        (error) => {
          console.error(error);
          // Handle error
        }
      );
  }

  sellerPostings(sellerId: string, username: string) {
    console.log('edit', sellerId);
    this.router.navigate(['/seller-postings'], {
      queryParams: {
        sellerId: sellerId,
        username: username,
      },
    });
  }
}
