<app-kckdashboard></app-kckdashboard>

<div class="main">
  <div class="div" *ngFor="let details of subscriptionDetails">
    <div class="container card cardmain">
      <div class="row">
        <div class="col p-4">
          <p class="details">Subscription Details</p>
          <p style="color: gray; margin-bottom: 0">
            Subscription listings > Subscription Details
          </p>
        </div>
      </div>

      <div class="row p-4">
        <div class="col me-3 p-4 card">
          <p>
            SubscriptionID:
            <span
              ><b>{{ details.subscriptionID }}</b></span
            >
          </p>
          <p>
            Subscription start date:
            <span
              ><b>{{ details.subscriptionStartDate| date }}</b></span
            >
          </p>

          <p>
            Subscription days :
            <span
              ><b>{{ details.subscriptionDays }}</b></span
            >
          </p>

          <p>
            Remaining days :
            <span
              ><b>{{ details.remainingDays }}</b></span
            >
          </p>
          <p>
            Subscription status:
            <span
              ><b>{{
                details.subscriptionActive ? "Active" : "Deactive"
              }}</b></span
            >
          </p>

          <p>
            Payment status:
            <span
              ><b>{{ details.paymentStatus }}</b></span
            >
          </p>
          <p>
            Payment method:
            <span
              ><b>{{ details.paymentMethod }}</b></span
            >
          </p>

          <p class="card-title">
            <b><u>Delivery details</u></b>
          </p>
          <div class="row">
            <div class="col card">
              <p>
                Delivery for:
                <span
                  ><b>{{ details.userName }}</b></span
                >
              </p>
              <p>
                Location:
                <span
                  ><b>{{ details.address.address }}</b></span
                >
              </p>
              <p>
                Address:
                <span
                  ><b>{{ details.address.locality }}</b></span
                >
              </p>
              <p>
                Contact details:
                <span
                  ><b>{{ details.address.deliveryNumber }}</b></span
                >
              </p>
            </div>
          </div>
        </div>

        <div class="col ms-3 p-4 card">
          <p class="card-title">
            <b><u>Order Summary</u></b>
          </p>

          <div
            class="row p-3"
            *ngFor="let items of subscriptionDetails[0].orderItems"
          >
            <div class="col">{{ items.name }}</div>
            <div class="col" style="text-align: center">
              quantity: {{ items.quantity }}
            </div>
            <div class="col" style="text-align: right">{{ items.price }}</div>
            <hr style="margin-bottom: 1px" />
          </div>

          <div class="row p-3">
            <div class="col">Offer price</div>
            <div class="col"></div>
            <div class="col" style="text-align: right">
              {{ details.offerPrice }}
            </div>
            <hr style="margin-bottom: 1px" />
          </div>

          <div class="row p-3">
            <div class="col">Packing charge</div>
            <div class="col"></div>
            <div class="col" style="text-align: right">
              {{ details.totalDeliveryCharge }}
            </div>
            <hr style="margin-bottom: 1px" />
          </div>

          <div class="row p-3">
            <div class="col">GST(5%)</div>
            <div class="col"></div>
            <div class="col" style="text-align: right">
              {{ details.gstPrice }}
            </div>
            <hr style="margin-bottom: 1px" />
          </div>


          <div
            class="row p-3"
            style="
              background: linear-gradient(to right, #f3f3d5 0%, #ffd5d5 100%);
            "
          >
            <div class="col">Amount to be paid</div>
            <div class="col" style="text-align: right">
              {{ details.totalPrice }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-4 mb-4">
    <div class="row">
      <div class="col">

        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">SubscriptionID</th>
              <th scope="col">Subscription start date</th>
              <th scope="col">Remaining days</th>
              <th scope="col">Subscription Status</th>
              <th class="align-center" scope="col">View details</th>
            </tr>
          </thead>
          <tbody *ngFor="let subscription of allSubscriptions let i = index">
            <tr>
              <th scope="row">{{i + 1}}</th>
              <td>{{subscription.subscriptionID}}</td>
              <td>{{subscription.subscriptionStartDate | date}}</td>
              <td>{{subscription.remainingDays}}</td>
              <td>{{subscription.subscriptionActive ? 'Active' : 'Deactive'}}</td>
              <td class="align-center">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  (click)="goToSubscriptionDetails(subscription._id)"
                 
                >
                  Details
                </button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>
