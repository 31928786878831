<app-kckdashboard></app-kckdashboard>

<div class="container main">
  <div class="container over m-3">
    <div class="row">
      <div class="col">
        <p class="header">
          <b> User Reports</b>
        </p>
      </div>
    </div>
    <div class="row m-2">
      <p style="color: #006600; font-size: 25px;">Users overview</p>
    </div>

    <div class="row m-3">
      <div class="col">
        <div class="card user p-0">
          <div class="card-body">
            <p class="card-title">Total no of users</p>
            <p class="card-text" style="text-align: center">
              <b>{{ totalUsers }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card active">
          <div class="card-body">
            <p class="card-title">Active users</p>
            <p class="card-text" style="text-align: center">
              <b>{{ activeUsers }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card delete">
          <div class="card-body">
            <p class="card-title">Delete users</p>
            <p class="card-text" style="text-align: center">
              <b>{{ deletedUsers }}</b>
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="col"></div> -->
    </div>
  </div>

  <div class="container m-3">
    <table class="table caption-top table-striped">
      <caption>
        <p class="list">List of users</p>
      </caption>
      <thead>
        <tr>
          <th scope="col">Sl.No</th>
          <th scope="col">{{ "UserID" | translate }}</th>
          <th scope="col">{{ "Username" | translate }}</th>
          <th scope="col">{{ "Usercontact" | translate }}</th>
          <th scope="col">{{ "Joiningdate" | translate }}</th>
          <th scope="col">Account status</th>
          <th scope="col">Action</th>
        </tr>
      </thead>

      <tbody class="usertable">
        <tr *ngFor="let item of sellersLists; let i = index">
          <th scope="row">{{ calculateIndexSeller(i) }}</th>
          <td>{{ item.profileID }}</td>
          <td>{{ item.username }}</td>
          <td>{{ item.mobile }}</td>
          <td>{{ item.date | slice : 0 : 10 }}</td>
          <td *ngIf="item.delete === false">
            <span class="badge rounded-pill bg-success">Active</span>
          </td>
          <td *ngIf="item.delete === true">
            <span class="badge rounded-pill bg-danger">Inactive</span>
          </td>

          <td>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm"
              (click)="sellerDetails(item.profileID)"
            >
              User Details
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col"></div>
      <div class="col">
        <ul class="pagination">
          <li class="page-item" [class.disabled]="sellerStartPage === 0">
            <a
              class="page-link"
              (click)="sellerPrevPage()"
              style="cursor: pointer"
              >Previous</a
            >
          </li>
          <li
            class="page-item"
            *ngFor="
              let page of sellerPages.slice(sellerStartPage, sellerEndPage)
            "
            [class.active]="sellerCurrentPage === page"
          >
            <a
              class="page-link"
              (click)="sellerGoToPage(page)"
              style="cursor: pointer"
              >{{ page }}</a
            >
          </li>
          <li
            class="page-item"
            [class.disabled]="
              sellerCurrentPage === sellerTotalPages || sellerTotalPages <= 15
            "
          >
            <a
              class="page-link"
              (click)="sellerNextPage()"
              style="cursor: pointer"
              >Next</a
            >
          </li>
        </ul>
      </div>
      <div class="col"></div>
    </div>
    <h5 class="nodata" *ngIf="sellersLists.length === 0">noDataToShow</h5>
  </div>
</div>
