import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DropdownstateService } from 'src/app/dropdownstate.service';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth.service';

const ADMIN_URL = environment.adminUrl;

interface SellerResponse {
  sellers: any;
  currentPage: number;
  totalPages: number;
}

@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.css'],
})

export class UserdetailsComponent {
  UserAnalytics: any;
  totalUsers: number;
  deletedUsers: number;
  activeUsers: number;

  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    public dropdownService: DropdownstateService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    // this.authUser();
    this.fetchUserAnalytics();
    this.fetchSeller();
  }

  calculateIndexSeller(i: number): number {
    return (this.sellerCurrentPage - 1) * 15 + i + 1;
  }
  sellerCurrentPage = this.dropdownService.sellerCurrentPage || 1;
  sellersLists: any[] = [];
  sellerTotalPages = 0;
  sellerPages: number[] = [];
  sellerStartPage: number = this.dropdownService.sellerStartPage;
  sellerEndPage: number = this.dropdownService.sellerEndPage;

  fetchSeller() {
    console.log('services clicked');
    // console.log(this.productStatus,this.productCategory)
    const params = new HttpParams().set(
      'page',
      this.sellerCurrentPage.toString()
    ); // Pass the current page number
    // .set('approvalStatus', this.approvalStatus)
    //.set('serviceCategory', this.serviceCategory)
    //.set('serviceStatus', this.serviceStatus)
    //.set('isApprovedServiceStatus', this.isApprovedServiceStatus);

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get<SellerResponse>(ADMIN_URL + '/seller', { params, headers })
      .subscribe(
        (response) => {
          console.log(response);
          this.sellersLists = response.sellers;
          this.sellerCurrentPage = response.currentPage;
          this.sellerTotalPages = response.totalPages;
          this.sellerPages = Array.from(
            { length: this.sellerTotalPages },
            (_, index) => index + 1
          );
        },
        (error) => {
          console.error('Error:', error);
        }
      );
  }

  totalPostCount: number;
  fetchSellerPostCount(profileID: string) {
    console.log(profileID);
    const token = this.authService.getToken();

    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // Assuming you have an API endpoint to fetch the post count for a specific seller
    const url = `${ADMIN_URL}/getTotalposts/${profileID}`;
    return this.http.get(url, { headers });
  }

  sellerPrevPage(): void {
    console.log(this.sellerStartPage);

    if (this.sellerCurrentPage > 1) {
      this.dropdownService.sellerStartPage--;
      this.dropdownService.sellerEndPage--;
      this.sellerStartPage = this.dropdownService.sellerStartPage;
      this.sellerEndPage = this.dropdownService.sellerEndPage;
      this.sellerCurrentPage--;
      this.fetchSeller();
    }
  }

  sellerGoToPage(page: number): void {
    console.log(this.sellerStartPage);
    this.dropdownService.sellerCurrentPage = page;
    this.sellerCurrentPage = page;
    this.fetchSeller();
  }

  sellerNextPage(): void {
    console.log('working');
    if (this.sellerCurrentPage < this.sellerTotalPages) {
      this.dropdownService.sellerStartPage++;
      this.dropdownService.sellerEndPage++;
      this.sellerStartPage = this.dropdownService.sellerStartPage;
      this.sellerEndPage = this.dropdownService.sellerEndPage;
      this.sellerCurrentPage++;
      this.fetchSeller();
    }
  }

  editSeller(sellerId: string, username: string) {
    console.log('edit', sellerId);
    this.router.navigate(['/seller-postings'], {
      queryParams: {
        sellerId: sellerId,
        username: username,
      },
    });
  }

  sellerDetails(sellerId: string) {
    this.router.navigate(['/seller-details'], {
      queryParams: {
        sellerId: sellerId,
      },
    });
  }

  // authUser() {
  //   const token = localStorage.getItem('token');
  //   if (!token) {
  //     this.router.navigate(['/login']);
  //   }
  //   const headers = new HttpHeaders({
  //     Authorization: token, // Include the token in the Authorization header
  //   });
  //   this.http.get(ADMIN_URL + '/verifyToken', { headers }).subscribe(
  //     (res) => {
  //       console.log('User is authenticated');
  //       if ((res as any).message === 'Invalid token') {
  //         this.router.navigate(['/login']);
  //       }
  //     },
  //     (err) => {
  //       // Token is invalid, redirect to the login page
  //       this.router.navigate(['/login']);
  //     }
  //   );
  // }

  //to fetch user analytics
  fetchUserAnalytics(): void {
    const token = this.authService.getToken();

    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    console.log('the UserAnalytics function is called');
    this.http.get(ADMIN_URL + `/UserAnalytics`, { headers }).subscribe(
      (data) => {
        this.UserAnalytics = data;
        this.totalUsers = this.UserAnalytics.totalCount;
        this.deletedUsers = this.UserAnalytics.deletedCount;
        this.activeUsers = this.UserAnalytics.activeCount;
      },
      (error) => {
        console.error('Error fetching orders:', error);
      }
    );
  }
}
