import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const ADMIN_URL = environment.adminUrl;
export interface ChangePasswordResponse {
  message: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  constructor(private router: Router, private http: HttpClient) {}

  ngOnInit() {
    this.authUser();
  }

  onLogin() {
    // Perform login logic here

    // Redirect to the admin page
    this.router.navigate(['/login']);
  }

  username: string = '';
  currentPassword: string = '';
  newPassword: string = '';
  ConfirmNewPassword: string = '';

  authUser() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
      return;
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });

    this.http.get<any>(ADMIN_URL + '/verifyToken', { headers }).subscribe(
      (response) => {
        console.log(response);
        if (response.message == 'Invalid token') {
          this.router.navigate(['/login']);
        }
        if (response.message == 'No token, authorization denied') {
          this.router.navigate(['/login']);
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  changePassword() {
    console.log(this.newPassword,this.ConfirmNewPassword)
    if (
      this.newPassword === '' ||
      this.username === '' ||
      this.ConfirmNewPassword === '' ||
      this.currentPassword === ''
    ) {
      return alert('All input fields are required');
    }
    if (this.newPassword !== this.ConfirmNewPassword) {
      return alert('Passwords does not match');
    }
    // Password regex pattern
    const userInput = this.newPassword;
    if (userInput.length < 8) {
      return alert('Password must be at least 8 characters long.');
    } else if (!/[A-Z]/.test(userInput) || !/[a-z]/.test(userInput)) {
      return alert('Password must contain at least one uppercase letter and one lowercase letter.');
    }

    const url = ADMIN_URL + `/user/change-password/${this.username}`;
    console.log(url);
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/login']);
    }
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    const formData = new FormData();
    formData.append('currentPassword', this.currentPassword);
    formData.append('newPassword', this.newPassword);

    return this.http
      .put<ChangePasswordResponse>(url, formData, { headers })
      .subscribe((res) => {
        console.log(res);
        if (res.message == 'Password changed successfully') {
          alert('Password changed successfully');
          return this.router.navigate(['/login']);
        }
        if (res.message == 'User not found') {
          return alert('User not found');
        }
        if (res.message == 'Current password is incorrect') {
          return alert('Current password is incorrect');
        }
      });
  }
}
