import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const ADMIN_URL = environment.adminUrl;

export interface UserCounts {
  totalCount: number;
  deletedCount: number;
  notDeletedCount: number;
}
export interface productsCounts {
  totalProducts: number;
  notApproved: number;
  Approved: number;
  activeProducts: number;
  inactiveProducts: number;
}
export interface serviceCounts {
  totalServices: number;
  notApprovedService: number;
  ApprovedServices: number;
  activeServices: number;
  inactiveServices: number;
}
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {

  totalUsers: number;
  deletedUsers: number;
  activeUsers: number;

  totalProducts: number;
  notApprovedProducts: number;
  ApprovedProducts: number;
  activeProducts: number;
  inactiveProducts: number;

  totalServices: number;
  notApprovedServices: number;
  ApprovedServices: number;
  activeServices: number;
  inactiveServices: number;
  totalViews:number=0;
  totalContacts:number=0;




ngOnInit(): void {
  this.authUser();
this.getUserCounts()
this.getProductsCounts()
this.getServicesCounts()

}
getViews(){
  this.getViewsCounts()
}
authUser() {
  const token = localStorage.getItem('token');
  if (!token) {
    this.router.navigate(['/login']);
  }
  const headers = new HttpHeaders({
    Authorization: token, // Include the token in the Authorization header
  });
  this.http.get(ADMIN_URL + '/verifyToken', { headers }).subscribe(
    (res) => {
      console.log('User is authenticated');
      if ((res as any).message === 'Invalid token') {
        this.router.navigate(['/login']);
      }
    },
    (err) => {
      // Token is invalid, redirect to the login page
      this.router.navigate(['/login']);
    }
  );
}
goBack() {
  // Navigate back in the router history
  this.location.back()
}

getUserCounts(): void  {
  const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
   this.http.get<UserCounts>(`${ADMIN_URL}/userCounts`,{headers})
  .subscribe((res)=>{
    console.log(res);
    this.totalUsers=res.totalCount;
    this.deletedUsers = res.deletedCount;
    this.activeUsers = res.notDeletedCount
  });
}

getProductsCounts(): void  {
  const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
  this.http.get<productsCounts>(`${ADMIN_URL}/productCounts`,{headers})
 .subscribe((res)=>{
   console.log(res);
   this.totalProducts=res.totalProducts;
   this.notApprovedProducts = res.notApproved;
   this.ApprovedProducts = res.Approved;
   this.activeProducts = res.activeProducts;
  this.inactiveProducts = res.inactiveProducts;
 });
}
getServicesCounts(): void  {
  const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
  this.http.get<serviceCounts>(`${ADMIN_URL}/serviceCounts`,{headers})
 .subscribe((res)=>{
   console.log(res);
   this.totalServices=res.totalServices;
   this.notApprovedServices = res.notApprovedService;
   this.ApprovedServices = res.ApprovedServices;
   this.activeServices = res.activeServices;
  this.inactiveServices = res.inactiveServices;
 });
}

  constructor(public router: Router,
    private http:HttpClient, private route:ActivatedRoute, private location: Location) {}
  onLogout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
  onAdminClick() {
    this.router.navigate(['/dashboard']);
  }
  onSettings() {
    // Perform login logic here
    console.log('hi');
    // Redirect to the admin page
    this.router.navigate(['/settings']);
  }
  getViewsCounts(){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    this.http.get<{ totalViews: number }>(`${ADMIN_URL}/totalViews`,{headers})
    .subscribe(
      (data) => {
        this.totalViews = data.totalViews;
        console.log("hello",this.totalViews);
      },
      (error) => {
        console.error(error);
      })
  }

  getContactsCount(){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: token, // Include the token in the Authorization header
    });
    this.http.get<{ totalContacts: number }>(`${ADMIN_URL}/totalContacts`,{headers})
    .subscribe(
      (data) => {
        this.totalContacts = data.totalContacts;
        console.log("hello",this.totalContacts);
      },
      (error) => {
        console.error(error);
      })
  }
}
