import { Component, OnInit } from '@angular/core';
import { TiffinDeliveryLocationsService } from './tiffin-delivery-locations.service';
import { environment } from 'src/environments/environment';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';

import Swal from 'sweetalert2';
const BACKEND_URL = environment.adminUrl;

@Component({
  selector: 'app-add-delivery-person',
  templateUrl: './add-delivery-person.component.html',
  styleUrls: ['./add-delivery-person.component.css']
})
export class AddDeliveryPersonComponent implements OnInit {
  tiffinLocations: any;
  selectedLocation:any=[];
  username:any=null;
  phoneNumber:any=null;
  keyword:any=null;

  // defaultLocation(){
  //   const defaultLocation = {
  //     address: 'Trivandrum medical college',
  //     latitude: 18.254,
  //     longitude: 15.1,
  //   };

  //   this.selectedLocation.push(defaultLocation.address)
  // }

  toggleSelected(location: any): void {
    if (location.selected) {
      this.selectedLocation.push(location.address);
    } else {
      const index = this.selectedLocation.findIndex((loc: any) => loc.id === location.id);
      if (index !== -1) {
        this.selectedLocation.splice(index, 1);
      }
    }
  }

  CKID: string;
  dbID: string;
  kitchenDetails: any; // Update the type as needed
  deliverableLocations: any;
  userImageURL: any; 
  
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.dbID = params['dbID'];
     
      // Now, you can use this.CKID in your component logic
      console.log('this.dbID', this.dbID);
      this.getKckDetails();
  
    });
    // this.defaultLocation()
    // this.tiffinLocations = this.TiffinDelveryLocationsService.tiffinLocations;
    
  }

  constructor(  private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,    private authService: AuthService,    private TiffinDeliveryLocationsService: TiffinDeliveryLocationsService) {}

    getKckDetails(): void {
      const ID = this.dbID;
  
      const token = this.authService.getToken();
      console.log('the token is ', token);
  
      console.log('the order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      console.log('the oder function is called');
      this.http
        .get(BACKEND_URL + `/kitchenDetailsDescription?id=${ID}`, { headers })
        .subscribe(
          (data) => {
            console.log('Fetched order details:', data);
            this.kitchenDetails = data;
            console.log('kitchenDetails:', this.kitchenDetails);
            this.deliverableLocations = this.kitchenDetails.deliverableLocations
  this.CKID=this.kitchenDetails.profileID
            console.log('this.CKID',this.CKID);
            this.getDeliveryPersonsByKitchenID();
          },
          (error) => {
            console.error('Error fetching order details:', error);
          }
        );
    }

  createDeliveryAccount() {
    if(this.selectedLocation.length==0||!this.username||!this.keyword||!this.phoneNumber){
      Swal.fire({
        icon: 'error',
        title: 'Fill all required data',
        text: 'Please fill all the required datas.'
      });
      return
    }
    console.log('createDeliveryAccount', this.selectedLocation, this.username, this.phoneNumber, this.keyword);

    const newDeliveryPerson = {
      username: this.username,
      keyword: this.keyword,
      phone: this.phoneNumber,
      assignedLocation: this.selectedLocation,
      assignedKitchen: this.CKID
      // KCKID: this.KCKID,
    };
 console.log('createDeliveryAccount', newDeliveryPerson);
    this.http.post<any>(`${BACKEND_URL}/delivery/create`, newDeliveryPerson)
      .subscribe(
        response => {
          console.log('Delivery person created successfully:', response.message);
          if (response.message === 'Delivery person created successfully') {
            Swal.fire({
              icon: 'success',
              title: 'User Created Successfully',
              text: 'The user has been created successfully.'
            }).then(() => {
              // Reload the window
              window.location.reload();
            });
          }
        },
        error => {
          console.error('Error creating delivery person:', error.error);
          if(error.error.message=="User already exists"){
            Swal.fire({
              icon: 'error',
              title: 'User Already Exists',
              text: 'The user with this phone number already exists.'
            });
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops an error occured',
              text: 'Internal server error.'
            });
          }
          // Handle error (e.g., display an error message)
        }
      );
  }



  
  existingDeliveryPerson: any 

  addNewDeliveryPerson: boolean = false
  checkExistingDeliveryPerson(): void {
    this.http.get<any>(`${BACKEND_URL}/checkDeliveryPhone?phone=${this.phoneNumber}`).subscribe(
      (response) => {
        this.existingDeliveryPerson = response.exists;
        if (this.existingDeliveryPerson) {
          Swal.fire({
            title: 'Delivery Person Exists',
            text: 'The delivery person already exists. Do you want to assign your kitchen to the delivery person?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, assign kitchen',
            cancelButtonText: 'No, cancel'
          }).then((result) => {
            if (result.isConfirmed) {
              this.assignKitchenToDeliveryPerson(this.phoneNumber,this.CKID);
            }
          });
        } else {
          this.addNewDeliveryPerson = true;
        }
        console.log("this.existingDeliveryPerson:",this.existingDeliveryPerson);
      },
      (error) => {
        console.error('Error fetching delivery persons:', error);
        // Handle error (e.g., display an error message)
      }
    );
  }


  assignKitchenToDeliveryPerson(phone, kitchenID): void {
    if (phone && kitchenID) {
      this.http.put<any>(`${BACKEND_URL}/assignKitchen`, { phone, kitchenID }).subscribe(
        (response) => {
          Swal.fire({
            title: 'Success',
            text: response.message,
            icon: 'success',
            confirmButtonText: 'OK'
          });
          this.getDeliveryPersonsByKitchenID()
        },
        (error) => {
          console.error('Error assigning kitchen:', error);
          Swal.fire({
            title: 'Error',
            text: 'An error occurred while assigning the kitchen.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
    } else {
     console.log('Please enter both phone number and kitchen ID.');
    }
  }



  deliveryPersons: any[] = [];
  getDeliveryPersons() {
    this.http.get<any>(`${BACKEND_URL}/deliveryPersons/all`).subscribe(
      (response) => {
        this.deliveryPersons = response.deliveryPersons;
        console.log(this.deliveryPersons);
      },
      (error) => {
        console.error('Error fetching delivery persons:', error);
        // Handle error (e.g., display an error message)
      }
    );
  }

  getDeliveryPersonsByKitchenID(): void {
    if (!this.CKID) {
      console.error('Kitchen ID is required');
      return;
    }
    
 
    
    this.http.get<any>(`${BACKEND_URL}/deliveryPersonsByKitchenID?kitchenID=${this.CKID}`, ).subscribe(
      (response) => {
        this.deliveryPersons = response.deliveryPersons;
        console.log(this.deliveryPersons);
      },
      (error) => {
        console.error('Error fetching delivery persons:', error);
        // Handle error (e.g., display an error message)
      }
    );
  }
  getAssignedLocations(person: any): string {
    return person.assignedLocations.join(', ');
  }

  deleteAccount(id: string) {
    const url = `${BACKEND_URL}/deleteAccount?id=${id}`;
  
    this.http.delete<any>(url)
      .subscribe(
        response => {
          // Display a SweetAlert with success message
          Swal.fire({
            icon: 'success',
            title: 'Account Deleted',
            text: 'The account has been deleted successfully.',
          }).then(() => {
            this.getDeliveryPersons(); // Reload data after successful deletion
          });
        },
        error => {
          // Display a SweetAlert with error message
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete account. Please try again later.',
          });
          console.error('Error deleting account:', error);
        }
      );
  }

  removeAccount(phone: string){
    const kitchenID= this.CKID;
    if (phone ) {
      this.http.put<any>(`${BACKEND_URL}/removeKitchen`, { phone ,kitchenID}).subscribe(
        (response) => {
          Swal.fire({
            title: 'Success',
            text: response.message,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            this.getDeliveryPersonsByKitchenID(); // Reload data after successful deletion
          });;
        },
        (error) => {
          console.error('Error removing kitchen:', error);
          Swal.fire({
            title: 'Error',
            text: 'An error occurred while assigning the kitchen.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
    } else {
     console.log('Please enter both phone number and kitchen ID.');
    }
  }
}
