<app-kckdashboard></app-kckdashboard>
<div class="main">
  <div class="container mt-4">
    <div class="row">
      <div class="col">
        <p class="header"><b>Account details</b></p>
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <div class="row">
      <div class="col card p-2">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th class="align-center" scope="col">Name</th>
              <th scope="col" class="align-center">Role</th>
              <th scope="col" class="align-center">Account status</th>
              <th scope="col" class="align-center">password</th>
              <th scope="col" class="align-center">Delete</th>
            </tr>
          </thead>
          <tbody *ngFor="let accountDetails of accountDetails; let i = index">
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td class="align-center">{{ accountDetails.username }}</td>
              <td class="align-center">{{ accountDetails.role }}</td>
              <td
                class="align-center"
                [class.active]="!accountDetails.accountDelete"
                [class.deleted]="accountDetails.accountDelete"
              >
                {{ accountDetails.accountDelete ? "deleted" : "active" }}
              </td>
              <td class="align-center">
                <button
                  type="button"
                  [disabled]="accountDetails.accountDelete"
                  class="btn btn-outline-primary"
                  (click)="passwordReset(accountDetails.username)"
                >
                  Reset
                </button>
               
              </td>
              <td class="align-center">
                <button
                  type="button"
                  class="btn btn-danger"
                  [disabled]="accountDetails.accountDelete"
                  (click)="deleteAccount(accountDetails.username)"
                >
                  delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
