
  <!------------------------------------------ Search starts here ---------------------------------------->
  <nav>
    <div class="navbar">
      <a class="nav-link">
        <i class="fa fa-user-circle fa-2x adminicon"></i>
        <h6 (click)="onAdminClick()" class="adminheading">Admin</h6>
        <button class="settingsbtn" (click)="onSettings()">
          <i class="fa fa-sliders settingsicon" aria-hidden="true"></i>
        </button>
      </a>
      <!-- <form onsubmit="event.preventDefault()" class="searchuser">
        <input class="formsearch" placeholder="Search" aria-label="Search" [(ngModel)]="searchInput" [ngModelOptions]="{ standalone: true }">
        <button class="btnsearch">Search UserId</button>
      </form> -->
      <div class="logout" (click)="onLogout()">
        <i class="fa fa-sign-out logouticon" aria-hidden="true"></i>
        <h6>Log Out</h6>
      </div>
      <img class="imglogo" src="../../../assets/logo.jpg" alt="Amigo" />
    </div>
  </nav>
  <div (click)="goBack()">
    <i class="fa fa-chevron-circle-left backButton" aria-hidden="true"></i>
  </div>

  <div class="container">
    <div class="container">
      <br>
      <h2 style="text-align: center; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; color: blue;">Seller Search</h2>
      <br>
      <div class="row">
        <div class="col-8">
          <input type="text" [(ngModel)]="searchTerm" placeholder="Search by Profile ID or Mobile" class="search-input form-control">
        </div>
        <div class="col-4">
          <button class="btn btn-primary search-input" (click)="searchSeller()"><i class="fa fa-search"></i> Search</button>
        </div>
      </div>

    </div>
    <br>
    <p style="font-weight: 600;">Search results:</p>

    <table class="table caption-top table-striped">


      <thead>
        <tr>
          <th scope="col">Sl.No</th>
          <th scope="col">Seller ID</th>
          <th scope="col">Seller Name</th>
          <th scope="col">Seller Contact</th>
          <th scope="col">Joining Date</th>
          <th scope="col">Account Status</th>
          <th scope="col">Postings</th>

          <th>{{ "action" | translate }}</th>
          <th>No of posts</th>
          <th>Reports</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of sellerSearch; let i = index">
          <th scope="row">{{i+1}}</th>
          <td>{{ item.profileID }}</td>
          <td>{{ item.username }}</td>
          <td>{{ item.mobile }}</td>
          <td>{{ item.date | slice : 0 : 10 }}</td>
          <td *ngIf="item.delete === false">
            <span class="badge rounded-pill bg-success">Active</span>
          </td>
          <td *ngIf="item.delete === true">
            <span class="badge rounded-pill bg-danger">Inactive</span>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm"
              (click)="editSeller(item.profileID, item.username)"
            >
              Posting Details
            </button>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm"
              (click)="sellerDetails(item.profileID)"
            >
              Seller Details
            </button>
          </td>
          <td style="font-weight: 600;">{{ item.postCount }}</td>
          <td [ngStyle]="{'color': item.reports.length > 0 ? 'red' : '', 'font-weight': item.reports.length > 0 ? '600' : '600'}">{{item.reports.length}}</td>
        </tr>
      </tbody>
    </table>
    <h5 class="nodata" *ngIf="sellerSearch.length === 0 && searched===true">
      No data to show
    </h5>
    <h5 class="nodata" *ngIf="!searched">
      Search for seller
    </h5>



  <!------------------------------------------ Search ends here ---------------------------------------->
