import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
const ADMIN_URL = environment.adminUrl;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}


  getSubscriptionsDetails(dataBaseID: string): Observable<any> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // Pass the dataBaseID as a query parameter
    const params = { dataBaseID: dataBaseID };
    return this.http.get<any>(`${ADMIN_URL}/subscriptionsDetails`, { headers, params });
  }
}
