<nav>
  <div class="navbar">
    <a class="nav-link">
      <i class="fa fa-user-circle fa-2x adminicon"></i>
      <h6 (click)="onAdminClick()" class="adminheading">Admin</h6>
      <button class="settingsbtn" (click)="onSettings()">
        <i class="fa fa-sliders settingsicon" aria-hidden="true"></i>
      </button>
    </a>
    <!-- <form onsubmit="event.preventDefault()" class="searchuser">
      <input class="formsearch" placeholder="Search" aria-label="Search" [(ngModel)]="searchInput" [ngModelOptions]="{ standalone: true }">
      <button class="btnsearch">Search UserId</button>
    </form> -->
    <div class="logout" (click)="onLogout()">
      <i class="fa fa-sign-out logouticon" aria-hidden="true"></i>
      <h6>Log Out</h6>
    </div>
    <img class="imglogo" src="../../../assets/logo.jpg" alt="Amigo" />
  </div>
</nav>
<div (click)="goBack()">
  <i class="fa fa-chevron-circle-left backButton" aria-hidden="true" ></i>
</div>
<br /><br />
<div class="container">
  <div class="row">
    <h2 id="userOverview">Users Overview</h2>
  </div>
</div>
    <br>
<div class="container">
  <div class="row overviews">
    <div class="col userCol">
      <p><b>Total No. of Users</b></p>
      <h2 >{{totalUsers}}</h2>
    </div>
    <div class="col userCol">
      <p><b>Active Users</b></p>
      <h2 style="color: rgb(9, 172, 9);">{{activeUsers}}</h2>

    </div>
    <div class="col userCol">
      <p><b>Deleted Users</b></p>
      <h2 style="color: red;">{{deletedUsers}}</h2>

    </div>
  </div>
</div>
<br>
<!-- product section -->

<div class="container">
  <div class="row">
    <h2 id="productOverview">Products Overview</h2>
  </div>
</div>
    <br>
<div class="container">
  <div class="row overviews">
    <div class="col userCol">
      <p><b>Total No. of Products</b></p>
      <h2 >{{totalProducts}}</h2>
    </div>
    <div class="col userCol">
      <p><b>Approved and Active</b></p>
      <h2 style="color: rgb(9, 172, 9);">{{ApprovedProducts}}</h2>

    </div>
    <div class="col userCol">
      <p><b>Waiting for Approval and Active</b></p>
      <h2 style="color: rgb(235, 125, 22);" >{{notApprovedProducts}}</h2>

    </div>
    <div class="col userCol">
      <p><b>Active Products</b></p>
      <h2 style="color: rgb(42, 108, 252);">{{activeProducts}}</h2>

    </div>
    <div class="col userCol">
      <p><b>Inactive Products</b></p>
      <h2 style="color: red;">{{inactiveProducts}}</h2>

    </div>
  </div>
</div>
<br>
<!-- Services section -->
<div class="container">
  <div class="row">
    <h2 id="serviceOverview">Services Overview</h2>
  </div>
</div>
    <br>
<div class="container">
  <div class="row overviews">
    <div class="col userCol">
      <p><b>Total No. of Services</b></p>
      <h2 >{{totalServices}}</h2>
    </div>

    <div class="col userCol">
      <p><b>Approved and Active</b></p>
      <h2 style="color: rgb(9, 172, 9);">{{ApprovedServices}}</h2>

    </div>
    <div class="col userCol">
      <p><b>Waiting for Approval and Active</b></p>
      <h2 style="color: rgb(235, 125, 22);" >{{notApprovedServices}}</h2>

    </div>
    <div class="col userCol">
      <p><b>Active Services</b></p>
      <h2 style="color: rgb(42, 108, 252);;">{{activeServices}}</h2>

    </div>
    <div class="col userCol">
      <p><b>Inactive Services</b></p>
      <h2 style="color: red;">{{inactiveServices}}</h2>
    </div>
  </div>
</div>
<br>
<!-- -----views section ----- -->
<div class="container">
  <div class="row">
    <h2>Views Overview</h2>
  </div>
</div>
<br>
<div class="container">
  <div class="row">
    <div class="row userCol">
      <p><b>No of product views</b></p>
      <div class="col-6">
        <button type="button" class="btn btn-primary" (click)="getViews()">Total views</button>
      </div>
      <div class="col-6">
        <h3 >{{totalViews!==0?totalViews:null}}</h3>
      </div>
    </div>
    <div class="row userCol">
      <p><b>No of Contacts</b></p>
      <div class="col-6">
        <button type="button" class="btn btn-primary" (click)="getContactsCount()">Total contacts</button>
      </div>
      <div class="col-6">
        <h3 >{{totalContacts!==0?totalContacts:null}}</h3>
      </div>
    </div>
  </div>
  </div>
