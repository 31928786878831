import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const BACKEND_URL = environment.adminUrl;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-view-delivery-persons',
  templateUrl: './view-delivery-persons.component.html',
  styleUrls: ['./view-delivery-persons.component.css'],
})
export class ViewDeliveryPersonsComponent implements OnInit {
  deliveryPersons: any[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getDeliveryPersons();
  }

  getDeliveryPersons() {
    this.http.get<any>(`${BACKEND_URL}/deliveryPersons/all`).subscribe(
      (response) => {
        this.deliveryPersons = response.deliveryPersons;
        console.log(this.deliveryPersons);
      },
      (error) => {
        console.error('Error fetching delivery persons:', error);
        // Handle error (e.g., display an error message)
      }
    );
  }

  getAssignedLocations(person: any): string {
    return person.assignedLocations.join(', ');
  }

  deleteAccount(id: string) {
    const url = `${BACKEND_URL}/deleteAccount?id=${id}`;
  
    this.http.delete<any>(url)
      .subscribe(
        response => {
          // Display a SweetAlert with success message
          Swal.fire({
            icon: 'success',
            title: 'Account Deleted',
            text: 'The account has been deleted successfully.',
          }).then(() => {
            this.getDeliveryPersons(); // Reload data after successful deletion
          });
        },
        error => {
          // Display a SweetAlert with error message
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete account. Please try again later.',
          });
          console.error('Error deleting account:', error);
        }
      );
  }
}
