import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  FormGroup,
  FormControl,
  AbstractControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common'; // Import DatePipe

import * as moment from 'moment';

import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

const ADMIN_URL = environment.adminUrl;

@Component({
  selector: 'app-kitchendetails',
  templateUrl: './kitchendetails.component.html',
  styleUrls: ['./kitchendetails.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
})
export class KitchendetailsComponent {
  // daysSelected: any[] = [];
  // event: any;
  // holidayDates: any;
  // tomorrow: Date;
  // isSelected = (event: any) => {
  //   const date =
  //     event.getFullYear() +
  //     '-' +
  //     ('00' + (event.getMonth() + 1)).slice(-2) +
  //     '-' +
  //     ('00' + event.getDate()).slice(-2);
  //   return this.daysSelected.find((x) => x == date) ? 'selected' : null;
  // };

  // select(event: any, calendar: any) {
  //   const date =
  //     event.getFullYear() +
  //     '-' +
  //     ('00' + (event.getMonth() + 1)).slice(-2) +
  //     '-' +
  //     ('00' + event.getDate()).slice(-2);
  //   const index = this.daysSelected.findIndex((x) => x == date);
  //   if (index < 0) this.daysSelected.push(date);
  //   else this.daysSelected.splice(index, 1);

  //   calendar.updateTodaysDate();
  // }

  // tomorrowDate() {
  //   const today = new Date();
  //   this.tomorrow = new Date(today);
  //   this.tomorrow.setDate(this.tomorrow.getDate() + 2);
  // }

  daysSelected: any[] = [];
  event: any;
  holidayDates: any;
  today: Date;
  tomorrow: Date;
  twoMonthsLater: Date;

  isSelected = (event: any) => {
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2);
    return this.daysSelected.find((x) => x == date) ? 'selected' : null;
  };

  select(event: any, calendar: any) {
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2);
    const index = this.daysSelected.findIndex((x) => x == date);
    if (index < 0) this.daysSelected.push(date);
    else this.daysSelected.splice(index, 1);

    calendar.updateTodaysDate();
  }

  tomorrowDate() {
    this.tomorrow = new Date(this.today);
    this.tomorrow.setDate(this.tomorrow.getDate() + 2);

    this.twoMonthsLater = new Date(this.today);
    this.twoMonthsLater.setMonth(this.today.getMonth() + 2);
    this.twoMonthsLater.setDate(0); // To get the last day of the month
  }



  saveKitchenLocation(): void {
    if(this.kitchenLatitude == null || this.kitchenLongitude == null){
      Swal.fire({
        title:"Missing Coordinates",
        text:"Please enter both latitude and longitude",
        icon:"warning"
      });
    }
    else{
      Swal.fire({
        title:"Do you want to update the kitchen location?",
        icon:"question",
        showCancelButton:true,
        confirmButtonText:"Yes",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      }).then((result)=>{
        if(result.isConfirmed) {
  
          const token = this.authService.getToken();
          const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  
          const locationData = {
            latitude: this.kitchenLatitude,
            longitude: this.kitchenLongitude
          };
  
          this.http
            .put(`${ADMIN_URL}/kitchen/updateLocation/${this.profileID}`, locationData, { headers })
            .subscribe(
              (response: any) => {
                console.log('Kitchen location updated successfully:', response);
                Swal.fire({
                  icon: 'success',
                  title: 'Kitchen location updated successfully!',
                  confirmButtonText: 'Okay',
                  confirmButtonColor: 'rgb(38 117 79)',
                });
  
                // Reset latitude and longitude after success
               
                
              },
              (error) => {
                console.error('Error updating kitchen location:', error);
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Error updating kitchen location',
                });
              }
            );
        }
      });
    }
  }
  

  //variables name
  dataBaseID: string;
  kitchenDetails: any;
  menuItems: any;
  orderDetails: any;
  profileID: string;
  minDate: Date;
  dbID: string;

  isSubNameDisabled: boolean = true; // Initially set to true to disable the input
  isPriceDisabled: boolean = true;
  ismaximumOrderDisabled: boolean = true;

  toggleSubNameEdit(item: any) {
    item.isSubNameDisabled = !item.isSubNameDisabled; // Toggle the disabled state
  }

  togglePriceEdit(item: any) {
    item.isPriceDisabled = !item.isPriceDisabled;
  }

  toggleMaximunOrdersEdit(item: any) {
    item.ismaximumOrderDisabled = !item.ismaximumOrderDisabled;
  }

  subName: string = '';
  range = new FormGroup({
    start: new FormControl<Date | null>(null, this.dateValidator()),
    end: new FormControl<Date | null>(null, this.dateValidator()),
  });
  deliveryCharges: number;

  bankDetails: any = {
    bankName: '',
    bankBranch: '',
    IFSCcode: '',
    accountNumber: '',
    gstNumber: '',
    legalEntityName: '',
  };

  private dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedDate: Date | null = control.value;
      if (selectedDate && selectedDate < this.minDate) {
        return { matStartDateInvalid: true };
      } else {
        return null;
      }
    };
  }

  maxSelectableStartDate(): Date | null {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    return currentDate;
  }

  maxSelectableEndDate(): Date | null {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 2); // Move to two days ahead
    return currentDate;
  }

  isDateDisabled(date: Date): boolean {
    // Determine whether the date should be disabled
    // For example, if the date is today or tomorrow
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    return date.getTime() <= tomorrow.getTime();
  }

  isEndDateSelected: boolean = false;

  endDateSelected(): void {
    this.isEndDateSelected = !!this.range.controls.end.value;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {

    this.today = new Date();
    this.tomorrowDate();
    // this.tomorrowDate();
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate() + 1);
    this.range.controls.start.statusChanges.subscribe((status) => {
      // If the start date is invalid, reset its value
      if (status === 'INVALID') {
        this.range.controls.start.setValue(null);
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.dataBaseID = params['dateBaseID'];
    });
    console.log('the profile id is ', this.dataBaseID);
    this.checkLogin();
    if (this.isAdmin() || this.isSuperAdmin()) {
      this.fetchOrderDetails();
    } else if (this.isKitchen()) {
      this.fetchOrderDetailsForKitchen();
    }
    // this.fetchOrderDetails();
    // this.fetchOrderDetailsForKitchen();
   
  }

  // add delivery locations for kitchen start
  address: string = '';
latitude: Number = null;
longitude: Number = null;
kitchenLatitude: Number = null;
kitchenLongitude: Number = null;
radius: Number=null;
tableToggle: boolean=false;
enable: boolean=true;




savedeliverableLocations() {
  if(this.address==''||this.latitude==null||this.longitude==null||this.radius==null){
    Swal.fire({
      title:"Some fields are missing",
      text:"Please enter all the fields",
      icon:"warning"
    })
  }
  else{
    Swal.fire({
      title:"Do you want to add this location",
      icon:"question",
      showCancelButton:true,
      confirmButtonText:"Yes",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result)=>{
      if(result.isConfirmed) {

  const token = this.authService.getToken();
  const addNewLocation=
  {
  address: this.address,
  latitude: this.latitude,
  longitude: this.longitude,
  radius: this.radius,
  enable: this.enable,
  }


  this.deliverableLocations.push(addNewLocation)
  
  // const deliverableLocations = [
  //   {
  //     address: 'test address',
  //     latitude: 76.1,
  //     longitude: 72.1,
  //     radius: 3,
  //     enable: true,
  //   },
  // ];
  const deliverableLocations= this.deliverableLocations
  console.log("deliverableLocations",this.deliverableLocations);
  // return
  
  const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  this.http
    .put(
      `${ADMIN_URL}/kitchen/adddeliverableLocations/${this.profileID}`,
      { deliverableLocations },
      {
        headers: headers,
      }
    )
    .subscribe(
      (response: any) => {
        console.log(
          'Kitchen location updated successfully:',
          response
        );
        // Handle success if needed
        Swal.fire({
          icon: 'success',
          title: 'Kitchen location added successfully!',
          // text: ' successfully.',
          confirmButtonText: 'Okay',
          confirmButtonColor: 'rgb(38 117 79)',
        });
        this.address = '';
        this.latitude = null;
        this.longitude = null;
        this.radius =null;
      },
      (error) => {
        console.error('Error updating kitchen location:', error);
        // Handle error if needed
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error updating kitchen location',
        });
      }
    );
    }
   })
 }
}

toggleLocationStatus(){
  const token = this.authService.getToken();
  console.log("New location data:",this.deliverableLocations)
  // this.deliverableLocations=temp

  const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  this.http
    .put(
      `${ADMIN_URL}/kitchen/adddeliverableLocations/${this.profileID}`,
      { deliverableLocations:this.deliverableLocations },
      {
        headers: headers,
      }
    )
      .subscribe(
        (response) => {
          console.log("Server response::", this.deliverableLocations);
          // Handle success response here
        },
        (error) => {
          console.error("Error updating locations:", error);
          // Handle error response here
        }
      );
}

deleteLocation(index){
  Swal.fire({
    title:"Are you sure",
    text:"You won't be able to revert this!",
    icon:"warning",
    showCancelButton:true,
    confirmButtonText:"Yes, Delete",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
  }).then((result)=> {
    if(result.isConfirmed){

      const token = this.authService.getToken();
      this.deliverableLocations.splice(index,1)
      console.log("New location data:",this.deliverableLocations)
      // this.deliverableLocations=temp

      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      this.http
        .put(
          `${ADMIN_URL}/kitchen/adddeliverableLocations/${this.profileID}`,
          { deliverableLocations:this.deliverableLocations },
          {
            headers: headers,
          }
        )
          .subscribe(
            (response) => {
              console.log("Server response::", this.deliverableLocations);
              // Handle success response here
            },
            (error) => {
              console.error("Error updating locations:", error);
              // Handle error response here
            }
          );
    }
    })
  }

  locationEnable(location: any, event: MouseEvent) {
    event.preventDefault(); // Prevent the checkbox from toggling immediately
  
    Swal.fire({
      title: `Do you want to ${location.enable ? "disable" : "enable"} this location?`,
      icon: "question",
      confirmButtonText: `Yes, ${location.enable ? "Disable" : "Enable"}`,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        location.enable = !location.enable; // Toggle the value on confirmation
        this.toggleLocationStatus()
        console.log("Updated state:", location.enable, this.deliverableLocations);
      }
    });
  }
  
  
  // add delivery locations for kitchen end

  checkLogin(): void {
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }

  isAdmin(): boolean {
    return this.authService.isAuthenticated() && this.authService.isAdmin();
  }

  isSuperAdmin(): boolean {
    return (
      this.authService.isAuthenticated() && this.authService.isSuperAdmin()
    );
  }

  isKitchen(): boolean {
    return this.authService.isAuthenticated() && this.authService.isKitchen();
  }


  deliverableLocations=[]
  fetchOrderDetails(): void {
    const ID = this.dataBaseID;

    const token = this.authService.getToken();
    console.log('the token is ', token);

    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    console.log('the oder function is called');
    this.http
      .get(ADMIN_URL + `/kitchenDetailsDescription?id=${ID}`, { headers })
      .subscribe(
        (data) => {
          console.log('Fetched order details:', data);
          this.kitchenDetails = data;
          console.log('kitchenDetails:', this.kitchenDetails);

          this.menuItems = this.kitchenDetails.menuItems;
          this.profileID = this.kitchenDetails.profileID;
          this.fetchKCKUserImages(this.profileID);
          if (
            this.kitchenDetails &&
            this.kitchenDetails.offers &&
            this.kitchenDetails.offers.offerName
          ) {
            this.offer.name = this.kitchenDetails.offers.offerName;
          }

          if (this.kitchenDetails && this.kitchenDetails.offers) {
            if (this.kitchenDetails.offers.offer1) {
              this.offer.offer1 = this.kitchenDetails.offers.offer1;
            }

            if (this.kitchenDetails.offers.offerpercentage1) {
              this.offer.offerpercentage1 =
                this.kitchenDetails.offers.offerpercentage1;
            }

            if (this.kitchenDetails.offers.offer2) {
              this.offer.offer2 = this.kitchenDetails.offers.offer2;
            }

            if (this.kitchenDetails.offers.offerpercentage2) {
              this.offer.offerpercentage2 =
                this.kitchenDetails.offers.offerpercentage2;
            }

            if (this.kitchenDetails.offers.offer3) {
              this.offer.offer3 = this.kitchenDetails.offers.offer3;
            }

            if (this.kitchenDetails.offers.offerpercentage3) {
              this.offer.offerpercentage3 =
                this.kitchenDetails.offers.offerpercentage3;
            }

            if (this.kitchenDetails.offers.offer4) {
              this.offer.offer4 = this.kitchenDetails.offers.offer4;
            }

            if (this.kitchenDetails.offers.offerpercentage4) {
              this.offer.offerpercentage4 =
                this.kitchenDetails.offers.offerpercentage4;
            }
          }

          if (this.kitchenDetails && this.kitchenDetails.deliveryCharge) {
            this.deliveryCharges = this.kitchenDetails.deliveryCharge;
          }

          if (
            this.kitchenDetails &&
            this.kitchenDetails.bankDetails &&
            this.kitchenDetails.bankDetails.bankName
          ) {
            console.log(
              'hjfasldhfksdahfiosdahfisda:::::::::::',
              this.kitchenDetails.bankDetails.bankName
            );
            this.bankDetails.bankName =
              this.kitchenDetails.bankDetails.bankName;
            this.bankDetails.bankBranch =
              this.kitchenDetails.bankDetails.bankBranch;
            this.bankDetails.IFSCcode =
              this.kitchenDetails.bankDetails.IFSCcode;
            this.bankDetails.accountNumber =
              this.kitchenDetails.bankDetails.accountNumber;
          }

          if (this.kitchenDetails && this.kitchenDetails.GSTINnumber) {
            this.bankDetails.gstNumber = this.kitchenDetails.GSTINnumber;
          }

          if (
            this.kitchenDetails &&
            this.kitchenDetails.disabledDate &&
            this.kitchenDetails.disabledDate.startDate
          ) {
            this.range.controls.start.setValue(
              new Date(this.kitchenDetails.disabledDate.startDate)
            );
          }

          if (
            this.kitchenDetails &&
            this.kitchenDetails.disabledDate &&
            this.kitchenDetails.disabledDate.endDate
          ) {
            this.range.controls.end.setValue(
              new Date(this.kitchenDetails.disabledDate.endDate)
            );
          }

          if (this.kitchenDetails && this.kitchenDetails.legalEntityName) {
            this.bankDetails.legalEntityName =
              this.kitchenDetails.legalEntityName;
          }

          if (this.kitchenDetails && this.kitchenDetails.KCKHolidays) {
            this.holidayDates = this.kitchenDetails.KCKHolidays;
          }
          if (
            this.kitchenDetails &&
            this.kitchenDetails.subscriptionOfferPercentage
          ) {
            this.subscriptionOfferPercentage =
              this.kitchenDetails.subscriptionOfferPercentage;
          }
          if (
            this.kitchenDetails &&
            this.kitchenDetails.subscriptionOfferPercentage2
          ) {
            this.subscriptionOfferPercentage2 =
              this.kitchenDetails.subscriptionOfferPercentage2;
          }


          if (
            this.kitchenDetails &&
            this.kitchenDetails.deliverableLocations
          ) {
          this.deliverableLocations=  this.kitchenDetails.deliverableLocations
          }

          if (this.kitchenDetails && this.kitchenDetails.location) {
            const [kitchenLongitude, kitchenLatitude] =
              this.kitchenDetails.location.coordinates;
              console.log('Kitchen Location:',  this.kitchenDetails.location);
           this.kitchenLongitude=kitchenLongitude;
           this.kitchenLatitude=kitchenLatitude;
              console.log('Kitchen Latitude:', kitchenLatitude);
            console.log('Kitchen Longitude:', kitchenLongitude);
          }

          console.log("deliverableLocations:", this.deliverableLocations)
          // if (this.kitchenDetails && this.kitchenDetails.invoicePrefix) {
          //   this.invoicePrefix = this.kitchenDetails.invoicePrefix;
          // }

          // if (this.kitchenDetails && this.kitchenDetails.invoiceSuffix) {
          //   this.invoicePrefix = this.kitchenDetails.invoiceSuffix;
          // }
          console.log('menuItems: ', this.menuItems);
          console.log('kitchen details', this.kitchenDetails);
          // this.fetchKCKOrderDetails();
        },
        (error) => {
          console.error('Error fetching order details:', error);
        }
      );
  }

  fetchOrderDetailsForKitchen(): void {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    console.log('the oder function is called');
    this.http
      .get(ADMIN_URL + `/kitchen/kitchenDetailsDescription`, { headers })
      .subscribe(
        (data) => {
          console.log('Fetched order details:', data);
          this.kitchenDetails = data;
          this.dataBaseID = this.kitchenDetails._id;
          this.menuItems = this.kitchenDetails.menuItems;
          this.profileID = this.kitchenDetails.profileID;
          this.fetchKCKUserImages(this.profileID)
          console.log('menuItems: ', this.menuItems);
          console.log('kitchen details', this.kitchenDetails);
          if (
            this.kitchenDetails &&
            this.kitchenDetails.offers &&
            this.kitchenDetails.offers.offerName
          ) {
            this.offer.name = this.kitchenDetails.offers.offerName;
          }

          if (this.kitchenDetails && this.kitchenDetails.offers) {
            if (this.kitchenDetails.offers.offer1) {
              this.offer.offer1 = this.kitchenDetails.offers.offer1;
            }

            if (this.kitchenDetails.offers.offerpercentage1) {
              this.offer.offerpercentage1 =
                this.kitchenDetails.offers.offerpercentage1;
            }

            if (this.kitchenDetails.offers.offer2) {
              this.offer.offer2 = this.kitchenDetails.offers.offer2;
            }

            if (this.kitchenDetails.offers.offerpercentage2) {
              this.offer.offerpercentage2 =
                this.kitchenDetails.offers.offerpercentage2;
            }

            if (this.kitchenDetails.offers.offer3) {
              this.offer.offer3 = this.kitchenDetails.offers.offer3;
            }

            if (this.kitchenDetails.offers.offerpercentage3) {
              this.offer.offerpercentage3 =
                this.kitchenDetails.offers.offerpercentage3;
            }

            if (this.kitchenDetails.offers.offer4) {
              this.offer.offer4 = this.kitchenDetails.offers.offer4;
            }

            if (this.kitchenDetails.offers.offerpercentage4) {
              this.offer.offerpercentage4 =
                this.kitchenDetails.offers.offerpercentage4;
            }
          }

          if (this.kitchenDetails && this.kitchenDetails.deliveryCharge) {
            this.deliveryCharges = this.kitchenDetails.deliveryCharge;
          }

          if (
            this.kitchenDetails &&
            this.kitchenDetails.bankDetails &&
            this.kitchenDetails.bankDetails.bankName
          ) {
            console.log(
              'hjfasldhfksdahfiosdahfisda:::::::::::',
              this.kitchenDetails.bankDetails.bankName
            );
            this.bankDetails.bankName =
              this.kitchenDetails.bankDetails.bankName;
            this.bankDetails.bankBranch =
              this.kitchenDetails.bankDetails.bankBranch;
            this.bankDetails.IFSCcode =
              this.kitchenDetails.bankDetails.IFSCcode;
            this.bankDetails.accountNumber =
              this.kitchenDetails.bankDetails.accountNumber;
          }

          if (this.kitchenDetails && this.kitchenDetails.GSTINnumber) {
            this.bankDetails.gstNumber = this.kitchenDetails.GSTINnumber;
          }

          if (
            this.kitchenDetails &&
            this.kitchenDetails.disabledDate &&
            this.kitchenDetails.disabledDate.startDate
          ) {
            this.range.controls.start.setValue(
              new Date(this.kitchenDetails.disabledDate.startDate)
            );
          }

          if (
            this.kitchenDetails &&
            this.kitchenDetails.disabledDate &&
            this.kitchenDetails.disabledDate.endDate
          ) {
            this.range.controls.end.setValue(
              new Date(this.kitchenDetails.disabledDate.endDate)
            );
          }

          if (this.kitchenDetails && this.kitchenDetails.legalEntityName) {
            this.bankDetails.legalEntityName =
              this.kitchenDetails.legalEntityName;
          }

          if (this.kitchenDetails && this.kitchenDetails.KCKHolidays) {
            this.holidayDates = this.kitchenDetails.KCKHolidays;
          }
          if (this.kitchenDetails && this.kitchenDetails.location) {
            const [kitchenLongitude, kitchenLatitude] =
              this.kitchenDetails.location.coordinates;
              console.log('Kitchen Location:',  this.kitchenDetails.location);
           this.kitchenLongitude=kitchenLongitude;
           this.kitchenLatitude=kitchenLatitude;
              console.log('Kitchen Latitude:', kitchenLatitude);
            console.log('Kitchen Longitude:', kitchenLongitude);
          }

          // this.fetchKCKOrderDetails();
        },
        (error) => {
          console.error('Error fetching order details:', error);
        }
      );
  }

  // fetchKCKOrderDetails(): void {
  //   console.log('the order function is called');

  //   const token = this.authService.getToken();

  //   console.log('the order function is called');
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   const url = `${ADMIN_URL}/orderDetailsByID?profileID=${this.profileID}`;

  //   this.http.get(url, { headers }).subscribe(
  //     (data: any) => {
  //       this.orderDetails = data;
  //       console.log('order details', this.orderDetails);
  //     },
  //     (error) => {
  //       console.error('Error fetching orders:', error);
  //     }
  //   );
  // }

  selectedItem: any = '';
  uploadedFiles: File[] = [];
  menuItem: any[] = [
    {
      name: 'Veg meals',
      availability: true,
      price: '',
      type: ['LUN'],
      maximumOrders: '',
    },

    {
      name: 'Non-veg meals',
      availability: true,
      price: '',
      type: ['LUN'],
      maximumOrders: '',
    },
    {
      name: 'Biriyani (Chicken)',
      availability: true,
      price: '',
      type: ['LUN'],
      maximumOrders: '',
    },
    {
      name: 'Cut fruits Combo',
      availability: true,
      price: '',
      type: ['LUN'],
      maximumOrders: '',
    },
    {
      name: 'Fibre rich lunch combo',
      availability: true,
      price: '',
      type: ['LUN'],
      maximumOrders: '',
    },
  ];

  clearForm() {
    this.newItem = {
      name: '',
      price: null,
      type: [],
    };
  }
  listingMenuItems: any[] = [];
  uploadedImages: File[] = [];
  isModalOpen = false;
  menuItemDropdown: boolean = false;
  newItem: any = {
    name: '',
    price: '',
    type: [],
    maximumOrders: '',
    remainingOrders: '',
  };
  subscriptionOfferPercentage: number;
  subscriptionOfferPercentage2: number;

  limitDigits(event: any) {
    const value = event.target.value;
    const maxDigits = 4;
    const sanitizedValue = value.replace(/[^0-9]/g, ''); // Remove non-digit characters
    if (sanitizedValue.length > maxDigits) {
      event.target.value = sanitizedValue.slice(0, maxDigits);
    } else {
      event.target.value = sanitizedValue;
    }
    this.newItem.price = event.target.value; // Update the bound property with the sanitized value
    console.log(
      'the price of the item atfer entering the price',
      this.newItem.price
    );
  }

  limitMaximumOrderDigits(event: any) {
    const value = event.target.value;
    const maxDigits = 4;
    const sanitizedValue = value.replace(/[^0-9]/g, ''); // Remove non-digit characters
    if (sanitizedValue.length > maxDigits) {
      event.target.value = sanitizedValue.slice(0, maxDigits);
    } else {
      event.target.value = sanitizedValue;
    }
    this.newItem.maximumOrders = event.target.value; // Update the bound property with the sanitized value
    this.newItem.remainingOrders = event.target.value;
    console.log(
      'the price of the item atfer entering the maximumOrder',
      this.newItem.maximumOrders,
      this.newItem
    );
  }

  handleImageUpload(event: any) {
    const files = event.target.files;

    if (files.length > 0) {
      const file = files[0];

      this.compressAndCropImage(file)
        .then((processedFile) => {
          const timestamp = Date.now();
          const newFileName = `${this.profileID}_${timestamp}`;
          const renamedFile = new File([processedFile], newFileName, {
            type: processedFile.type,
          });

          this.uploadedFiles = [renamedFile];
        })
        .catch((error) => {
          console.error('Image processing error:', error);
        });
    }
  }

  compressAndCropImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 800;
          const squareSize = 150;

          let width = image.width;
          let height = image.height;

          // Determine the size for square cropping
          const size = Math.min(width, height);
          const xOffset = (width - size) / 2;
          const yOffset = (height - size) / 2;

          // Set canvas size for square cropping
          canvas.width = squareSize;
          canvas.height = squareSize;

          const ctx = canvas.getContext('2d');

          // Perform square cropping
          ctx.drawImage(
            image,
            xOffset,
            yOffset,
            size,
            size,
            0,
            0,
            squareSize,
            squareSize
          );

          canvas.toBlob(
            (blob) => {
              const croppedFile = new File([blob], 'cropped_image.jpg', {
                type: blob.type,
              });
              resolve(croppedFile);
            },
            'image/jpeg',
            0.9
          );
        };
        image.src = event.target.result as string;
      };

      reader.onerror = (error) => {
        console.error('File reading error:', error);
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  addMenuItemdrop() {
    console.log(this.newItem);
    if (
      this.selectedItem == '' ||
      !this.newItem.price ||
      this.newItem.price == '' ||
      this.newItem.price == null ||
      this.newItem.maximumOrders == '' ||
      this.newItem.maximumOrders == null ||
      !this.newItem.maximumOrders
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter all required fields before saving!',
      });
      return;
    }
    // Check if the item already exists in the array
    const itemExists = this.listingMenuItems.some(
      (item) => item.name === this.selectedItem
    );

    if (itemExists) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An item with the same name already exists!',
      });
      return;
    }
    // Create a new object to hold the form data
    const newMenuItem = {
      name: this.selectedItem, // Assuming you want to use the selected item from the dropdown
      price: this.newItem.price,
      subName: this.subName,
      maximumOrders: this.newItem.maximumOrders,
      remainingOrders: this.newItem.maximumOrders,
      type: this.newItem.type,
      availability: true,
      imageName:
        this.uploadedFiles.length > 0 ? this.uploadedFiles[0].name : '',
    };

    console.log('newMenuItem: ', newMenuItem);

    // Push the new menu item to the menuItems array
    this.listingMenuItems.push(newMenuItem);
    console.log(this.listingMenuItems);

    this.uploadedImages.push(...this.uploadedFiles);
    console.log('the uploaded images :', this.uploadedImages);

    this.saveMenuItem(newMenuItem);

    this.uploadedFiles = [];

    // Optionally, you can reset the form fields after saving
    this.resetForm();

    // Optionally, you can hide the modal after saving
    this.hideModal();
  }

  hideModallisting() {
    this.resetForm();
    this.menuItemDropdown = false; // Set menuItemDropdown to false to hide the modal
  }

  resetForm() {
    this.selectedItem = '';
    this.newItem = { name: '', price: null, maximumOrders: null, type: [] };
    this.uploadedFiles = [];
    this.subName = '';
  }

  hideModal() {
    this.isModalOpen = false;
    this.clearForm();
    this.menuItemDropdown = false;
  }

  onPinCodeKeyDown(event: any): void {
    const value = event.target.value;
    const maxDigits = 3;
    const sanitizedValue = value.replace(/[^0-9]/g, ''); // Remove non-digit characters
    if (sanitizedValue.length > maxDigits) {
      event.target.value = sanitizedValue.slice(0, maxDigits);
    } else {
      event.target.value = sanitizedValue;
    }
  }

  offer: any = {
    name: '',
    offer1: '',
    offerpercentage1: '',
    offer2: '',
    offerpercentage2: '',
    offer3: '',
    offerpercentage3: '',
    offer4: '',
    offerpercentage4: '',
  };

  //---------------to save the new item post --------------------------------

  hideOffers() {}

  saveMenuItem(newMenuItem) {
    const token = this.authService.getToken();
    // console.log('the date picked is:', this.range.value);
    console.log(this.listingMenuItems);

    if (this.listingMenuItems.length === 0) {
      // Show SweetAlert when newItem is empty
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please add menu items before saving!',
      });
      return;
    }

    const formData = new FormData();
    // const menuItems = this.listingMenuItems;
    const menuItems = newMenuItem;
    // if(this.offer){
    //   formData.append('offer', JSON.stringify(this.offer));
    // }

    // Convert the array to a JSON string
    const menuItemsJSON = JSON.stringify(menuItems);
    formData.append('menuItems', menuItemsJSON);

    // Check if start and end dates have values and are valid date objects

    for (let i = 0; i < this.uploadedImages.length; i++) {
      formData.append('uploadedFiles', this.uploadedImages[i]);
    }

    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.http
      .put(`${ADMIN_URL}/kitchen/tiffinSaveMenu/${this.profileID}`, formData, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          console.log('Menu items updated successfully:', response);
          // Handle success if needed
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Menu items updated successfully.',
            confirmButtonText: 'Okay',
            confirmButtonColor: 'rgb(38 117 79)',
          }).then((result) => {
            if (result.isConfirmed) {
              // this.router.navigate(['/home']);
              this.clearForm();
              if (this.isAdmin() || this.isSuperAdmin()) {
                this.fetchOrderDetails();
              } else if (this.isKitchen()) {
                this.fetchOrderDetailsForKitchen();
              }
            }
          });
        },
        (error) => {
          console.error('Error updating menu items:', error);
          // Handle error if needed
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error updating menu items. Please try again.',
          });
        }
      );
  }

  saveSubscriptionOfferPercent() {
    const token = this.authService.getToken();
    const subscriptionOfferData = {
      subscriptionOfferPercentage: this.subscriptionOfferPercentage,
      subscriptionOfferPercentage2: this.subscriptionOfferPercentage2,
    };
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.http
      .put(
        `${ADMIN_URL}/kitchen/updateSubscriptionOffer/${this.profileID}`,
        subscriptionOfferData,
        {
          headers: headers,
        }
      )
      .subscribe(
        (response: any) => {
          console.log(
            'Subscription offer percentage updated successfully:',
            response
          );
          // Handle success if needed
          Swal.fire({
            icon: 'success',
            title: 'Subscription offer set successfully!',
            // text: ' successfully.',
            confirmButtonText: 'Okay',
            confirmButtonColor: 'rgb(38 117 79)',
          });
        },
        (error) => {
          console.error('Error updating subscription offer percentage:', error);
          // Handle error if needed
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error updating subscription offer percentage',
          });
        }
      );
  }
  // ------------------------------- to uploads items and images ends--------------------------------

  giveApproval() {
    const token = this.authService.getToken();
    const approvalStatus = 'true';
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('approvalStatus', approvalStatus)
      .set('profileID', this.profileID);

    Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to give approval?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, give approval!',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('the order function is called');
        console.log('headers', headers);
        console.log('params', params);

        const options = { headers, params };

        this.http
          .put(ADMIN_URL + '/giveApprovalForKitchen', {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
              this.fetchOrderDetails();
            },
            (error) => {
              console.log(error);
            }
          );
      }
    });
  }

  selectedStartDate: Date;
  selectedEndDate: Date;

  // Define filter function for start date
  startDateFilter = (date: Date): boolean => {
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    return date >= threeMonthsAgo;
  };

  // Define filter function for end date
  endDateFilter = (date: Date): boolean => {
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    return date >= threeMonthsAgo;
  };

  // Method to handle start date selection
  onStartDateSelected(event: MatDatepickerInputEvent<Date>): void {
    this.selectedStartDate = event.value;
    console.log('selectedStartDate: ', this.selectedStartDate);
  }

  // Method to handle end date selection
  onEndDateSelected(event: MatDatepickerInputEvent<Date>): void {
    this.selectedEndDate = event.value;
    console.log('selectedEndDate: ', this.selectedEndDate);
  }
  downloadInvoices() {
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    if (!this.selectedStartDate || !this.selectedEndDate) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select both start and end date!',
      });
      return;
    }

    const startDateStr = this.selectedStartDate;
    const endDateStr = this.selectedEndDate;

    console.log('startDateStr:', startDateStr);
    console.log('endDateStr:', endDateStr);
   
    this.http
      .get(
        `${ADMIN_URL}/downloadInvoicesExcel/${startDateStr}/${endDateStr}/${this.profileID}`,
        { headers: headers, responseType: 'blob' }
      )
      .subscribe(
        (response: any) => {
          if (response.type === 'application/json') {
            // Parse the JSON blob
            response.text().then((data: any) => {
              const errorObject = JSON.parse(data);
              console.log(errorObject);
              console.log('Error downloading invoices:', errorObject.error);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorObject.error
                  ? errorObject.error
                  : 'Failed to download invoices!',
              });
            });
          } else {
            const blob = new Blob([response], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }); // Change content type to Excel
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = 'invoices.xlsx'; // Change file extension to .xlsx for Excel
            link.click();
            window.URL.revokeObjectURL(downloadURL);
          }
        },
        (error) => {
          console.log('Error downloading invoices:', error.error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              error.error && error.error.error
                ? error.error.error
                : 'Failed to download invoices!',
          });
        }
      );
  }

  downloadSubscriptionInvoices() {
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    if (!this.selectedStartDate || !this.selectedEndDate) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select both start and end date!',
      });
      return;
    }

    const startDateStr = this.selectedStartDate;
    const endDateStr = this.selectedEndDate;
    
    this.http
      .get(
        `${ADMIN_URL}/downloadSubscriptionInvoicesExcel/${startDateStr}/${endDateStr}/${this.profileID}`,
        { headers: headers, responseType: 'blob' }
      )
      .subscribe(
        (response: any) => {
          if (response.type === 'application/json') {
            // Parse the JSON blob
            response.text().then((data: any) => {
              const errorObject = JSON.parse(data);
              console.log(errorObject);
              console.log('Error downloading invoices:', errorObject.error);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorObject.error
                  ? errorObject.error
                  : 'Failed to download invoices!',
              });
            });
          } else {
            const blob = new Blob([response], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }); // Change content type to Excel
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = 'invoices.xlsx'; // Change file extension to .xlsx for Excel
            link.click();
            window.URL.revokeObjectURL(downloadURL);
          }
        },
        (error) => {
          console.log('Error downloading invoices:', error.error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              error.error && error.error.error
                ? error.error.error
                : 'Failed to download invoices!',
          });
        }
      );
  }

  disapproval() {
    console.log('the order function is called this.profileID ', this.profileID);
    const token = this.authService.getToken();
    const approvalStatus = 'false';
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('approvalStatus', approvalStatus)
      .set('profileID', this.profileID);

    Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to remove approval?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove approval!',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('the order function is called');
        console.log('headers', headers);
        console.log('params', params);

        const options = { headers, params };

        this.http
          .put(ADMIN_URL + '/removeApprovalForKitchen', {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
              this.fetchOrderDetails();
            },
            (error) => {
              console.log(error);
            }
          );
      }
    });
  }

  saveBankDetails() {
    // Check if bankDetails is provided

    if (!this.bankDetails || !Object.keys(this.bankDetails).length) {
      Swal.fire('Error', 'Please provide bank details', 'error');
      return;
    }

    let bankDetailsFilled = false;

    // Check if any bank details field is filled
    for (const key in this.bankDetails) {
      if (this.bankDetails[key]) {
        bankDetailsFilled = true;
        break;
      }
    }

    // If any bank details field is filled, then check if all fields are filled
    if (bankDetailsFilled) {
      for (const key in this.bankDetails) {
        if (!this.bankDetails[key]) {
          Swal.fire('Error', `Please enter ${key}`, 'error');
          return;
        }
      }
    }

    // Set your headers
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    // Define the body of your request including bankDetails
    const body = {
      bankDetails: this.bankDetails, // Include bankDetails here
      profileID: this.profileID,
    };

    console.log('the body is ', body);

    // Make the HTTP request with headers and body
    this.http
      .put(ADMIN_URL + '/dashboard/addBankDetails', body, { headers })
      .subscribe(
        (data: any) => {
          Swal.fire(data.message);
          if (this.isAdmin() || this.isSuperAdmin()) {
            this.fetchOrderDetails();
          } else if (this.isKitchen()) {
            this.fetchOrderDetailsForKitchen();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  saveOffers() {
    // Check if bankDetails is provided

    // Set your headers
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    // Define the body of your request including bankDetails
    const body = {
      offer: this.offer,

      profileID: this.profileID,
    };

    console.log('the body is ', body);

    // Make the HTTP request with headers and body
    this.http
      .put(ADMIN_URL + '/dashboard/addOffers', body, { headers })
      .subscribe(
        (data: any) => {
          Swal.fire(data.message);
          if (this.isAdmin() || this.isSuperAdmin()) {
            this.fetchOrderDetails();
          } else if (this.isKitchen()) {
            this.fetchOrderDetailsForKitchen();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  saveDeliveryCharge() {
    // Check if bankDetails is provided

    if (!this.bankDetails || !Object.keys(this.bankDetails).length) {
      Swal.fire('Error', 'Please provide bank details', 'error');
      return;
    }

    let bankDetailsFilled = false;

    // Check if any bank details field is filled
    for (const key in this.bankDetails) {
      if (this.bankDetails[key]) {
        bankDetailsFilled = true;
        break;
      }
    }

    // If any bank details field is filled, then check if all fields are filled
    if (bankDetailsFilled) {
      for (const key in this.bankDetails) {
        if (!this.bankDetails[key]) {
          Swal.fire('Error', `Please enter ${key}`, 'error');
          return;
        }
      }
    }

    // Set your headers
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    // Define the body of your request including bankDetails
    const body = {
      profileID: this.profileID,

      deliveryCharge: this.deliveryCharges ? this.deliveryCharges : 0,
    };

    console.log('the body is ', body);

    // Make the HTTP request with headers and body
    this.http
      .put(ADMIN_URL + '/dashboard/addDeliveryCharge', body, { headers })
      .subscribe(
        (data: any) => {
          Swal.fire(data.message);
          if (this.isAdmin() || this.isSuperAdmin()) {
            this.fetchOrderDetails();
          } else if (this.isKitchen()) {
            this.fetchOrderDetailsForKitchen();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  setActiveStatus(menuItem: any) {
    const dbID = this.dataBaseID;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to activate this menu item.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, activate it!',
      cancelButtonText: 'No, keep it inactive'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http
          .put(`${ADMIN_URL}/dashboard/menuItem/${menuItem._id}/${dbID}`, { availability: true }, { headers })
          .subscribe(
            (data: any) => {
              Swal.fire(data.message, '', 'success');
              if (this.isAdmin() || this.isSuperAdmin()) {
                this.fetchOrderDetails();
              } else if (this.isKitchen()) {
                this.fetchOrderDetailsForKitchen();
              }
            },
            (error) => {
              console.error(error);
              Swal.fire('Error', 'An error occurred while activating the menu item.', 'error');
            }
          );
      }
    });
  }

  deActivateMenu(id: string) {
    const dbID = this.dataBaseID;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    // Display a confirmation dialog using SweetAlert
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to deactivate this menu item.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, deactivate it!',
      cancelButtonText: 'No, keep it active',
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with put request to deactivate
        this.http
          .put(`${ADMIN_URL}/dashboard/menuItem/${id}/${dbID}`, { availability: false }, { headers })
          .subscribe(
            (data: any) => {
              Swal.fire(data.message, '', 'success');
              if (this.isAdmin() || this.isSuperAdmin()) {
                this.fetchOrderDetails();
              } else if (this.isKitchen()) {
                this.fetchOrderDetailsForKitchen();
              }
            },
            (error) => {
              console.log(error);
              Swal.fire(
                'Error',
                'An error occurred while deactivating the menu item.'
              );
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked "No", do nothing
        Swal.fire('Cancelled', 'The operation was cancelled.', 'info');
      }
    });
  }

  removeHolidays(): void {
    const dbID = this.dataBaseID;
    const token = localStorage.getItem('token');
    // const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // Display SweetAlert confirmation
    Swal.fire({
      title: 'Are you sure you want to cancel holidays?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cancel holidays',
      confirmButtonColor: '#d33',
      cancelButtonText: 'No, keep holidays',
    }).then((result) => {
      // If user clicks on "Yes, cancel holidays" button
      if (result.isConfirmed) {
        // Send the HTTP request to remove holidays
        this.http
          .put<any>(`${ADMIN_URL}/kitchen/cancelHolidays/${dbID}`, { headers })
          .subscribe(
            (res) => {
              console.log('Holidays cancelled successfully:', res);
              // Handle success response, if needed
              Swal.fire(
                'Holidays Cancelled!',
                'Your holidays have been cancelled.',
                'success'
              ).then(() => {
                // Reload the page after successfully cancelling holidays
                // location.reload();
                if (this.isAdmin() || this.isSuperAdmin()) {
                  this.fetchOrderDetails();
                } else if (this.isKitchen()) {
                  this.fetchOrderDetailsForKitchen();
                }
              });
            },
            (error) => {
              console.error('Error cancelling holidays:', error);
              // Handle error response, if needed
              Swal.fire(
                'Error',
                'Failed to cancel holidays. Please try again later.',
                'error'
              );
            }
          );
      }
    });
  }

  //to edit remaining orders
  toggleInput(item: any) {
    item.isInputEnabled = !item.isInputEnabled;
  }

  updateSaveChange(
    id: string,
    orders: string,
    subName: string,
    price: string,
    maximumOrder: string
  ) {
    console.log(id, orders, subName, price);
    const dbID = this.dataBaseID;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to update this menu item.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        this.http
          .put(
            `${ADMIN_URL}/dashboard/remainingOrder/${id}/${dbID}/${orders}/${price}/${maximumOrder}`,
            { subName },
            { headers }
          )
          .subscribe(
            (data: any) => {
              Swal.fire(data.message, '', 'success');
              if (this.isAdmin() || this.isSuperAdmin()) {
                this.fetchOrderDetails();
              } else if (this.isKitchen()) {
                this.fetchOrderDetailsForKitchen();
              }
            },
            (error) => {
              console.log(error);
              Swal.fire(
                'Error',
                'An error occurred while updating the menu item.'
              );
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'The operation was cancelled.', 'info');
      }
    });
  }

  //to set holidays
  setHolidays() {
    if (this.daysSelected.length > 0) {
      const token = this.authService.getToken();
      // const selectedDatesString = this.daysSelected.join(',');
      const selectedDatesUTC = this.daysSelected.map((date) =>
        moment.utc(date).format()
      );
      const selectedDatesString = selectedDatesUTC.join(',');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('selectedDates', selectedDatesString)
        .set('profileID', this.profileID);

      console.log(this.daysSelected);
      console.log(selectedDatesString);

      Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to give set holidays?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('the order function is called');
          console.log('headers', headers);
          console.log('params', params);

          const options = { headers, params };

          this.http.put(ADMIN_URL + '/setHolidays', {}, options).subscribe(
            (data: any) => {
              Swal.fire(data.message);
              if (this.isAdmin() || this.isSuperAdmin()) {
                this.fetchOrderDetails();
              } else if (this.isKitchen()) {
                this.fetchOrderDetailsForKitchen();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
      });
    } else {
      Swal.fire('Please select the date');
    }
  }

  //Invoice Number------------------------
  invoicePrefix: string;
  invoiceSuffix: string;
  // saveInvoiceSettings() {
  //   console.log(this.invoicePrefix, this.invoiceSuffix);

  //   // Set your headers
  //   const token = localStorage.getItem('token');
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   // Define the body of your request including bankDetails
  //   const body = {
  //     invoicePrefix: this.invoicePrefix,
  //     invoiceSuffix: this.invoiceSuffix,
  //     profileID: this.profileID,
  //   };

  //   console.log('the body is ', body);

  //   // Make the HTTP request with headers and body
  //   this.http
  //     .put(ADMIN_URL + '/dashboard/updateInvoice', body, { headers })
  //     .subscribe(
  //       (data: any) => {
  //         Swal.fire(data.message);
  //         if (this.isAdmin() || this.isSuperAdmin()) {
  //           this.fetchOrderDetails();
  //         } else if (this.isKitchen()) {
  //           this.fetchOrderDetailsForKitchen();
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }


  saveInvoiceSettings() {
    if(this.invoiceSuffix|| this.invoicePrefix){
    // Display a confirmation alert
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to save the invoice settings?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Set your headers
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  
        // Define the body of your request including bankDetails
        const body = {
          invoicePrefix: this.invoicePrefix,
          invoiceSuffix: this.invoiceSuffix,
          profileID: this.profileID,
        };
  
        console.log('the body is ', body);
  
        // Make the HTTP request with headers and body
        this.http
          .put(ADMIN_URL + '/dashboard/updateInvoice', body, { headers })
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
              if (this.isAdmin() || this.isSuperAdmin()) {
                this.fetchOrderDetails();
              } else if (this.isKitchen()) {
                this.fetchOrderDetailsForKitchen();
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    });
  }
  else{
    Swal.fire('Please fill out invoice prefix or invoice suffix');
  }
  }
  
  

  //Image upload option for carousel starts
  defaultImages = 'assets/personImages/defaultimage.jpg';

  selectedImageUrls: (string | ArrayBuffer)[] = [];
  userImageData: File[] = [];
  userThumbNailImage: File | undefined;

  uploadButton1Enabled: boolean = true;
  uploadButton2Enabled: boolean = false;
  uploadButton3Enabled: boolean = false;

  productImageURL: [] = [];
  productImage1: string;
  productImage2: string;
  productImage3: string;
  // productImage4: string;
  // productImage5: string;
  // productImage6: string;

  checkProductImage: string = '';
  editProductDBID: any = undefined;

  
  onImageSelected(event, index) {
    console.log('Event is:', event);
    //Check file size less than 3 MB
    const file = event.target.files[0];
    console.log('file size', file.size);
    if (file.size > 3 * 1024 * 1024) {
      Swal.fire({
        icon: 'error',
        title: 'File size exceeded 3MB',
        text: 'Please select file size less than 3MB',
      });
      return;
    }

    this.compressImage(file, index)
      .then((compressedFile) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImageUrls[index] = reader.result;
        };
        reader.readAsDataURL(compressedFile as Blob);

        const compressedFileName = this.getCompressedImageName(index);
        const compressedImageFile = new File(
          [compressedFile as Blob],
          compressedFileName
        );
        this.userImageData[index] = compressedImageFile;

        if (index === 0) {
          this.compressFurtherImage(compressedImageFile)
            .then((furtherCompressedFile) => {
              this.userThumbNailImage = furtherCompressedFile;
            })
            .catch((error) => {
              console.error('Listing image compression error:', error);
            });
        }
      })
      .catch((error) => {
        console.error('Image compression error:', error);
      });
    if (index === 0) {
      this.uploadButton1Enabled = true;
      this.uploadButton2Enabled = !this.editProductDBID;
    } else if (index === 1) {
      this.uploadButton2Enabled = true;
      this.uploadButton3Enabled = !this.editProductDBID;
    } else if (index === 2) {
      this.uploadButton3Enabled = true;
      // Enable the upload button for the previous index (index - 1)
      this.uploadButton2Enabled = true;
    }
  }


  getCompressedImageName(index) {
    const profileID = this.profileID; // Replace with the actual profile ID variable
    return `${profileID}_image${index + 1}`;
  }
  compressImage(file, index) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const size = Math.min(image.width, image.height);
          const xOffset = (image.width - size) / 2;
          const yOffset = (image.height - size) / 2;
          const maxWidth = 800;
          const maxHeight = 800;
          let width = size;
          let height = size;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(
            image,
            xOffset,
            yOffset,
            size,
            size,
            0,
            0,
            width,
            height
          );

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], 'compressed_image.jpg');
              if (compressedFile.size > 300 * 1024) {
                const quality = (300 * 1024) / compressedFile.size;
                canvas.toBlob(
                  (furtherBlob) => {
                    const furtherCompressedFile = new File(
                      [furtherBlob],
                      'further_compressed_image.jpg'
                    );
                    resolve(furtherCompressedFile);
                  },
                  'image/jpeg',
                  quality
                );
              } else {
                resolve(compressedFile);
              }
            },
            'image/jpeg',
            0.9
          );
        };
        if (typeof event.target.result === 'string') {
          image.src = event.target.result;
        }
      };
      reader.onerror = (error) => {
        console.error('File reading error:', error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  compressFurtherImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          let width = image.width;
          let height = image.height;

          if (file.size > 300 * 1024) {
            const maxSize = 150;
            if (width > height) {
              height *= maxSize / width;
              width = maxSize;
            } else {
              width *= maxSize / height;
              height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob: Blob) => {
                const furtherCompressedFile = new File(
                  [blob],
                  this.profileID + '_thumbnailImage.jpg'
                );
                resolve(furtherCompressedFile);
              },
              'image/jpeg',
              1
            );
          } else {
            // If the image size is smaller or equal to 300 KB, perform only square cropping
            const size = Math.min(width, height);
            const xOffset = (width - size) / 2;
            const yOffset = (height - size) / 2;
            const squareSize = 150;

            canvas.width = squareSize;
            canvas.height = squareSize;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(
              image,
              xOffset,
              yOffset,
              size,
              size,
              0,
              0,
              squareSize,
              squareSize
            );

            canvas.toBlob(
              (blob: Blob) => {
                const croppedFile = new File(
                  [blob],
                  this.profileID + '_thumbnailImage.jpg'
                );
                resolve(croppedFile);
              },
              'image/jpeg',
              1
            );
          }
        };
        image.src = event.target.result;
      };
      reader.onerror = (error) => {
        console.error('Listing image file reading error:', error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }


  
  users: any;
  KCKID: string;
  userName: string;


  fetchKCKUserImages(profileID: string): void {
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    
    this.http.get(`${ADMIN_URL}/KCKGetUserForPost/${profileID}`, { headers }).subscribe(
      (response: any) => {
        this.users = response;
        console.log('this.users', this.users);
        this.KCKID = this.users.KCKID;
        this.userName = this.users.username;

        if (this.users.menuItems.length > 0) {
          this.listingMenuItems = this.users.menuItems;
        }
        this.productImage1 = this.users.userImageURL[0];
        this.productImage2 = this.users.userImageURL[1];
        this.productImage3 = this.users.userImageURL[2];
      },
      (error) => {
        console.error(error);
        // Handle error
      }
    );
}


saveKitchenImages(type) {
  const token = this.authService.getToken();
  const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  const profileID = this.kitchenDetails.profileID;
  const formData = new FormData();
  const userThumbNailImage = this.userThumbNailImage;
  const userImageData = this.userImageData;

  formData.append('userThumbNailImage', userThumbNailImage);

  for (let i = 0; i < this.uploadedImages.length; i++) {
    formData.append('uploadedFiles', this.uploadedImages[i]);
  }

  for (let i = 0; i < userImageData.length; i++) {
    formData.append('userImageData', userImageData[i]);
    console.log('images for edit', userImageData);
  }

  this.http
    .put(`${ADMIN_URL}/tiffinSaveMenu/${profileID}`, formData, { headers })
    .subscribe(
      (response) => {
        console.log('Images updated successfully:', response);

        if (type === 'save') {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Images updated successfully.',
            confirmButtonText: 'Okay',
            confirmButtonColor: 'rgb(38 117 79)',
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/home']);
            }
          });
        }
      },
      (error) => {
        console.error('Error updating Image items: ', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error updating images. Please try again.',
        });
      }
    );
}

goToAddDeliveryPerson(){


  this.router.navigate(['/add-delivery-person'], {
    queryParams: {
      profileID: this.profileID,
      dbID:this.dataBaseID
    },
  });
}


//Image upload option for carousel ends

}
